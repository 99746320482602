import Vue from 'vue';
import dayjs from 'dayjs';
import _ from 'lodash';
import store from '../store/store';
import 'dayjs/locale/vi';
const Swal = require('sweetalert2');
import { clientId, expires } from '../api/mock/enum';
import { isMobile } from 'mobile-device-detect';
import { Noti } from '../main';
import { APP_ENV, BASE_URL, FEATURE_HANDLER, FEATURE_SHARE_FB, SAFE_TIME_QR } from '../common/global';
import html2canvas from 'html2canvas';
import { eventTypeMessage } from '../common/global';
import AccountService from '../api/account';
import Constants from '../utils/constants';
import {
  countMax3dProCombine,
  countMax3dProCombo,
  generateMax3DProCombine,
  generateMax3DProCombo,
  convertArrayToObject,
  calcAmountKenoCombo,
  getCombinations,
  countNumberInList
  // genMaxGameNumber
} from '../utils/functions';
import storageHelper from 'storage-helper';
import { mapGetters } from 'vuex';

var updateLocale = require('dayjs/plugin/updateLocale');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.updateLocale('vi', {
  weekdays: [' Chủ nhật ', 'Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5', 'Thứ 6', 'Thứ 7'],
  weekdaysShort: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
  weekdaysMin: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7']
});

const BREAK_POINT_DESKTOP = 950;
const TZ_WHITE_LIST = ['Asia/Saigon', 'Asia/Ho_Chi_Minh', 'Asia/Bangkok'];

Vue.mixin({
  updated() {},
  computed: {
    showDownload() {
      return true;
    },
    constants() {
      return Constants;
    },
    FEATURE_HANDLER() {
      return FEATURE_HANDLER;
    },
    isMobileApp() {
      return window && !!window.ReactNativeWebView;
    },
    isTimezoneWhiteList() {
      const tz = dayjs.tz.guess();
      return TZ_WHITE_LIST.includes(tz);
    },
    isReview() {
      return !!this.$store.getters.getterReview || !this.isTimezoneWhiteList;
    },
    checkHideAssociateProduction() {
      //Duy return false hoặc tìm kiếm các hàm liên quan
      return false; // !!window.ReactNativeWebView && APP_ENV === 'production';
    },
    bannerTime: {
      get() {
        return this.$store.getters.getterBannerTime;
      },
      set(newVal) {
        this.$store.commit('setBannerTime', newVal);
      }
    },
    compareTime() {
      if (!this.bannerTime) return false;
      const { from_date, to_date } = this.bannerTime;
      return (
        new CustomDate(from_date).getTime() < new CustomDate().getTime() &&
        new CustomDate().getTime() < new CustomDate(to_date).getTime()
      );
    },
    isPlayTrialModeMobile() {
      return isMobile && this.$store.getters.getterPlayTrial;
    },
    currentTrialStep: {
      get() {
        return this.$store.getters.getterCurrentTrialStep;
      },
      set(newVal) {
        this.$store.commit('setCurrentTrialStep', newVal);
      }
    },
    isShowConfirmPayment: {
      get() {
        return this.$store.getters.getterIsShowConfirmPayment;
      },
      set(newVal) {
        storageHelper.setItem('is_show_confirm_payment', newVal);
        this.$store.commit('setIsShowConfirmPayment', newVal);
      }
    },
    isShowOverlayTrial() {
      return this.$store.getters.getterShowOverlay && this.$store.getters.getterPlayTrial;
    },
    isSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    },
    isPaymentIntegration() {
      return this.$store.getters.getterPaymentIntegration;
    },
    isAllowPopupOverflowIframe() {
      return this.$store.getters.getterAllowPopupOverflowIframe;
    },
    isExistModalOpen: {
      get() {
        return this.$store.getters.getterIsExistModalOpen;
      },
      set(newVal) {
        this.$store.commit('setIsExistModalOpen', newVal);
      }
    },
    isExistModalOpenNormal: {
      get() {
        return this.$store.getters.getterIsExistModalOpenNormal;
      },
      set(newVal) {
        this.$store.commit('setIsExistModalOpenNormal', newVal);
      }
    },
    depositBalance: {
      get() {
        return this.$store.getters.getterDepositBalance;
      },
      set(newVal) {
        this.$store.commit('setDepositBalance', newVal);
      }
    },
    pendingBalance: {
      get() {
        return this.$store.getters.getterPendingBalance;
      },
      set(newVal) {
        this.$store.commit('setPendingBalance', newVal);
      }
    },
    rewardBalance: {
      get() {
        return this.$store.getters.getterRewardBalance;
      },
      set(newVal) {
        this.$store.commit('setRewardBalance', newVal);
      }
    },
    accountId() {
      return this.$store.getters.getterAccountId;
    },
    isWeluckMode() {
      return this.$store.getters.getterIsWeluckMode;
    },
    isSinglePeriodMode() {
      return false;
    },
    isShowShareFB() {
      return !!+FEATURE_SHARE_FB;
    },
    gameUrlByHandler() {
      if (!this.$store.getters.getterGameUrl) return {};
      return convertArrayToObject(this.$store.getters.getterGameUrl, 'slug');
    },
    userCampaignConfig() {
      return this.$store.getters.getterUserInfo?.campaign_config || {};
    },
    statisticKenoResult: {
      get() {
        return this.$store.getters.getterStatisticKenoResult;
      },
      set(newVal) {
        this.$store.commit('setStatisticKenoResult', newVal);
      }
    },
    maxGameLatestResult: {
      get() {
        return this.$store.getters.getterMaxGameLatestResult;
      },
      set(newVal) {
        this.$store.commit('setMaxGameLatestResult', newVal);
      }
    },
    isMobileScreen() {
      return isMobile;
    },
    isProduction() {
      return APP_ENV === 'production';
    },
    isPartnerMode() {
      return !!this.getterPartnerData?.id && !this.getterConfig?.disable_partner_mode;
    },
    isThanhCo() {
      return window.location.host.includes('thanhco');
    },
    ...mapGetters('partner', ['getterPartnerData', 'getterConfig'])
  },

  methods: {
    countMax3dProCombine,
    countMax3dProCombo,
    generateMax3DProCombine,
    generateMax3DProCombo,
    calcAmountKenoCombo,
    getCombinations,
    countNumberInList,
    onAccountLinkProfile({ _aff_sid, token, _aff_network, account_id }) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch('onAccountLinksWithId', { _aff_sid, token, _aff_network, account_id })
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Liên kết tài khoản thành công',
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              resolve();
            });
          })
          .catch((error) => {
            if (!error.response.data.status) {
              let objError = error.response.data.data;
              if (objError.code === '002.001.042') {
                Swal.fire({
                  icon: 'error',
                  title: 'Tài khoản này đã được liên kết. Vui lòng tạo liên kết với tài khoản khác!',
                  showConfirmButton: false,
                  timer: 5000
                });
                reject();
                return;
              }
              Swal.fire({
                icon: 'error',
                title: 'Liên kết tài khoản thất bại',
                showConfirmButton: false,
                timer: 2000
              }).console.error(error);
              reject();
            }
          })
          .finally(() => {
            reject();
          });
      });
    },

    onAssociateOauth(location, decodeUrlSaveextra, tokenInfoOauth2, infoOauth2, isHome = false) {
      return new Promise((resolve) => {
        if (isHome) {
          this.$store.dispatch('destroyToken', true);
          this.$store.commit('setIsCheckCodeError', true);
        }

        this.onBoxModalSaveextra(decodeUrlSaveextra, location, clientId);

        resolve(true);
      });
    },
    sendMessageToApp(message) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(message, window.origin);
      }
    },
    onBoxModalSaveextra(decodeUrlSaveextra, location) {
      let url = `${decodeUrlSaveextra}/oauth-login/?redirectUrl=${encodeURIComponent(location)}&clientID=${clientId}`;

      if (window.ReactNativeWebView) {
        this.sendMessageToApp(JSON.stringify({ event: 'OPEN_DEEP_LINK', data: { url } }));
        return;
      }

      let w = 1280;
      let h = 720;
      const y = window.top.outerHeight / 2 + window.top.screenY - h / 2;
      const x = window.top.outerWidth / 2 + window.top.screenX - w / 2;

      let newWindow = window.open(
        url,
        'name',
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
      );

      if (window.focus) {
        newWindow.focus();
      }
    },

    async onAfterAccountLinks({ _aff_sid, token, _aff_network }, isCheck = false, isShowLoginSuccess = true) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch('onAccountLinks', { _aff_sid, token, _aff_network })
          .then(async (resp) => {
            if (!resp.data && isCheck) return reject();
            let data = await this.$store.dispatch('onAfterRetrieveToken', resp.data.data);
            if (data) {
              this.getUserInfoLogin();
              this.onAfterLogin(isShowLoginSuccess);
            }
            resolve(resp);
          })
          .catch((resp) => {
            reject(resp);
          });
      });
    },

    async onAfterPutAccountLinks({ _aff_sid, token, _aff_network }, isCheck = false) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch('onPutAccountLinks', { _aff_sid, token, _aff_network })
          .then(async (resp) => {
            if (!resp.data && isCheck) return reject();
            let data = await this.$store.dispatch('onAfterRetrieveToken', resp.data.data);
            if (data) {
              this.getUserInfoLogin();
            }
            resolve(resp);
          })
          .catch((resp) => {
            reject(resp);
          });
      });
    },

    getMobileThresshold() {
      return BREAK_POINT_DESKTOP - 1;
    },
    formatPrice(value, currency = 'đ') {
      if (value !== undefined && value !== null) {
        let val = (value / 1).toFixed(0).replace('.', ',');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + currency;
      }
      return 0 + currency;
    },
    revertPrice(value) {
      return +value?.replace(/[^0-9]/g, '');
    },
    getTimeZone(time) {
      if (time !== undefined && time !== null) {
        var res = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return dayjs(
          time.toLocaleString('vi', {
            timeZone: res
          })
        ).format('HH:mm D/M/YYYY');
      }
      return '';
    },
    getOnlyTime(time, format = 'HH:mm') {
      if (time !== undefined && time !== null) {
        var res = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return dayjs(
          time.toLocaleString('vi', {
            timeZone: res
          })
        )
          .locale('vi')
          .format(format);
      }
      return '';
    },
    getOnlyDate(time, format = 'D/M/YYYY') {
      if (time !== undefined && time !== null) {
        var res = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return dayjs(
          time.toLocaleString('vi', {
            timeZone: res
          })
        )
          .locale('vi')
          .format(format);
      }
      return '';
    },
    getDay(time) {
      if (time !== undefined && time !== null) {
        return dayjs(time)
          .locale('vi')
          .format('dddd');
      }
      return '';
    },
    formatDate(time, format = 'D/M/YYYY') {
      if (time !== undefined && time !== null) {
        return dayjs(time)
          .locale('vi')
          .format(format);
      }
      return '';
    },
    formatString(string, data) {
      let result = string;

      Object.keys(data).forEach((key) => {
        if (key === 'lottery_time') {
          data[key] = this.getOnlyDate(data[key]) + ' ' + this.getOnlyTime(data[key]);
        }
        if (key === 'last_four_serial') {
          data[key] = 'XXXX-' + data[key];
        }
        if (key === 'reward_amount') {
          data[key] = this.formatPrice(data[key]);
        }

        if (key === 'game_name' && typeof data[key] === 'string') {
          data[key] = data[key].toLowerCase();
        }

        result = result.replace(`[${key}]`, data[key]);
      });

      return result;
    },
    getHour(value) {
      return _.parseInt(value.split(/ |:/)[1]);
    },
    getMinute(value) {
      return _.parseInt(value.split(/ |:/)[2]);
    },
    getSecond(value) {
      return _.parseInt(value.split(/ |:/)[3]);
    },
    checkTimer(obj) {
      if (!obj?.lottery_time) {
        return false;
      }
      const timestamp = new CustomDate(obj.lottery_time).getTime();
      let now = CustomDate.now();
      let safeTime = timestamp - (this.$store.getters.getterMetadataKeno.count_down + 5 || 0) * 1000;

      if (this.isPaymentIntegration) {
        safeTime -= SAFE_TIME_QR * 1000;
      }

      return now <= safeTime;
    },
    checkTimerPower(obj, metaData) {
      const timestamp = new CustomDate(obj.lottery_time).getTime();
      let now = CustomDate.now();
      return now <= timestamp - (metaData.count_down || 0) * 1000;
    },
    filterPowerPeriod(array, count_down) {
      const result = array.filter((f) => {
        const timestamp = new CustomDate(f.lottery_time).getTime();
        let now = CustomDate.now();
        return now <= timestamp - (count_down || 0) * 1000;
      });
      if (result.length > 0) {
        return result;
      }
      return [
        {
          lottery_time: this.getFulltime(new CustomDate())
        }
      ];
    },
    findObjectPower(array, metaData) {
      const result = array.find((f) => {
        const timestamp = new CustomDate(f.lottery_time).getTime();
        let now = CustomDate.now();

        return now <= timestamp - (metaData.count_down || 0) * 1000;
      });
      if (result) {
        return result;
      }
      return {
        lottery_time: this.getFulltime(new CustomDate())
      };
    },
    checkTimerSecond(obj) {
      let now = new CustomDate();
      return (
        (this.getHour(obj.lottery_time) == now.getHours() &&
          this.getMinute(obj.lottery_time) - now.getMinutes() < 10 &&
          this.getMinute(obj.lottery_time) - now.getMinutes() > 0) ||
        this.getHour(obj.lottery_time) > now.getHours()
      );
    },
    findObjectKeno(array) {
      const result = array.find((f) => {
        const timestamp = new CustomDate(f.lottery_time).getTime();
        let now = CustomDate.now();
        let safeTime = timestamp - (this.$store.getters.getterMetadataKeno.count_down || 0) * 1000;
        if (this.isPaymentIntegration) {
          safeTime -= SAFE_TIME_QR * 1000;
        }
        return now <= safeTime;
      });
      if (result) {
        return result;
      }
      return {
        lottery_time: this.getFulltime(new CustomDate())
      };
    },
    filterKenoPeriod(array) {
      const result = array.filter((f) => {
        const timestamp = new CustomDate(f.lottery_time).getTime();
        let now = CustomDate.now();
        return now <= timestamp - (store.getters.getterMetadataKeno.count_down || 0) * 1000;
      });
      if (result.length > 0) {
        return result;
      }
      return [
        // {
        //   lottery_time: this.getFulltime(new CustomDate())
        // }
      ];
    },
    getFulltime(date, format = 'YYYY-MM-DD HH:mm:ss') {
      return dayjs(date).format(format);
    },
    sortDial(array) {
      return array.sort(function(a, b) {
        return parseInt(a.period_no) - parseInt(b.period_no);
      });
    },
    checkExistTime(arrayDate) {
      let now = new CustomDate();
      return arrayDate.length > 0
        ? now.toDateString() === new CustomDate(arrayDate.pop().lottery_time).toDateString()
        : false;
      // if(now.)
      //  this.getOnlyDate(date);
    },
    replaceAll(string, search, replace) {
      return string.replace(new RegExp(search, 'g'), replace);
    },
    updateTimer(countDownDate) {
      this.updateTimerText(countDownDate - new CustomDate().getTime());
    },
    updateTimerText(milliseconds) {
      if (milliseconds > 0) {
        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));
        var hours = Math.floor((milliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60)) + 10;
        var seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);

        // Display the result in the element with id="elementId"
        // eslint-disable-next-line no-unused-vars

        let str = '';
        if (days > 0) str += days + ' ngày ';
        if (hours > 0) str += hours + ':';

        str += this.padStart(minutes + '', 2, '0') + ':';
        str += this.padStart(seconds + '', 2, '0');
        return str;
        // $("#" + elementId).text(str);
      }
    },
    timerToString(timer) {
      if (timer > 0) {
        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(timer / (60 * 60 * 24));
        var hours = Math.floor((timer % (60 * 60 * 24)) / (60 * 60));
        var minutes = Math.floor((timer % (60 * 60)) / 60);
        var seconds = Math.floor(timer % 60);

        // Display the result in the element with id="elementId"
        // eslint-disable-next-line no-unused-vars

        let str = '';
        if (days > 0) str += days + ' ngày ';
        if (hours > 0) str += hours + ':';

        str += this.padStart(minutes + '', 2, '0') + ':';

        str += this.padStart(seconds + '', 2, '0');

        return str;
        // $("#" + elementId).text(str);
      }
    },
    createTimer(dateTime, elementId, hrefId) {
      var countDownDate;
      if (dateTime != null) {
        dateTime = this.replaceAll(dateTime, '-', '/');

        // Set the date we're counting down to
        countDownDate = new CustomDate(dateTime).getTime();
      } else {
        countDownDate = new CustomDate().getTime();
      }

      // Update the count down every 1 second
      setInterval(() => {
        this.updateTimer(countDownDate, elementId, hrefId);
      }, 1000);

      this.updateTimer(countDownDate, elementId, hrefId);
    },
    padStart(string, length, padString) {
      while (string.length < length) string = padString + string;
      return string;
    },
    formatNumber(number) {
      if (number) {
        return number < 10 ? `0${number}` : number;
      }
      return '0';
    },
    getRouteStatisticalByGameId(gameId) {
      return store.getters.getterStatisticalUrl.filter((p) => p.id === gameId)[0].url;
    },
    calculateTimer(gameData) {
      if (!gameData) {
        return 0;
      }
      const { periodList, metadata } = gameData;
      const dateStr = new CustomDate().toISOString();

      let item = periodList[0] || {
        lottery_time: dateStr
      };
      let lottery_time = item.lottery_time;
      return (
        (new CustomDate(lottery_time).getTime() - CustomDate.now()) / 1000 - (metadata ? metadata.count_down || 0 : 0)
      );
    },
    convertArrayToObject(data, key) {
      if (!key) {
        return {};
      }
      return data.reduce((result, item) => {
        if (!item) {
          return result;
        }
        if (item[key]) {
          return {
            ...result,
            [item[key]]: {
              ...item
            }
          };
        } else {
          return result;
        }
      }, {});
    },
    randomIntFromInterval(min, max) {
      // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min);
    },

    getGroupNumber(quantity) {
      return this.$store.dispatch('genTtbsNumber', {
        data: {
          result: JSON.parse(JSON.stringify(this.maxGameLatestResult))
        },
        level: 1,
        order: 1,
        excludeList: [],
        isMaxGame: true,
        quantity
      });
    },
    matchExact(regex, str) {
      let match = str.match(regex);
      return match && str === match[0];
    },
    formatNumberFromString(string) {
      const number = string.split('.').join('');
      if (isNaN(parseFloat(number))) {
        return null;
      }
      return parseFloat(number);
    },
    mixNumber(number) {
      if (typeof number === 'number') {
        return `${number < 10 ? '0' : ''}${number}`;
      }
      return '';
    },
    getTransactionFee(totalPrice, shipRate, shipFee, paymentRate, paymentFee) {
      if (totalPrice <= 0) {
        return {
          totalPrice,
          transactionFee: 0,
          resultPrice: 0
        };
      }
      const B = totalPrice * shipRate + shipFee;
      const C = (totalPrice + B) * paymentRate + paymentFee;
      return {
        totalPrice,
        transactionFee: B + C,
        resultPrice: totalPrice + B + C
      };
    },
    isInViewport(el, paddingBottom = 0) {
      const bounding = el.getBoundingClientRect();
      if (
        window.innerWidth <= this.getMobileThresshold() ||
        document.documentElement.clientWidth <= this.getMobileThresshold()
      ) {
        return (
          bounding.top >= 0 &&
          bounding.left >= 0 &&
          bounding.bottom + paddingBottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
      }

      return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    getCustomKenoResult(listNumber = []) {
      let small = 0;
      let big = 0;
      let odd = 0;
      let even = 0;

      listNumber.forEach((number) => {
        if (+number < 41) {
          small++;
        } else {
          big++;
        }

        if (+number % 2 == 0) {
          even++;
        } else {
          odd++;
        }
      });

      return {
        big,
        small,
        even,
        odd
      };
    },
    getCustomKenoShortName(additional) {
      return additional.text == 'draw_big_small'
        ? 'HOÀ (L-N)'
        : additional.text == 'draw_even_odd'
        ? 'HOÀ (C-L)'
        : additional.name;
    },
    formatPhone(phone) {
      return [phone.substr(0, 3), phone.substr(3, 3), phone.substr(6)].join(' ');
    },
    onAfterLogin(isShowLoginSuccess = true) {
      if (isShowLoginSuccess) {
        Swal.fire({
          icon: 'success',
          title: 'Đăng nhập thành công',
          showConfirmButton: false,
          timer: 2000
        });
      }
      if (window.location.host.includes('app')) {
        if (this.$route && (this.$route.name === 'intro-profile' || this.$route.name === 'intro')) {
          this.$store.commit('setModalTerm', false);
        } else {
          this.$store.commit('setModalTerm', true);
        }
      }
      const redirectAfterLogin = this.$store.getters.getterRedirectAfterLogin;

      if (this.$store.getters.getterCheckLoginBuyGame) {
        this.$store.commit('setCheckLoginAfterBuyGame', true);
      } else if (redirectAfterLogin && redirectAfterLogin !== '/') {
        this.$router.push(redirectAfterLogin);
        this.$store.commit('setRedirectAfterLogin', '/');
      }
    },
    onUpdateUserInfo(resp) {
      let timerSet = resp.data.data.date_of_birth;
      if (timerSet) {
        timerSet = new CustomDate(timerSet);
      }
      let userInfo = {
        ...resp.data.data,
        date_of_birth: timerSet,
        lastName: resp.data.data.name ? resp.data.data.name.split(' ').pop() : ''
      };
      this.$store.commit('setUserInfo', userInfo);
      this.$store.commit('setUserSettings', userInfo.metadata || {});
    },
    getUserInfoLogin() {
      return new Promise((resolve, reject) => {
        let accountId = this.$store.getters.getterAccountId;
        AccountService.getUserInfo(accountId)
          .then((resp) => {
            this.onUpdateUserInfo(resp);

            resolve(true);
          })
          .catch((e) => {
            console.error(e);
            reject(false);
          });
      });
    },
    onUpdateLinkAccount(seconds = 100) {
      if (
        this.$store.getters.getterIsAcceptAssociate &&
        !this.$store.getters.getterIsBuyGameOauth2 &&
        this.$store.getters.getterNumberAssociate < 1
      ) {
        setTimeout(() => {
          this.$store.commit('setModalAssociate', true);
        }, seconds);
        this.$store.commit('setIsAcceptAssociate', false);
      }
      this.$store.commit('setObjOauth2TextError', false);
    },
    getProductHandler(item) {
      const listGame = this.$store.getters.getterListGame;
      if (!item || !item.game_id || !item.product_id) {
        return '';
      }
      if (
        listGame &&
        listGame[item.game_id] &&
        listGame[item.game_id].product_list &&
        listGame[item.game_id].product_list[item.product_id]
      ) {
        return listGame[item.game_id].product_list[item.product_id].handler;
      }
      return '';
    },
    getProductName(item) {
      const listGame = this.$store.getters.getterListGame;
      if (!item || !item.game_id || !item.product_id) {
        return '';
      }
      if (
        listGame &&
        listGame[item.game_id] &&
        listGame[item.game_id].product_list &&
        listGame[item.game_id].product_list[item.product_id]
      ) {
        return listGame[item.game_id].product_list[item.product_id].name;
      }
      return '';
    },
    isCorrectMaxGame(index, ticket, ticketResult) {
      if (!ticketResult || ticketResult.length < 1) return false;
      if (!ticket.reward) return false;
      const ticketIndex = parseInt(index / 3);
      const ticketNumber = ticket.ticket_info.slice(ticketIndex * 3, ticketIndex * 3 + 3).join('');
      return ticketResult.flat().includes(ticketNumber);
    },
    isOldKenoRule(lottery_time) {
      return new CustomDate(this.getOnlyTime(lottery_time, 'YYYY/MM/DD')) < new CustomDate('2021/04/05');
    },
    runPlayTrial(step, isOpen = true) {
      const isPlayTrialMode = this.$store.getters.getterPlayTrial;
      if (!step || !isPlayTrialMode) {
        this.currentTrialStep = null;
        this.$store.commit('setShowOverlay', false);
        return;
      }

      if (isOpen) {
        this.currentTrialStep = step;
        this.$store.commit('setShowOverlay', true);
      } else {
        this.currentTrialStep = null;
        this.$store.commit('setShowOverlay', false);
      }
    },
    checkIsShowStepTrial(step) {
      return step === this.currentTrialStep;
      // const queryMyTooltip = document.querySelector(`.myTooltip[data-step="${step}"]`);
      // if (!queryMyTooltip) return false;
      // return queryMyTooltip.classList.contains('active');
    },
    notiPlayTrial(title = 'Tính năng chưa sẵn sàng ở chế độ chơi thử.') {
      Noti.fire({
        icon: 'info',
        title,
        timer: 3000,
        confirmButtonText: 'Đóng'
      });
    },
    setAccessToken(token) {
      Vue.$cookies.set('access_token', 'Bearer ' + token, expires, null, null, true, 'None');
    },
    sendMessageToParent(event, data) {
      if (this.$store.state.loadByIframe) {
        window.parent.postMessage(
          {
            event,
            data
          },
          this.$store.state.locationLoad
        );
      }
    },
    handleDownloadQr(canvasSelector, handler = 'image') {
      const currentDate = new CustomDate();
      const fileName = `${handler}_${this.formatDate(currentDate, 'DD_MM_YYYY_HH[h]mm')}`;
      // this.downloadImage(fileName, this.imgSrc);
      const qrCodeEl = document.querySelector(canvasSelector);
      if (qrCodeEl) {
        if (window.ReactNativeWebView) {
          this.sendMessageToApp(
            JSON.stringify({ event: 'SAVE_IMAGE', data: { image: qrCodeEl.toDataURL('image/png') } })
          );
        } else {
          this.downloadImage(fileName, qrCodeEl.toDataURL());
        }
      } else {
        Noti.fire({
          icon: 'error',
          title: 'Tải mã QR không thành công. Vui lòng thử lại.',
          showConfirmButton: false,
          timer: 3000
        });
      }
    },
    downloadImage(filename, data) {
      let element = document.createElement('a');
      element.setAttribute('href', data);
      element.setAttribute('download', filename);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    censorData(data, showLength = 4, fixedLength = null) {
      if (data == null || data == undefined) {
        return '';
      }
      const length = fixedLength || data.length;
      if (showLength == 0) {
        return ''.padStart(length, '*');
      }
      if (length <= showLength) {
        return data;
      }
      return data.substr(-showLength).padStart(length, '*');
    },
    generateLinkWithParams(url, params, isPathAndSearch) {
      if (!params || !url) {
        return url || false;
      }
      try {
        let _url = new URL(url);

        for (const [key, value] of Object.entries(params)) {
          _url.searchParams.set(key, value);
        }
        if (isPathAndSearch) {
          return _url.pathname + _url.search;
        } else {
          return _url.href;
        }
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    postMessage(titleEvent, data = {}) {
      if (window.ReactNativeWebView) {
        this.sendMessageToApp(
          JSON.stringify({
            event: titleEvent,
            data
          })
        );
      }
    },
    openDeepLink(url, type) {
      if (this.isMobileApp) {
        this.postMessage('OPEN_DEEP_LINK', {
          url
        });
      } else {
        setTimeout(() => {
          window.open(url, type || '_blank');
        }, 0);
      }
    },
    generateDeepLink(target_url) {
      return this.generateLinkWithParams(`${this.isMobileApp ? BASE_URL : window.location.origin}/deep-link`, {
        url: target_url
      });
    },
    linkToDeepLink(target_url) {
      let _target_url = this.generateDeepLink(target_url);
      this.openDeepLink(_target_url);
    },
    enqueuePaymentSuccessData(newVal) {
      const listData = this.$store.getters.getterQuickPaymentSuccessData;
      listData.push(newVal);
      this.$store.commit('setQuickPaymentSuccessData', listData);
    },
    dequeuePaymentSuccessData() {
      const listData = this.$store.getters.getterQuickPaymentSuccessData;
      listData.shift();
      this.$store.commit('setQuickPaymentSuccessData', listData);
    },
    generateFakeBackground() {
      let node = document.querySelector('#app');
      if (!node) {
        return;
      }

      html2canvas(node, {
        x: window.scrollX,
        y: window.scrollY,
        width: window.innerWidth,
        height: window.innerHeight,
        imageTimeout: 5000
      })
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/jpeg', 0.5);
          this.$store.commit('setFakeBackground', imgData);
          this.sendMessageToParent(eventTypeMessage.SEND_FAKE_BACKGROUND, imgData);
        })
        .catch(function(error) {
          console.error('oops, something went wrong!', error);
        });
    },
    handleParentOpenModal(isGenBackground = true) {
      let node = document.querySelector('#app');
      if (isGenBackground) {
        this.generateFakeBackground();
      }
      node.style.display = 'none';
      document.body.style.background = 'transparent';

      this.sendMessageToParent(eventTypeMessage.OPEN_MODAL);
    },
    handleParentCloseModal() {
      let node = document.querySelector('#app');
      this.sendMessageToParent(eventTypeMessage.CLOSE_MODAL);
      setTimeout(() => {
        node.style.display = 'block';
        document.body.style.background = '#f7f7f7';
      }, 200);
    },
    generateFakeBackgroundDebounce: _.debounce(function() {
      this.generateFakeBackground();
    }, 500),
    handleToggleModal(newVal) {
      if (!this.isAllowPopupOverflowIframe) {
        return;
      }
      if (newVal) {
        this.isExistModalOpenNormal++;
      } else {
        this.$nextTick().then(() => {
          this.isExistModalOpenNormal--;
        });
      }
    },
    setCookieInIframe(key, value, expires = null) {
      Vue.$cookies.set(key, value, expires, null, null, true, 'None');
    },
    refreshBalances() {
      if (!this.accountId) return;
      AccountService.getBalances(this.accountId)
        .then((resp) => {
          this.depositBalance = resp.data.data.deposit_wallet;
          this.rewardBalance = resp.data.data.withdraw_wallet;
          this.pendingBalance = resp.data.data.pending_wallet || 0;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    isJSON(data) {
      try {
        JSON.parse(data);
      } catch (e) {
        return false;
      }
      return true;
    },
    createPermutations(xs) {
      let ret = [];
      for (let i = 0; i < xs.length; i = i + 1) {
        let rest = this.createPermutations(xs.slice(0, i).concat(xs.slice(i + 1)));
        if (!rest.length) {
          ret.push([xs[i]]);
        } else {
          for (let j = 0; j < rest.length; j = j + 1) {
            ret.push([xs[i]].concat(rest[j]));
          }
        }
      }
      return ret;
    },
    createUniqueArray(arr) {
      return [...new Set(arr.map((a) => JSON.stringify(a)))].map((a) => JSON.parse(a));
    },
    genKenoNumber(level = 1, order = 1, excludeList = [], lotteryResult = []) {
      return this.$store.dispatch('genTtbsNumber', {
        data: JSON.parse(JSON.stringify(this.statisticKenoResult)),
        level,
        order,
        excludeList,
        lotteryResult
      });
      // console.log(JSON.stringify(this.statisticKenoResult));
      // return genKenoNumber(JSON.parse(JSON.stringify(this.statisticKenoResult)), ...args);
    },
    convertTicketOrderByName(name) {
      const listName = ['A', 'B', 'C', 'D', 'E', 'F'];
      const foundIndex = listName.findIndex((item) => item === name);
      return foundIndex < 0 ? -1 : foundIndex + 1;
    },
    checkIsAvailablePeriod(lottery_time, count_down = 0) {
      const timestamp = new CustomDate(lottery_time).getTime();
      let now = CustomDate.now();
      return now <= timestamp - (count_down || 0) * 1000;
    },
    timeoutAsync(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    hexToRGBA(h, opacity = 1) {
      let r = 0,
        g = 0,
        b = 0;

      // 3 digits
      if (h.length == 4) {
        r = '0x' + h[1] + h[1];
        g = '0x' + h[2] + h[2];
        b = '0x' + h[3] + h[3];

        // 6 digits
      } else if (h.length == 7) {
        r = '0x' + h[1] + h[2];
        g = '0x' + h[3] + h[4];
        b = '0x' + h[5] + h[6];
      }

      // return "rgba("+ +r + "," + +g + "," + +b + ")";
      return `rgba(${+r},${+g},${+b},${opacity})`;
    },
    setupThemeVariables: function(h = 6.09, s = 96.28, l = 42.16) {
      const rootElement = document.querySelector(':root');
      // let defaultPrimaryColor = getComputedStyle(rootElement).getPropertyValue('--primary-color');

      // let primaryColor = `hsl(${h}deg, ${s}%, ${l}%)`

      rootElement.style.setProperty('--primary-color', `hsl(${h}deg, ${s}%, ${l}%)`);

      rootElement.style.setProperty('--primary-color-darker', `hsl(${h}deg, ${s}%, ${l - 5}%)`);
      rootElement.style.setProperty('--primary-color-darkest', `hsl(${h}deg, ${s}%, ${l - 10}%)`);

      rootElement.style.setProperty('--primary-color-lighter', `hsl(${h}deg, ${s}%, ${l + 5}%)`);
      rootElement.style.setProperty('--primary-color-lightest', `hsl(${h}deg, ${s}%, ${l + 10}%)`);
    }
  }
});
