<template>
  <button
    type="button"
    @click="requestCaptcha"
    :disabled="isDisabled"
    class="btn w-100 text-uppercase btnConfirm position-relative"
    :class="{ 'opacity-7 cursor-none ': timer > 0 || isDisabled }"
  >
    <span v-if="timer > 0">Vui lòng đợi sau {{ timer }}</span>
    <span v-else>ĐĂNG KÝ</span>
  </button>
</template>
<script>
export default {
  props: {
    isDisabled: {
      type: Boolean
    },
    timer: {
      type: Number
    }
  },
  methods: {
    requestCaptcha() {
      this.$emit('requestCaptcha');
    }
  }
};
</script>
