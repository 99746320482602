export const GameUrl = {
  keno: {
    id: 1,
    url: '/xo-so-dien-toan-keno'
  },
  power: {
    id: 2,
    url: '/xo-so/dien-toan-power-6-55'
  },
  mega: {
    id: 3,
    url: '/xo-so/dien-toan-mega-6-45'
  }
};

export const GameUrlSubMainResult = [
  {
    id: 1,
    url: '/ket-qua/keno'
  }
  // {
  //   id: 2,
  //   url: '/powerwer'
  // }
];

export const PaymentMethodWithdraw = [
  {
    id: 1,
    name: 'Nạp lại tài khoản mua vé'
  },
  {
    id: 2,
    name: 'Chuyển khoản ngân hàng',
    image_url: ''
  },
  {
    id: 3,
    name: 'Chuyển khoản ví Momo',
    image_url: ''
  },
  {
    id: 4,
    name: 'Chuyển khoản ví ZaloPay',
    image_url: ''
  },
  {
    id: 6,
    name: 'Chuyển khoản ví ViettelPay',
    image_url: ''
  },
  {
    id: 7,
    name: 'Rút tiền về ngân hàng',
    image_url: ''
  }
];

export const GameListStatistical = [
  {
    id: 1,
    name: 'Bộ số',
    url_link: '/thong-ke/keno-bo-so'
  },
  {
    id: 2,
    name: 'Đầu đuôi',
    url_link: '/thong-ke/keno-dau-duoi',
    statisticObject: 'digit'
  },
  {
    id: 3,
    name: 'Chẵn lẻ / lớn nhỏ',
    url_link: '/thong-ke/keno-chan-le-lon-nho',
    statisticObject: 'custom'
  }
  // {
  //   id: 4,
  //   name: 'Bộ số yêu thích',
  //   url_link: '/thong-ke/keno-bo-so-yeu-thich'
  // }
];

export const GameListTimeDialFilter = [
  {
    key: 10,
    value: '10 kỳ gần nhất'
  },
  {
    key: 20,
    value: '20 kỳ gần nhất'
  },
  {
    key: 30,
    value: '30 kỳ gần nhất'
  },
  {
    key: 50,
    value: '50 kỳ gần nhất'
  },
  {
    key: 100,
    value: '100 kỳ gần nhất'
  },
  {
    key: 500,
    value: '500 kỳ gần nhất'
  },
  {
    key: 1000,
    value: '1000 kỳ gần nhất'
  }
];

export const KenoListTimeDialFilter = [
  {
    key: 30,
    value: '30 kỳ gần nhất'
  },
  {
    key: 50,
    value: '50 kỳ gần nhất'
  },
  {
    key: 0,
    value: 'Trong ngày'
  }
];

export const ListTicketFavorite = {
  status: true,
  data: {
    total: 12,
    data: [
      {
        id: 1,
        name: 'Bộ số yêu thích của tui',
        ticket_info: [1, 10, 20, 30]
      },
      {
        id: 2,
        name: 'Bộ số yêu thích của tui 2',
        ticket_info: [3, 7, 43, 75, 32]
      }
    ]
  }
};

export const ListClearVersion = [
  {
    value: 'clear-v4'
  }
];

export const timerHomeScreen = 3;
export const expires = '30d';

export const clientId = 'E73C599A441815F3';
