<template>
  <div v-if="isErrorPopupOauth2" class="contentLanding">
    <div class="invalidError">
      <h6>Liên kết không hợp lệ!</h6>
      <a @click="onClose()" class="btn btnConfirm text-uppercase font-weight-600 m-0 mt-2">Quay về Trang chủ</a>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    isErrorPopupOauth2: {
      get() {
        return this.$store.getters.getterIsErrorPopupOauth2;
      },
      set(newVal) {
        this.$store.commit('setIsErrorPopupOauth2', newVal);
      }
    }
  },
  created() {
    if (this.isErrorPopupOauth2) {
      this.onAddClassBody();
    }
  },
  methods: {
    onAddClassBody() {
      document.getElementsByTagName('body')[0].classList.remove('overflow-hidden');
    },
    onClose() {
      this.isErrorPopupOauth2 = false;
      document.getElementsByTagName('body')[0].classList.remove('overflow-hidden');
      this.$router.push('/').catch(() => {});
    }
  },
  watch: {
    isErrorPopupOauth2: function(newVal) {
      if (newVal) {
        this.onAddClassBody();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.invalidError {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 3rem;
  border-radius: 5px;
  text-align: center;
}

.contentLanding {
  background-color: rgba(0, 0, 0, 0.4);
  overflow: hidden;
  height: 100%;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  display: flex;
  .container {
    overflow-y: scroll;
    padding-top: 80px;
  }
}

@media (max-width: 640px) {
  .invalidError {
    padding: 1rem;
    width: max-content;
  }
}
</style>
