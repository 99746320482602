import './utils/customDate';
import { BootstrapVue } from 'bootstrap-vue';
import dayjs from 'dayjs';
import firebase from 'firebase/app';
import storageHelper from 'storage-helper';
import HealthCheckServices from './api/healthCheck';

window.storageHelper = storageHelper;

import 'firebase/auth'; // for authentication
import 'firebase/database'; // for realtime database
import 'firebase/firestore'; // for cloud firestore
import 'firebase/functions';
import 'firebase/messaging'; // for cloud messaging
import 'firebase/storage'; // for storage
import { reject } from 'lodash/reject';
import { isIOS, isMobile } from 'mobile-device-detect';
import Swal from 'sweetalert2';
import VCalendar from 'v-calendar';
import { confirmed, max, min, numeric, regex, required } from 'vee-validate/dist/rules.esm.js';
import VeeValidate, { Validator } from 'vee-validate/dist/vee-validate.minimal.esm.js';
import Vue from 'vue';
import VueContentPlaceholders from 'vue-content-placeholders';
import VueCookies from 'vue-cookies';
import VueLazyload from 'vue-lazyload';
import GameAppService from './api/game';
import IntegrationService from './api/integration';
import VuePusher from './plugins/vue-pusher';

import { Axios, AxiosNoty, AxiosCommonSerice } from './common/config';
import {
  DOMAIN_NAME,
  PROMOTION_CONFIG,
  NOTY_BASE_URL_API,
  eventTypeMessage,
  mapGameData,
  APP_ENV
} from './common/global';
import './js/firebase';
import './js/mixin';
import router from './js/router';
// import vi from 'vee-validate/dist/locale/vi';
import vi from './js/vi';
import App from './layouts/App';
import DeepLink from './views/DeepLink';
import store from './store/store';
import { clearAllInterval, convertArrayToObject, getQueryVariable } from './utils/functions';
import VueSocketIO from 'vue-socket.io';
import socketio from 'socket.io-client';
import './assets/sass/main.scss';
import AccountService from './api/account';
import { register } from 'register-service-worker';
import Bus from '@/utils/bus';
require('vue-image-lightbox/dist/vue-image-lightbox.min.css');
const { firebaseConfig } = require('./js/firebase');
import NotificationService from './api/notification';
import Waiting from './views/Waiting';
import _ from 'lodash';
import PartnerService from './api/partner';
import ExcludeFeatureWrapper from './components/ExcludeFeatureWrapper';

export const io = socketio(NOTY_BASE_URL_API, {
  transports: ['websocket'],
  reconnection: true,
  autoConnect: false,
  reconnectionAttempts: 5,
  reconnectionDelay: 3000
});

export const Noti = Swal.mixin({
  confirmButtonColor: '#d31904',
  cancelButtonColor: '#efefef',
  confirmButtonText: 'Có',
  cancelButtonText: 'Không'
});
Vue.use(VueCookies);
Vue.component('ExcludeFeatureWrapper', ExcludeFeatureWrapper);

Vue.prototype.CustomDate = CustomDate;

firebase.initializeApp(firebaseConfig);

register('/serviceWorker.js', {
  ready(registration) {
    if (firebase.messaging.isSupported()) {
      Vue.prototype.$messaging = firebase.messaging();
      Vue.prototype.$messaging.usePublicVapidKey(
        'BMp_YhppEy_elr06qWsXHEq_X2etJPmq0w6U5kKncPS8bUmibu-BN11ApiTxXM57KGw1vHBJP-vsfaaF4aHhSdg'
      );
      Vue.prototype.$messaging.useServiceWorker(registration);
    }
    store.commit('setFireBaseReady', true);
  },
  updated(registration) {
    registration.waiting.postMessage({ action: 'skipWaiting' });
    // TODO: Show confirm popup
    window.location.reload();
  }
});

const locationLoad = window.location != window.parent.location ? document.referrer : document.location.href;
const loadByIframe = window.location != window.parent.location;

if (loadByIframe) {
  window.addEventListener('message', handleParentMessage);
}
let dataAuthorizeIframe = null;
function handleParentMessage(e) {
  try {
    const loadUrl = new URL(locationLoad);
    if (e.origin === loadUrl.origin) {
      const { data, event } = e.data;
      const { AUTHORIZE_IFRAME } = eventTypeMessage;
      switch (event) {
        case AUTHORIZE_IFRAME:
          dataAuthorizeIframe = data;
          break;

        default:
          break;
      }
    }
  } catch (e) {
    console.error(e);
  }
}

new Vue({
  data() {
    return {
      dataAuthorizeIframe: null
    };
  },
  render: (h) => h(Waiting),
  async created() {
    await HealthCheckServices.check();
    Bus.$on('waiting-finish', this.onReady);
    this.$mount('#waiting');
  },
  destroyed() {
    Bus.$off('waiting-finish', this.onReady);
  },
  methods: {
    onReady() {
      initApp();
      window.parent.postMessage(
        {
          event: eventTypeMessage.FINISH_WAITING_ROOM
        },
        locationLoad
      );
    }
  }
});

function initApp() {
  Validator.extend('required', required);
  Validator.extend('min', min);
  Validator.extend('max', max);
  Validator.extend('numeric', numeric);
  Validator.extend('regex', regex);
  Validator.extend('confirmed', confirmed);

  let installPromptEvent;
  let isShow = false;
  let timerAppClick = null;

  Validator.extend('required', {
    validate(value) {
      return {
        required: true,
        valid: ['', null, undefined].indexOf(value) === -1
      };
    },
    computesRequired: true
  });

  Validator.localize({ vi: vi });
  Vue.use(VeeValidate, { locale: 'vi', fieldsBagName: 'vvFields' });

  let windows = window.location.hostname;
  if (windows.includes(DOMAIN_NAME)) {
    document.domain = DOMAIN_NAME;
  }
  // Use v-calendar & v-date-picker components
  Vue.use(VCalendar, {
    componentPrefix: 'vc', // Use <vc-calendar /> instead of <v-calendar />
    locales: {
      vi: {
        masks: {
          L: 'DD/MM/YYYY',
          title: 'MM YYYY'
          // ...optional `title`, `weekdays`, `navMonths`, etc
        }
      }
    }
  });

  Vue.config.productionTip = false;

  Vue.use(BootstrapVue);
  Vue.use(dayjs);

  Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: 'https://d604u3cjqmj8m.cloudfront.net/resources/images/no-photo.png',
    attempt: 1,
    listenEvents: ['scroll'],
    lazyComponent: true
  });
  Vue.use(VueContentPlaceholders);

  Vue.use(
    new VueSocketIO({
      debug: APP_ENV !== 'production',
      connection: io,
      vuex: {
        store,
        actionPrefix: 'SOCKET_',
        mutationPrefix: 'SOCKET_'
      }
    })
  );

  Vue.use(VuePusher, {
    api_key: process.env.VUE_APP_PUSHER_KEY,
    options: {
      cluster: 'ap1',
      logToConsole: true
    }
  });

  router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      const isLogin = store.getters.getterLoggedIn;

      if (!isLogin) {
        if (to.name === 'intro-profile') {
          router.push('/mua-ho-concept');
          return;
        } else {
          router.push('/');
        }
        store.commit('setLocationHome', to.meta.isRedirect ? false : true);
        store.commit('setRedirectAfterLogin', to.path);
        store.commit('setModalLogin', true);
      } else {
        next();
      }
    } else {
      next();
    }
  });

  const EventBus = new Vue();

  Object.defineProperties(Vue.prototype, {
    $bus: {
      get: function() {
        return EventBus;
      }
    }
  });

  function initialize() {
    return new Promise((resolve) => {
      GameAppService.getListGameId().then((resp) => {
        if (resp.data && resp.data.status) {
          const data = resp.data.data.sort((a) => {
            if (a.handler === 'xsmn') {
              return -1;
            } else {
              return 0;
            }
          });
          const gameDataHandler = convertArrayToObject(data, 'handler');
          let gameUrlCallback = [];
          let resultGameUrlCallback = [];
          let statisticalGameUrlCallback = [];
          const listShowBuyTogether = ['power', 'mega', 'max3dpro'];
          data.forEach((game) => {
            const { handler, id } = game || {};
            const { shortName } = mapGameData[handler];

            if (id && handler) {
              gameUrlCallback.push({
                id,
                url: `/xo-so/dien-toan-${handler}-${id}`,
                name: 'Mua vé ' + shortName,
                headerName: 'Vé số ' + shortName,
                slug: handler,
                url_buy_group_detail: `/xo-so-mua-chung/{buy_group_id}/dien-toan-${handler}-${id}`,
                shortName,
                url_buy_together: `/xo-so-mua-chung/dien-toan-${handler}-${id}`,
                isShowBuyTogether: listShowBuyTogether.includes(handler),
                isShowKenoFeed: ['keno'].includes(handler),
                url_feed_keno: `/nuoi-keno/dien-toan-${handler}-${id}`
              });

              resultGameUrlCallback.push({
                id,
                url: `/ket-qua/${handler}-${id}`,
                name: `Kết quả ${handler === 'xsmn' ? `vé số ${shortName}` : shortName}`,
                slug: handler
              });

              // statisticalGameUrlCallback.push({
              //   id,
              //   url: `/thong-ke/${handler}${handler === 'keno' ? '-bo-so' : ''}`,
              //   name: 'Thống kê ' + shortName,
              //   slug: handler
              // });
            }
          });
          return resolve({ gameUrlCallback, resultGameUrlCallback, statisticalGameUrlCallback, gameDataHandler });
        }
      });
    }).catch(() => {});
  }

  const actionByPath = {
    '/': ['CD/filterXsmnPeriod'],
    '/game-session': ['CD/filterXsmnPeriod'],
    '/xo-so/dien-toan-xsmn-8': ['CD/filterXsmnPeriod']
  };

  function getToday() {
    const date = new CustomDate();
    // date.setHours(0, 0, 0, 0);
    date.setMinutes(date.getMinutes() + 15);
    return date.getTime();
  }

  async function getPeriodData() {
    let oldData = null;
    try {
      oldData = JSON.parse(window.storageHelper.getItem('game_periods'));
    } catch (error) {
      console.error(error);
    }
    if (!oldData) {
      const resp = await GameAppService.getGamePeriods().catch((e) => {
        console.error(e);
      });
      if (resp && resp.data && resp.data.status) {
        try {
          window.storageHelper.setItem('game_periods', JSON.stringify({ data: resp.data.data, cacheTime: getToday() }));
        } catch (error) {
          console.error({ error });
        }

        return resp.data.data;
      }

      return {};
    }

    if (oldData.cacheTime) {
      if (oldData.cacheTime < getToday()) {
        const resp = await GameAppService.getGamePeriods().catch((e) => {
          console.error(e);
        });
        if (resp && resp.data && resp.data.status) {
          window.storageHelper.setItem('game_periods', JSON.stringify({ data: resp.data.data, cacheTime: getToday() }));
          return resp.data.data;
        }
        return {};
      }
    }
    if (oldData.data) {
      return oldData.data;
    }
    return {};
  }

  function processCountDown() {
    store.commit('CD/updateCurrentTimestamp');
    const currentPath = router.currentRoute.path;
    if (actionByPath[currentPath]) {
      actionByPath[currentPath].forEach((action) => {
        store.dispatch(action);
      });
    }
  }

  function getConfig() {
    return new Promise((resolve) => {
      Axios.get(PROMOTION_CONFIG, {
        headers: { Authorization: '' }
      })
        .then((res) => {
          if (res.status) {
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
          console.error(e);
        });
    }).catch(() => {});
  }

  function getConfigMaintenance() {
    return new Promise((resolve) => {
      Axios.get(`https://assets-vl.s3-ap-southeast-1.amazonaws.com/maintenance-config.json?time=${CustomDate.now()}`, {
        headers: { Authorization: '' }
      })
        .then((res) => {
          if (res.status) {
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
          console.error(e);
        });
    }).catch(() => {});
  }

  new Vue({
    template: '#app',
    router,
    store,
    data() {
      return {
        saveLocationMode: false,
        isDoneInitData: false,
        isDoneAuthorIframe: false,
        redirectAfterAuthorIframe: ''
      };
    },
    async created() {
      this.setupThemeVariables();
      if (loadByIframe) {
        this.$store.commit('setLocationLoad', locationLoad);
        this.$store.commit('setLoadByIframe', true);
        window.addEventListener('message', this.handleParentMessage);
        if (dataAuthorizeIframe) {
          this.authorIframe(dataAuthorizeIframe);
        }
      }
      this.$store.commit('setSaveHistoryFlagLength', window.history.length);
      if (window.location.host.includes('app')) {
        this.$store.commit('setIsApp', true);
      }

      let isClearLogin = false;
      try {
        isClearLogin = JSON.parse(window.storageHelper.getItem('_clear-login'));
      } catch (error) {
        console.error(error);
      }
      if (window.location.host.includes('app') && isClearLogin) {
        Vue.$cookies.remove('access_token');
        this.$store.commit('setDestroyToken');
        window.storageHelper.setItem('_clear-login', false);
      }

      let configMaintenance = await getConfigMaintenance();
      if (configMaintenance) {
        const configMaintenanceData = configMaintenance?.data[APP_ENV || 'staging'];
        const isMaintenance = configMaintenanceData?.isMaintenance;

        store.commit('setIsMaintain', isMaintenance);
        store.commit('setGlobalExcludeFeature', configMaintenanceData?.exclude_feature || []);

        if (isMaintenance) {
          this.$mount('#app');
          return;
        }
      }
      if (window.location.host.includes('thanhco')) {
        this.setupThemeVariables(201, 75, 40);
        await this.getPartnerConfig('thanhco');
      } else {
        await this.checkPartnerConfig();
      }

      store.dispatch('getObjBanner');
      let data = await initialize();
      if (data) {
        store.commit('setGameUrl', data.gameUrlCallback);
        store.commit('setResultGameUrl', data.resultGameUrlCallback);
        store.commit('setStatisticalUrl', data.statisticalGameUrlCallback);
        store.commit('setListGameHandler', data.gameDataHandler);
      }

      let configPromo = await getConfig();
      if (configPromo && configPromo.data) {
        if (typeof configPromo.data == 'string')
          try {
            configPromo.data = JSON.parse(configPromo.data);
          } catch (e) {
            console.error(e);
          }
        store.commit('setIsSmsVerify', configPromo.data.sms_verify);
        store.commit('setDataPromo', configPromo.data.new_user);
        store.commit('setBannerTime', configPromo.data.banner_time);
        store.commit('setDataPromoBe', configPromo.data.be_promo);
        store.commit('setVnMobilePhonePrefix', configPromo.data.vn_mobile_phone_prefix);

        if (APP_ENV === 'production' && configPromo.data.ace_tracking) {
          document.body.innerHTML += `
        <div id="ace-crm-script-tag-bound">
          <script type="text/javascript">
            var aceCrmLoaded = false;
            var aceCrmDomain = "https://acecrm.info/";
            var aceParrams = { client_id: 5237121, campaign_id: 89, website_id: 139 };
            (function() { if (!aceCrmLoaded) { var ga = document.createElement("script");
              ga.type = "text/javascript";
              ga.id = "aceCrmScriptTag";
              ga.src = aceCrmDomain + "assets/js/ace-crm-script.js"; var s = document.getElementsByTagName("script");
              s[0].parentNode.insertBefore(ga, s[0]); } else { new aceCrm(aceParrams).run(); } })();
          </script>
        </div>`;
        }
      }

      this.isDoneInitData = true;

      const periodData = await getPeriodData();
      // window.addEventListener('touchend', function() {
      //   window.scroll(0, window.scrollY);
      // });

      store.commit('CD/setAllGameData', periodData);
      const style = document.createElement('style');
      style.innerHTML = `
    p,h1,h2,h3,h4,button,h5,h6,span,div,a {
      -webkit-touch-callout:none;
      -webkit-user-select:none;
      -moz-user-select:none;
      -ms-user-select:none;
      user-select:none;
    }
    `;

      // if (!loadByIframe) {
      //   this.endLoadingAndMount();
      // }
      setInterval(processCountDown, 1000);
      if (window.location.host.includes('app')) {
        document.head.appendChild(style);
      }

      // Detects if device is in standalone mode
      const isInStandaloneMode = () => 'standalone' in window.navigator && window.navigator.standalone;
      if (isIOS && !isInStandaloneMode() && !loadByIframe) {
        this.$store.commit('setIsHomeScreenWithIOS', true);
      }

      let me = this.$store;

      window.addEventListener('beforeinstallprompt', function(e) {
        // console.error(e);
        // Prevent the mini-infobar from appearing on mobile
        e.preventDefault();
        installPromptEvent = e;
        me.commit('setDeferredPrompt', e);
        console.error('accept beforeinstallprompt');
        // Stash the event so it can be triggered later.
        // if (me.getters.getterIsRegister && !me.getters.getterIsHomeScreen) {
        //   me.dispatch('handlerHomeScreen');
        // }
      });

      window.addEventListener('click', function() {
        if (!isShow && installPromptEvent && me.getters.getterIsRegister && !me.getters.getterIsHomeScreen) {
          installPromptEvent.prompt();
          installPromptEvent.userChoice.then(function(choiceResult) {
            if (choiceResult.outcome === 'accepted') {
              let userdata = null;
              try {
                userdata = window.storageHelper.getItem('user_local');
              } catch (error) {
                console.error(error);
              }

              const userDataObj = userdata ? JSON.parse(userdata) : null;
              window.dataLayer.push({
                event: 'add-to-home-screen',
                phoneNumber: userDataObj.phone ? userDataObj.phone : ''
              });
            } else {
              me.commit('setIsHomeScreen', true);
            }
            isShow = true;
          });
        }
      });

      window.addEventListener('beforeunload', function() {
        window.dataLayer.push({
          event: 'end-page'
        });
      });

      timerAppClick = setTimeout(() => {
        document.getElementById('app').click();
      }, 5000);

      // Data for GTM start
      const isAppEl = document.getElementById('isApp');
      if (isMobile && !!window.ReactNativeWebView) {
        isAppEl.value = 'app';
      } else {
        isAppEl.value = 'web';
      }
      window.addEventListener('storage', (event) => {
        if (event.storageArea != window.storageHelper) return;
        if (event.key === 'user_local') {
          // Do something with event.newValue
          let userData = {};
          try {
            userData = JSON.parse(window.storageHelper.getItem('user_local'));
          } catch (error) {
            return;
          }

          if (!userData.id) {
            this.$store.dispatch('destroyToken');
          } else {
            const accessToken = this.$cookies.get('access_token');

            this.$store.commit('setToken', accessToken);
            this.$store.commit('setUserProfile', userData);
            Axios.defaults.headers.common['Authorization'] = accessToken;
            AxiosNoty.defaults.headers.common['Authorization'] = accessToken;
            AxiosCommonSerice.defaults.headers.common['Authorization'] = accessToken;
            this.getUserInfoLogin(AccountService);
          }
        }
      });
    },
    computed: {
      isOpenModalQuickPaymentSuccess: {
        get() {
          return this.$store.getters.getterIsOpenModalQuickPaymentSuccess;
        },
        set(newVal) {
          this.$store.commit('setIsOpenModalQuickPaymentSuccess', newVal);
        }
      },
      isExistModalOpen: {
        get() {
          return this.$store.getters.getterIsExistModalOpen;
        },
        set(newVal) {
          this.$store.commit('setIsExistModalOpen', newVal);
        }
      },
      isExistModalOpenNormal: {
        get() {
          return this.$store.getters.getterIsExistModalOpenNormal;
        },
        set(newVal) {
          this.$store.commit('setIsExistModalOpenNormal', newVal);
        }
      },
      isOpenPopupWaitingPayment: {
        get() {
          return this.$store.getters.getterIsOpenPopupWaitingPayment;
        },
        set(newVal) {
          return this.$store.commit('setIsOpenPopupWaitingPayment', newVal);
        }
      },
      isOpenModalPaymentSuccess: {
        get() {
          return this.$store.getters.getterIsOpenModalPaymentSuccess;
        },
        set(newVal) {
          this.$store.commit('setIsOpenModalPaymentSuccess', newVal);
        }
      },
      transactionFinisedFromParent: {
        get() {
          return this.$store.getters.getterTransactionFinisedFromParent;
        },
        set(newVal) {
          this.$store.commit('setTransactionFinisedFromParent', newVal);
        }
      },
      loggedIn() {
        return this.$store.getters.getterLoggedIn;
      }
    },
    methods: {
      checkMaintenanceStatus() {
        return new Promise(async () => {
          const res = await GameAppService.getMaintenanceStatus();
          const maintenanceData = res?.data?.data;
          if (maintenanceData) {
            const { system_maintenance, game } = maintenanceData;
            if (system_maintenance) {
              this.$store.commit('setIsMaintain', true);
              return;
            }

            const gameData = this.$store.getters.getterListGame;
            if (game) {
              Object.keys(game).forEach((gameId) => {
                // set status game to maintenance
                if (gameData[gameId]) {
                  gameData[gameId].status = !game[gameId];
                }
              });
            }
            this.$store.commit('setListGame', gameData);
          }
        });
      },
      async checkPartnerConfig() {
        const { hostname } = window.location;

        const splitHostName = hostname.split('.');
        const whitelistSubDomain = ['app', 'web-staging', 'web-sbx', 'tailoc68', 'muavietlot'];

        const handler = splitHostName[0];

        if (handler && !whitelistSubDomain.includes(handler)) {
          try {
            let querySearch = this.$route.query;

            if (_.isEmpty(querySearch)) {
              querySearch = getQueryVariable(window.location.search) || {};
            }

            const { data, redirect_url } = querySearch;
            let source = querySearch.source;
            const isTerra = window.location.href && window.location.href.includes('terraIdToken');
            const terraIdToken = window.location.href.split('terraIdToken=')[1];

            if (isTerra) {
              source = 'terra';
            }

            if (!source) {
              return;
            }

            const { data: partnerData, status } = await PartnerService.getPartnerInfo(source);
            if (status) {
              let config = '';
              if (partnerData.config) {
                config = JSON.parse(partnerData.config);
              }
              this.$store.commit('partner/setConfig', config);
              this.$store.commit('partner/setPartnerData', partnerData);

              if (source) {
                const { data: loginData, status: loginStatus } = await PartnerService.accountPartnerLogin({
                  partner_code: source,
                  // signature: __sign,
                  data: isTerra ? terraIdToken : data
                });

                if (loginStatus) {
                  this.$store.commit('setRedirectAfterLogin', redirect_url);

                  let result = await this.$store.dispatch('onAfterRetrieveToken', loginData);
                  if (result) {
                    this.getUserInfoLogin();
                    this.onAfterLogin(false);

                    const query = Object.assign({}, this.$route.query);
                    delete query.source;
                    // delete query.__sign;
                    delete query.data;
                    delete query.redirect_url;
                    this.$router.replace({ path: redirect_url ? redirect_url : '/', query });
                    this.$store.commit('setIsTerraMode', isTerra);
                    if (loginData.sms_verified == 0) {
                      // show model
                      store.commit('setModalRegister', false);
                      this.$store.commit('setRedirectAfterLogin', null);
                      this.$store.commit('setModalVerifyPhone', true);
                      return;
                    }
                  }
                }
              }
            }
          } catch (error) {
            console.error('checkPartnerConfig', error);
            this.$store.dispatch('destroyToken').then(() => {
              this.$router.push('/');
            });
          }
        }
      },
      async getPartnerConfig(source) {
        try {
          const { data: partnerData, status } = await PartnerService.getPartnerInfo(source);
          if (status) {
            let config = '';
            if (partnerData.config) {
              config = JSON.parse(partnerData.config);
            }

            if (config.default_path) {
              this.$router.push(config.default_path).catch(() => {});
            }

            this.$store.commit('partner/setConfig', config);
            this.$store.commit('partner/setPartnerData', partnerData);
          }
        } catch (error) {
          console.error('checkPartnerConfig', error);
          // this.$store.dispatch('destroyToken').then(() => {
          //   this.$router.push('/');
          // });
        }
      },
      setUserFromParent({
        birthday = '',
        email = '',
        name = '',
        passport = '',
        phone = '',
        user_id = '',
        invite_code = '',
        weluck_token = ''
      }) {
        this.$store.commit('setParentUserInfo', {
          birthday,
          email,
          name,
          passport,
          phone,
          user_id,
          invite_code
        });

        if (weluck_token) {
          this.onAfterAccountLinks({ _aff_sid: '0', token: weluck_token, _aff_network: 'weluck' }, false, false);
        }
      },
      async authorIframe({ api_key, partner }) {
        try {
          if (!api_key || !partner) {
            throw '';
          }

          const res = await IntegrationService.validateIntegration({
            signature: api_key,
            merchant: partner
          });

          if (res.status) {
            const { utm_source, aff_sid, merchant_invite_code } = res.data;

            let querySearch = this.$route.query;

            if (_.isEmpty(querySearch)) {
              querySearch = getQueryVariable(window.location.search) || {};
            }

            const {
              url_callback,
              payment_integration,
              allow_popup_overflow_iframe,
              allow_popup_register,
              save_location_mode,
              weluck_mode,
              kol,
              ...configLayout
            } = querySearch;

            const listClassConfig = {
              fullLayout: 'layout-fluid',
              noGutter: 'layout-no-gutters',
              noHeaderShadow: 'layout-no-header-shadow',
              headerBorderBottom: 'layout-header-border-bottom',
              noSlideBanner: 'layout-no-slide-banner',
              noFooter: 'layout-no-footer'
            };

            this.$store.commit('setInfoOauth2', {
              _aff_sid: aff_sid,
              _aff_network: utm_source,
              _utm_source_invite_code: merchant_invite_code
            });

            this.$store.commit('setCustomStyleData', configLayout);

            if (payment_integration !== undefined) {
              this.$store.commit('setPaymentIntegration', payment_integration === 'true');
            }

            if (allow_popup_overflow_iframe !== undefined) {
              this.$store.commit('setAllowPopupOverflowIframe', allow_popup_overflow_iframe === 'true');
            }

            if (allow_popup_register !== undefined) {
              this.$store.commit('setAllowPopupRegister', allow_popup_register === 'true');
            }

            if (weluck_mode !== undefined) {
              this.$store.commit('setWeluckMode', weluck_mode === 'true');
            }

            if (save_location_mode !== undefined) {
              this.saveLocationMode = save_location_mode === 'true';
            }

            // ADD CLASS OVERWRITE STYLE
            const bodyElement = document.body;
            Object.entries(listClassConfig).forEach(([key, value]) => {
              if (configLayout[key] === 'true') {
                bodyElement.classList.add(value);
              }
            });

            // LINK TO CALLBACK

            let linkTo = '';

            if (this.saveLocationMode) {
              let saveLocation = '';
              try {
                saveLocation = sessionStorage.getItem('save_location_pathname');
              } catch (error) {
                console.error(error.message);
              }

              if (saveLocation) {
                linkTo = saveLocation;
              } else {
                linkTo = url_callback;
              }
            } else {
              linkTo = url_callback;
            }

            // Send kol to desination page as query param
            if (kol) {
              linkTo += `?kol=${kol}`;
            }

            if (linkTo) {
              this.redirectAfterAuthorIframe = linkTo;
            }

            this.isDoneAuthorIframe = true;
          } else {
            throw '';
          }
        } catch (e) {
          if (timerAppClick) {
            clearTimeout(timerAppClick);
          }
          this.sendMessageToParent(eventTypeMessage.ACCESS_DENIED);
        }
      },
      handleTransactionFinished(data) {
        if (data.status && !this.isOpenModalQuickPaymentSuccess && !this.isOpenModalPaymentSuccess) {
          this.isOpenPopupWaitingPayment = true;
        }
        let olSocket = this.$cookies.get('old_socket_id');

        if (data.status && olSocket) {
          this.transactionFinisedFromParent = true;
        }
      },
      handleParentMessage(e) {
        try {
          const loadUrl = new URL(locationLoad);
          if (e.origin === loadUrl.origin) {
            const { data, event } = e.data;
            const {
              SEND_ACCOUNT_INFO,
              AUTHORIZE_IFRAME,
              SEND_TRANSACTION_FINISHED,
              HANDLE_MODAL_LOGIN,
              REQUEST_LOGOUT,
              REQUEST_URI
            } = eventTypeMessage;
            switch (event) {
              case SEND_ACCOUNT_INFO:
                this.setUserFromParent(data);
                break;
              case AUTHORIZE_IFRAME:
                this.authorIframe(data);
                break;
              case SEND_TRANSACTION_FINISHED:
                this.handleTransactionFinished(data);
                break;
              case HANDLE_MODAL_LOGIN:
                this.$store.commit('setModalLogin', data);
                break;
              case REQUEST_LOGOUT:
                this.$store.dispatch('destroyToken').then(() => {
                  this.sendMessageToParent(eventTypeMessage.SIGN_OUT);
                });
                break;
              case REQUEST_URI:
                this.$router.push(data);
                break;

              default:
                break;
            }
          }
        } catch (e) {
          console.error(e);
        }
      },

      async endLoadingAndMount() {
        const initLoading = document.getElementById('init_loading');
        if (initLoading) {
          initLoading.remove();
        }
        this.$mount('#app');
      }
    },

    beforeDestroy() {
      clearAllInterval();
      this.$socket.close();
      var old_element = window;
      var new_element = old_element.cloneNode(true);
      old_element.parentNode.replaceChild(new_element, old_element);
      if (loadByIframe) {
        window.removeEventListener('message', this.handleParentMessage);
      }
    },
    render(h) {
      if (this.$route.path === '/deep-link') {
        return h(DeepLink);
      } else {
        return h(App);
      }
    },
    mounted() {
      this.$socket.connect();

      if (this.isMobileApp) {
        this.sendMessageToApp(JSON.stringify({ event: 'GET_REVIEW_STATUS' }));
      }
      window.addEventListener('vl-message', (event) => {
        let data = event.data;
        data = this.isJSON(data) ? JSON.parse(data) : data;

        switch (data.event) {
          case 'FCM_UPDATE': {
            this.$store.commit('setDriverUniqueId', data.data.driver_unique_id);
            this.$store.commit('setTimezone', data.data.timezone);
            window.storageHelper.setItem('fcm-token', data.data.token);
            if (this.$store.getters.getterLoggedIn) {
              NotificationService.updateToken(this.$store.getters.getterAccountId, data.data).catch((e) => {
                console.error(e);
              });
            }
            break;
          }
          case 'review-status': {
            this.$store.commit('setReview', data.data);
            if (data.data || !this.isTimezoneWhiteList) {
              this.$router.push('/ket-qua/xsmn-8');
            }
            break;
          }
          case 'REQUEST_SCANNER': {
            this.$store.commit('setBarcode', data.data);
            this.$router.push('/do-ve/ket-qua/');
            break;
          }
          case 'REQUEST_CAMERA': {
            this.$router.push('/soi-ve/ket-qua/');
            break;
          }
        }
      });

      if (this.isDoneAuthorIframe && this.redirectAfterAuthorIframe) {
        this.$router.push(this.redirectAfterAuthorIframe);
      }
    },
    watch: {
      isExistModalOpen: {
        handler(newVal, oldVal) {
          if (newVal === 1 && oldVal === 0) {
            this.handleParentOpenModal();
            return;
          }

          if (newVal === 0 && oldVal === 1) {
            this.handleParentCloseModal();
          }
        },
        immediate: true
      },
      isExistModalOpenNormal: {
        handler(newVal, oldVal) {
          if (newVal === 1 && oldVal === 0) {
            this.sendMessageToParent(eventTypeMessage.OPEN_MODAL_BACKDROP);
            return;
          }

          if (newVal === 0 && oldVal === 1) {
            this.sendMessageToParent(eventTypeMessage.CLOSE_MODAL_BACKDROP);
          }
        },
        immediate: true
      },
      $route() {
        this.checkMaintenanceStatus();
        if (loadByIframe && this.saveLocationMode) {
          try {
            sessionStorage.setItem('save_location_pathname', window.location.pathname);
          } catch (error) {
            console.error(error.message);
          }
        }
      },
      isDoneInitData: function(newVal) {
        if (newVal && !loadByIframe) {
          this.endLoadingAndMount();
          return;
        }

        if (newVal && this.isDoneAuthorIframe && loadByIframe) {
          this.endLoadingAndMount();
        }
      },
      isDoneAuthorIframe: function(newVal) {
        if (this.isDoneInitData && newVal) {
          this.endLoadingAndMount();
        }
      },
      loggedIn: function(newValue) {
        if (!newValue) {
          if (this.$route.matched.some((record) => record.meta.requiresAuth)) {
            this.$router.push('/');
          }
        }
      }
    }
  });

  store.dispatch('updateTrackingId');
}
