<template>
  <div>
    <p class="text-center mb-lg-2">
      <span v-if="isSmsVerify"
        >Vui lòng điền mã xác nhận sẽ được tổng đài cung cấp qua cuộc gọi tự động đến số điện thoại</span
      >
      <span v-else>Vui lòng điền mã xác nhận đã được gửi đến số điện thoại</span>
      <span class="font-weight-bold"> {{ phoneNumber }} </span>
      để xác minh.
    </p>
    <form @submit.prevent ref="form2">
      <b-form-group>
        <div class="modalRegisterLabel">
          <label for="verificationInput">Mã xác nhận</label>
          <button
            type="button"
            :disabled="timer > 0"
            @click="requestCaptcha"
            class="modalRegisterSendAgain pointer btn p-0"
          >
            <p v-if="timer == 0">Gửi lại mã</p>
            <p v-else for="verificationInput" class="modalRegisterLabelRight">(Gửi lại sau {{ timer }} giây)</p>
          </button>
        </div>
        <b-form-input
          @keyup.enter="handlerConfirmRegister"
          ref="codeOverview"
          v-model="codeOverview"
          id="codeOverview"
          trim
          v-validate="{
            min: isSmsVerify ? 4 : 6,
            max: isSmsVerify ? 4 : 6,
            required: true,
            numeric: true
          }"
          :class="{
            input: true,
            'is-danger': errors.has('codeOverview')
          }"
          type="number"
          data-vv-as="Mã xác nhận"
          name="codeOverview"
          pattern="[0-9]*"
        ></b-form-input>
        <span v-show="errors.has('codeOverview')" class="is-danger-text position-relative">{{
          errors.first('codeOverview')
        }}</span>
      </b-form-group>
      <div class="h-100 modalRegisterFooter">
        <div class="justify-content-center">
          <div class="w-100">
            <button
              @click="handlerConfirmRegister()"
              class="btn w-100 text-uppercase btnConfirm position-relative test"
              :disabled="isLoading || errors.has(verificationNumber) || isDisabled"
              :class="{ 'opacity-7 cursor-none': isLoading || errors.has(verificationNumber) || !codeOverview }"
            >
              XÁC MINH
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      codeOverview: ''
    };
  },
  props: {
    phoneNumber: {
      type: String
    },
    isPromo: {
      type: Boolean
    },
    timer: {
      type: Number
    },
    code: {
      type: String
    },
    isLoading: {
      type: Boolean
    },
    verificationNumber: {
      type: String
    },
    isSmsVerify: {
      type: Boolean
    },
    errorTitle: {
      type: String
    }
  },
  created() {
    this.$bus.$off('validate', this.handlerError());
    this.$bus.$on('validate', ($event) => this.handlerError($event));
  },
  mounted() {
    this.codeOverview = this.code;
  },
  computed: {
    isDisabled() {
      const lengthCheck = this.isSmsVerify ? 4 : 6;
      return this.codeOverview.length !== lengthCheck;
    }
  },
  methods: {
    handlerConfirmRegister() {
      if (this.$validator.errors.any()) return;
      this.$emit('handlerConfirmRegister', this.codeOverview);
    },
    requestCaptcha() {
      this.$emit('requestCaptcha');
    },
    handlerError(event) {
      if (event) {
        this.errors.clear();
        if (event.code) {
          this.errors.add({
            field: 'codeOverview',
            msg: event.message
          });
        } else {
          this.errors.add({
            field: 'code',
            msg: 'Có lỗi xãy ra hãy thử lại.'
          });
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.modalRegisterContent {
  .modalRegisterLabel {
    .modalRegisterLabelRight {
      font-style: italic;
      font-size: 14px;
    }
    .modalRegisterLabelPass {
      margin-top: 16px;
    }
  }
  input::-webkit-input-placeholder {
    font-size: 15px;
    line-height: 3;
    color: #999999;
  }
  p {
    font-size: 15px;
  }
}
</style>
