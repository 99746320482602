import { AxiosNoty } from '../common/config';

// const baseURL = notyBaseURL;
//const baseURL = 'http://localhost:6969';
export const NotificationService = {
  getLastNotification(accountId) {
    return AxiosNoty.get(`/accounts/${accountId}/latest-notifications`)
      .then((resp) => {
        return resp.data;
      })
      .catch((e) => {
        return e.response.data;
      });
  },
  getAllNotification(accountId, data) {
    return AxiosNoty.get(`/accounts/${accountId}/notifications`, {
      params: data
    })
      .then((resp) => {
        return resp.data;
      })
      .catch((e) => {
        return e.response.data;
      });
  },
  updateToken(accountId, data) {
    return AxiosNoty.patch(`/accounts/${accountId}/notify-token`, data)
      .then((resp) => {
        return resp.data;
      })
      .catch((e) => {
        return e.response.data;
      });
  },
  updateSeenNotify(accountId) {
    return AxiosNoty.patch(`/accounts/${accountId}/seen-notifications`, { seen_time: new CustomDate().toISOString() })
      .then((resp) => {
        return resp;
      })
      .catch(() => {
        return {};
      });
  }
};

export default NotificationService;
