export default {
  getterInviteCodeDisplayData(state) {
    return state.inviteCodeDisplayData;
  },
  getterUserInviteCode(state) {
    return state.inviteCode;
  },
  getterPopupNotifyMessageData(state) {
    return state.popupNotifyMessageData;
  },
  getterIsOpenPopupNotifyMessage(state) {
    return state.isOpenPopupNotifyMessage;
  },
  getterIsOpenPopupPaymentError(state) {
    return state.isOpenPopupPaymentError;
  },
  getterIsLoadBalances(state) {
    return state.isLoadBalances;
  },
  getterIsMaintain(state) {
    return state.isMaintain;
  },
  getterDeferredPrompt(state) {
    return state.deferredPrompt;
  },
  getterIsRegister(state) {
    return state.isRegister;
  },
  getterIsApp(state) {
    return state.isApp;
  },
  getterIsHomeScreen(state) {
    return JSON.parse(state.isHomeScreen);
  },
  getterModalTerm(state) {
    return state.isModalTerm;
  },
  getterIsOnlyMobile(state) {
    return state.isOnlyMobile;
  },
  getterMetadataKeno(state) {
    return state.metadataKeno || {};
  },
  getterObjPrizeKeno(state) {
    return state.objPrizeKeno;
  },
  getterListProductGame(state) {
    return state.listProductGame;
  },
  getterNotification(state) {
    return state.isNotification;
  },
  getterValRegister(state) {
    return state.showModalRegister;
  },
  getterValForgotPassword(state) {
    return state.showModalForgotPassword;
  },
  getterValPhone(state) {
    return state.showModalPhone;
  },
  getterValVerifyPhone(state) {
    return state.showVerifyPhone;
  },
  getterIsTerraMode(state) {
    return state.isTerraMode;
  },
  getterValLogin(state) {
    return state.showModalLogin;
  },
  getterCheckLoginBuyGame(state) {
    return state.isCheckLoginBuyGame;
  },
  getterCheckLoginAfterBuyGame(state) {
    return state.isCheckLoginAfterBuyGame;
  },
  getterValTimerWaiting(state) {
    return state.timerWaiting;
  },
  getterValTimerWaitingPhone(state) {
    return state.timerWaitingPhone;
  },
  getterLoggedIn(state) {
    return !!state.token;
  },
  getterUser(state) {
    return state.users;
  },
  getterUserProfile(state) {
    return state.userProfile;
  },
  getterAccountId(state) {
    // if (!state.userProfile) {
    //   dispatch('destroyToken');
    // }
    return state.userProfile ? state.userProfile.id : '';
  },
  getterBanner(state) {
    return state.objBanner || {};
  },
  getterIsLoadingBanner(state) {
    return state.isLoadingBanner;
  },
  getterIsLoadingProduct(state) {
    return state.isLoadingProduct;
  },
  getterDepositBalance(state) {
    return state.depositBalance;
  },
  getterPendingBalance(state) {
    return state.pendingBalance;
  },
  getterRewardBalance(state) {
    return state.rewardBalance;
  },
  getterListTicketActived(state) {
    return state.listTicketActived || [];
  },
  getterListTicketOverview(state) {
    return state.listTicketOverview || [];
  },
  getterListTicketOverviewFeedKeno(state) {
    return state.listTicketOverviewFeedKeno || [];
  },
  getterListTimeDialOverview(state) {
    return state.listTimeDialOverview || [];
  },
  getterListTimeDialOverviewForReal(state) {
    return state.listTimeDialOverviewForReal || [];
  },
  getterListTicketOverviewForReal(state) {
    return state.listTicketOverviewForReal || [];
  },
  getterListTicketPowerOverview(state) {
    return state.listTicketPowerOverview || [];
  },
  getterListTimeDialPowerOverview(state) {
    return state.listTimeDialPowerOverview || [];
  },
  getterKeepDataActived(state) {
    return state.keepDataActived || [];
  },
  getterUserInfo(state) {
    return state.userInfo;
  },
  getterLocationHome(state) {
    return state.locationHome;
  },
  getterWithdrawHistoryDetail(state) {
    return state.withdrawHistoryDetail;
  },
  getterInputPasswordAgain(state) {
    return state.inputPasswordAgain;
  },
  getterOrderTab(state) {
    return state.orderTab;
  },
  getterRouterExpire(state) {
    return state.isCheckRouter;
  },
  getterListTicketFavoriteKeno(state) {
    return state.ListTicketFavoriteKeno;
  },
  getterTextExpire(state) {
    return state.textExpire;
  },
  getterListAllFavorite(state) {
    return state.listAllFavorite;
  },
  getterListAllFavoriteDropdown(state) {
    return state.listAllFavoriteDropdown;
  },
  getterIsCreateFavorite(state) {
    return state.isCreateFavorite;
  },
  getterLoadingDropdown(state) {
    return state.isLoadingDropdown;
  },
  getterTotalPageDropdown(state) {
    return state.totalPageDropdown;
  },
  getterIsSaveReceiver(state) {
    return state.isSaveReceiver;
  },
  getterLastName(state) {
    return state.lastName;
  },
  getterGameStatus(state) {
    return state.gameStatus || {};
  },
  getterIsOpenModalTransactionResult(state) {
    return state.isOpenModalTransactionResult;
  },
  getterCart(state) {
    return state.cart;
  },
  getterPowerPeriod(state) {
    return state.powerPeriod;
  },
  getterMegaPeriod(state) {
    return state.megaPeriod;
  },
  getterListGame(state) {
    return state.listGame;
  },
  getterIsSentValidate(state) {
    return state.isSentValidate;
  },
  getterDisplayOrderId(state) {
    return state.display_order_id;
  },
  getterListGameHandler(state) {
    return state.listGameHandler;
  },
  getterListFavoriteProp(state) {
    return state.listFavoriteProp;
  },
  getterSavePagination(state) {
    return state.savePagination;
  },
  getterSavePaginationNotiPage(state) {
    return state.savePaginationNotiPage;
  },
  getterSavePaginationFavorite(state) {
    return state.savePaginationFavorite;
  },
  getterlistPageNotification(state) {
    return state.listPageNotification;
  },
  getterHistoryResult(state) {
    return state.historyResult;
  },
  getterModalDeposit(state) {
    return state.modalDeposit;
  },
  getterSaveHistoryFlagLength(state) {
    return state.saveHistoryFlagLength;
  },
  getterSaveFilterListActived(state) {
    return state.saveFilterListActived;
  },
  getterSaveFilterListActivedGiftTicket(state) {
    return state.saveFilterListActivedGiftTicket;
  },
  getterIsOpenClearCart(state) {
    return state.isOpenClearCart;
  },
  getterRedirectAfterLogin(state) {
    return state.redirectAfterLogin;
  },
  getterKenoCustomProductList(state) {
    return state.kenoCustomProductList;
  },
  getterUserSettings(state) {
    return state.userSettings;
  },
  getterTooltipCoordinates(state) {
    return state.tooltipCoordinates;
  },
  getterPlayTrial(state) {
    return state.playTrial;
  },
  getterIsOpenModalPlayTrial(state) {
    return state.isOpenModalPlayTrial;
  },
  getterCurrentTrialStep(state) {
    return state.currentTrialStep;
  },
  getterShowOverlay(state) {
    return state.showOverlay;
  },
  getterIsOpenModalPaymentSuccess(state) {
    return state.isOpenModalPaymentSuccess;
  },
  getterIsOpenPopupCongrats(state) {
    return state.isOpenPopupCongrats;
  },
  getterUrlPopupGrate(state) {
    return state.urlPopupGrate;
  },
  getterObjNoCloseOnEscAndBackdrop(state) {
    return state.objNoCloseOnEscAndBackdrop;
  },
  getterLoadByIframe(state) {
    return state.loadByIframe;
  },
  getterListPaymentMethod(state) {
    return state.listPaymentMethod;
  },
  getterLocationLoad(state) {
    return state.locationLoad;
  },
  getterParentUserInfo(state) {
    return state.parentUserInfo;
  },
  getterIsConnectedSocket(state) {
    return state.isConnectedSocket;
  },
  getterQuickBuyInfo(state) {
    return state.modalQuickBuyInfo;
  },
  getterQuickPaymentSuccessData(state) {
    return state.quickPaymentSuccessData;
  },
  getterIsOpenModalQuickPaymentSuccess(state) {
    return state.isOpenModalQuickPaymentSuccess;
  },
  getterCurrentTransaction(state) {
    return state.currentTransaction;
  },
  getterCustomStyleData(state) {
    return state.customStyleData;
  },
  getterPaymentIntegration(state) {
    return state.paymentIntegration;
  },
  getterAllowPopupOverflowIframe(state) {
    return state.allowPopupOverflowIframe;
  },
  getterAllowPopupRegister(state) {
    return state.allowPopupRegister;
  },
  getterFakeBackground(state) {
    return state.fakeBackground;
  },
  getterIsExistModalOpen(state) {
    return state.isExistModalOpen;
  },
  getterIsExistModalOpenNormal(state) {
    return state.isExistModalOpenNormal;
  },
  getterPayloadGameTrial(state) {
    return state.payloadGameTrial;
  },
  getterIsOpenPopupWaitingPayment(state) {
    return state.isOpenPopupWaitingPayment;
  },
  getterTransactionFinisedFromParent(state) {
    return state.transactionFinisedFromParent;
  },
  getterNewOrder(state) {
    return state.newOrder;
  },
  getterReloadFlagResultScreen(state) {
    return state.reloadFlagResultScreen;
  },
  getterIsOpenPopupCongrat(state) {
    return state.isOpenPopupCongrat;
  },
  getterPopupCongrateData(state) {
    return state.popupCongrateData;
  },
  getterIsWeluckMode(state) {
    return state.isWeluckMode;
  },
  getterListPeriodFullCapacity(state) {
    return state.listPeriodFullCapacity;
  },
  getterListIdols(state) {
    return state.listIdols;
  },
  getterNewFeature(state) {
    return state.newFeature;
  },
  getterReferralCode(state) {
    return state.referralCode;
  },
  getterDataRequestPayment(state) {
    return state.dataRequestPayment;
  },
  getterIsOpenModalShare(state) {
    return state.isOpenModalShare;
  },
  getterWaitingData(state) {
    return state.waitingData;
  },
  getterIsOpenPopupGiftTicket(state) {
    return state.isOpenPopupGiftTicket;
  },
  getterPopupGiftTicketData(state) {
    return state.popupGiftTicketData;
  },
  getterIsOpenSendThank(state) {
    return state.isOpenSendThank;
  },
  getterPopupSendThankData(state) {
    return state.popupSendThankData;
  },
  getterListNotifications(state) {
    return state.listNotifications;
  },
  getterIsOpenPopupThank(state) {
    return state.isOpenPopupThank;
  },
  getterPopupThankData(state) {
    return state.popupThankData;
  },
  getterSaveSelectIdolData(state) {
    return state.saveSelectIdolData;
  },
  getterIsSetSelectIdolData(state) {
    return state.isSetSelectIdolData;
  },
  getterBuyTicketAgainData(state) {
    return state.buyTicketAgainData;
  },
  getterIsShowConfirmPayment(state) {
    return state.isShowConfirmPayment;
  },
  getterKenoThreeLastestResult(state) {
    return state.kenoThreeLastestResult;
  },
  getterIsLoadingGameResult(state) {
    return state.isLoadingGameResult;
  },
  getterIsShowPeriodHint(state) {
    return state.isShowPeriodHint;
  },
  getterThreeLatestResult(state) {
    return state.threeLatestResult;
  },
  getterExcludeListNumber(state) {
    return state.excludeListNumber;
  },
  getterStatisticKenoResult(state) {
    return state.statisticKenoResult;
  },
  getterMaxGameLatestResult(state) {
    return state.maxGameLatestResult;
  },
  getterIsOpenFilterBuyTogether(state) {
    return state.isOpenFilterBuyTogether;
  },
  getterFilterBuyTogetherValue(state) {
    return state.filterBuyTogetherValue;
  },
  getterPowerGameType(state) {
    return state.powerGameType;
  },
  getterMegaGameType(state) {
    return state.megaGameType;
  },
  getterListenedReview(state) {
    return state.isListenedReview;
  }
};
