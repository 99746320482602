<template>
  <CommonModal
    :isNoCloseOnEsc="true"
    :isNoCloseOnBackdrop="true"
    :isOpen="modalVerifyPhoneData"
    @updateOpen="handleModal"
    :isHiddenFooter="true"
    :hideHeaderClose="true"
  >
    <div id="recaptchaWrapper">
      <div id="captcha-container" />
    </div>
    <div v-if="!sent">
      <div class="text-center">
        <label class="text-title">Cập nhật thông tin</label>
      </div>
      <label for="phoneNumberInput">Vui lòng cập nhật số điện thoại cho tài khoản</label>
      <b-form-input
        v-validate="{
          min: 10,
          max: 10,
          regex: /(03|07|08|09|05)+([0-9]{8})\b/,
          required: true
        }"
        :class="{ input: true, 'is-danger': errors.has('phoneNumber') }"
        name="phoneNumber"
        v-model="phoneNumber"
        id="phoneNumberInput"
        data-vv-as="Số điện thoại"
        type="number"
        ref="inputRegister"
        pattern="[0-9]*"
        autocomplete="off"
      ></b-form-input>
      <span v-show="errors.has('phoneNumber')" class="is-danger-text position-relative">{{
        errors.first('phoneNumber')
      }}</span>
      <button
        :disabled="isDisabled"
        @click="onVerify()"
        class="btn w-100 text-uppercase btnConfirm position-relative test"
      >
        CẬP NHẬT
      </button>
    </div>
    <AccountModalRegisterComfirmCode
      :phoneNumber="phoneNumber"
      :timer="timer"
      :code="code"
      :isSmsVerify="true"
      @handlerConfirmRegister="onCodeChange"
      @requestCaptcha="requestCaptchaCommon"
      v-else
    />
  </CommonModal>
</template>
<script>
import CommonModal from '../CommonModal.vue';
import AccountService from '../../api/account';
import AccountModalRegisterComfirmCode from '../../components/Account/AccountModalRegisterComfirmCode';
import { SIGNATURE_SECRET_KEY } from '../../common/global';
import { mapGetters } from 'vuex';

let TIMER = 15;
import md5 from 'js-md5';
import Swal from 'sweetalert2';

export default {
  components: {
    CommonModal,
    AccountModalRegisterComfirmCode
  },
  created() {},
  methods: {
    handleModal(value) {
      this.modalVerifyPhoneData = value;
    },
    onCloseModal() {
      this.modalVerifyPhoneData = false;
    },
    onVerify() {
      const signature = md5(this.phoneNumber + '_' + SIGNATURE_SECRET_KEY + '_');
      let partner = this.getterPartnerData?.handler;

      var data = {
        phone: this.phoneNumber,
        source: partner,
        signature: signature
      };

      AccountService.postSmsSendingV2(data)
        .then((resp) => {
          if (resp) {
            this.sent = true;
            this.startCountDown();
          }
        })
        .catch((e) => {
          if (e.response) {
            const { message, code } = e.response.data.data;

            if (code == '009.001.002') {
              return;
            }
            this.$bus.$emit('validate', { code, message });

            if (code === '008.001.001') {
              Swal.fire({
                icon: 'error',
                title: message,
                showConfirmButton: false,
                timer: 2000
              });
              window.dataLayer.push({
                event: 'request-sms-otp-fail',
                phoneNumber: this.phoneNumber
              });
              return;
            }
            this.errors.add({
              field: code === '009.001.002' ? 'promoCode' : 'phoneNumber',
              msg: message
            });
          }
          Swal.fire({
            icon: 'error',
            title: 'Có lỗi xảy ra hãy thử lại',
            showConfirmButton: false,
            timer: 2000
          });
          this.onCloseModal();
        })
        .finally(() => {
          this.isDisabledButton = false;
          this.isLoading = false;
        });
    },
    onCodeChange(code) {
      if (!code) {
        return;
      }
      let partner = this.getterPartnerData?.handler;

      const data = {
        phone: this.phoneNumber,
        source: partner,
        verify_code: code,
        account_id: this.accountId
      };
      AccountService.updatePartnerPhoneV2(data)
        .then((resp) => {
          if (resp) {
            this.onCloseModal();
            Swal.fire({
              icon: 'success',
              title: 'Cập nhật thành công',
              showConfirmButton: false,
              timer: 2000
            });
          }
        })
        .catch((e) => {
          if (e.response) {
            const { message } = e.response.data.data;
            Swal.fire({
              icon: 'error',
              title: message,
              showConfirmButton: false,
              timer: 2000
            });
            window.dataLayer.push({
              event: 'request-sms-otp-fail',
              phoneNumber: this.phoneNumber
            });
            return;
          }
          Swal.fire({
            icon: 'error',
            title: 'Có lỗi xảy ra hãy thử lại',
            showConfirmButton: false,
            timer: 2000
          });
          this.onCloseModal();
        })
        .finally(() => {
          this.isDisabledButton = false;
          this.isLoading = false;
        });
    },
    async requestCaptchaCommon() {
      this.onVerify();
    },
    startCountDown() {
      const currentTimestamp = new CustomDate().getTime();

      window.storageHelper.setItem('verify_code_timestamp_phone', currentTimestamp);
      this.timer = TIMER;
      if (this.verifyInterval) {
        clearInterval(this.verifyInterval);
      }
      this.verifyInterval = setInterval(() => {
        this.timer--;
        if (this.timer <= 0) {
          this.timer = 0;
          clearInterval(this.verifyInterval);
        }
      }, 1000);
    }
  },
  data() {
    let lastTimestamp = 0;
    try {
      lastTimestamp = parseInt(window.storageHelper.getItem('verify_code_timestamp_phone'));
    } catch (error) {
      console.error(error);
    }
    const currentTimestamp = new CustomDate().getTime();
    const sec = parseInt((currentTimestamp - lastTimestamp) / 1000);
    return {
      phoneNumber: '',
      code: '',
      timer: sec > TIMER ? 0 : TIMER - sec,
      sent: false
    };
  },
  destroyed() {
    clearInterval(this.verifyInterval);
  },
  computed: {
    ...mapGetters('partner', ['getterPartnerData']),
    vnMobilePhonePrefix() {
      return this.$store.getters.getterVnMobilePhonePrefix;
    },
    accountId() {
      return this.$store.getters.getterAccountId;
    },
    modalVerifyPhoneData: {
      get() {
        return this.$store.getters.getterValVerifyPhone;
      },
      set(newVal) {
        this.$store.commit('setModalVerifyPhone', newVal);
      }
    },
    isSmsVerify() {
      let isVietNameMobile = false;
      if (this.phoneNumber && this.phoneNumber.length === 10) {
        for (let phonePrefix of this.vnMobilePhonePrefix) {
          if (this.phoneNumber.startsWith(phonePrefix)) {
            isVietNameMobile = true;
          }
        }
      }

      return isVietNameMobile || this.$store.getters.getterIsSmsVerify;
    },
    isDisabled() {
      return this.errors.has('phoneNumber') || !this.phoneNumber;
    }
  }
};
</script>
<style lang="scss" scoped>
.modal-header {
  .close {
    display: none !important;
  }
}
.text-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}
</style>
