<template>
  <footer id="footer" class="footer myFooter d-none d-lg-block">
    <div>
      <div class="w-100">
        <div class="footerMid container">
          <div class="row justify-content-between">
            <div class="col-lg-5 textGray">
              <a href="/" class>
                <img alt="image" class="heightAuto" width="159" src="../assets/img/logo_thantai_pc.png" />
              </a>
              <p class="font-weight-500">CÔNG TY CỔ PHẦN GIẢI TRÍ SỐ WELUCK</p>
              <p>Địa chỉ: 104 Mai Thị Lựu , Phường Đa Kao, Quận 1, <span class="text-nowrap">TP. HCM</span></p>
              <div>
                <span> E-mail: {{ emailSupport }}</span>
              </div>
              <p>Giấy phép kinh doanh số: 0317046356</p>
              <p class="mb-10">
                Cấp ngày: 23/11/2021 bởi Sở Kế Hoạch và Đầu Tư <span class="text-nowrap">TP. HCM</span>
              </p>
              <a v-if="false" href="#">
                <img
                  src="https://s3-ap-southeast-1.amazonaws.com/gocashback.vn/resources/images/logoSaleNoti.png"
                  width="119"
                  class="heightAuto"
                />
              </a>
              <div class="d-flex mt-lg-3"></div>
            </div>
            <!-- <div class="col-lg-3">
              <h6 class="text-uppercase font-weight-600 mb-15 textBlue">Mua vé số điện toán</h6>
              <router-link
                v-for="gameData in getListGameId"
                :key="gameData.id"
                v-on:click.native="handleClickRoute(gameData.id)"
                class="mb-12 text-dark d-block"
                :to="gameData.url"
                >{{ gameData.headerName }}</router-link
              >
            </div> -->
            <div class="col-lg-2">
              <h6 class="text-uppercase font-weight-600 mb-15 textBlue">thông tin</h6>
              <div>
                <router-link to="/thong-tin/gioi-thieu" class>
                  <p class="mb-12 text-dark">Giới thiệu</p>
                </router-link>
              </div>
              <div>
                <router-link to="/thong-tin/dieu-khoan-su-dung" class>
                  <p class="mb-12 text-dark">Điều khoản sử dụng</p>
                </router-link>
              </div>
              <div>
                <router-link to="/lien-he" class>
                  <p class="mb-12 text-dark">Liên hệ</p>
                </router-link>
              </div>
              <div>
                <div class="d-flex mt-2 infoContact">
                  <!-- <a href="viber://chat?number=0902905788" class="d-inline-block pointer mr-2"
                    ><img width="30" src="../assets/img/icon/icon_viber.svg"
                  /></a>
                  <a href="https://zalo.me/0902905788" target="_blank" class="d-inline-block pointer mx-1"
                    ><img width="30" src="../assets/img/icon/icon_zalo.svg"
                  /></a> -->
                  <a href="https://m.me/111694644907922" target="_blank" class="d-inline-block pointer"
                    ><img width="30" class="h-auto" src="../assets/img/icon/icon_fbmess-blue.svg"
                  /></a>
                </div>
              </div>
            </div>
            <div class="col-lg-2" v-if="showDownload">
              <h6 class="text-uppercase font-weight-600 mb-15 textBlue">Tải ứng dụng</h6>
              <!-- <div>
                <a
                  href="https://apps.apple.com/vn/app/t%C3%A0i-l%E1%BB%99c-mua-h%E1%BB%99-vietlott/id1536375131?l=vi"
                  target="_blank"
                >
                  <img alt="img" src="../assets/img/app-store.png" width="148" class="heightAuto max-width-100" />
                </a>
              </div> -->
              <div>
                <a href="https://play.google.com/store/apps/details?id=com.thantai.app" target="_blank" class>
                  <img alt="img" src="../assets/img/google-play.png" width="148" class="heightAuto max-width-100" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100">
        <div class="footerBottom">
          <div class="footerBottomContent container">
            <div class="row justify-content-center">
              <p class="textBlue text-center py-3">Copyright © 2020 Thần Tài. All rights reserved.</p>
              <!-- <div class="col-lg-6">
                <div class="d-flex justify-content-end">
                  <p class="textBlue">Chấp nhận thanh toán</p>
                </div>
                <div class="d-flex justify-content-end footerBottomContentPayment">
                  <a href="/" class="pr-10">
                    <img alt width="38" class="heightAuto" src="../assets/img/atm.png" />
                  </a>
                  <a href="/" class="pr-10">
                    <img alt width="38" class="heightAuto" src="../assets/img/visa.png" />
                  </a>
                  <a href="/" class="pr-10">
                    <img alt width="38" class="heightAuto" src="../assets/img/master-card.png" />
                  </a>
                  <a href="/" class>
                    <img alt width="26" class="heightAuto" src="../assets/img/momo.png" />
                  </a>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import { EMAIL_SUPPORT } from '../common/global';
export default {
  data() {
    return {
      emailSupport: EMAIL_SUPPORT
    };
  },
  computed: {
    getListGameId() {
      let hiddenList = ['max4d', 'xsmn'];
      return this.$store.getters.getterGameUrl.filter((item) => !hiddenList.includes(item.slug));
    },
    listGameById() {
      return this.$store.getters.getterListGame;
    }
  },
  methods: {
    handleClickRoute(id) {
      window.dataLayer.push({
        event: 'click-choose-game',
        gameName: this.listGameById[id].name,
        labelChooseGame: 'Menu Footer'
      });
    },
    routeLink(value) {
      this.$router.push(value).catch(() => {});
    }
  }
};
</script>

<style lang="scss" scoped>
.max-width-100 {
  max-width: 100%;
}
</style>
