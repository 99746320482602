<template>
  <EmptyLayout v-if="!finishWaiting">
    <div v-if="isShowWaiting" class="waitingScreen">
      <div class="waitingScreen__content">
        <img draggable="false" class="waitingScreen__relax" :src="require('../assets/img/waiting-relax.svg')" />
        <h6>{{ title }}</h6>
        <p>{{ content }}</p>
        <strong v-if="isShowCount">{{ count }}</strong>
      </div>
    </div>
  </EmptyLayout>
</template>
<script>
import { mapGetters } from 'vuex';
import EmptyLayout from '../layouts/EmptyLayout.vue';
import Bus from '@/utils/bus';
import { Axios } from '../common/config';
import { APP_ENV, WHITE_KEY_WAITING } from '../common/global';
import Vue from 'vue';

export default {
  components: {
    EmptyLayout
  },
  data() {
    const count = window.storageHelper.getItem('_wating_count');

    return {
      timeout: null,
      count,
      finishWaiting: false,
      isShowWaiting: false,
      isShowCount: false,
      title: '',
      content: ''
    };
  },
  computed: {
    ...mapGetters(['getterWaitingData', 'getterValRegister'])
  },
  created() {
    this.checkWaitingConfig();
  },
  beforeDestroy() {
    if (this.timeout) {
      clearInterval(this.timeout);
    }
  },
  methods: {
    handleFinishWaiting() {
      Bus.$emit('waiting-finish');
      this.finishWaiting = true;

      this.endLoadingApp('block');
    },
    async getConfigMaintenanceWaiting() {
      return Axios.get(
        `https://assets-vl.s3.ap-southeast-1.amazonaws.com/waiting-config.json?time=${CustomDate.now()}`,
        {
          headers: { Authorization: '' }
        }
      )
        .then((res) => {
          return res.data;
        })
        .catch((e) => {
          console.error(e);
          return {};
        });
    },
    async checkWaitingConfig() {
      let dataWaiting = await this.getConfigMaintenanceWaiting();
      dataWaiting = dataWaiting?.[APP_ENV] || dataWaiting?.staging;
      const whiteKey = Vue.$cookies.get('whitelist_key') || null;

      if (!dataWaiting || !dataWaiting?.maintenance_mode || whiteKey === WHITE_KEY_WAITING) {
        this.handleFinishWaiting();
        return;
      }
      const { user_type, count_down, title, content, show_countdown } = dataWaiting;
      this.content = content;
      this.title = title;
      this.isShowCount = !!show_countdown;
      const isToken = Vue.$cookies.get('access_token') || null;
      if ((user_type === 'new' && !isToken) || user_type === 'all') {
        this.isShowWaiting = true;
        this.endLoadingApp('none');
        if (!count_down) {
          this.handleFinishWaiting();
        } else {
          const countDown = this.count && this.count < count_down ? this.count : count_down;
          this.count = countDown;
          this.timeout = setInterval(async () => {
            if (this.count <= 0) {
              let dataWaitingAgain = await this.getConfigMaintenanceWaiting();
              dataWaitingAgain = dataWaitingAgain?.[APP_ENV] || dataWaitingAgain?.staging;
              const {
                count_down: count_down_again,
                maintenance_mode: maintenance_mode_again,
                user_type: user_type_again,
                content,
                title
              } = dataWaitingAgain;

              if (maintenance_mode_again) {
                this.content = content;
                this.title = title;
                if ((user_type_again === 'new' && !isToken) || user_type_again === 'all') {
                  this.count = count_down_again;
                } else {
                  this.endCountDown();
                }
              } else {
                this.endCountDown();
              }
              return;
            }
            this.count--;
            window.storageHelper.setItem('_wating_count', this.count);
          }, 1000);
        }
      } else {
        this.handleFinishWaiting();
      }

      this.endLoadingApp('none');
    },
    endLoadingApp(value) {
      const initLoading = document.getElementById('init_loading');
      if (initLoading) {
        initLoading.style.display = value;
      }
    },
    endCountDown() {
      window.storageHelper.removeItem('_wating_count');
      this.handleFinishWaiting();
      clearInterval(this.timeout);
    }
  }
};
</script>
<style lang="scss" scoped>
.waitingScreen {
  background-image: url('../assets/img/waiting-background.svg');
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: center;
  &__content {
    text-align: center;
    padding-top: 50px;
  }

  h6 {
    font-size: 24px;
    color: #115bcb;
    margin: 15px 0;
  }

  p {
    font-size: 16px;
    width: 60vw;
    margin: 0 auto;
  }

  strong {
    font-size: 36px;
    font-weight: 500;
    color: #115bcb;
  }

  // &__relax {
  //   background-image: url('../assets/img/waiting-relax.svg');
  //   width: 100vw;
  //   height: 100vh;
  // }

  @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
    img {
      width: 300px;
    }

    &__content {
      padding-left: 10px;
      padding-right: 10px;
    }

    p {
      width: 90vw;
    }
  }
}
</style>
