export const playList = {
  max3d: [
    {
      key: 1,
      value: 'Vé thường',
      isActive: true,
      handler: ''
    },
    {
      key: 2,
      value: 'Đảo số',
      isActive: false
    },
    {
      key: 3,
      value: 'Ôm 1 vị trí',
      isActive: false
    }
  ],
  'max3d+': [
    {
      key: 1,
      value: 'Vé thường',
      isActive: true,
      handler: ''
    },
    {
      key: 2,
      value: 'Đảo số',
      isActive: false
    },
    {
      key: 3,
      value: 'Ôm 1 vị trí',
      isActive: false
    }
  ],
  max3dpro: [
    {
      key: 1,
      value: 'Vé thường',
      isActive: true,
      handler: ''
    },
    {
      key: 2,
      value: 'Đảo số',
      isActive: false
    },
    {
      key: 3,
      value: 'Ôm 1 vị trí',
      isActive: false
    }
  ],
  max4d: [
    {
      key: 1,
      value: 'Cơ bản',
      isActive: true,
      handler: ''
    },
    {
      key: 2,
      value: 'Bao',
      isActive: true,
      handler: 'combo'
    },
    {
      key: 3,
      value: 'Tổ hợp',
      isActive: true,
      handler: 'combine'
    },
    {
      key: 4,
      value: 'Cuộn 1',
      isActive: true,
      handler: 'head'
    },
    {
      key: 5,
      value: 'Cuộn 4',
      isActive: true,
      handler: 'tail'
    }
  ],
  power: [
    {
      key: 1,
      value: 'Vé thường',
      isActive: true,
      handler: ''
    },
    {
      key: 2,
      value: 'Vé bao',
      isActive: true,
      handler: 'combo'
    }
  ],
  mega: [
    {
      key: 1,
      value: 'Vé thường',
      isActive: true,
      handler: ''
    },
    {
      key: 2,
      value: 'Vé bao',
      isActive: true,
      handler: 'combo'
    }
  ]
};

export const cartItemName = {
  max3d: {
    default: {
      key: 1,
      value: 'Vé thường'
    },
    reverse: {
      key: 2,
      value: 'Đảo số'
    },
    keep: {
      key: 3,
      value: 'Ôm 1 vị trí'
    }
  },
  'max3d+': {
    default: {
      key: 1,
      value: 'Vé thường'
    },
    reverse: {
      key: 2,
      value: 'Đảo số'
    },
    keep: {
      key: 3,
      value: 'Ôm 1 vị trí'
    }
  },
  max3dpro: {
    default: {
      key: 1,
      value: 'Vé thường'
    },
    reverse: {
      key: 2,
      value: 'Đảo số'
    },
    keep: {
      key: 3,
      value: 'Ôm 1 vị trí'
    },
    combo: {
      key: 4,
      value: 'Vé bao [count] bộ ba số'
    },
    combine: {
      key: 5,
      value: 'Bao bộ ba số'
    }
  },
  max4d: {
    default: {
      key: 1,
      value: 'Cơ bản'
    },
    combo: {
      key: 2,
      value: 'Bao'
    },
    combine: {
      key: 3,
      value: 'Tổ hợp'
    },
    head: {
      key: 4,
      value: 'Cuộn 1'
    },
    tail: {
      key: 5,
      value: 'Cuộn 4'
    }
  }
};

export const playTrialContent = {
  keno: {
    content: 'Hãy chọn từ 1 đến 10 con số để tham gia dự thưởng.',
    position: ''
  },
  kenoBs: {
    content: 'Hãy chọn 1 trong 8 cách chơi bổ sung cho vé của Quý khách.',
    position: 'bottom'
  },
  mega: {
    content: 'Quý khách cần chọn 6 con số để tham gia dự thưởng.',
    customContent: 'Quý khách hãy chọn số lượng số phù hợp với từng cách chơi bao.',
    position: ''
  },
  power: {
    content: 'Quý khách cần chọn 6 con số để tham gia dự thưởng.',
    customContent: 'Quý khách hãy chọn số lượng số phù hợp với từng cách chơi bao.',
    position: ''
  },
  max3d: {
    content: 'Quý khách cần chọn 3 con số để tạo thành 1 con số có 3 chữ số để tham gia dự thưởng.',
    position: ''
  },
  'max3d+': {
    content: 'Quý khách cần chọn 6 con số để tạo thành 2 con số có 3 chữ số để tham gia dự thưởng.',
    position: ''
  },
  max3dpro: {
    content: 'Quý khách cần chọn 6 con số để tạo thành 2 con số có 3 chữ số để tham gia dự thưởng.',
    position: ''
  },
  max4d: {
    content: 'Quý khách cần chọn 4 con số để tạo thành 1 con số có 4 chữ số để tham gia dự thưởng.',
    position: 'top'
  }
};

export const NOT_FOUND_URL = '/khong-tim-thay/404';
export const URL_DEFAUTL_CONGRATS = '/tai-khoan/ket-qua/V000000000';
export const SAFE_TIME_QR = 60; // seconds
export const TIME_OUT_SAVE_SOCKET_ID = 60 * 3; // seconds

export const TIME_REFRESH_KENO_ONLINE = 60 * 3; // seconds

export const DEFAUL_PAYMENT_METHOD = 'wallet';
export const DEFAUL_PAYMENT_METHOD_QUICK_BUY = 'vnpay-qr';
export const PAYMENT_METHOD_DIVINE = 'divine';

export const DEFAUL_SHIPPING_INDEX = 0;

export const TICKET_LENGTH_MAX3DPRO_BASIC = 6;

export const DOMAIN_NAME = process.env.VUE_APP_DOMAIN_NAME;
// export const EMAIL_SUPPORT = process.env.VUE_APP_EMAIL_SUPPORT;
export const EMAIL_SUPPORT = 'cskh@thantai68.net';
export const NOTY_BASE_URL_API = process.env.VUE_APP_NOTY_BASE_URL_API;
export const BASE_URL_API = process.env.VUE_APP_BASE_URL_API;
// export const BASE_URL_API = 'http://localhost:10010';
export const BASE_URL = process.env.VUE_APP_BASE_URL;
export const PROMOTION_CONFIG = process.env.VUE_APP_PROMOTION_CONFIG;
export const DOMAIN_WESCAN = process.env.VUE_APP_DOMAIN_WESCAN;
export const INVITE_CODE_WESCAN = process.env.VUE_APP_INVITE_CODE_WESCAN || '';
export const FEATURE_QUICK_BUY = process.env.VUE_APP_FEATURE_QUICK_BUY || false;
export const FEATURE_SHARE_FB = process.env.VUE_APP_FEATURE_SHARE_FB || false;

export const COMMON_SERVICE_API = process.env.VUE_APP_COMMON_SERVICE_API;
export const WHITE_KEY_WAITING = process.env.VUE_APP_WHITE_KEY_WAITING;

export const APP_ENV = process.env.VUE_APP_ENV;

export const SIGNATURE_SECRET_KEY = process.env.VUE_APP_SIGNATURE_SECRET_KEY;

export const eventTypeMessage = {
  SEND_ACCOUNT_INFO: 'SEND_ACCOUNT_INFO',
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  DEPOSIT_SUCCESS: 'DEPOSIT_SUCCESS',
  BUY_TICKET_SUCCESS: 'BUY_TICKET_SUCCESS',
  AUTHORIZE_IFRAME: 'AUTHORIZE_IFRAME',
  ACCESS_DENIED: 'ACCESS_DENIED',
  REQUEST_PAYMENT: 'REQUEST_PAYMENT',
  SEND_TRANSACTION_FINISHED: 'SEND_TRANSACTION_FINISHED',
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  SEND_FAKE_BACKGROUND: 'SEND_FAKE_BACKGROUND',
  OPEN_MODAL_BACKDROP: 'OPEN_MODAL_BACKDROP',
  CLOSE_MODAL_BACKDROP: 'CLOSE_MODAL_BACKDROP',
  GAME_CLICK: 'GAME_CLICK',
  HANDLE_MODAL_LOGIN: 'HANDLE_MODAL_LOGIN',
  REQUEST_LOGOUT: 'REQUEST_LOGOUT',
  REQUEST_URI: 'REQUEST_URI',
  FINISH_WAITING_ROOM: 'FINISH_WAITING_ROOM',
  REQUEST_SCANNER: 'REQUEST_SCANNER'
};

export const SHAREFB_TYPE = {
  GIFT: 'GIFT',
  GIFT_WIN: 'GIFT_WIN',
  GIFTED: 'GIFTED',
  GIFTED_WIN: 'GIFTED_WIN',
  REWARD_REPLY: 'REWARD_REPLY',
  BUY_REPLY: 'BUY_REPLY'
};

export const SOCKET_EVENT = {
  NOTI: 'noti',
  DEPOSIT_PAYMENT: 'deposit_payment',
  ORDER_PAYMENT: 'order_payment',
  SUBSCRIBED: 'subscribed',
  UNSUBSCRIBED: 'unsubscribed',
  CONNECT: 'connect',
  DISCONNECT: 'disconnect'
};

export const TICKET_MESSAGE_ENUM = {
  REWARD_REPLY: 'reward_reply',
  BUY_REPLY: 'buy_reply'
};

export const labelTransStatus = {
  0: 'Đang xử lý',
  1: 'Hoàn tất',
  2: 'Đã huỷ'
};

export const mapGameData = {
  keno: {
    shortName: 'KENO'
  },
  mega: {
    shortName: 'MEGA 6/45'
  },
  power: {
    shortName: 'POWER 6/55'
  },
  max3d: {
    shortName: 'MAX3D'
  },
  'max3d+': {
    shortName: 'MAX3D+'
  },
  max3dpro: {
    shortName: 'MAX3D PRO'
  },
  max4d: {
    shortName: 'MAX4D'
  },
  xsmn: {
    shortName: 'kiến thiết'
  }
};

export const gamePriceList = [
  {
    label: '10k',
    value: 10000,
    key: 1
  },
  {
    label: '20k',
    value: 20000,
    key: 2
  },
  {
    label: '50k',
    value: 50000,
    key: 3
  },
  {
    label: '100k',
    value: 100000,
    key: 4
  },
  {
    label: '200k',
    value: 200000,
    key: 5
  },
  {
    label: '500k',
    value: 500000,
    key: 6
  },
  {
    label: '1M',
    value: 1000000,
    key: 7
  }
];

export const MAX_PRICE_3D_PRO = 100000;

export const BUS_EVENT = {
  UPDATE_ORDER_REPLY: 'UPDATE_ORDER_REPLY',
  UPDATE_REWARD_REPLY: 'UPDATE_REWARD_REPLY'
};

export const KENO_TYPE = {
  BASIC: 'basic',
  ADDED: 'added',
  COMBO: 'combo'
};

export const PUSHER_CHANNEL = {
  NEW_RESULT: 'NEW_RESULT',
  SYNC_WALLET: 'SYNC_WALLET',
  SCAN_TICKET_RESULT: 'SCAN_TICKET_RESULT'
};

export const FEATURE_HANDLER = {
  CART: 'cart',
  USER_INFO: 'user_info',
  DEPOSIT: 'deposit',
  WITHDRAW: 'withdraw',
  TRANSACTION_HISTORY: 'transaction_history',
  GIFT_TRANSACTION: 'gift_transaction',
  GIFTED_TRANSACTION: 'gifted_transaction',
  INVITE_FRIEND: 'invite_friend',
  LOGOUT: 'logout',
  DEPOSIT_BALANCE: 'deposit_balance',
  REWARD_BALANCE: 'reward_balance',
  QUICK_BUY: 'quick_buy',
  BUY_GROUP: 'buy_group',
  SUMMARY: 'summary',
  HOME_TAB_NAVIGATE: 'home_tab_navigate',
  //HEADER
  HEADER_NOTI_ICON: 'header_noti_icon',
  HEADER_CART_ICON: 'header_cart_icon',
  HEADER_BACK_ICON: 'header_back_icon',
  HEADER_MENU_ICON: 'header_menu_icon',

  //NAVIGATION
  BOTTOM_NAVIGATE: 'bottom_navigate',
  TAB_LOTTERY_RESULT: 'tab_lottery_result',
  PRODUCT_INDEX: 'product_index'
};

export const optionBuyTogetherFilter = [
  { text: 'Thời gian đóng', value: 'lottery_time', sort_type: 'asc' },
  { text: 'Tỉ lệ hoàn thành', value: 'finish_percent', sort_type: 'desc' },
  { text: 'Tỉ lệ đã góp', value: 'my_percent', sort_type: 'desc' },
  { text: 'Loại bao', value: 'product_name', sort_type: 'asc' }
];

/**
 * FLow play trial
 * choose_game -> choose_game_number -> choose_game_number_in_modal
 * -> choose_game_price_in_modal -> choose_confirm_in_modal -> choose_more_ticket_number
 * -> choose_buy_now -> show_popup_payment
 */
