import { Axios } from '../common/config';
const state = {
  categoryLuatChoi: [],
  timeWaitingArticle: null,
  listQuestionFAQ: []
};

const getters = {
  getterCategoryLuatChoi(state) {
    return state.categoryLuatChoi || [];
  },
  getterListQuestionFAQ(state) {
    return state.listQuestionFAQ || [];
  },
  getterTimeWaitingArticle(state) {
    return state.timeWaitingArticle;
  }
};

const actions = {
  getArticleBySlug(context, slug) {
    return new Promise((resolve, reject) => {
      Axios.get(`/articles/${slug}`)
        .then((resp) => {
          if (resp.data.status) {
            resolve(resp.data.data);
          }
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          context.commit('setIsLoadingProduct', false);
        });
    });
  },
  getArticleByCategorySlug(context, categorySlug) {
    return new Promise((resolve, reject) => {
      let param = undefined;
      if (categorySlug === 'cau-hoi-thuong-gap') {
        param = {
          showContent: 1
        };
      }
      Axios.get(`/articles/categories/${categorySlug}`, {
        params: param
      })
        .then((resp) => {
          if (resp.data.status) {
            resolve(resp.data.data);
          }
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          context.commit('setIsLoadingProduct', false);
        });
    });
  }
};

const mutations = {
  setCategoryLuatChoi(state, value) {
    state.categoryLuatChoi = value;
  },
  setListQuestionFAQ(state, value) {
    state.listQuestionFAQ = value;
  },
  setTimeWaitingArticle(state, value) {
    state.timeWaitingArticle = value;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
