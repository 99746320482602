<template>
  <div
    v-if="!isThanhCo"
    :class="{
      mb40: isPlayTrial
    }"
  >
    <HeaderTrial class="d-lg-none" v-if="isPlayTrial" />

    <header
      class="myHeader"
      :class="{
        mt40: isPlayTrial
      }"
      :style="{
        'background-color': customStyleData.headerBackgroundColor,
        'border-bottom-color': customStyleData.headerBorderBottomColor
      }"
    >
      <div class="d-lg-flex align-items-center myNavbar container">
        <b-navbar toggleable="lg" type="light">
          <div class="myNavbarItems customMyNavbarItems position-relative px-0">
            <div class="w-100 h-100 position-absolute-mobile">
              <a class="d-flex h-100 justify-content-center pointer">
                <img
                  :src="srcLogo"
                  class="logoHeader"
                  width="160px"
                  @click="!isReview && !isThanhCo && routeLink('/')"
                />
              </a>
            </div>
            <div v-if="!isReview" class="m-auto-0 pl-3 myNavbarItemsToggle zIndex99 d-flex align-items-center">
              <ExcludeFeatureWrapper :featureName="FEATURE_HANDLER.HEADER_BACK_ICON">
                <button
                  v-if="isPartnerMode"
                  @click="backApp()"
                  class="headerBtn pr-3 bg-white d-inline-block d-lg-none mb-1"
                >
                  <!-- <img class="icon" src="../../../assets/img/icon/icon_arrow_left.svg" /> -->
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 492 492"
                    style="enable-background: new 0 0 492 492"
                    xml:space="preserve"
                    width="16px"
                    height="16px"
                    fill="#0d234a"
                  >
                    <g>
                      <g>
                        <path
                          d="M198.608,246.104L382.664,62.04c5.068-5.056,7.856-11.816,7.856-19.024c0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12
      C361.476,2.792,354.712,0,347.504,0s-13.964,2.792-19.028,7.864L109.328,227.008c-5.084,5.08-7.868,11.868-7.848,19.084
      c-0.02,7.248,2.76,14.028,7.848,19.112l218.944,218.932c5.064,5.072,11.82,7.864,19.032,7.864c7.208,0,13.964-2.792,19.032-7.864
      l16.124-16.12c10.492-10.492,10.492-27.572,0-38.06L198.608,246.104z"
                        />
                      </g>
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                </button>
              </ExcludeFeatureWrapper>

              <ExcludeFeatureWrapper :featureName="FEATURE_HANDLER.HEADER_MENU_ICON">
                <b-navbar-toggle class="myNavbarItemsToggler" target="nav-collapse"></b-navbar-toggle>
              </ExcludeFeatureWrapper>
            </div>

            <div v-if="!isReview" class="m-auto-0 pr-3 d-block d-lg-none zIndex99">
              <navbar-icons-rep></navbar-icons-rep>
            </div>
          </div>
          <b-collapse id="nav-collapse" is-nav class="myNavbarContent">
            <div class="myNavbarContentNavbar">
              <b-navbar-nav class="z-1001 myNavbarContentTxt">
                <b-nav-item extra-toggle-classes="nav-link-custom" text="Kết quả">
                  <b
                    v-for="gameResultData in getterResultGameId"
                    :key="gameResultData.id"
                    @click="routeLinkResultPage(gameResultData.url)"
                    class="overideNavLink"
                  >
                    Kết quả
                  </b>
                </b-nav-item>
              </b-navbar-nav>
              <b-navbar-nav class="z-1001 myNavbarContentTxt">
                <b-nav-item extra-toggle-classes="nav-link-custom" text="Trực tiếp kết quả xổ số">
                  <router-link to="/truc-tiep"
                    ><b>
                      Trực tiếp
                    </b></router-link
                  >
                </b-nav-item>
              </b-navbar-nav>
              <!-- <b-navbar-nav class="z-1001 myNavbarContentTxt">
                <b-nav-item extra-toggle-classes="nav-link-custom" text="Hướng dẫn">
                  <router-link to="/thong-tin/huong-dan-download">
                     <a><strong>Hướng dẫn</strong></a>
                  </router-link>
                </b-nav-item>
              </b-navbar-nav> -->
              <b-navbar-nav class="myNavbarContentTxtMobile">
                <a class="nav-link" @click="routeLink('/thong-tin/gioi-thieu')">Giới thiệu</a>
              </b-navbar-nav>
              <b-navbar-nav class="myNavbarContentTxtMobile">
                <a class="nav-link" @click="routeLink('/thong-tin/dieu-khoan-su-dung')">Điều khoản sử dụng</a>
              </b-navbar-nav>
              <b-navbar-nav class="myNavbarContentTxtMobile">
                <a class="nav-link" @click="routeLink('/lien-he')">Liên hệ</a>
              </b-navbar-nav>
              <b-navbar-nav
                v-if="isMobile && !isMobileApp && showDownload"
                class="myNavbarContentTxtMobile myNavbarHeight text-center mt-4"
              >
                <p class="color-grey font-18 mb-2">Tải ứng dụng</p>
                <div class="d-flex justify-content-center">
                  <!-- <div class="px-2">
                    <a
                      href="https://apps.apple.com/vn/app/t%C3%A0i-l%E1%BB%99c-mua-h%E1%BB%99-vietlott/id1536375131?l=vi"
                      target="_blank"
                    >
                      <img alt="img" src="../assets/img/app-store.png" width="148" class="heightAuto" />
                    </a>
                  </div>
                  <div class="px-2">
                    <a @click="routeLink('/thong-tin/huong-dan-download')">
                      <img alt="img" src="../assets/img/google-play.png" width="148" class="heightAuto" />
                    </a>
                  </div> -->
                  <div class="px-2">
                    <a :href="`https://play.google.com/store/apps/details?id=com.thantai.app`" target="_blank" class>
                      <img alt="img" src="../assets/img/google-play.png" width="148" class="heightAuto" />
                    </a>
                  </div>
                </div>
              </b-navbar-nav>
            </div>
          </b-collapse>
          <div v-if="!loggedIn" class="ml-auto myNavbarContentBtn d-none d-lg-block">
            <div v-if="!isWeluckMode" class="d-flex align-items-center">
              <a class=" mr-lg-2 pointer btnCustom text-uppercase" @click="handlerRegister">Đăng ký</a>
              <a class="btn headerBtn pointer mx-lg-2" @click="hanlerLogin">Đăng nhập</a>
            </div>
          </div>
          <navbar-icons v-else></navbar-icons>
        </b-navbar>
      </div>
    </header>
  </div>
</template>
<script>
import NavbarIcons from './HeaderDesktop/NavbarIcons';
import NavbarIconsRep from './HeaderDesktop/NavbarIconsRep';
import { isMobile } from 'mobile-device-detect';
import HeaderTrial from '@/components/UI/Header/HeaderTrial';
import { mapGetters } from 'vuex';
import '@terra-js/web-bridge';
import TerraKit from '@terra-js/terra-kit';

export default {
  components: {
    NavbarIcons,
    NavbarIconsRep,
    HeaderTrial
  },
  data() {
    return {
      modalRegister: true,
      isLogin: true,
      isMobile: isMobile,
      titleTutorial: {
        'luat-choi-xsmn': 'Cách chơi vé số kiến thiết'
      }
    };
  },
  mounted() {
    this.getListGameRule();
  },
  computed: {
    ...mapGetters('partner', ['getterExcludeFeature', 'getterPartnerData', 'getterConfig']),
    ...mapGetters(['getterGlobalExcludeFeature']),
    checkHideFeatureBuyGroup() {
      return (
        this.getterExcludeFeature?.includes(this.FEATURE_HANDLER.BUY_GROUP) ||
        this.getterGlobalExcludeFeature?.includes(this.FEATURE_HANDLER.BUY_GROUP) ||
        this.isPaymentIntegration
      );
    },
    isApp() {
      return this.$store.getters.getterIsApp;
    },
    getLoadByIframe() {
      return this.$store.state.loadByIframe;
    },
    isMobileApp() {
      return window && !!window.ReactNativeWebView;
    },
    listGameById() {
      return this.$store.getters.getterListGame;
    },
    getterResultGameId() {
      return this.$store.getters.getterResultGameUrl.filter((item) => item.slug == 'xsmn');
    },
    loggedIn() {
      const isLoggedIn = this.$store.getters.getterLoggedIn;
      return isLoggedIn;
    },
    isPlayTrial() {
      return this.$store.getters.getterPlayTrial;
    },
    customStyleData() {
      return this.$store.getters.getterCustomStyleData;
    },
    listGameRule: {
      get() {
        return this.$store.getters.getterCategoryLuatChoi?.filter((item) => item.tag.includes('xsmn'));
      },
      set(newVal) {
        this.$store.commit('setCategoryLuatChoi', newVal);
      }
    },
    isPartnerMode() {
      return !!this.getterPartnerData?.id;
    },
    srcLogo() {
      return this.getterConfig?.custom_logo || require('../assets/img/logo_thantai_pc.png');
    },
    isTerra() {
      return this.$store.getters.getterIsTerraMode;
    }
  },
  methods: {
    routeLink(value) {
      this.$router.push(value).catch(() => {});
    },
    hanlerLogin() {
      this.$store.commit('setRedirectAfterLogin', null);
      this.$store.commit('setModalLogin', true);
    },
    handlerRegister() {
      this.$store.commit('setRedirectAfterLogin', null);
      this.$store.commit('setModalRegister', true);
    },
    handleClickRoute(id) {
      if (!id) return;
      try {
        let currentId = (id + '')?.replace('buy_group_', '');
        window.dataLayer.push({
          event: 'click-choose-game',
          gameName: this.listGameById[currentId].name,
          labelChooseGame: 'Menu Header'
        });
      } catch (error) {
        console.error('handleClickRoute', error);
      }
    },
    getListGameRule() {
      this.$store.dispatch('getArticleByCategorySlug', 'luat-choi').then((resp) => {
        this.listGameRule = resp;
      });
    },
    routeLinkResultPage(value) {
      this.$router.push(value).catch(() => {
        this.$store.commit('setReloadFlagResultScreen', CustomDate.now());
      });
    },
    backApp() {
      if (this.isTerra && this.$route.path === '/') {
        TerraKit.finish();
        return;
      }
      this.$router.replace('/?back_app');
      // this.$router.replace({
      //   query: {
      //     ...this.$route.query,
      //     back_app: ''
      //   }
      // });
      // this.$router.push('/backapp').catch(() => {});
    }
  }
};
</script>
<style lang="scss">
.zIndex99 {
  z-index: 99;
}
.myNavbarContent {
  background-color: transparent !important;
  padding: 0 !important;
  text-transform: uppercase;
}
.myNavbarItemsToggler {
  height: 25px;
  width: 25px;
  background-image: url('../assets/img/icon_menu.svg') !important;
}
.myNavbarItemsToggler.not-collapsed {
  background-image: url('../assets/img/icon_menu_close.svg') !important;
}

.myNavbarContentTxt {
  @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
    min-height: unset;
    text-transform: initial;
  }
}

.overideNavLink {
  color: #333333 !important;
  &:hover {
    color: #0056b3 !important;
  }
}
</style>
