<template>
  <CommonModal
    title="Chia sẻ"
    @updateOpen="handleOpenModalShare"
    :isOpen="isOpenModalShare"
    :isHiddenFooter="true"
    dialogClass="popupShareFB"
    :isNoCloseOnBackdrop="isLoadingShare"
    :isNoCloseOnEsc="isLoadingShare"
  >
    <div class="pb-3">
      <div ref="shareHolder" id="shareHolder" :style="{ height: holderHeight }">
        <div
          v-if="getShareFbData"
          id="imgShareFb"
          class="imgShare"
          :class="{
            [getShareFbData.classNames]: true
          }"
          :style="shareImgTransform"
        >
          <img
            :draggable="false"
            :src="require(`@/assets/img/sharefb/${getShareFbData.image}`)"
            class="imgShare__background"
          />
          <div class="imgShare__kol" :class="{ win: SHAREFB_TYPE.GIFT_WIN || SHAREFB_TYPE.GIFT_WIN }">
            <span>/{{ getterDataRequestPayment.gift_code }}</span>
          </div>
          <div
            v-if="getterDataRequestPayment.gift_type === SHAREFB_TYPE.GIFT"
            class="imgShare__content"
            :class="{ noMessage: !getterDataRequestPayment.gift_message }"
          >
            <div>
              <span class="colorGold">{{ getLastName ? getLastName : `(${getPhone})` }}</span>
              đã tặng vé số cho {{ getterDataRequestPayment.gift_code }}
            </div>
            <span v-if="getterDataRequestPayment.gift_message">
              với lời nhắn :
            </span>
          </div>
          <div
            v-if="getterDataRequestPayment.gift_type === SHAREFB_TYPE.GIFTED"
            class="imgShare__content"
            :class="{ noMessage: !getterDataRequestPayment.gift_message }"
          >
            <div>
              <span class="colorGold"
                >{{ getterDataRequestPayment.name }} ({{ getterDataRequestPayment.username }})</span
              >
              đã tặng vé số cho {{ getterDataRequestPayment.gift_code }}
              <span v-if="getterDataRequestPayment.gift_message">
                với lời nhắn :
              </span>
            </div>
          </div>
          <div v-if="getterDataRequestPayment.gift_type === SHAREFB_TYPE.GIFT_WIN" class="imgShare__content">
            <div>
              Yay! {{ getterDataRequestPayment.gift_code }} đã trúng thưởng từ vé số của
              <span class="colorGold">{{ getLastName }} ({{ getPhone }})</span>
            </div>
          </div>
          <div v-if="getterDataRequestPayment.gift_type === SHAREFB_TYPE.GIFTED_WIN" class="imgShare__content">
            <div>
              Yay, {{ getterDataRequestPayment.gift_code }} đã trúng thưởng từ vé số của
              <span class="colorGold"
                >{{ getterDataRequestPayment.name }} ({{ getterDataRequestPayment.username }})</span
              >
            </div>
          </div>

          <div
            v-if="getterDataRequestPayment.gift_type === SHAREFB_TYPE.BUY_REPLY"
            class="imgShare__content"
            :class="{ noMessage: !getterDataRequestPayment.gift_message }"
          >
            <div>
              {{ getterDataRequestPayment.gift_code }} gửi lời cảm ơn
              <span class="colorGold textNoWrap"
                >{{ getterDataRequestPayment.name }} ({{ getterDataRequestPayment.username }})</span
              >
              đã tặng vé số
              <span v-if="getterDataRequestPayment.gift_message">
                với lời nhắn :
              </span>
            </div>
          </div>

          <div v-if="getterDataRequestPayment.gift_type === SHAREFB_TYPE.REWARD_REPLY" class="imgShare__content">
            <div>
              {{ getterDataRequestPayment.gift_code }} gửi lời cảm ơn
              <span class="colorGold textNoWrap"
                >{{ getterDataRequestPayment.name }} ({{ getterDataRequestPayment.username }})</span
              >
              đã tặng vé số và trúng thưởng
              <span class="font-weight-bold">{{ getterDataRequestPayment.total_reward }}</span>
            </div>
          </div>

          <template
            v-if="
              getterDataRequestPayment.gift_type === SHAREFB_TYPE.GIFT ||
                getterDataRequestPayment.gift_type === SHAREFB_TYPE.GIFTED ||
                getterDataRequestPayment.gift_type === SHAREFB_TYPE.BUY_REPLY ||
                getterDataRequestPayment.gift_type === SHAREFB_TYPE.REWARD_REPLY
            "
          >
            <div class="imgShare__message" v-if="getterDataRequestPayment.gift_message">
              {{ getterDataRequestPayment.gift_message }}
            </div>
          </template>
          <div v-else>
            <div
              class="imgShare__message reward"
              :class="{
                bigReward: getterDataRequestPayment.total_reward >= 100000000
              }"
            >
              {{ formatPrice(getterDataRequestPayment.total_reward, '') }}
            </div>
          </div>
        </div>
        <div v-else>
          <content-placeholders>
            <content-placeholders-img class="imgSharePlaceholder" />
          </content-placeholders>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-3">
        <button @click="getImage" class="btn btnDownload mr-2">
          <img src="@/assets/img/icon/cloud_download_black_24dp.svg" alt="icon download" /> tải ảnh
        </button>
        <button @click="handleShareFb" class="btn" :disabled="isLoadingShare">
          <b-spinner v-if="isLoadingShare" small class="mr-2"></b-spinner>
          <img v-else src="@/assets/img/icon/icon_invite-fb.svg" alt="icon fb" /> chia sẻ facebook
        </button>
      </div>
    </div>
  </CommonModal>
</template>

<script>
import html2canvas from 'html2canvas';
import { mapGetters } from 'vuex';
import CommonModal from './CommonModal.vue';
import { SHAREFB_TYPE } from '../common/global';
import OrderAppService from '../api/order';

import _ from 'lodash';

export default {
  name: 'ModalShareFb',
  components: { CommonModal },
  data() {
    return {
      SHAREFB_TYPE,
      shareFbData: {
        GIFT: { imageType: 'order', classNames: 'gift', image: 'STREAMER_TANGVE.jpg' },
        GIFT_WIN: {
          imageType: 'reward',
          classNames: 'giftWin',
          image: 'STREAMER_VETRUNGTHUONG.jpg'
        },
        GIFTED: {
          imageType: 'gifted_order',
          classNames: 'gift',
          image: 'STREAMER_TANGVE.jpg'
        },
        GIFTED_WIN: {
          imageType: 'gifted_reward',
          classNames: 'giftWin',
          image: 'STREAMER_VETRUNGTHUONG.jpg'
        },
        GIFTED_NO_MESSAGE: {
          classNames: 'gift',
          image: 'STREAMER_TANGVE.jpg'
        },
        BUY_REPLY: {
          imageType: 'buy_reply',
          classNames: 'gift',
          image: 'STREAMER_TANGVE.jpg'
        },
        REWARD_REPLY: {
          imageType: 'reward_reply',
          classNames: 'thankWin',
          image: 'STREAMER_VETRUNGTHUONG.jpg'
        }
      },
      isLoadingShare: false,
      shareImgTransform: null,
      holderHeight: 'auto'
    };
  },
  computed: {
    ...mapGetters(['getterIsOpenModalShare']),
    isOpenModalShare: {
      get() {
        return this.getterIsOpenModalShare;
      },
      set(value) {
        this.$store.commit('setIsOpenModalShare', value);
      }
    },
    ...mapGetters(['getterDataRequestPayment']),
    isGiftOrder() {
      return !!this.getterDataRequestPayment.gift_code;
    },
    getLastName() {
      const { name } = this.$store.getters.getterUserInfo || {};
      return name?.split(' ').pop();
    },
    getPhone() {
      const { phone } = this.$store.getters.getterUserInfo || {};
      return this.censorData(phone, 3, 6);
    },
    getShareFbData() {
      const { gift_type, gift_message } = this.getterDataRequestPayment;
      if (gift_type) {
        const listNoMessage = ['GIFT', 'GIFTED'];

        if (listNoMessage.includes(gift_type) && !gift_message) {
          return this.shareFbData.GIFTED_NO_MESSAGE;
        } else {
          return this.shareFbData[gift_type];
        }
      } else {
        return null;
      }
    }
  },
  methods: {
    setShareImgTransform() {
      // Delay animation
      setTimeout(() => {
        const { shareHolder } = this.$refs;
        const scale = shareHolder?.clientWidth / 1200 || 1;
        this.shareImgTransform = {
          transform: `scale(${scale})`,
          transformOrigin: '0 0'
        };
        // Set height to auto, wait for it to render, then recalculate height
        this.holderHeight = 'auto';
        setTimeout(() => {
          const oldHeight = shareHolder.clientHeight;
          this.holderHeight = `${scale * oldHeight}px`;
        }, 100);
      }, 100);
      // transform = holder width / default width
    },
    handleOpenModalShare(value) {
      this.isOpenModalShare = value;
    },
    handleShareFb: _.debounce(async function() {
      this.isLoadingShare = true;
      const { ticket_id, order_id, gift_type } = this.getterDataRequestPayment;
      let bodyFormData = new FormData();

      if (order_id) {
        bodyFormData.append('order_id', order_id);
      }

      if (ticket_id) {
        bodyFormData.append('ticket_id', ticket_id);
      }

      if (this.shareFbData[gift_type].imageType) {
        bodyFormData.append('type', this.shareFbData[gift_type].imageType);
      } else {
        this.isLoadingShare = false;
        return;
      }

      let node = document.getElementById('shareHolder');
      html2canvas(node)
        .then((canvas) => {
          if (canvas.toBlob) {
            canvas.toBlob((blob) => {
              bodyFormData.append('image', blob);
              OrderAppService.postOrderGiftImage(bodyFormData)
                .then((res) => {
                  if (res.data.image_url) {
                    if (this.isMobileApp) {
                      this.postMessage('OPEN_DEEP_LINK', {
                        url: this.generateDeepLink('https://www.facebook.com/sharer/sharer.php?u=' + res.data.image_url)
                      });
                    } else {
                      window.open(
                        'https://www.facebook.com/sharer/sharer.php?u=' + res.data.image_url,
                        '',
                        'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600'
                      );
                    }
                  }
                })
                .catch((e) => {
                  console.error('oops, something went wrong!', e);
                })
                .finally(() => {
                  this.isLoadingShare = false;
                });
            });
          }
        })
        .catch(function(error) {
          this.isLoadingShare = false;
          console.error('oops, something went wrong!', error);
        });
    }, 200),
    getImage() {
      let node = document.getElementById('imgShareFb');
      html2canvas(node)
        .then((canvas) => {
          const fileName = `share_fb_${this.formatDate(new CustomDate(), 'DD_MM_YYYY_HH[h]mm')}`;

          if (window.ReactNativeWebView) {
            this.sendMessageToApp(
              JSON.stringify({ event: 'SAVE_IMAGE', data: { image: canvas.toDataURL('image/png') } })
            );
          } else {
            this.downloadImage(fileName, canvas.toDataURL());
          }
        })
        .catch(function(error) {
          console.error('oops, something went wrong!', error);
        });
    }
  },
  watch: {
    isOpenModalShare: function(newValue) {
      if (!newValue) {
        // this.$store.commit('setDataRequestPayment', {});
        this.isLoadingShare = false;
      } else {
        this.$nextTick(() => {
          this.setShareImgTransform();
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.imgShare {
  font-family: 'Playfair Display', serif;

  line-height: 1.3;
  min-height: 300px;
  background: #00489d;
  color: white;

  border-radius: 5px;
  overflow: hidden;
  margin: 0;
  position: relative;
  width: 1200px;
  height: 628px;
  overflow: hidden;

  &__kol {
    position: absolute;
    bottom: 7.1%;
    left: 19.5%;
    font-size: 25px;
    font-weight: 600;
  }

  &__background {
    max-width: 100%;
  }

  &__content {
    position: absolute;
    text-align: center;
    font-size: 33px;
    width: 42%;
  }

  &__message {
    position: absolute;
    text-align: center;
    display: block;
    width: 42%;
    font-size: 30px;
    padding: 1.5%;
    background: rgba(#f40303, 0.5);

    &.reward {
      font-size: 60px;
    }

    &.bigReward {
      font-size: 55px;
    }
  }

  &.gift &__content {
    top: 11%;
    right: 11.4%;
    &.noMessage {
      top: 30%;
      font-size: 32px;
    }
  }

  &.gift &__message {
    top: 32%;
    right: 11.4%;
  }

  &.giftWin &__content {
    top: 31%;
    right: 4.5%;
    width: 51%;
    font-size: 34px;
  }

  &.giftWin &__message {
    top: 48%;
    right: 8%;
    padding: 0;
    font-size: 81px;
    font-weight: bold;
    line-height: 1.2em;
    height: 1.4em;
  }
  &.giftWin &__kol {
    bottom: 5.9%;
    left: 70%;
  }

  &.thankWin &__content {
    top: 11%;
    right: 11.4%;
    &.noMessage {
      top: 30%;
      font-size: 32px;
    }
  }

  &.thankWin &__message {
    top: 32%;
    right: 11.4%;
  }

  &.thankWin &__kol {
    bottom: 5.9%;
    left: 70%;
  }
}
.imgSharePlaceholder {
  height: 300px;
  margin-bottom: 20px;
}
.btn {
  height: 36px;
  border-radius: 3px;
  background-color: #00489d;
  color: #fff;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.18px;

  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
  }

  &:hover {
    color: #fff;
  }

  &.btnDownload {
    background-color: #e5edf5;
    color: #00489d;
  }
}
.font-31 {
  font-size: 31px;
}

.colorGold {
  color: #ffe655 !important;
}
</style>
<style lang="scss">
.popupShareFB {
  max-width: 800px !important;
}

@media (max-width: map-get($grid-breakpoints, 'sm')) {
  .popupShareFB {
    max-width: 800px !important;
    .modal-body {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
</style>
