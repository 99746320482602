var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"text-center mb-lg-2"},[(_vm.isSmsVerify)?_c('span',[_vm._v("Vui lòng điền mã xác nhận sẽ được tổng đài cung cấp qua cuộc gọi tự động đến số điện thoại")]):_c('span',[_vm._v("Vui lòng điền mã xác nhận đã được gửi đến số điện thoại")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.phoneNumber)+" ")]),_vm._v(" để xác minh. ")]),_c('form',{ref:"form2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',[_c('div',{staticClass:"modalRegisterLabel"},[_c('label',{attrs:{"for":"verificationInput"}},[_vm._v("Mã xác nhận")]),_c('button',{staticClass:"modalRegisterSendAgain pointer btn p-0",attrs:{"type":"button","disabled":_vm.timer > 0},on:{"click":_vm.requestCaptcha}},[(_vm.timer == 0)?_c('p',[_vm._v("Gửi lại mã")]):_c('p',{staticClass:"modalRegisterLabelRight",attrs:{"for":"verificationInput"}},[_vm._v("(Gửi lại sau "+_vm._s(_vm.timer)+" giây)")])])]),_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
          min: _vm.isSmsVerify ? 4 : 6,
          max: _vm.isSmsVerify ? 4 : 6,
          required: true,
          numeric: true
        }),expression:"{\n          min: isSmsVerify ? 4 : 6,\n          max: isSmsVerify ? 4 : 6,\n          required: true,\n          numeric: true\n        }"}],ref:"codeOverview",class:{
          input: true,
          'is-danger': _vm.errors.has('codeOverview')
        },attrs:{"id":"codeOverview","trim":"","type":"number","data-vv-as":"Mã xác nhận","name":"codeOverview","pattern":"[0-9]*"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handlerConfirmRegister($event)}},model:{value:(_vm.codeOverview),callback:function ($$v) {_vm.codeOverview=$$v},expression:"codeOverview"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('codeOverview')),expression:"errors.has('codeOverview')"}],staticClass:"is-danger-text position-relative"},[_vm._v(_vm._s(_vm.errors.first('codeOverview')))])],1),_c('div',{staticClass:"h-100 modalRegisterFooter"},[_c('div',{staticClass:"justify-content-center"},[_c('div',{staticClass:"w-100"},[_c('button',{staticClass:"btn w-100 text-uppercase btnConfirm position-relative test",class:{ 'opacity-7 cursor-none': _vm.isLoading || _vm.errors.has(_vm.verificationNumber) || !_vm.codeOverview },attrs:{"disabled":_vm.isLoading || _vm.errors.has(_vm.verificationNumber) || _vm.isDisabled},on:{"click":function($event){return _vm.handlerConfirmRegister()}}},[_vm._v(" XÁC MINH ")])])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }