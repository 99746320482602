import Vue from 'vue';
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);
export default {
  setInviteCodeDisplayData(state, value) {
    state.inviteCodeDisplayData = value;
  },
  setInviteCode(state, value) {
    state.inviteCode = value;
  },
  setIsLoadBalances(state, value) {
    state.isLoadBalances = value;
  },
  setIsMaintain(state, value) {
    state.isMaintain = value;
  },
  setDeferredPrompt(state, value) {
    state.deferredPrompt = value;
  },
  setIsRegister(state, value) {
    window.storageHelper.setItem('__is_register', JSON.stringify(value));
    state.isRegister = value;
  },
  setIsHomeScreen(state, value) {
    Vue.$cookies.set('__timerHomeScreen', JSON.stringify(value), '1d');
    state.isHomeScreen = value;
  },
  setIsApp(state, value) {
    state.isApp = value;
  },
  setModalDeposit(state, value) {
    state.modalDeposit = value;
  },
  setModalTerm(state, value) {
    window.storageHelper.setItem('__modal_term', JSON.stringify(value));
    state.isModalTerm = value;
  },
  setDriverUniqueId(state, value) {
    state.driverUniqueId = value;
  },
  setTimezone(state, value) {
    state.timezone = value;
  },
  setUserProfile(state, value) {
    state.userProfile = value;
    window.storageHelper.setItem('user_local', JSON.stringify(value));
  },
  setModalNotification(state, value) {
    state.isNotification = value;
  },
  setModalRegister(state, value) {
    state.showModalRegister = value;
    if (!value) {
      state.inviteCode = '';
    }
  },
  setModalForgotPassword(state, value) {
    state.showModalForgotPassword = value;
  },
  setModalLogin(state, value) {
    state.showModalLogin = value;
  },
  setModalVerifyPhone(state, value) {
    state.showVerifyPhone = value;
  },
  setIsTerraMode(state, value) {
    state.isTerraMode = value;
  },
  setTimerWaiting(state, value) {
    state.timerWaiting = value;
  },
  setlistNotifications(state, value) {
    state.listNotifications = value;
  },
  setListPageNotification(state, value) {
    state.listPageNotification = value;
  },
  setTimerWaitingPhone(state, value) {
    state.timerWaitingPhone = value;
  },
  setToken(state, value) {
    state.token = value;
  },
  setDepositBalance(state, value) {
    state.depositBalance = value;
  },
  setPendingBalance(state, value) {
    state.pendingBalance = value;
  },
  setRewardBalance(state, value) {
    state.rewardBalance = value;
  },
  setObjPrize(state, value) {
    state.objPrizeKeno = value;
  },
  setListProductGame(state, value) {
    state.listProductGame = value;
  },
  setMetadataKeno(state, value) {
    if (value) {
      state.metadataKeno = value;
    }
  },
  setIsOpenPopupPaymentError(state, value) {
    state.isOpenPopupPaymentError = value;
  },
  setIsOpenPopupNotifyMessage(state, value) {
    state.isOpenPopupNotifyMessage = value;
  },
  setPopupNotifyMessageData(state, value) {
    state.popupNotifyMessageData = value;
  },
  setIsSentValidate(state, value) {
    state.isSentValidate = value;
  },
  changeUsers(state, value) {
    state.users.phoneNumber = value.phoneNumber;
    state.users.password = value.password;
    state.users.name = value.name;
    state.users.hash = value.hash;
    state.users.sessionId = value.sessionId;
    state.users.callbackSocialLinkModify = value.callbackSocialLinkModify;
  },
  setDestroyToken(state) {
    state.token = null;
  },
  setCheckLoginBuyGame(state, value) {
    state.isCheckLoginBuyGame = value;
  },
  setCheckLoginAfterBuyGame(state, value) {
    state.isCheckLoginAfterBuyGame = value;
  },
  setObjBanner(state, value) {
    state.objBanner = value;
  },
  setIsLoadingBanner(state, value) {
    state.isLoadingBanner = value;
  },
  setIsLoadingProduct(state, value) {
    state.isLoadingProduct = value;
  },

  setListTicketActived(state, value) {
    state.listTicketActived = value;
  },
  setListTicketOverview(state, value) {
    state.listTicketOverview = value;
  },
  setListTicketOverviewFeedKeno(state, value) {
    state.listTicketOverviewFeedKeno = value;
  },
  setListTimeDialOverview(state, value) {
    state.listTimeDialOverview = value;
  },
  setListTimeDialOverviewForReal(state, value) {
    state.listTimeDialOverviewForReal = value;
    window.storageHelper.setItem('list_timer_dial_for_real', JSON.stringify(value));
  },
  setListTicketOverviewForReal(state, value) {
    state.listTicketOverviewForReal = value;
    window.storageHelper.setItem('list_ticket_for_real', JSON.stringify(value));
  },
  setListTimeDialPowerOverview(state, value) {
    state.listTimeDialPowerOverview = value;
  },
  setListTicketPowerOverview(state, value) {
    state.listTicketPowerOverview = value;
  },
  setKeepDataActived(state, value) {
    state.keepDataActived = value;
    window.storageHelper.setItem('keep_data', JSON.stringify(value));
  },
  setUserInfo(state, value) {
    state.userInfo = value;
  },
  setLocationHome(state, value) {
    state.locationHome = value;
  },

  setWithdrawHistoryDetail(state, value) {
    state.withdrawHistoryDetail = value;
  },
  setInputPasswordAgain(state, value) {
    state.inputPasswordAgain = value;
  },
  setOrderTab(state, value) {
    state.orderTab = value;
  },
  setRouterExpire(state, obj) {
    state.isCheckRouter = obj.isChecked;
    state.textExpire = obj.isText
      ? 'Phiên đăng nhập đã hết hạn.<br />Vui lòng đăng nhập lại để tiếp tục.'
      : 'Bạn cần đăng nhập để mua vé.';
  },
  setListTicketFavoriteKeno(state, value) {
    state.ListTicketFavoriteKeno = value;
  },
  setListAllFavorite(state, value) {
    state.listAllFavorite = value;
  },
  setListAllFavoriteDropdown(state, value) {
    state.listAllFavoriteDropdown = value;
  },
  setIsCreateFavorite(state, value) {
    state.isCreateFavorite = value;
  },
  setIsSaveReceiver(state, value) {
    state.isSaveReceiver = value;
  },
  setLastname(state, value) {
    state.lastName = value;
  },
  setLoadingDropdown(state, value) {
    state.isLoadingDropdown = value;
  },
  setTotalPageDropdown(state, value) {
    state.totalPageDropdown = value;
  },
  setIsOpenModalTransactionResult(state, value) {
    state.isOpenModalTransactionResult = value;
  },
  setCart(state, value) {
    state.cart = value;
  },
  setPowerPeriod(state, value) {
    state.powerPeriod = value;
  },
  setMegaPeriod(state, value) {
    state.megaPeriod = value;
  },
  setListGame(state, value) {
    const gameStatus = {};
    if (value) {
      Object.keys(value).forEach((key) => {
        gameStatus[key] = value[key].status;
      });
      state.gameStatus = gameStatus;
    }
    state.listGame = value;
  },
  setDisplayOrderId(state, value) {
    state.display_order_id = value;
  },
  setListGameHandler(state, value) {
    state.listGameHandler = value;
  },
  setListFavoriteProp(state, value) {
    state.listFavoriteProp = value;
  },
  setSavePagination(state, value) {
    state.savePagination = value;
  },
  setSavePaginationNotiPage(state, value) {
    state.savePaginationNotiPage = value;
  },
  setsavePaginationFavorite(state, value) {
    state.savePaginationFavorite = value;
  },
  setHistoryResult(state, value) {
    state.historyResult = value;
  },
  setSaveHistoryFlagLength(state, value) {
    state.saveHistoryFlagLength = value;
  },
  setSaveFilterListActived(state, value) {
    state.saveFilterListActived = value;
  },
  setSaveFilterListActivedGiftTicket(state, value) {
    state.saveFilterListActivedGiftTicket = value;
  },
  setRedirectAfterLogin(state, value) {
    state.redirectAfterLogin = value;
  },
  setKenoCustomProductList(state, value) {
    state.kenoCustomProductList = value;
  },
  setGameStatus(state, value) {
    const { game_id, status } = value;
    if (game_id) {
      state.gameStatus = { ...state.gameStatus, [game_id]: status };
    }
  },
  setUserSettings(state, value) {
    state.userSettings = value;
  },
  setTooltipCoordinates(state, value) {
    state.tooltipCoordinates = {
      ...state.tooltipCoordinates,
      ...value
    };
  },
  setPlayTrial(state, value) {
    state.playTrial = value;
  },
  setIsOpenModalPlayTrial(state, value) {
    state.isOpenModalPlayTrial = value;
  },
  setCurrentTrialStep(state, value) {
    state.currentTrialStep = value;
  },
  setShowOverlay(state, value) {
    state.showOverlay = value;
  },
  setIsOpenModalPaymentSuccess(state, value) {
    state.isOpenModalPaymentSuccess = value;
  },
  setIsOpenPopupCongrats(state, value) {
    state.isOpenPopupCongrats = value;
  },
  setUrlPopupGrate(state, value) {
    state.urlPopupGrate = value;
  },
  setObjNoCloseOnEscAndBackdrop(state, value) {
    state.objNoCloseOnEscAndBackdrop.isNoCloseOnEsc = value.isNoCloseOnEsc;
    state.objNoCloseOnEscAndBackdrop.isNoCloseOnBackdrop = value.isNoCloseOnBackdrop;
  },
  setLoadByIframe(state, value) {
    state.loadByIframe = value;
  },
  setListPaymentMethod(state, value) {
    state.listPaymentMethod = value;
  },
  setIsConnectedSocket(state, value) {
    state.isConnectedSocket = value;
  },
  setLocationLoad(state, value) {
    state.locationLoad = value;
  },
  setParentUserInfo(state, value) {
    state.parentUserInfo = value;
  },
  setModalQuickBuyInfo(state, value) {
    state.modalQuickBuyInfo = value;
  },
  setQuickPaymentSuccessData(state, value) {
    state.quickPaymentSuccessData = value;
  },
  setIsOpenModalQuickPaymentSuccess(state, value) {
    state.isOpenModalQuickPaymentSuccess = value;
  },
  setCurrentTransaction(state, value) {
    state.currentTransaction = value;
  },
  setCustomStyleData(state, value) {
    state.customStyleData = value;
  },
  setPaymentIntegration(state, value) {
    state.paymentIntegration = value;
  },
  setAllowPopupOverflowIframe(state, value) {
    state.allowPopupOverflowIframe = value;
  },
  setAllowPopupRegister(state, value) {
    state.allowPopupRegister = value;
  },
  setFakeBackground(state, value) {
    state.fakeBackground = value;
  },
  setIsExistModalOpen(state, value) {
    state.isExistModalOpen = value;
  },
  setIsExistModalOpenNormal(state, value) {
    state.isExistModalOpenNormal = value;
  },
  setPayloadGameTrial(state, value) {
    state.payloadGameTrial = value;
  },
  setIsOpenPopupWaitingPayment(state, value) {
    state.isOpenPopupWaitingPayment = value;
  },
  setTransactionFinisedFromParent(state, value) {
    state.transactionFinisedFromParent = value;
  },
  setNewOrder(state, value) {
    state.newOrder = value;
  },
  setReloadFlagResultScreen(state, value) {
    state.reloadFlagResultScreen = value;
  },
  setIsOpenPopupCongrat(state, value) {
    state.isOpenPopupCongrat = value;
  },
  setPopupCongrateData(state, value) {
    state.popupCongrateData = value;
  },
  setWeluckMode(state, value) {
    state.isWeluckMode = value;
  },
  setListPeriodFullCapacity(state, value) {
    state.listPeriodFullCapacity = value;
  },
  setListIdols(state, value) {
    state.listIdols = value;
  },
  setReferralCode(state, value) {
    state.referralCode = value;
  },
  setDataRequestPayment(state, value) {
    state.dataRequestPayment = value;
  },
  setIsOpenModalShare(state, value) {
    state.isOpenModalShare = value;
  },
  setListWaitingData(state, value) {
    state.waitingData = value;
  },
  setIsOpenPopupGiftTicket(state, value) {
    state.isOpenPopupGiftTicket = value;
  },
  setPopupGiftTicketData(state, value) {
    state.popupGiftTicketData = value;
  },
  setIsOpenSendThank(state, value) {
    state.isOpenSendThank = value;
  },
  setPopupSendThankData(state, value) {
    state.popupSendThankData = value;
  },
  setIsOpenPopupThank(state, value) {
    state.isOpenPopupThank = value;
  },
  setPopupThankData(state, value) {
    state.popupThankData = value;
  },
  setSaveSelectIdolData(state, value) {
    state.saveSelectIdolData = value;
  },
  setIsSetSelectIdolData(state, value) {
    state.isSetSelectIdolData = value;
  },
  setFireBaseReady(state, ready) {
    state.firebaseReady = ready;
  },
  setBuyTicketAgainData(state, value) {
    state.buyTicketAgainData = value;
  },
  setIsShowConfirmPayment(state, value) {
    state.isShowConfirmPayment = value;
  },
  setKenoThreeLastestResult(state, value) {
    state.kenoThreeLastestResult = value;
  },
  setIsLoadingGameResult(state, value) {
    state.isLoadingGameResult = value;
  },
  setIsShowPeriodHint(state, value) {
    state.isShowPeriodHint = value;
  },
  setThreeLatestResult(state, value) {
    state.threeLatestResult = value;
  },
  setExcludeListNumber(state, value) {
    state.excludeListNumber = value;
  },
  setStatisticKenoResult(state, value) {
    state.statisticKenoResult = value;
  },
  setMaxGameLatestResult(state, value) {
    state.maxGameLatestResult = value;
  },
  setIsOpenFilterBuyTogether(state, value) {
    state.isOpenFilterBuyTogether = value;
  },
  setFilterBuyTogetherValue(state, value) {
    state.filterBuyTogetherValue = value;
  },
  setPowerGameType(state, value) {
    state.powerGameType = value;
  },
  setMegaGameType(state, value) {
    state.megaGameType = value;
  },
  setListenedReview(state, value) {
    state.isListenedReview = value;
  }
};
