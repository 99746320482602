<template>
  <CommonModal
    @updateOpen="isOpen = $event"
    dialogClass="popupThankYou"
    :isOpen="isOpen"
    @handleConfirm="handleSendMessage"
    @handleCancle="handleCancelSendMessage"
    title="Gửi cảm ơn"
    confirmTextBtn="Gửi"
    cancleTextBtn="Để sau"
    :isCustom="false"
    :isCustomUI="true"
    :isConfirmBtnDisable="isConfirmBtnDisable"
  >
    <div>
      <p class="mb-2">
        Gửi cảm ơn đến {{ popupSendThankData.name }}
        {{ popupSendThankData.username ? `(${censorData(popupSendThankData.username, 3, 6)})` : '' }}
      </p>
      <div>
        <div class="position-relative">
          <b-form-input
            autocomplete="off"
            v-model="message"
            type="text"
            class="customInput"
            placeholder="Nhập nội dung"
            maxlength="140"
            @focus="handleFocus"
          ></b-form-input>
          <!-- <span class="arrowDown"></span> -->
        </div>

        <!-- <div class="listHint scrollbar">
          <b-collapse id="collapse-hint">
            <div v-for="(item, index) in listHintComp" :key="index">
              <div @click="chooseMessage(item)" class="d-flex align-items-center hintItem">
                <p class="color-black text-truncate">{{ item }}</p>
              </div>
            </div>
          </b-collapse>
        </div> -->
      </div>
    </div>
  </CommonModal>
</template>

<script>
import OrderAppService from '../api/order';
import { BUS_EVENT, TICKET_MESSAGE_ENUM } from '../common/global';
import { Noti } from '../main';
import CommonModal from './CommonModal.vue';
export default {
  components: { CommonModal },
  data() {
    return {
      message: '',
      listHint: {
        reward_reply: ['Trúng rồi nè, dắt [name] đi ăn luôn.', 'Cám ơn [name] nha!'],
        buy_reply: ['Cảm ơn [name] đã tặng tui nha.', 'Cảm ơn [name] nha, hồi hợp quá.']
      },
      showHint: false
    };
  },
  computed: {
    isConfirmBtnDisable() {
      return !this.message.trim().length;
    },
    isOpen: {
      get() {
        return this.$store.getters.getterIsOpenSendThank;
      },
      set(newValue) {
        this.$store.commit('setIsOpenSendThank', newValue);
      }
    },
    popupGiftTicketData: {
      get() {
        return this.$store.getters.getterPopupGiftTicketData;
      },
      set(newVal) {
        return this.$store.commit('setPopupGiftTicketData', newVal);
      }
    },
    popupSendThankData: {
      get() {
        return this.$store.getters.getterPopupSendThankData;
      },
      set(newVal) {
        return this.$store.commit('setPopupSendThankData', newVal);
      }
    },
    listAllNotifications: {
      get() {
        return this.$store.getters.getterlistPageNotification;
      },
      set(value) {
        this.$store.commit('setListPageNotification', value);
      }
    },
    listNotifications: {
      get() {
        return this.$store.getters.getterListNotifications;
      },
      set(value) {
        this.$store.commit('setlistNotifications', value);
      }
    },
    popupCongrateData: {
      get() {
        return this.$store.getters.getterPopupCongrateData;
      },
      set(newVal) {
        return this.$store.commit('setPopupCongrateData', newVal);
      }
    }
    // listHintComp() {
    //   if (this.listHint?.[this.popupSendThankData?.type]) {
    //     let { name } =
    //       (this.popupSendThankData.fromPopupGift ? this.popupGiftTicketData.raw_data : this.popupSendThankData) || {};

    //     return this.listHint?.[this.popupSendThankData?.type].map((item) => item.replace('[name]', name));
    //   }
    //   return [];
    // }
  },
  mounted() {
    // this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
    //   if (collapseId === 'collapse-hint') {
    //     this.showHint = isJustShown;
    //   }
    // });
  },
  methods: {
    handleFocus() {
      // if (!this.showHint) {
      //   this.$root.$emit('bv::toggle::collapse', 'collapse-hint');
      // }
    },
    chooseMessage(value) {
      this.message = value;
      if (this.showHint) {
        this.$root.$emit('bv::toggle::collapse', 'collapse-hint');
      }
    },
    handleSendMessage() {
      OrderAppService.saveTicketMessage(this.popupSendThankData.display_ticket_id, {
        type: this.popupSendThankData.type,
        message: this.message,
        additon_ticket: this.popupSendThankData?.listDisplayTicket
          ? this.popupSendThankData?.listDisplayTicket
          : undefined
      })
        .then(() => {
          Noti.fire({
            icon: 'success',
            title: 'Đã gửi cảm ơn',
            showConfirmButton: false,
            timer: 2000
          });

          if (this.popupSendThankData.type === TICKET_MESSAGE_ENUM.REWARD_REPLY) {
            this.$bus.$emit(BUS_EVENT.UPDATE_REWARD_REPLY, this.message);
          } else {
            this.$bus.$emit(BUS_EVENT.UPDATE_ORDER_REPLY, this.message);
          }

          if (this.popupSendThankData.fromPopupGift || this.popupSendThankData.fromPopupGiftReward) {
            const { id: notiId } = this.popupSendThankData.fromPopupGift
              ? this.popupGiftTicketData
              : this.popupCongrateData;
            const newPopupGiftTicketData = JSON.parse(
              JSON.stringify(this.popupSendThankData.fromPopupGift ? this.popupGiftTicketData : this.popupCongrateData)
            );
            const foundIndexNoti = this.listAllNotifications.findIndex((item) => item.id === notiId);
            const foundIndexLastNoti = this.listNotifications.findIndex((item) => item.id === notiId);

            if (this.popupSendThankData.type === TICKET_MESSAGE_ENUM.REWARD_REPLY) {
              newPopupGiftTicketData.raw_data.reward_reply_message = this.message;
            } else {
              newPopupGiftTicketData.raw_data.buy_reply_message = this.message;
            }

            if (foundIndexNoti >= 0) {
              let oldListNoti = [...this.listAllNotifications];
              oldListNoti[foundIndexNoti] = { ...newPopupGiftTicketData };
              this.listAllNotifications = oldListNoti;
            }

            if (foundIndexLastNoti >= 0) {
              let oldListNoti = [...this.listNotifications];
              oldListNoti[foundIndexLastNoti] = { ...newPopupGiftTicketData };
              this.listNotifications = oldListNoti;
            }

            if (this.popupSendThankData.fromPopupGift) {
              this.popupGiftTicketData = newPopupGiftTicketData;
            } else {
              this.popupCongrateData = newPopupGiftTicketData;
            }

            this.handleCancelSendMessage();
          }

          this.message = '';
        })
        .catch(() => {
          Noti.fire({
            icon: 'error',
            title: 'Gửi cảm ơn không thành công. Vui lòng thử lại.',
            showConfirmButton: false,
            timer: 2000
          });
        });
    },
    handleCancelSendMessage() {
      if (this.popupSendThankData.fromPopupGift) {
        this.$store.commit('setIsOpenPopupGiftTicket', true);
      }

      if (this.popupSendThankData.fromPopupGiftReward) {
        this.$store.commit('setIsOpenPopupCongrat', true);
      }
    }
  },
  watch: {
    isOpen: function(value) {
      if (value) {
        this.message = '';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.hintItem {
  padding: 10px 0.75rem;
  border-bottom: 1px solid #efefef;
  cursor: pointer;
  &:hover {
    background-color: #efefef;
  }
}
.listHint {
  max-height: 300px;
  overflow-y: auto;
}
.customInput {
  height: 40px;
  font-size: 15px;
  padding-right: 30px;

  &:focus {
    border-color: #efefef !important;
  }
}

.arrowDown {
  width: 7px;
  height: 7px;

  border-left: 2px solid #999;
  border-bottom: 2px solid #999;

  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-65%) rotateZ(-45deg);
}
</style>
<style lang="scss">
.popupThankYou {
  max-width: 410px !important;
}
</style>
