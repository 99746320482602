import { Axios } from '../common/config';
export const OrderAppService = {
  postOrder(data) {
    return Axios.post('/orders', data).then((resp) => {
      return resp;
    });
  },
  getTransactionHistory(status, offset = 1, limit = 10, from = '', to = '', gameId, q = '', type = 'OWN', reply) {
    return Axios.get(`/orders`, { params: { status, from, to, limit, offset, gameId, q, type, reply } }).then(
      (resp) => resp
    );
  },
  getTransactionHistoryBuyGroup(
    status,
    offset = 1,
    limit = 10,
    from = '',
    to = '',
    gameId,
    q = '',
    type = 'OWN',
    reply
  ) {
    return Axios.get(`/group-order/orders`, {
      params: { status, from, to, limit, offset, gameId, q, type, reply }
    }).then((resp) => resp);
  },
  getTicketDetail(ticketId) {
    return Axios.get(`/tickets/${ticketId}`).then((response) => response);
  },
  getGroupTicketDetail(ticketId) {
    return Axios.get(`/ticket-groups/${ticketId}`).then((response) => response);
  },
  postOrderV2(data) {
    return Axios.post('/v2/orders', data).then((resp) => {
      return resp;
    });
  },
  postGroupOrder(data) {
    return Axios.post('/group-order/orders', data).then((resp) => {
      return resp;
    });
  },
  postOrderTraditional(data) {
    return Axios.post('/traditional-ticket/orders', data).then((resp) => {
      return resp;
    });
  },
  postOrderCancelTicket(ticket_id, account_id) {
    return Axios.post(`/ticket/${ticket_id}/cancel-ticket`, { account_id }).then((resp) => {
      return resp;
    });
  },
  postOrderBuyNextPeriod(ticket_id, account_id) {
    return Axios.post(`/ticket/${ticket_id}/buy-next-period`, { account_id }).then((resp) => {
      return resp;
    });
  },
  getTicketStatus(ticketId) {
    return Axios.get(`/tickets/get-ticket-status/${ticketId}`).then((response) => response);
  },
  postOrderQuickBuy(data) {
    return Axios.post('/instant-orders', data).then((resp) => {
      return resp.data;
    });
  },
  updateMailForOrder(orderId, data) {
    return Axios.post(`/instant-orders/${orderId}/mails`, data).then((resp) => {
      return resp.status;
    });
  },
  updateClientIdForPayment(data) {
    return Axios.post('/payment-clients', data).then((resp) => {
      return resp.data;
    });
  },
  checkOrderCapacity(data) {
    return Axios.post('/orders/check-order-capacity', data).then((resp) => {
      return resp.data;
    });
  },
  postOrderGiftImage(data) {
    return Axios.post('/orders/gift-images', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then((resp) => resp.data);
  },
  saveTicketMessage(ticketId, data) {
    return Axios.post(`/tickets/${ticketId}/messages`, data).then((resp) => {
      return resp.data;
    });
  },
  getBarcodeResult(data) {
    return Axios.post(`/barcode-result`, data).then((response) => response);
  }
};

export default OrderAppService;
