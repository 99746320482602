var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"headerClass":"","footerClass":_vm.footerClass,"modal-class":( _obj = {
    customModal: true,
    pt40: _vm.isHeaderPlaytrial,
    fullHeight: _vm.fullHeight
  }, _obj[_vm.modalClass] = !!_vm.modalClass, _obj ),"content-class":( _obj$1 = {}, _obj$1[_vm.contentClass] = !!_vm.contentClass, _obj$1 ),"dialog-class":("customDialog " + _vm.dialogClass),"body-class":("pt-0 " + (_vm.isNoPaddingContent ? 'px-0' : '')),"hide-footer":_vm.isHiddenFooter,"no-close-on-esc":_vm.isNoCloseOnEsc,"scrollable":_vm.isScrollable,"centered":_vm.isCentered,"hide-header":_vm.isHiddenHeader,"static":_vm.isStatic,"no-close-on-backdrop":_vm.isNoCloseOnBackdrop,"hide-backdrop":_vm.isHideBackdrop,"hide-header-close":_vm.hideHeaderClose},on:{"hide":_vm.hideModal,"show":_vm.showModal,"shown":_vm.shownModal},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('p',{staticClass:"modalTitle"},[_vm._v(_vm._s(_vm.title))])]},proxy:true},(_vm.isBodyTooltip && _vm.isHeaderPlaytrial)?{key:"modal-footer",fn:function(){return [_c('Tooltip',{attrs:{"dataStep":_vm.dataStepBody,"content":_vm.contentBodyTooltip,"position":_vm.positionBodyTooltip,"tooltipContentClass":"mt-4"}},[_c('div',{staticClass:"w-100 text-center d-md-block mb-md-2",class:{ 'd-none': _vm.countAction < 2 }},[(_vm.isHasSlotTextFooter)?_vm._t("textFooter"):_c('div',{class:{
            textFooter: true,
            'textFooter--italic': _vm.isTextFooterItalic
          }},[_vm._v(" "+_vm._s(_vm.textFooter)+" ")])],2),_c('div',{staticClass:"w-100 actionGroup d-flex justify-content-center"},[((_vm.countAction < 2 && _vm.isHasSlotTextFooter) || _vm.textFooter)?_c('div',{staticClass:"pr-2 w-100 d-flex align-items-center d-md-none"},[(_vm.isHasSlotTextFooter)?_vm._t("textFooter"):_c('div',{class:{
              textFooter: true,
              'textFooter--italic': _vm.isTextFooterItalic
            }},[_vm._v(" "+_vm._s(_vm.textFooter)+" ")])],2):_vm._e(),(_vm.isCancleBtn)?_c('button',{staticClass:"btn w-100 text-uppercase actionGroup__btn actionGroup__btn--cancle",class:{
            custom: _vm.isCustom,
            customUI: _vm.isCustomUI,
            fullWidth: _vm.isFullWidthCancelButton
          },attrs:{"type":"submit","disabled":_vm.isCancleBtnDisable || _vm.isCancleBtnLoading},on:{"click":function($event){$event.preventDefault();return _vm.handleCancleBtn($event)}}},[(_vm.isCancleBtnLoading)?_c('div',{staticClass:"text-center w-100"},[_c('b-spinner',{attrs:{"small":""}})],1):_c('span',[_vm._v(_vm._s(_vm.cancleTextBtn ? _vm.cancleTextBtn : 'Hủy'))])]):_vm._e(),(_vm.isConfirmTooltip && _vm.isConfirmBtn && _vm.isHeaderPlaytrial)?_c('Tooltip',{attrs:{"dataStep":_vm.dataStepConfirm,"content":_vm.contentConfirmTooltip,"position":_vm.positionConfirmTooltip}},[(_vm.isConfirmBtn)?_c('button',{staticClass:"btn w-100 text-uppercase actionGroup__btn actionGroup__btn--confirm actionGroup__btn--tooltip",class:{
              custom: _vm.isCustom,
              fullWidth: _vm.isFullWidthConfirmButton
            },attrs:{"type":"submit","disabled":_vm.isConfirmBtnDisable || _vm.isConfirmBtnLoading},on:{"click":function($event){$event.preventDefault();return _vm.handleConfirmBtn($event)}}},[(_vm.isConfirmBtnLoading)?_c('div',{staticClass:"text-center w-100"},[_c('b-spinner',{attrs:{"small":""}})],1):_c('span',[_vm._v(_vm._s(_vm.confirmTextBtn ? _vm.confirmTextBtn : 'Xác nhận'))])]):_vm._e()]):(_vm.isConfirmBtn)?_c('button',{staticClass:"btn w-100 text-uppercase actionGroup__btn actionGroup__btn--confirm",class:{
            custom: _vm.isCustom,
            fullWidth: _vm.isFullWidthConfirmButton,
            customUI: _vm.isCustomUI
          },attrs:{"type":"submit","disabled":_vm.isConfirmBtnDisable || _vm.isConfirmBtnLoading},on:{"click":function($event){$event.preventDefault();return _vm.handleConfirmBtn($event)}}},[(_vm.isConfirmBtnLoading)?_c('div',{staticClass:"text-center w-100"},[_c('b-spinner',{attrs:{"small":""}})],1):_c('span',[_vm._v(_vm._s(_vm.confirmTextBtn ? _vm.confirmTextBtn : 'Xác nhận'))])]):_vm._e()],1)])]},proxy:true}:(_vm.isHasSlotFooter)?{key:"modal-footer",fn:function(){return [_vm._t("slotFooter")]},proxy:true}:{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100 text-center d-md-block mb-md-2",class:{ 'd-none': _vm.countAction < 2 }},[(_vm.isHasSlotTextFooter)?_vm._t("textFooter"):_c('div',{class:{
          textFooter: true,
          'textFooter--italic': _vm.isTextFooterItalic
        }},[_vm._v(" "+_vm._s(_vm.textFooter)+" ")])],2),_c('div',{staticClass:"w-100 actionGroup d-flex justify-content-center"},[((_vm.countAction < 2 && _vm.isHasSlotTextFooter) || _vm.textFooter)?_c('div',{staticClass:"pr-2 w-100 d-flex align-items-center d-md-none"},[(_vm.isHasSlotTextFooter)?_vm._t("textFooter"):_c('div',{class:{
            textFooter: true,
            'textFooter--italic': _vm.isTextFooterItalic
          }},[_vm._v(" "+_vm._s(_vm.textFooter)+" ")])],2):_vm._e(),(_vm.isCancleBtn)?_c('button',{staticClass:"btn w-100 text-uppercase actionGroup__btn actionGroup__btn--cancle",class:{
          custom: _vm.isCustom,
          customUI: _vm.isCustomUI,
          fullWidth: _vm.isFullWidthCancelButton
        },attrs:{"type":"submit","disabled":_vm.isCancleBtnDisable || _vm.isCancleBtnLoading},on:{"click":function($event){$event.preventDefault();return _vm.handleCancleBtn($event)}}},[(_vm.isCancleBtnLoading)?_c('div',{staticClass:"text-center w-100"},[_c('b-spinner',{attrs:{"small":""}})],1):_c('span',[_vm._v(_vm._s(_vm.cancleTextBtn ? _vm.cancleTextBtn : 'Hủy'))])]):_vm._e(),(_vm.isConfirmTooltip && _vm.isConfirmBtn && _vm.isHeaderPlaytrial)?_c('Tooltip',{attrs:{"dataStep":_vm.dataStepConfirm,"content":_vm.contentConfirmTooltip,"position":_vm.positionConfirmTooltip}},[(_vm.isConfirmBtn)?_c('button',{staticClass:"btn w-100 text-uppercase actionGroup__btn actionGroup__btn--confirm actionGroup__btn--tooltip",class:{
            custom: _vm.isCustom,
            fullWidth: _vm.isFullWidthConfirmButton,
            customUI: _vm.isCustomUI
          },attrs:{"type":"submit","disabled":_vm.isConfirmBtnDisable || _vm.isConfirmBtnLoading},on:{"click":function($event){$event.preventDefault();return _vm.handleConfirmBtn($event)}}},[(_vm.isConfirmBtnLoading)?_c('div',{staticClass:"text-center w-100"},[_c('b-spinner',{attrs:{"small":""}})],1):_c('span',[_vm._v(_vm._s(_vm.confirmTextBtn ? _vm.confirmTextBtn : 'Xác nhận'))])]):_vm._e()]):(_vm.isConfirmBtn)?_c('button',{staticClass:"btn w-100 text-uppercase actionGroup__btn actionGroup__btn--confirm",class:{
          custom: _vm.isCustom,
          fullWidth: _vm.isFullWidthConfirmButton,
          customUI: _vm.isCustomUI
        },attrs:{"type":"submit","disabled":_vm.isConfirmBtnDisable || _vm.isConfirmBtnLoading},on:{"click":function($event){$event.preventDefault();return _vm.handleConfirmBtn($event)}}},[(_vm.isConfirmBtnLoading)?_c('div',{staticClass:"text-center w-100"},[_c('b-spinner',{attrs:{"small":""}})],1):_c('span',[_vm._v(_vm._s(_vm.confirmTextBtn ? _vm.confirmTextBtn : 'Xác nhận'))])]):_vm._e()],1)]},proxy:true}],null,true),model:{value:(_vm.isOpenModel),callback:function ($$v) {_vm.isOpenModel=$$v},expression:"isOpenModel"}},[_c('div',[_vm._t("default")],2),_c('div',{attrs:{"id":"idReward"}}),(_vm.showOverlay)?_c('div',{staticClass:"overlayScreen"}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }