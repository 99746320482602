import storageHelper from 'storage-helper';
let rememberHomeScreenIOS = false;
let gameKenoType = 'basic';
let objResultGameTrial = {};
try {
  rememberHomeScreenIOS = JSON.parse(storageHelper.getItem('__remember_home_ios'));

  gameKenoType = storageHelper.getItem('__game_keno_type');

  objResultGameTrial = JSON.parse(storageHelper.getItem('__result_game_trial'));
} catch (e) {
  console.error(e.message);
}

const state = {
  isReview: false,
  gameUrl: {},
  resultGameUrl: {},
  statisticalUrl: {},
  gameId: {},
  serverStatus: true,
  dataPromo: {},
  dataPromoBe: {},
  bannerTime: {},
  isSmsVerify: false,
  isHomeScreenWithIOS: false,
  rememberHomeScreenIOS: rememberHomeScreenIOS,
  infoFormValidated: undefined,
  gameKenoType,
  tempSelectedGameBasic: [],
  tempSelectedGameAdded: [],
  tempSelectedGameCombo: [],
  objResultGameTrial,
  vnMobilePhonePrefix: [],
  barcode: '',
  globalExcludeFeature: []
};

const getters = {
  getterReview(state) {
    return state.isReview || false;
  },
  getterGameId(state) {
    return state.gameId || {};
  },
  getterObjResultGameTrial(state) {
    return state.objResultGameTrial;
  },
  getterGameUrl(state) {
    return state.gameUrl;
  },
  getterResultGameUrl(state) {
    return state.resultGameUrl;
  },
  getterStatisticalUrl(state) {
    return state.statisticalUrl;
  },
  getterServerStatus(state) {
    return state.serverStatus;
  },
  getterDataPromo(state) {
    return state.dataPromo;
  },
  getterBannerTime(state) {
    return state.bannerTime;
  },
  getterDataPromoBe(state) {
    return state.dataPromoBe;
  },
  getterIsSmsVerify(state) {
    return state.isSmsVerify;
  },
  getterIsHomeScreenWithIOS(state) {
    return state.isHomeScreenWithIOS;
  },
  getterRememberHomeScreenIOS(state) {
    return state.rememberHomeScreenIOS;
  },
  getterInfoFormValidated(state) {
    return state.infoFormValidated;
  },
  getterGameKenoType(state) {
    return state.gameKenoType;
  },
  getterTempSelectedGameAdded(state) {
    return state.tempSelectedGameAdded;
  },
  getterTempSelectedGameBasic(state) {
    return state.tempSelectedGameBasic;
  },
  getterTempSelectedGameCombo(state) {
    return state.tempSelectedGameCombo;
  },
  getterVnMobilePhonePrefix(state) {
    return state.vnMobilePhonePrefix;
  },
  getterBarcode(state) {
    return state.barcode;
  },
  getterGlobalExcludeFeature(state) {
    return state.globalExcludeFeature;
  }
};

const actions = {};

const mutations = {
  setReview(state, value) {
    state.isReview = value;
  },
  setGameUrl(state, value) {
    value.forEach((item) => {
      state.gameId[item.slug] = item.id;
    });
    state.gameUrl = value;
  },
  setObjResultGameTrial(state, value) {
    storageHelper.setItem('__result_game_trial', JSON.stringify(value));
    state.objResultGameTrial = value;
  },
  clearObjResultGameTrial() {
    storageHelper.removeItem('__result_game_trial');
    state.objResultGameTrial = {};
  },
  setResultGameUrl(state, value) {
    state.resultGameUrl = value;
  },
  setServerStatus(state, value) {
    state.serverStatus = value;
  },
  setStatisticalUrl(state, value) {
    state.statisticalUrl = value;
  },
  setDataPromo(state, value) {
    state.dataPromo = value;
  },
  setBannerTime(state, value) {
    state.bannerTime = value;
  },
  setDataPromoBe(state, value) {
    state.dataPromoBe = value;
  },
  setIsSmsVerify(state, value) {
    state.isSmsVerify = value;
  },
  setIsHomeScreenWithIOS(state, value) {
    state.isHomeScreenWithIOS = value;
  },
  setRememberHomeScreenIOS(state, value) {
    storageHelper.setItem('__remember_home_ios', JSON.stringify(value));
    state.rememberHomeScreenIOS = value;
  },
  setInfoFormValidated(state, value) {
    state.infoFormValidated = value;
  },
  setGameKenoType(state, value) {
    storageHelper.setItem('__game_keno_type', value);
    state.gameKenoType = value;
  },
  setTempSelectedGameBasic(state, value) {
    state.tempSelectedGameBasic = value;
  },
  setTempSelectedGameAdded(state, value) {
    state.tempSelectedGameAdded = value;
  },
  setTempSelectedGameCombo(state, value) {
    state.tempSelectedGameCombo = value;
  },
  setVnMobilePhonePrefix(state, value) {
    state.vnMobilePhonePrefix = value;
  },
  setBarcode(state, value) {
    state.barcode = value;
  },
  setGlobalExcludeFeature(state, value) {
    state.globalExcludeFeature = value;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
