<template>
  <div>Vui lòng đợi trong giây lát...</div>
</template>

<script>
export default {
  mounted() {
    this.run();
  },
  methods: {
    run() {
      const target_url = this.$route.query.url;
      const link = this.generateLinkWithParams(target_url);
      this.openDeepLink(link, '_self');
    }
  }
};
</script>
