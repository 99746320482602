<template>
  <div id="app">
    <component v-if="!isError && !isMaintain && !isLoading" :is="layout">
      <router-view class="content" :layout.sync="layout" />
      <b-alert
        v-if="isIOS && isMobileSafari && !homeScreenIOSData"
        @dismissed="hideAlert"
        v-model="isHomeScreenIOS"
        class="d-block d-lg-none position-fixed fixed-bottom m-0 homeScreenIOS"
        dismissible
      >
        Vui lòng nhấn vào hình bên dưới để thêm {{ isThanhCo ? 'Thánh Cô' : 'Tài Lộc' }} vào màn hình chính
        <div class="d-flex align-items-center mt-3">
          <div
            class="checkbox-custom-input-wrapper mr-2 checkbox-custom-input-wrapper-home-screen"
            :class="{
              checked: rememberHomeScreenIOS
            }"
          >
            <input
              id="input-remember"
              class="checkbox-hidden-input"
              :class="{
                'checkbox-custom-input-wrapper--checked': rememberHomeScreenIOS
              }"
              type="checkbox"
              :checked="rememberHomeScreenIOS"
              @click="rememberHomeScreenIOS = !rememberHomeScreenIOS"
            />
            <span
              v-if="rememberHomeScreenIOS"
              class="checkbox-custom-input"
              :class="{
                'checkbox-custom-input--checked': rememberHomeScreenIOS
              }"
              >&#x2713;</span
            >
          </div>
          <label for="input-remember" class="w-100 mb-0">Không hiển thị lại lần sau</label>
        </div>
      </b-alert>
    </component>
    <UIBannerEvent v-if="compareTime" />
    <NotFound v-show="isError" />
    <Maintaince v-if="isMaintain || isAPIError" />
    <ModalErrorOauth />
    <ModalVerifyPhone />
    <modal-register />
    <modal-forgot />
    <modal-login />
    <ModalAssociate />
    <CommonModal
      v-if="popupCongrateData.handler === 'reward_congrat'"
      v-show="true"
      :isOpen="isOpenPopupCongrat"
      @updateOpen="updateOpenPopupCongrat"
      :isHiddenFooter="false"
      :isHiddenHeader="true"
      :isNoPaddingContent="true"
      :modalClass="'popupCongrats'"
      :isNoCloseOnEsc="objNoCloseOnEscAndBackdrop.isNoCloseOnEsc"
      :isNoCloseOnBackdrop="objNoCloseOnEscAndBackdrop.isNoCloseOnBackdrop"
      :isStatic="true"
      :isCustom="false"
      :cancleTextBtn="isCongratGiftTicket ? 'Xem vé' : 'Chi tiết'"
      :confirmTextBtn="isCongratGiftTicket ? 'Gửi cảm ơn' : 'Xem vé'"
      :isCloseWhenCancle="isCongratGiftTicket ? true : false"
      :isConfirmBtn="!isCongratGiftTicketHasMessage"
      :isCancleBtn="!isTraditional"
      @handleCancle="
        () => {
          isCongratGiftTicket ? handleConfirmPopupCongrat() : handleOpenDetailTransaction();
        }
      "
      @handleConfirm="
        () => {
          isCongratGiftTicket ? handleRewardSendThank() : handleConfirmPopupCongrat();
        }
      "
    >
      <span class="iconX" @click="updateOpenPopupCongrat(false)">×</span>
      <img
        id="modal_reward"
        draggable="false"
        alt="trung thuong"
        class="popupReward__img"
        src="https://d1aleksa3dshq6.cloudfront.net/notify/Ve-trung-thuong.jpg"
      />
      <div class="popupCongrats__content">
        <p>
          Bạn đã trúng thưởng vé
          {{ popupCongrateData.raw_data && popupCongrateData.raw_data.game_name }} đài
          {{ popupCongrateData.raw_data.product_name }} ngày
          {{ popupCongrateData.raw_data && popupCongrateData.raw_data.lottery_time }}
        </p>
        <p v-if="isCongratGiftTicket">Tài Lộc đã hoàn tất trả thưởng cho bạn, đừng quên cảm ơn người tặng nhé.</p>
        <p v-else>Tài Lộc đã hoàn tất trả thưởng cho bạn.</p>

        <p class="mt-3">
          <em class="color-white" v-if="popupCongrateData.raw_data && popupCongrateData.raw_data.reward_reply_message"
            >Bạn đã gửi cảm ơn: "{{ popupCongrateData.raw_data.reward_reply_message }}"</em
          >
        </p>
      </div>
    </CommonModal>
    <CommonModal
      v-else
      v-show="true"
      :isOpen="isOpenPopupCongrat"
      @updateOpen="updateOpenPopupCongrat"
      :isHiddenFooter="false"
      :isHiddenHeader="true"
      :isNoPaddingContent="true"
      :modalClass="'popupCongrats'"
      :isStatic="true"
      :isCustom="false"
      cancleTextBtn="Xem vé"
      confirmTextBtn="Chia sẻ"
      :isCloseWhenCancle="true"
      @handleCancle="handleConfirmPopupCongrat"
      @handleConfirm="handleShareGiftWin"
    >
      <span class="iconX" @click="updateOpenPopupCongrat(false)">×</span>
      <img
        id="modal_reward"
        draggable="false"
        alt="trung thuong"
        class="popupReward__img"
        src="https://d1aleksa3dshq6.cloudfront.net/notify/Ve-trung-thuong.jpg"
      />
      <div class="popupCongrats__content">
        <div class="my-3">
          <p v-if="popupCongrateData.raw_data">
            Wow, vé số {{ popupCongrateData.raw_data.game_name }} kỳ quay #{{
              popupCongrateData.raw_data.period_no
            }}
            bạn tặng <strong>{{ popupCongrateData.raw_data.gift_code }}</strong> đã trúng thưởng
            <strong>{{ popupCongrateData.raw_data.reward_amount }}</strong>
          </p>
        </div>
      </div>
    </CommonModal>

    <!-- MODAL GIFT TICKET -->
    <CommonModal
      @updateOpen="updateOpenPopupGiftTicket"
      dialogClass="popupGiftTicket"
      :isOpen="isOpenPopupGiftTicket"
      @handleConfirm="handleSendThank"
      @handleCancle="
        $router.push(
          popupGiftTicketData.raw_data.display_ticket_id
            ? `/tai-khoan/ve-duoc-tang/${popupGiftTicketData.raw_data.display_ticket_id}`
            : '/'
        )
      "
      title="Bạn được tặng vé số"
      confirmTextBtn="Gửi cảm ơn"
      cancleTextBtn="Xem vé"
      :isCustom="false"
      :isCustomUI="true"
      :isConfirmBtn="!(popupGiftTicketData.raw_data && popupGiftTicketData.raw_data.buy_reply_message)"
    >
      <div class="text-center">
        <img id="modal_reward" draggable="false" alt="tang ve" src="@/assets/img/icon/tangve-giftbox.svg" width="50%" />
        <div class="my-3">
          {{ popupGiftTicketData.content }}
        </div>
        <em class="color-blue" v-if="popupGiftTicketData.raw_data && popupGiftTicketData.raw_data.buy_reply_message"
          >Bạn đã gửi cảm ơn: "{{ popupGiftTicketData.raw_data.buy_reply_message }}"</em
        >
      </div>
    </CommonModal>

    <!-- MODAL THANK -->
    <CommonModal
      @updateOpen="isOpenPopupThank = $event"
      dialogClass="popupThank"
      :isOpen="isOpenPopupThank"
      title=""
      confirmTextBtn="Chia sẻ"
      :isCustom="false"
      :isCustomUI="true"
      :isCancleBtn="false"
      @handleConfirm="handleShareThank"
    >
      <div class="text-center">
        <img
          v-if="popupThankData.handler === 'buy_reply'"
          draggable="false"
          alt="cam on"
          src="@/assets/img/thantai-tangve.png"
          width="150px"
        />
        <img v-else draggable="false" alt="cam on" src="@/assets/img/thantai-trungthuong.png" width="150px" />
        <div class="my-2" v-if="popupThankData.raw_data">
          <div v-if="popupThankData.handler === 'buy_reply'">
            {{ popupThankData.raw_data.gift_code }} gửi lời cảm ơn vì bạn tặng vé:
            <em> "{{ popupThankData.raw_data.message }}" </em>
          </div>
          <div v-else>
            {{ popupThankData.raw_data.gift_code }} gửi lời cảm ơn vì vé bạn tặng đã trúng thưởng:
            <em> "{{ popupThankData.raw_data.message }}" </em>
          </div>
        </div>
      </div>
    </CommonModal>

    <!-- MODAL CONGRATE TRIAL -->
    <CommonModal
      v-show="true"
      :isOpen="isOpenPopupCongratTrial"
      @updateOpen="updateOpenPopupCongratTrial"
      :isHiddenFooter="true"
      :isHiddenHeader="true"
      :isNoPaddingContent="true"
      :modalClass="'popupCongrats'"
      :isNoCloseOnEsc="objNoCloseOnEscAndBackdrop.isNoCloseOnEsc"
      :isNoCloseOnBackdrop="objNoCloseOnEscAndBackdrop.isNoCloseOnBackdrop"
      :isStatic="true"
    >
      <img
        id="modal_reward"
        draggable="false"
        alt="trung thuong"
        class="popupReward__img"
        src="https://d1aleksa3dshq6.cloudfront.net/notify/popup-trung-thuong.jpg"
        @click="handlePopupCongratClick"
      />
    </CommonModal>
    <CommonModal
      :title="'Nạp tiền thành công'"
      :isOpen="isOpenPopupDepositSuccess"
      @updateOpen="updateOpenPopupDepositSuccess"
      :confirmTextBtn="'Mua vé'"
      :cancleTextBtn="'chi tiết'"
      @handleCancle="$router.push(dataDeposit.target_url || '/')"
      @handleConfirm="$router.push('/')"
    >
      <div>
        <div class="text-center">
          <img src="@/assets/img/icon/icon_result-success.svg" alt="img succes" width="60px" height="60px" />
          <div class="mt-3">
            Tài khoản ví đã được nạp thêm
            <b>{{ dataDeposit.amount }}</b>
          </div>
        </div>
      </div>
    </CommonModal>
    <CommonModal
      :title="'Thông báo'"
      :isOpen="isOpenPopupChangePeriod"
      :confirmTextBtn="'Đồng ý'"
      :cancleTextBtn="'Không'"
      :isNoCloseOnBackdrop="true"
      :modalClass="'customModalClassPeriod'"
      :isNoCloseOnEsc="true"
      :isCancleBtnLoading="isCancleBtnLoading"
      :isConfirmBtnLoading="isConfirmBtnLoading"
      @handleHide="handleCloseModalChangePeriod"
      @handleCancle="handleCancleModalChangePeriod"
      @handleConfirm="handleConfirmModalChangePeriod"
    >
      <div v-if="objectItemTicketNotify && Object.keys(objectItemTicketNotify).length > 0">
        <p class="mb-4">
          Vé {{ objectItemTicketNotify.game_name }} kỳ quay
          <span class="font-weight-600">#{{ objectItemTicketNotify.period_no }}</span> -
          <span class="font-weight-600">{{ getOnlyTime(objectItemTicketNotify.lottery_time) }}</span>
          {{ getOnlyDate(objectItemTicketNotify.lottery_time) }}
          đã gặp lỗi trong quá trình xử lý từ máy chủ Vietlott. Bạn có muốn mua lại các bộ số đã chọn ở kỳ kế tiếp
          không?
        </p>
        <div class="ticketItem">
          <div class="cartItem__info">
            <div class="d-flex justify-content-between">
              <div class="d-flex w100">
                <div>
                  <img draggable="false" class="cartItem__info__img" :src="objectItemTicketNotify.image_url" alt="pw" />
                </div>
                <div class="cartItem__info__orderDetail">
                  <p>
                    <span v-if="!isLoading && getProductName(objectItemTicketNotify)">
                      <span>{{ getProductName(objectItemTicketNotify, isLoading) }}</span>
                      <span>&nbsp;-&nbsp;</span>
                    </span>
                    <span v-if="objectItemTicketNotify.details">{{ objectItemTicketNotify.details.length }} bộ số</span>
                  </p>
                  <p>
                    Kỳ quay:
                    <span class="font-weight-600">#{{ objectItemTicketNotify.period_no }}</span> -
                    <span class="font-weight-600">{{ getOnlyTime(objectItemTicketNotify.lottery_time) }}</span
                    >&nbsp;
                    <span>{{ getOnlyDate(objectItemTicketNotify.lottery_time) }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="cartItem__list">
            <div class="cartItem__list__item">
              <TicketItem
                :isHideChangeClick="true"
                v-for="item in objectItemTicketNotify.details"
                :key="item.id"
                :data="item"
                :gameType="objectItemTicketNotify.game_code"
                :productHandler="getProductHandler(objectItemTicketNotify.product_id)"
              />
            </div>
          </div> -->
        </div>
      </div>
    </CommonModal>
    <CommonModal
      :title="'Thông báo'"
      :isOpen="isOpenPopupPaymentError"
      :isNoCloseOnBackdrop="true"
      :isNoCloseOnEsc="true"
      :isHiddenFooter="true"
      @handleHide="isOpenPopupPaymentError = false"
      dialogClass="notiMomo"
    >
      <div class="pb-3">
        <div class="mb-3">
          <p class="mb-3">
            Tài Lộc ghi nhận Quý khách nạp tiền qua ví MOMO nhưng không có mã giao dịch (hoặc mã giao dịch không hợp lệ)
            nên hệ thống không thể xử lý nạp tiền tự động cho Quý khách.
          </p>
          <p class="text-italic mb-3" style="color:#d31904">
            Để tiền nạp tự động, Quý khách vui lòng copy mã giao dịch và dán vào ô lời nhắn khi chuyển tiền trong MOMO.
          </p>

          <p>
            Bạn có thể gửi tin nhắn đến Facebook Messenger hoặc gọi hotline để được hỗ trợ.
          </p>
        </div>
        <a class="btn notiMomoBtn notiMomoBtn__fbMessenger mb-2" href="https://m.me/www.tailoc68.vn" target="_blank"
          >nhắn facebook messenger</a
        >
        <a class="btn notiMomoBtn notiMomoBtn__hotline mt-1" href="tel:0962801301">gọi hotline</a>
      </div>
    </CommonModal>
    <CommonModal
      :title="'Thông báo'"
      :isOpen="isOpenPopupNotifyMessage"
      :confirmTextBtn="popupNotifyMessageData.button_label || 'Chi tiết'"
      :isNoCloseOnBackdrop="true"
      :isNoCloseOnEsc="true"
      @handleConfirm="handlePressConfirm"
      @handleHide="isOpenPopupNotifyMessage = false"
      :isCancleBtn="false"
      :isConfirmBtn="!!popupNotifyMessageData.button_url"
      :footerClass="'payment-error-footer'"
    >
      <div class="mb-3">
        {{ popupNotifyMessageData.popup_message }}
      </div>
    </CommonModal>
    <CommonModal
      title="Thông báo"
      :isOpen="isOpenPopupWaitingPayment"
      :isNoCloseOnBackdrop="true"
      :isNoCloseOnEsc="true"
      @handleConfirm="isOpenPopupWaitingPayment = false"
      @handleHide="isOpenPopupWaitingPayment = false"
      :isCancleBtn="false"
      dialogClass="popupWaitingPayment"
      confirmTextBtn="Đã hiểu"
      :isFullWidthConfirmButton="true"
    >
      <p>
        Giao dịch thanh toán đang được xử lý, vui lòng không tắt trình duyệt cho đến khi nhận được kết quả giao dịch
        trên website.
      </p>
    </CommonModal>
    <account-modal-term />
    <div class="gotoTop" :class="{ show: isShow }" v-if="isOnlyArticle">
      <button @click.prevent="handleGotoTop()" id="goTop">
        <span class="position-relative">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
            <g transform="translate(18783 2180)">
              <rect width="14" height="14" transform="translate(-18783 -2180)" fill="#fff" opacity="0" />
              <path
                d="M.585-7.421l.614.614a.375.375,0,0,0,.536-.005L4.158-9.336V1.443a.375.375,0,0,0,.375.375h.875a.375.375,0,0,0,.375-.375V-9.336L8.207-6.812a.375.375,0,0,0,.536.005l.614-.614a.375.375,0,0,0,0-.531L5.236-12.072a.375.375,0,0,0-.531,0L.585-7.951A.375.375,0,0,0,.585-7.421Z"
                transform="translate(-18780.475 -2167.818)"
                fill="#999"
              />
            </g>
          </svg>
        </span>
      </button>
    </div>
    <!-- <TooltipStep /> -->
    <CommonModal
      title="Chơi thử"
      @updateOpen="setModalConfirmPlayTrial"
      :isOpen="isOpenModalPlayTrial"
      @handleConfirm="handlePlayTrial"
      cancleTextBtn="Đóng"
      :confirmTextBtn="isPlayTrial ? 'Dừng chơi thử' : 'Bắt đầu chơi thử'"
    >
      <div class="modalConfirm pt-1 pb-3">
        <p>Quý khách đang truy cập vào chức năng chơi thử của ứng dụng.</p>
        <br />
        <p>Với chế độ chơi thử, Quý khách có thể thực hiện chọn mua vé, xem kết quả và nhận thông báo trúng thưởng.</p>
        <br />
        <div class="m-text-primary">
          <i> <b>Lưu ý:</b> Tất cả các giao dịch phát sinh trong chế độ chơi thử đều không có giá trị. </i>
        </div>
      </div>
    </CommonModal>
    <ModalQuickPaymentSuccess />
    <CommonModal
      title="Giao dịch trả thưởng"
      @updateOpen="setModalIsRewardTransaction"
      :isOpen="isRewardTransaction"
      :isHiddenFooter="true"
      :isHideBackdrop="true"
      dialogClass="popupRewardTrans"
      :fullHeight="true"
    >
      <div class="modalRewardTransaction">
        <p>Các bộ số trúng thưởng</p>
        <div v-for="(reward, index) in rewardTransactionData" :key="`reward_${index}`">
          <p class="modalRewardTransaction__ticketName">{{ reward.ticketName }}</p>
          <div class="modalRewardTransaction__groupInfo">
            <div>
              <span class="modalRewardTransaction__label">Trúng thưởng</span>
              <span class="modalRewardTransaction__value">{{ formatPrice(reward.rewardValue) }}</span>
            </div>
            <div>
              <span class="modalRewardTransaction__label">Trạng thái</span>
              <span class="modalRewardTransaction__value">{{ reward.labelStatus }}</span>
            </div>
            <button class="btn btnSecondary w-100" @click="handleOpenTransactionDetail(reward.displayTicketId)">
              Chi tiết
            </button>
          </div>
          <hr v-if="index !== rewardTransactionData.length - 1" />
        </div>
      </div>
    </CommonModal>

    <CommonModal
      :title="`Chi tiết giao dịch ${transactionDetailData.id}`"
      @updateOpen="setModalIsOpentransactionDetail"
      :isOpen="isOpentransactionDetail"
      :isHiddenFooter="true"
      dialogClass="popupRewardTrans"
      :fullHeight="true"
    >
      <div class="modalTransactionDetail">
        <div class="row">
          <div class="col-12">
            <div class="modalTransactionDetail__label">
              hình thức
            </div>
            <div class="modalTransactionDetail__value">Trả thưởng vé {{ transactionDetailData.title }}</div>
          </div>
          <div class="col-6">
            <div class="modalTransactionDetail__label">
              Số tiền
            </div>
            <div class="modalTransactionDetail__value">
              {{ formatPrice(transactionDetailData.amount) }}
            </div>
          </div>
          <div class="col-6">
            <div class="modalTransactionDetail__label">
              Phí giao dịch (0%)
            </div>
            <div class="modalTransactionDetail__value">
              {{ formatPrice(0) }}
            </div>
          </div>
          <div class="col-12">
            <div class="modalTransactionDetail__label">
              thực lĩnh
            </div>
            <div class="modalTransactionDetail__value">
              {{ formatPrice(transactionDetailData.amount) }}
            </div>
          </div>
          <div class="col-6">
            <div class="modalTransactionDetail__label">
              thời gian
            </div>
            <div class="modalTransactionDetail__value">
              {{ getOnlyDate(transactionDetailData.date) }} {{ getOnlyTime(transactionDetailData.date) }}
            </div>
          </div>
          <div class="col-6">
            <div class="modalTransactionDetail__label">
              Trạng thái
            </div>
            <div class="modalTransactionDetail__value">
              {{ labelTransStatus[transactionDetailData.status] || 'Đang xử lý' }}
            </div>
          </div>
          <div class="col-12">
            <div class="modalTransactionDetail__label">
              số điện thoại
            </div>
            <div class="modalTransactionDetail__value">
              <CensorData :data="transactionDetailData.phone" />
            </div>
          </div>
          <div class="col-12">
            <button class="btn btnPrimary btnPrimary--contained w-100" @click="linkToTicketDetail">
              xem vé trúng thưởng
            </button>
          </div>
        </div>
      </div>
    </CommonModal>
    <ModalShareFb />
    <!-- <FloatingPromo v-if="isShowFloatingPromo" /> -->
    <ModalSendThankYou />
    <div class="overlayScreen" v-if="isPlayTrial && isShowOverlay"></div>
  </div>
</template>
<script>
import ModalRegister from '../components/Account/ModalRegister';
import ModalForgot from '../components/Account/ModalForgot';
import ModalLogin from '../components/Account/ModalLogin';
import AccountModalTerm from '../components/Account/AccountModalTerm';
import Spinner from '../components/Spinner';
import AccountService from '../api/account';
import OrderAppService from '../api/order';
import { cartItemName } from '@/common/global.js';
// import _ from 'lodash';
import { ListClearVersion } from '../api/mock/enum';
import GameAppService from '../api/game';
import NotificationService from '../api/notification';
import { default as NotFound } from '../views/404/404';
import Maintaince from '../views/Maintaince';
import HealthCheckServices from '../api/healthCheck';
import OrderService from '../api/order';

import CommonModal from '../components/CommonModal';
import { isIOS, isMobileSafari } from 'mobile-device-detect';
// import CartItemDescription from '../components/Cart/CartItemDescription';
// import TicketItem from '../components/Cart/TicketItem';
// import NumberItem from '../components/Cart/NumberItem';
import { ErrorOrderService } from '@/api/mock/errors';
import ModalAssociate from '../components/Account/ModalAssociate.vue';
import ModalErrorOauth from '../components/Account/ModalErrorOauth.vue';
import ModalVerifyPhone from '../components/Account/ModalVerifyPhone.vue';
import UIBannerEvent from '../components/UI/Banner/UIBannerEvent';
import ModalQuickPaymentSuccess from '@/components/Game/ModalQuickPaymentSuccess';

import { getQueryVariable } from '../utils/functions';
import {
  eventTypeMessage,
  labelTransStatus,
  PUSHER_CHANNEL,
  SHAREFB_TYPE,
  SOCKET_EVENT,
  TICKET_MESSAGE_ENUM
} from '../common/global';
import dayjs from 'dayjs';
import PaymentService from '../api/payment';
// import { LIST_ROUTES_TO_HOMEPAGE_IN_GAME_TRIAL } from '../common/global';
const Swal = require('sweetalert2');
const ORDER_REQUEST_PENDING = 'order_request_pending';
const REWARD_CONGRAT = 'reward_congrat';
const GIFT_REWARD_CONGRAT = 'gift_reward_congrat';
const DEPOSIT_SUCCESS = 'deposit_success';
const MAINTENANCE_GAME = 'maintenance_game';
const MAINTENANCE_KENO_CUSTOM = 'maintenance_keno_custom';
const UPDATE_KENO_CUSTOM = 'update_keno_custom';
const PAYMENT_ERROR = 'payment_error';
const ORDER_SUCCESS = 'order_success';
const GIFT_SUCCESS = 'gift_success';
const BUY_REPLY = 'buy_reply';
const REWARD_REPLY = 'reward_reply';
import CensorData from '@/components/CensorData';
import { BASE_URL } from '../common/global';
import Bus from '@/utils/bus';
import ModalShareFb from '../components/ModalShareFb.vue';
import ModalSendThankYou from '../components/ModalSendThankYou.vue';
// import FloatingPromo from '../components/FloatingPromo.vue';
const { NOTI, SUBSCRIBED, CONNECT, DISCONNECT } = SOCKET_EVENT;

export default {
  name: 'app',
  data: function() {
    return {
      layout: 'div',
      isLoading: true,
      // isMaintain: false,
      isAPIError: false,
      isShow: false,
      rememberHomeScreenIOS: false,
      // urlPopupGrate: '/',
      isIOS: isIOS,
      isMobileSafari: isMobileSafari,
      isOpenPopupDepositSuccess: false,
      isOpenPopupChangePeriod: false,
      dataTicketNotify: [],
      objectItemTicketNotify: {},
      dataDeposit: {},
      timeWaiting: null,
      isCancleBtnLoading: false,
      isConfirmBtnLoading: false,
      isRewardTransaction: false,
      rewardTransactionData: [],
      isOpentransactionDetail: false,
      transactionDetailData: {},
      labelTransStatus: labelTransStatus,
      pusherChannel: null
    };
  },
  components: {
    UIBannerEvent,
    ModalRegister,
    ModalErrorOauth,
    ModalVerifyPhone,
    ModalForgot,
    listClearVersion: ListClearVersion,
    ModalLogin,
    Spinner,
    AccountModalTerm,
    Maintaince,
    NotFound,
    CommonModal,
    // NumberItem,
    // TicketItem,
    ModalAssociate,
    ModalQuickPaymentSuccess,
    CensorData,
    ModalShareFb,
    ModalSendThankYou
    // FloatingPromo
  },
  watch: {
    loggedIn: function(newV) {
      if (newV) {
        this.requestToken();
        this.loadLastNotification();
        this.handleEventLoggedIn();
        this.getReferralCode();
      }
      // Renew Socket id
      if (this.isConnectedSocket) {
        this.$socket.disconnect();
        this.$socket.connect();
      }
    },
    $route(to) {
      if (to.query && to.query.notify_id) {
        this.getOrderRequestPeriod(to.query.notify_id);
      }
      if (this.isAllowPopupOverflowIframe) {
        this.generateFakeBackgroundDebounce();
      }
    },
    transactionFinisedFromParent: function(newVal) {
      if (newVal && this.$socket.id) {
        this.checkUpdateClientForOrder();
      }
    },
    firebaseReady() {
      this.requestToken();
    },
    accountSyncWallet(newValue, prevValue) {
      if (newValue) {
        this.pusherChannel = this.pusher.subscribe(newValue);
        this.pusherChannel.bind('notification', this.handleSyncWallet);
      } else {
        this.pusherChannel.unbind('notification', this.handleSyncWallet);
        this.pusher.unsubscribe(prevValue);
      }
    }
  },
  mounted() {
    this.rememberHomeScreenIOS = this.homeScreenIOSData;
    this.onAuthen();

    if (this.isOpenClearCart) {
      console.error(
        '%c Ẩn nút clear cart!',
        'color:red;font-family:system-ui;font-size:3rem;-webkit-text-stroke: 1px black;font-weight:bold'
      );
    }
    if (this.accountSyncWallet) {
      this.pusherChannel = this.pusher.subscribe(this.accountSyncWallet);
      this.pusherChannel.bind('notification', this.handleSyncWallet);
    }
  },
  async created() {
    if (this.$route.query && this.$route.query.notify_id) {
      this.getOrderRequestPeriod(this.$route.query.notify_id);
    }
    if (this.isMaintain) {
      return;
    }

    Bus.$emit('init-firebase-listener');
    HealthCheckServices.check()
      .then((res) => {
        this.isAPIError = !res;
      })
      .catch((e) => {
        console.error(e);
      });
    this.initFirebase = this.initFirebase.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.handleEvent = this.handleEvent.bind(this);
    this.getInitData();
    if (this.getLoadByIframe) {
      this.sockets.subscribe(NOTI, function(data) {
        const parseData = JSON.parse(data);
        this.handleMessage(parseData);
      });
    } else {
      window.addEventListener('vl-message', this.handleEvent);
    }

    let isClearData = false;
    try {
      isClearData = window.storageHelper.getItem('clear_data');
    } catch (error) {
      console.error(error);
    }

    if (isClearData !== ListClearVersion[0].value) {
      document.cookie.split(';').forEach(function(c) {
        document.cookie = c.trim().split('=')[0] + '=;' + 'expires=Thu, 01 Jan 1970 00:00:00 UTC;';
      });
      window.storageHelper.clear();
      window.storageHelper.removeItem('clear_data');
    }
    window.storageHelper.setItem('clear_data', ListClearVersion[0].value);
    if (this.loggedIn) {
      this.getUserInfo();
      this.loadLastNotification();
      this.handleEventLoggedIn();
      this.getReferralCode();
    }
    // this.$store.dispatch('getPrizeKeno', this.listGameHandler.keno.id).then((resp) => {
    //   this.objPrizeKeno = resp.data.data.products;
    //   this.metadataKeno = JSON.parse(resp.data.data.metadata);
    // });

    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (!isSafari && !this.getLoadByIframe) {
      this.initFirebase();

      const channel = new BroadcastChannel('sw-messages');
      channel.addEventListener('message', this.handleMessage);

      const navigateChannel = new BroadcastChannel('sw-navigate');
      navigateChannel.addEventListener('message', (event) => {
        let data = event.data;
        this.$router.push(data.target_url).catch((err) => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            console.error(err);
          }
        });
      });
    }
  },
  computed: {
    popupNotifyMessageData: {
      get() {
        return this.$store.getters.getterPopupNotifyMessageData;
      },
      set(newVal) {
        return this.$store.commit('setPopupNotifyMessageData', newVal);
      }
    },
    isOpenPopupNotifyMessage: {
      get() {
        return this.$store.getters.getterIsOpenPopupNotifyMessage;
      },
      set(newVal) {
        return this.$store.commit('setIsOpenPopupNotifyMessage', newVal);
      }
    },
    isOpenPopupPaymentError: {
      get() {
        return this.$store.getters.getterIsOpenPopupPaymentError;
      },
      set(newVal) {
        return this.$store.commit('setIsOpenPopupPaymentError', newVal);
      }
    },
    isOpenPopupCongratTrial: {
      get() {
        return this.$store.getters.getterIsOpenPopupCongrats;
      },
      set(newVal) {
        return this.$store.commit('setIsOpenPopupCongrats', newVal);
      }
    },
    isOpenPopupCongrat: {
      get() {
        return this.$store.getters.getterIsOpenPopupCongrat;
      },
      set(newVal) {
        return this.$store.commit('setIsOpenPopupCongrat', newVal);
      }
    },
    popupCongrateData: {
      get() {
        return this.$store.getters.getterPopupCongrateData;
      },
      set(newVal) {
        return this.$store.commit('setPopupCongrateData', newVal);
      }
    },
    isCongratGiftTicket() {
      return this.popupCongrateData?.raw_data?.is_gifted || this.popupCongrateData.handler === 'gift_reward_congrat';
    },
    isCongratGiftTicketHasMessage() {
      return this.popupCongrateData?.raw_data?.is_gifted && this.popupCongrateData?.raw_data?.reward_reply_message;
    },
    isTraditional() {
      return this.popupCongrateData?.raw_data?.game_name === 'XSMN';
    },
    isOpenPopupGiftTicket: {
      get() {
        return this.$store.getters.getterIsOpenPopupGiftTicket;
      },
      set(newVal) {
        return this.$store.commit('setIsOpenPopupGiftTicket', newVal);
      }
    },
    popupGiftTicketData: {
      get() {
        return this.$store.getters.getterPopupGiftTicketData;
      },
      set(newVal) {
        return this.$store.commit('setPopupGiftTicketData', newVal);
      }
    },
    objNoCloseOnEscAndBackdrop() {
      return this.$store.getters.getterObjNoCloseOnEscAndBackdrop;
    },
    urlPopupGrate: {
      get() {
        return this.$store.getters.getterUrlPopupGrate;
      },
      set(newVal) {
        return this.$store.commit('setUrlPopupGrate', newVal);
      }
    },
    listGame: {
      get() {
        return this.$store.getters.getterListGame;
      },
      set(newVal) {
        this.$store.commit('setListGame', newVal);
      }
    },
    listGameHandlerById() {
      return this.convertArrayToObject(Object.values(this.$store.getters.getterListGameHandler), 'id');
    },

    isMaintain() {
      return this.$store.getters.getterIsMaintain;
    },
    homeScreenIOSData: {
      get() {
        return this.$store.getters.getterRememberHomeScreenIOS;
      },
      set(newVal) {
        this.$store.commit('setRememberHomeScreenIOS', newVal);
      }
    },
    isHomeScreenIOS: {
      get() {
        return this.$store.getters.getterIsHomeScreenWithIOS;
      },
      set(newVal) {
        this.$store.commit('setIsHomeScreenWithIOS', newVal);
      }
    },
    isOnlyArticle() {
      return this.$route.meta && this.$route.meta.isGoToTop;
    },
    isError() {
      return !this.$store.getters.getterServerStatus;
    },
    loggedIn() {
      return this.$store.getters.getterLoggedIn;
    },
    accountId() {
      return this.$store.getters.getterAccountId;
    },
    listGameHandler() {
      return this.$store.getters.getterListGameHandler;
    },
    listNotifications: {
      get() {
        return this.$store.state.listNotifications;
      },
      set(value) {
        this.$store.commit('setlistNotifications', value);
      }
    },
    isOpenClearCart() {
      return this.$store.getters.getterIsOpenClearCart;
    },
    isPlayTrial() {
      return this.$store.getters.getterPlayTrial;
    },
    isShowOverlay() {
      return this.$store.getters.getterShowOverlay && this.$route.meta?.isPlayTrialMode;
    },
    isOpenModalPlayTrial() {
      return this.$store.getters.getterIsOpenModalPlayTrial;
    },
    locationLoad() {
      return this.$store.getters.getterLocationLoad;
    },
    isConnectedSocket: {
      get() {
        return this.$store.getters.getterIsConnectedSocket;
      },
      set(newVal) {
        this.$store.commit('setIsConnectedSocket', newVal);
      }
    },
    getLoadByIframe() {
      return this.$store.state.loadByIframe;
    },
    getAllowPopupOverflowIframe() {
      return this.$store.getters.getterAllowPopupOverflowIframe;
    },
    isOpenPopupWaitingPayment: {
      get() {
        return this.$store.getters.getterIsOpenPopupWaitingPayment;
      },
      set(newVal) {
        return this.$store.commit('setIsOpenPopupWaitingPayment', newVal);
      }
    },
    transactionFinisedFromParent: {
      get() {
        return this.$store.getters.getterTransactionFinisedFromParent;
      },
      set(newVal) {
        this.$store.commit('setTransactionFinisedFromParent', newVal);
      }
    },
    isOpenPopupThank: {
      get() {
        return this.$store.getters.getterIsOpenPopupThank;
      },
      set(newVal) {
        return this.$store.commit('setIsOpenPopupThank', newVal);
      }
    },
    popupThankData: {
      get() {
        return this.$store.getters.getterPopupThankData;
      },
      set(newVal) {
        return this.$store.commit('setPopupThankData', newVal);
      }
    },
    firebaseReady() {
      return this.$store.state.firebaseReady;
    },
    accountSyncWallet() {
      if (this.accountId) {
        return `${this.accountId}/${PUSHER_CHANNEL.SYNC_WALLET}`;
      } else {
        return '';
      }
    },
    isShowFloatingPromo() {
      if (this.isMobileScreen) {
        return this.$route.name === 'Trang chủ' && !this.getLoadByIframe;
      }

      return !this.getLoadByIframe;
    }
  },
  sockets: {
    [CONNECT]: function() {
      this.isConnectedSocket = true;
      if (this.loggedIn) {
        this.$socket.emit(SUBSCRIBED, {
          socketId: this.$socket.id,
          accountId: this.accountId,
          platform: this.isMobileApp ? 'app' : 'web',
          timezone: dayjs.tz.guess()
        });
      }
      this.checkUpdateClientForOrder();
    },
    [DISCONNECT]: function() {
      this.isConnectedSocket = false;
    }
  },
  methods: {
    handlePressConfirm() {
      const url = this.popupNotifyMessageData.button_url;
      if (url) {
        if (url.includes('/deep-link')) {
          this.openDeepLink(`${BASE_URL}${url}`, '_blank');
        } else {
          this.$router.push(url);
        }
      }
      this.isOpenPopupNotifyMessage = false;
    },
    onAuthen() {
      let isUseSearch = false;
      if (!this.$route.query || !this.$route.query.aff_sid || !this.$route.query.utm_source) {
        this.$store.commit('setIsRouterAssociate', false);
        if (!window.location.search) return;
        isUseSearch = true;
      }

      this.$store.commit('setIsRouterAssociate');

      const isCheck = this.$route.name === 'Tài khoản' || this.$route.name === 'Chi tiết tài khoản';

      const { aff_sid, token, utm_source, utm_source_invite_code, action } = isUseSearch
        ? getQueryVariable(window.location.search)
        : this.$route.query;

      const { _aff_sid, _aff_network, _utm_source_invite_code } = {
        _aff_sid: aff_sid,
        _aff_network: utm_source,
        _utm_source_invite_code: utm_source_invite_code
      };
      if (_aff_network) {
        this.$store.commit('setInfoOauth2', { _aff_sid, _aff_network, _utm_source_invite_code });
      }

      if (!this.loggedIn) {
        if (action === 'login') {
          this.$store.commit('setModalRegister', false);
          this.$store.commit('setModalLogin', true);
        }
      }

      if (!token) {
        this.$store.commit('setIsRouterAssociate', false);
        return;
      }

      this.$store.commit('setTokenInfoOauth2', token);
      window.storageHelper.setItem('_aff_info_local_store', JSON.stringify({ _aff_sid, _aff_network }));

      //trang chủ
      if (!isCheck) {
        //chưa login
        if (this.loggedIn) {
          //thực hiện check và tạo liên kết
          this.$store
            .dispatch('onValidateAccountLinksWithId', { _aff_sid, token, _aff_network, account_id: this.accountId })
            .then((resp) => {
              if (!resp) return;
              if (!resp.data.data.linked) {
                this.$store.commit('setIsAcceptAssociate', true);
              }
            });
          this.$router.replace('/');
          return;
        }

        // từ trang vietlot đi qua saveetra
        if (this.$route.query.from == 'vietlot') {
          //put api/accounts.link
          this.onAfterPutAccountLinks({ _aff_sid, token, _aff_network });
          this.$router.replace('/');
          return;
        }

        // đi từ trang saveextra
        //post api/accounts.link
        this.onAfterAccountLinks({ _aff_sid, token, _aff_network });
        this.$router.replace('/');
      } else {
        //trang tài khoản
        if (!this.accountId) {
          this.$store.commit('setIsErrorPopupOauth2', true);
          return;
        }

        this.onAccountLinkProfile({ _aff_sid, token, _aff_network, account_id: this.accountId });
        let router = this.$route.name === 'Tài khoản' ? '/tai-khoan' : '/tai-khoan/chi-tiet/';
        this.$router.replace(router);
      }
    },
    gameType(game_id) {
      return this.listGameHandlerById[game_id].handler;
    },
    hideAlert() {
      this.homeScreenIOSData = this.rememberHomeScreenIOS;
    },
    handleGotoTop() {
      if (window.scrollY != 0) {
        document.getElementById('app').scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    },
    updateOpenPopupCongratTrial(value) {
      this.isOpenPopupCongratTrial = value;
    },
    updateOpenPopupCongrat(value) {
      this.isOpenPopupCongrat = value;
    },
    updateOpenPopupGiftTicket(value) {
      this.isOpenPopupGiftTicket = value;
    },
    updateOpenPopupDepositSuccess(value) {
      this.isOpenPopupDepositSuccess = value;
    },
    handleEvent({ data }) {
      const payload = this.isJSON(data) ? JSON.parse(data) : data;
      //log for testing
      switch (payload.event) {
        case 'FCM_UPDATE': {
          this.$store.commit('setDriverUniqueId', payload.data.driver_unique_id);
          this.$store.commit('setTimezone', payload.data.timezone);
          window.storageHelper.setItem('fcm-token', payload.data.token);
          if (this.$store.getters.getterLoggedIn) {
            NotificationService.updateToken(this.$store.getters.getterAccountId, payload.data).catch((e) => {
              console.error(e);
            });
          }
          break;
        }
        case 'OPEN_NOTIFICATION': {
          this.handleMessage(payload, true);
          break;
        }
        case 'FCM_MESSAGE': {
          this.handleMessage(payload);
        }
      }
    },
    handleMessage(event, openTargetUrl = false) {
      let data = event?.data?.data?.data || event?.data?.data || event?.data;
      let isShowNotifyMobile = event.data.isShowNotify;
      if (!data || typeof data !== 'string') {
        return;
      }
      data = JSON.parse(data);
      let { id, title, target_url, content_format, raw_data, image_url, viewed_notify, notify_time, handler } = data;
      raw_data = JSON.parse(raw_data);
      const content = this.formatString(content_format, raw_data);
      const customField = raw_data.custom_field;
      const isPopup = raw_data.is_popup;
      const notify_date = this.getOnlyDate(notify_time);
      const item = {
        id,
        title,
        target_url,
        content,
        image_url,
        viewed_notify,
        notify_time,
        notify_date,
        handler,
        customField,
        isPopup,
        raw_data
      };
      if (!this.$store.state.listPageNotification.find((x) => x.id === id)) {
        this.$store.state.listPageNotification.unshift(item);
      }
      if (!this.$store.state.listNotifications.find((x) => x.id === id)) {
        this.$store.state.listNotifications.pop();
        this.$store.state.listNotifications.unshift(item);
      }

      if (openTargetUrl) {
        this.$router.push(target_url);
      }
      if (window.ReactNativeWebView && isShowNotifyMobile) {
        this.sendMessageToApp(JSON.stringify({ event: 'SHOW_NOTIFY', data: item }));
      }

      if (item.customField) {
        const { popup_message, button_url, button_label } = item.customField;
        if (item.isPopup && popup_message) {
          this.$store.commit('setIsOpenPopupNotifyMessage', true);
          this.$store.commit('setPopupNotifyMessageData', {
            popup_message,
            button_url,
            button_label
          });
        }
      }
      switch (item.handler) {
        case REWARD_CONGRAT:
          if (this.isPlayTrial) {
            this.urlPopupGrate = item.target_url;
            this.updateOpenPopupCongratTrial(true);
          } else {
            this.popupCongrateData = item;
            this.updateOpenPopupCongrat(true);
          }
          break;
        case GIFT_REWARD_CONGRAT:
          this.popupCongrateData = item;
          this.updateOpenPopupCongrat(true);
          break;
        case BUY_REPLY:
        case REWARD_REPLY:
          this.popupThankData = item;
          this.isOpenPopupThank = true;
          break;
        case GIFT_SUCCESS:
          this.popupGiftTicketData = item;
          this.isOpenPopupGiftTicket = true;
          break;
        case ORDER_REQUEST_PENDING:
          if (this.dataTicketNotify.every((e) => e.ticket_id !== raw_data.ticket_id)) {
            this.dataTicketNotify.push(raw_data);
            this.handlerUpdateDataTicket();
          }
          break;
        case DEPOSIT_SUCCESS:
          this.dataDeposit = {
            amount: raw_data.reward_amount,
            target_url: item.target_url
          };
          this.updateOpenPopupDepositSuccess(true);
          this.refreshBalances();
          break;
        case MAINTENANCE_GAME:
          Swal.fire({
            icon: 'warning',
            title,
            text: raw_data.message,
            showConfirmButton: true,
            confirmButtonText: 'XÁC NHẬN',
            confirmButtonColor: '#e8a500',
            timer: 3000,
            showClass: {
              popup: 'swal2-noanimation',
              backdrop: 'swal2-noanimation'
            }
          });
          this.$store.commit('setGameStatus', raw_data);
          break;
        case MAINTENANCE_KENO_CUSTOM:
          Swal.fire({
            icon: 'warning',
            title,
            text: raw_data.message,
            showConfirmButton: true,
            confirmButtonText: 'XÁC NHẬN',
            confirmButtonColor: '#e8a500',
            timer: 3000,
            showClass: {
              popup: 'swal2-noanimation',
              backdrop: 'swal2-noanimation'
            }
          });
          this.$store.commit('setMetadataKeno', raw_data.metadata);
          break;
        case UPDATE_KENO_CUSTOM:
          this.$store.commit('setKenoCustomProductList', raw_data.product_list);
          Swal.fire({
            icon: 'warning',
            title,
            text: raw_data.message,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'MUA VÉ',
            cancelButtonText: 'LUẬT CHƠI',
            confirmButtonColor: '#e8a500',
            reverseButtons: true,
            timer: 15000,
            showClass: {
              popup: 'swal2-noanimation multiple-btn',
              backdrop: 'swal2-noanimation'
            }
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push(this.listGame[0].url);
            } else if (result.isDismissed) {
              this.$router.push('/luat-choi/luat-choi-keno');
            }
          });
          break;
        case ORDER_SUCCESS:
          this.$store.commit('setNewOrder', raw_data);
          break;
        case PAYMENT_ERROR:
          this.isOpenPopupPaymentError = true;
          break;
        default:
          break;
      }
    },
    initFirebase() {
      if (this.$messaging) {
        this.$messaging.onMessage(this.handleMessage);
      }
    },
    getUserInfo() {
      const { phone } = this.$store.getters.getterUserProfile || {};
      if (phone && this.$route.query.orderToken) {
        if (this.$route.query.phone_number !== phone) {
          this.$store.dispatch('destroyToken');
          return;
        } else {
          AccountService.claimUserTicket(this.accountId, { order_token: this.$route.query.orderToken });
        }
      }

      AccountService.getUserInfo(this.accountId)
        .then((resp) => {
          let timerSet = resp.data.data.date_of_birth;
          if (timerSet) {
            timerSet = new CustomDate(timerSet);
          }
          let userInfo = {
            ...resp.data.data,
            date_of_birth: timerSet,
            lastName: resp.data.data.name ? resp.data.data.name.split(' ').pop() : ''
          };
          this.$store.commit('setUserInfo', userInfo);
          this.$store.commit('setUserSettings', userInfo.metadata || {});
          this.requestToken();
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getInitData: async function() {
      this.isLoading = true;
      let partner = this.getterPartnerData?.handler;
      try {
        const listGameRes = await GameAppService.getListGame(partner);
        const listGameData = listGameRes?.data || {};
        const listGame = listGameData.data.reduce((result, item) => ({ ...result, [item.id]: { ...item } }), {});
        this.listGame = listGame;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    handlePopupCongratClick() {
      this.isOpenPopupCongratTrial = false;
      if (this.$store.getters.getterIsOpenModalPaymentSuccess) {
        this.$store.commit('setIsOpenModalPaymentSuccess', false);
      }
      this.$router.push(this.urlPopupGrate);
    },
    async requestToken() {
      if (this.getLoadByIframe || !this.firebaseReady) return;
      var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      if (!isSafari && !window.location.host.includes('app')) {
        try {
          const permission = await Notification.requestPermission();
          if (permission !== 'granted') {
            return;
          }
          const token = await this.$messaging.getToken();
          const additionData = this.$store.state.driverUniqueId
            ? {
                driver_unique_id: this.$store.state.driverUniqueId,
                timezone: this.$store.state.timezone || 'Asia/Ho_Chi_Minh'
              }
            : {};

          window.storageHelper.setItem('fcm-token', token);

          await NotificationService.updateToken(this.$store.getters.getterAccountId, {
            token,
            ...additionData
          });
        } catch (err) {
          console.error('Unable to get permission to notify.', err);
        }
      } else {
        if (this.$store.state.driverUniqueId) {
          let token = null;
          try {
            token = window.storageHelper.getItem('fcm-token');
          } catch (error) {
            console.error(error);
          }

          if (token) {
            const data = {
              token,
              driver_unique_id: this.$store.state.driverUniqueId,
              timezone: this.$store.state.timezone || 'Asia/Ho_Chi_Minh'
            };
            NotificationService.updateToken(this.$store.getters.getterAccountId, data).catch((e) => {
              console.error(e);
            });
          }
        }
      }
    },
    loadLastNotification() {
      NotificationService.getLastNotification(this.$store.getters.getterAccountId)
        .then((res) => {
          if (res.status) {
            this.listNotifications = res.data.map((data) => {
              let {
                title,
                target_url,
                content_format,
                raw_data,
                image_url,
                viewed_notify,
                handler,
                notify_time,
                id
              } = data;
              raw_data = JSON.parse(raw_data);
              const content = this.formatString(content_format, raw_data);
              const customField = raw_data.custom_field;
              const isPopup = raw_data.is_popup;

              return {
                title,
                target_url,
                content,
                image_url,
                viewed_notify,
                handler,
                customField,
                notify_time,
                isPopup,
                raw_data,
                id
              };
            });
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    handlerUpdateDataTicket() {
      if (this.dataTicketNotify && this.dataTicketNotify.length > 0) {
        this.isOpenPopupChangePeriod = true;
        this.objectItemTicketNotify = this.dataTicketNotify.slice(-1)[0];
      }
    },
    handleCloseModalChangePeriod(bvModalEvt) {
      this.updateLinkNoty();
      if (this.dataTicketNotify && this.dataTicketNotify.length > 0) {
        this.dataTicketNotify.pop();
        this.objectItemTicketNotify = this.dataTicketNotify.slice(-1)[0];
        if (this.dataTicketNotify.length == 0) {
          this.isOpenPopupChangePeriod = false;
        } else {
          bvModalEvt.preventDefault();
        }
      }
    },
    handleConfirmModalChangePeriod() {
      if (this.dataTicketNotify && this.dataTicketNotify.length > 0) {
        let data = this.dataTicketNotify.slice(-1)[0];
        if (this.timeWaiting) {
          clearTimeout(this.timeWaiting);
        }
        this.timeWaiting = setTimeout(() => {
          this.isConfirmBtnLoading = true;
          OrderAppService.postOrderBuyNextPeriod(data.ticket_id, this.accountId)
            .then(() => {
              Swal.fire({
                icon: 'success',
                title: 'Mua vé thành công',
                showConfirmButton: true,
                confirmButtonText: 'XEM VÉ',
                confirmButtonColor: '#e8a500',
                allowOutsideClick: false,
                allowEscapeKey: false,
                timer: 5000,
                showClass: {
                  popup: 'swal2-noanimation',
                  backdrop: 'swal2-noanimation'
                },
                customClass: {
                  title: 'sweetTitle'
                }
              }).then(() => {
                // this.$store.commit('setListTimeDialOverview', null);
                this.$router.replace('/tai-khoan/lich-su-dat-mua/').catch(() => {});
              });
              this.updateLinkNoty();
              this.dataTicketNotify.pop();
              this.objectItemTicketNotify = data;
              if (this.dataTicketNotify.length === 0) {
                this.isOpenPopupChangePeriod = false;
              }
            })
            .catch((e) => {
              let code = e.response.data.data.code;
              let error = ErrorOrderService.find((p) => p.key === code);
              if (!error) {
                Swal.fire({
                  icon: 'error',
                  title: 'Có lỗi xảy ra trong quá trình xử lý, vui lòng thực hiện lại!',
                  showConfirmButton: false,
                  timer: 3000
                });
                return;
              }
              Swal.fire({
                icon: 'error',
                title: `${error.message}`,
                showConfirmButton: false,
                timer: 3000
              });
            })
            .finally(() => {
              this.isConfirmBtnLoading = false;
            });
        }, 1000);
      }
    },
    handleCancleModalChangePeriod() {
      if (this.dataTicketNotify && this.dataTicketNotify.length > 0) {
        let data = this.dataTicketNotify.slice(-1)[0];
        if (this.timeWaiting) {
          clearTimeout(this.timeWaiting);
        }
        this.timeWaiting = setTimeout(() => {
          this.isCancleBtnLoading = true;
          OrderAppService.postOrderCancelTicket(data.ticket_id, this.accountId)
            .then(() => {
              Swal.fire({
                icon: 'success',
                title: 'Vé của bạn đã được hủy thành công',
                showConfirmButton: false,
                timer: 2000
              });
              this.$store.commit('setIsLoadBalances', true);
              this.updateLinkNoty();
              this.dataTicketNotify.pop();
              this.objectItemTicketNotify = data;
              if (this.dataTicketNotify.length === 0) {
                this.isOpenPopupChangePeriod = false;
              }
            })
            .catch((e) => {
              let code = e.response.data.data.code;
              if (code === '006.001.019') {
                Swal.fire({
                  icon: 'error',
                  title: 'Không tìm thấy',
                  showConfirmButton: false,
                  timer: 3000
                });
                return;
              }

              Swal.fire({
                icon: 'error',
                title: 'Có lỗi xảy ra trong quá trình xử lý, vui lòng thực hiện lại!',
                showConfirmButton: false,
                timer: 3000
              });
            })
            .finally(() => {
              this.isCancleBtnLoading = false;
            });
        }, 1000);
      }
    },
    getProductHandler(item) {
      if (!item || !item.game_id || !item.product_id) {
        return '';
      }
      if (
        this.listGame &&
        this.listGame[item.game_id] &&
        this.listGame[item.game_id].product_list &&
        this.listGame[item.game_id].product_list[item.product_id]
      ) {
        return this.listGame[item.game_id].product_list[item.product_id].handler;
      }
      return '';
    },
    getProductName(data) {
      const gameType = data.game_code;
      const productHandler = this.getProductHandler(data);
      const arrNoName = ['max3d', 'max3d+'];
      if (arrNoName.includes(gameType)) return '';
      if (gameType === 'max4d') {
        const middleHandler = productHandler?.replace(gameType, '').replace(/[0-9]/g, '');
        if (!middleHandler) return cartItemName[gameType].default.value;
        return cartItemName[gameType][middleHandler].value;
      }
      if (gameType === 'keno') {
        return data.game_name;
      }
      return data.details[0].product_name;
    },
    async getTicketStatus(ticketId) {
      return await OrderAppService.getTicketStatus(ticketId);
    },
    async getOrderRequestPeriod(noty_id) {
      if (!this.loggedIn) {
        return;
      }
      let respNotification = await AccountService.getNotificationById(
        this.accountId,
        noty_id,
        ORDER_REQUEST_PENDING
      ).catch(() => {
        Swal.fire({
          icon: 'error',
          title: 'Có lỗi xảy ra trong quá trình xử lý, vui lòng thực hiện lại!',
          showConfirmButton: false,
          timer: 3000
        });
      });
      if (respNotification && !respNotification.status) {
        return;
      }
      this.handlerAfterGetOrderRequest(respNotification);
    },
    async handlerAfterGetOrderRequest(respNotification) {
      const { raw_data } = respNotification.data || undefined;
      if (!raw_data) return;
      const rawData = JSON.parse(raw_data);
      try {
        let respTicketStatus = await this.getTicketStatus(rawData.ticket_id);
        if (respTicketStatus.data && !respTicketStatus.data.status) {
          return;
        }
        const { ticket_status } = respTicketStatus.data.data;
        if (ticket_status === 0) {
          this.dataTicketNotify.push(rawData);
          this.handlerUpdateDataTicket();
        } else {
          Swal.fire({
            icon: 'error',
            title: `Vé ${rawData.game_name} kỳ quay #${rawData.period_no} - ${this.getTimeZone(
              rawData.lottery_time
            )} đã được huỷ.`,
            showConfirmButton: false,
            timer: 5000
          });
          this.updateLinkNoty();
        }
      } catch (error) {
        console.error(error);
      }
    },
    updateLinkNoty() {
      if (this.$route.query && this.$route.query.notify_id) {
        this.$router.replace(this.$route.path);
      }
    },
    handlePlayTrial() {
      const newValue = !this.isPlayTrial;

      if (!newValue) {
        const isOpenModalPaymentSuccess = this.$store.getters.getterIsOpenModalPaymentSuccess;
        const isOpenPopupCongratTrial = this.$store.getters.getterIsOpenPopupCongrats;

        //kiểm tra modal payment success nếu đang mở sẽ đóng lại
        if (isOpenModalPaymentSuccess) {
          this.$store.commit('setIsOpenModalPaymentSuccess', false);
        }
        //kiểm tra modal thông báo kết quả trúng thưởng nếu đang mở sẽ đóng lại
        if (isOpenPopupCongratTrial) {
          this.$store.commit('setIsOpenPopupCongrats', false);
        }

        this.$store.dispatch('exitPlayTrialMode');

        //trả về trang chủ khi thoát thử game với danh sách các url tương ứng
        // if (LIST_ROUTES_TO_HOMEPAGE_IN_GAME_TRIAL.includes(this.$route.path)) {
        //   this.$router.replace('/');
        // }
        //xóa localstore khi thoát thử game
        this.$store.commit('clearObjResultGameTrial');
        this.$router.replace('/');
      }

      this.$store.commit('setPlayTrial', newValue);
    },
    setModalConfirmPlayTrial(newVal) {
      this.$store.commit('setIsOpenModalPlayTrial', newVal);
    },
    handleEventLoggedIn() {
      this.sendMessageToParent(eventTypeMessage.SIGN_IN);
      if (this.isConnectedSocket) {
        this.$socket.emit(SUBSCRIBED, {
          socketId: this.$socket.id,
          accountId: this.accountId,
          platform: this.isMobileApp ? 'app' : 'web',
          timezone: dayjs.tz.guess()
        });
      }
    },
    checkUpdateClientForOrder() {
      if (!this.transactionFinisedFromParent) {
        return;
      }

      let olSocket = this.$cookies.get('old_socket_id');
      let saveOrderReceiverId = this.$cookies.get('save_order_receiver_id');
      let saveOrderReceiverPhone = this.$cookies.get('save_order_receiver_phone');
      this.$store.commit('setModalQuickBuyInfo', {
        phoneNumber: saveOrderReceiverPhone,
        passportId: saveOrderReceiverId
      });
      this.setCookieInIframe('save_order_receiver_id', '', 0);
      this.setCookieInIframe('save_order_receiver_phone', '', 0);

      if (this.$socket.id !== olSocket) {
        OrderService.updateClientIdForPayment({
          old_id: olSocket,
          new_id: this.$socket.id
        }).then(() => {
          this.setCookieInIframe('old_socket_id', '', 0);
        });
      }
    },
    setModalIsRewardTransaction(value) {
      this.isRewardTransaction = value;
    },
    setModalIsOpentransactionDetail(value) {
      this.isOpentransactionDetail = value;
    },
    handleOpenDetailTransaction() {
      const {
        raw_data: { display_ticket_id }
      } = this.popupCongrateData;

      OrderService.getTicketDetail(display_ticket_id)
        .then((res) => {
          if (!res.data.status) {
            return;
          }

          let { ticketDetail } = res.data.data;
          const rewardTransactionData = [];

          ticketDetail = ticketDetail.filter((item) => item.reward);

          if (ticketDetail.length === 1 && ticketDetail[0].reward.length === 1) {
            this.handleOpenTransactionDetail(ticketDetail[0].reward[0].display_reward_id);
            return;
          }

          for (const ticket of ticketDetail) {
            if (!ticket.reward) {
              continue;
            }
            for (const rewardItem of ticket.reward) {
              const labelStatus = labelTransStatus[rewardItem.status] || 'Đang xử lý';
              rewardTransactionData.push({
                ticketName: 'Bộ số ' + ticket.ticket_name,
                rewardValue: rewardItem.amount,
                labelStatus,
                displayTicketId: rewardItem.display_reward_id
              });
            }
          }

          this.rewardTransactionData = rewardTransactionData;
          this.setModalIsRewardTransaction(true);
        })
        .catch();
    },
    handleOpenTransactionDetail(id) {
      if (this.transactionDetailData?.id === id) {
        this.setModalIsOpentransactionDetail(true);
        return;
      }
      PaymentService.getWithdrawHistoryDetail(this.accountId, id)
        .then((resp) => {
          const { status, data } = resp?.data || {};
          if (status) {
            this.transactionDetailData = data;
            this.setModalIsOpentransactionDetail(true);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    linkToTicketDetail() {
      this.setModalIsOpentransactionDetail(false);
      this.setModalIsRewardTransaction(false);
      this.updateOpenPopupCongrat(false);
      this.$router.push(
        this.popupCongrateData.raw_data.display_ticket_id
          ? `${
              this.popupCongrateData.raw_data.is_gifted
                ? '/tai-khoan/ket-qua-duoc-tang/'
                : this.isTraditional
                ? '/tai-khoan/lich-su-dat-mua/'
                : '/tai-khoan/ket-qua/'
            }${this.popupCongrateData.raw_data.display_ticket_id}`
          : '/'
      );
    },
    getReferralCode() {
      AccountService.getReferralCode(this.accountId).then((data) => {
        this.$store.commit('setReferralCode', data);
      });
    },
    handleSendThank() {
      const { name, username, display_ticket_id } = this.popupGiftTicketData?.raw_data || {};
      if (!display_ticket_id) return;

      this.$store.commit('setPopupSendThankData', {
        name,
        username,
        display_ticket_id,
        type: TICKET_MESSAGE_ENUM.BUY_REPLY,
        fromPopupGift: true
      });

      this.$store.commit('setIsOpenSendThank', true);
    },
    handleConfirmPopupCongrat() {
      let path = '';
      switch (true) {
        case this.popupCongrateData.handler === 'gift_reward_congrat':
          path = '/tai-khoan/ket-qua-da-tang/';
          break;
        case this.popupCongrateData.raw_data.is_gifted:
          path = '/tai-khoan/ket-qua-duoc-tang/';
          break;
        case this.isTraditional:
          path = '/tai-khoan/lich-su-dat-mua/';
          break;
        default:
          path = '/tai-khoan/ket-qua/';
          break;
      }

      this.$router.push(
        this.popupCongrateData.raw_data.display_ticket_id
          ? `${path}${this.popupCongrateData.raw_data.display_ticket_id}`
          : '/'
      );
    },
    handleRewardSendThank() {
      const { gift_name, gift_phone, display_ticket_id } = this.popupCongrateData?.raw_data || {};
      if (!display_ticket_id) return;

      this.$store.commit('setPopupSendThankData', {
        name: gift_name,
        username: gift_phone,
        display_ticket_id,
        type: TICKET_MESSAGE_ENUM.REWARD_REPLY,
        fromPopupGiftReward: true
      });

      this.$store.commit('setIsOpenSendThank', true);
    },
    handleShareThank() {
      const { display_ticket_id, gift_code, name, phone, message, reward_amount } = this.popupThankData?.raw_data || {};

      let paymentData = {
        gift_code,
        gift_message: message,
        name,
        gift_type: '',
        total_reward: reward_amount,
        username: phone,
        ticket_id: display_ticket_id
      };

      if (this.popupThankData.handler === 'buy_reply') {
        paymentData.gift_type = SHAREFB_TYPE.BUY_REPLY;
      } else {
        paymentData.gift_type = SHAREFB_TYPE.REWARD_REPLY;
      }
      if (!paymentData.gift_type) {
        return;
      }
      this.$store.commit('setDataRequestPayment', paymentData);
      this.$store.commit('setIsOpenModalShare', true);
    },
    handleShareGiftWin() {
      const { display_ticket_id, gift_code, name, phone, message, reward_amount } =
        this.popupCongrateData?.raw_data || {};
      let paymentData = {
        gift_code,
        gift_message: message,
        name,
        gift_type: SHAREFB_TYPE.GIFT_WIN,
        total_reward: this.revertPrice(reward_amount),
        username: phone,
        ticket_id: display_ticket_id
      };

      this.$store.commit('setDataRequestPayment', paymentData);
      this.$store.commit('setIsOpenModalShare', true);
    },
    handleSyncWallet(data) {
      this.pendingBalance = parseInt(data);
    }
  },
  beforeDestroy() {
    this.pusherChannel.unbind('notification', this.handleSyncWallet);
    this.pusher.unsubscribe(this.accountSyncWallet);
  }
};
window.onload = function() {
  closeNavbar();
};

function closeNavbar() {
  document.addEventListener('click', (e) => {
    var click = e.target;
    var open = document.querySelector('.navbar-collapse');
    var isOpen;
    if (open) {
      isOpen = open.classList.contains('show');
    }
    var isTogger = click.classList.contains('navbar-toggler');
    if (isOpen && !isTogger) {
      var clickBtn = document.querySelector('.not-collapsed');
      if (clickBtn) {
        clickBtn.click();
      }
    }
  });
}
</script>
<style lang="scss">
.text-italic {
  font-style: italic;
}
.gotoTop {
  opacity: 1;
  transition: 0.3s all;
}

#goTop {
  position: fixed;
  z-index: 1039;
  width: 32px;
  height: 32px;
  right: 20px;
  bottom: 20px;
  border-radius: 50% !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #cecece;
  border: none;
  // opacity: 0;
  transition: 0.2s all;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    top: -2px;
  }
  &:focus,
  &:active {
    outline: 0;
    box-shadow: none;
  }
  &:hover {
    background-color: #05668d;
    svg {
      path {
        fill: #fff;
      }
    }
  }
}
.payment-error-footer {
  button {
    max-width: 100% !important;
  }
}
.customModalClassPeriod {
  %box-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cartItem {
    background-color: #fff;
    padding-top: 1rem;
    margin-bottom: 1rem;
    &__info {
      font-size: 1rem;
      padding: 0 15px 9px 15px;
      &__img {
        width: 76px;
        height: 62px;
        object-fit: contain;
        @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
          width: 94px;
          height: 74px;
        }
      }

      &__totalPrice {
        color: #333333;
        font-weight: 600;
        font-size: 1.25rem;
        text-align: right;
        margin-bottom: 4px;

        @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
          margin-bottom: 8px;
          font-size: 1.875rem;
          font-weight: normal;
        }
      }
      &__btnDelete {
        @extend %box-center;
        border-radius: 3px;
        background-color: #f7f7f7;
        font-size: 0.875rem;
        // line-height: 1px;
        padding: 0;
        min-height: 30px;
        min-width: 92px;
        &:hover {
          background-color: darken(#f7f7f7, 10%);
        }
      }

      &__orderDetail {
        @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
          margin-left: 14px;
        }
      }

      @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
        padding: 0 19px 12px 19px;
      }
    }
    &__list {
      padding: 0 16px;
      background-color: #efefef;
      @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
        padding: 0 19px;
        padding-bottom: 10px;
      }
    }
  }
}

.notiMomoBtn {
  height: 40px;
  border-radius: 3px;
  padding: 7px 0px;
  width: 100%;
  font-weight: 600;
  font-size: 0.9375rem;
  &__fbMessenger {
    background-color: $primaryColor !important;
    color: #ffffff !important;
    text-transform: uppercase;
    &:hover {
      background-color: $primaryColorDarkest !important;
      color: #ffffff !important;
    }
  }

  &__hotline {
    background-color: #e5edf5 !important;
    text-transform: uppercase;
    color: $colorBlue !important;
    &:hover {
      background-color: darken(#e5edf5, 10%) !important;
    }
  }
}

.modalRewardTransaction {
  padding-bottom: 20px;
  &__ticketName {
    font-weight: 600;
    font-size: 15px;
    // margin-top: 20px;
    margin-bottom: 20px;
  }

  &__groupInfo {
    & > div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  }

  &__label {
    color: #666666;
    font-size: 14px;
  }

  &__value {
    font-weight: 600;
  }
}

.modalTransactionDetail {
  padding-bottom: 20px;
  .row > div {
    margin-bottom: 20px;
  }

  &__label {
    color: #666666;
    font-size: 14px;
    text-transform: uppercase;
  }

  &__value {
    font-weight: 600;
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .customModalClassPeriod {
    .customDialog {
      min-width: 734px;
    }

    .modal-body {
      padding: 0 20px 15px 20px;
    }
  }
}
@media (max-width: map-get($grid-breakpoints, 'fix-lg')) {
  .customModalClassPeriod {
    padding: 0px !important;

    .ticketItem {
      padding-bottom: 0.1rem;
    }

    .cartItem__info {
      font-size: 15px;
      font-weight: 400;
      padding: 0 0 9px 0;
    }
    .cartItem__info__img {
      width: 58px !important;
      height: auto !important;
      margin-right: 5px;
    }

    .modal-footer {
      padding-bottom: 12px !important;
    }

    .modal-dialog {
      height: 100%;
      margin: 0;
      width: 100%;
      min-height: 0;
      max-width: 100%;
      .modal-content {
        height: 100%;
        border: none;
        border-radius: 0;
      }
    }
  }

  #goTop {
    bottom: 15px;
    right: 15px;
    &:hover {
      background-color: #cecece;
      svg {
        path {
          fill: rgb(153, 153, 153);
        }
      }
    }
  }
}

strong {
  font-weight: 600;
}
</style>

<style lang="scss">
.popupWaitingPayment {
  max-width: 380px !important;
}

.notiMomo {
  max-width: 344px !important;
}

.popupThank {
  max-width: 410 !important;
}
.popupGiftTicket {
  max-width: 410px !important;
  .actionGroup__btn {
    &--confirm {
      color: #ffffff;
      background-color: $colorBlue;
      &:hover {
        background-color: darken($colorBlue, 10%);
      }
    }
  }
}

.popupRewardTrans {
  .modalTitle {
    color: #0d234a;
  }
}
</style>
