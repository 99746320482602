<template>
  <b-modal
    headerClass="border-bottom-0 pb-4"
    footerClass="border-top-0 py-2 px-3"
    modal-class="modalTerm modalCustomMobileSecond"
    body-class="pt-0"
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    @show="showModal"
    v-model="isModalTerm"
    scrollable
    centered
  >
    <template v-slot:modal-title>
      <p class="font-20 color-black-1">Điều khoản sử dụng</p>
    </template>

    <div v-html="content"></div>
    <template v-slot:modal-footer>
      <div class="d-flex justify-content-between align-items-center w-100 position-relative pt-1">
        <div class="gradientBoder" />

        <div class="d-flex align-items-center">
          <div
            class="checkbox-custom-input-wrapper rememberPassword mr-2"
            :class="{
              checked: agreePolicy
            }"
          >
            <input
              id="input-remember"
              class="checkbox-hidden-input"
              :class="{
                'checkbox-custom-input-wrapper--checked': agreePolicy
              }"
              type="checkbox"
              :checked="agreePolicy"
              @click="agreePolicy = !agreePolicy"
            />
            <span
              v-if="agreePolicy"
              class="checkbox-custom-input"
              :class="{
                'checkbox-custom-input--checked': agreePolicy
              }"
              >&#x2713;</span
            >
          </div>
          <label for="input-remember" class="w-100 mb-0">Tôi đã đọc và đồng ý</label>
        </div>
        <button
          :disabled="!isCheckLogin"
          @click="handlerUpdateModal"
          type="submit"
          class="btn text-uppercase my-lg-2 btnCheck"
        >
          <span> Tiếp tục</span>
        </button>
      </div>
    </template>
  </b-modal>
</template>
<script>
export default {
  components: {
    // ModalChooseFast
  },
  props: {},
  data() {
    return {
      agreePolicy: false,
      content: ''
    };
  },
  computed: {
    isCheckLogin() {
      return this.agreePolicy;
    },
    isModalTerm: {
      get() {
        return this.$store.getters.getterModalTerm;
      },
      set(newVal) {
        this.$store.commit('setModalTerm', newVal);
      }
    }
  },

  mounted() {
    // this.objPrizeKeno = this.$store.getters.getterObjPrizeKeno;
  },
  methods: {
    showModal() {
      this.getArticleBySlug();
    },
    handlerUpdateModal() {
      if (this.isCheckLogin) {
        this.agreePolicy = false;
        this.isModalTerm = false;
      }
    },
    getArticleBySlug() {
      let slug = 'dieu-khoan-su-dung';
      this.$store.dispatch('getArticleBySlug', slug).then((resp) => {
        const { content } = resp;
        this.content = content;
      });
    }
  },
  watch: {
    isModalTerm: function(newVal) {
      this.handleToggleModal(newVal);
    }
  }
};
</script>
<style lang="scss" scoped>
.gradientBoder {
  height: 1px;
  background: linear-gradient(0deg, #aaaaaa 0%, #ffffff 70%);
  position: absolute;
  top: -0.5rem;
  left: -1rem;
  right: -1rem;
}
.btnCheck {
  border-radius: 3px;
  // background-image: linear-gradient(to bottom, #e8a500, #d68c00);
  background-color: $primaryColor;
  color: #fff;
  padding: 0.2rem 0.75rem;
  line-height: 1.47;
  font-size: 0.9375rem;
  transition: 0.3s all;
  // &:hover {
  //   color: #fff;
  //   background-color: #9a1834;
  // }
}
@media (max-width: map-get($grid-breakpoints, 'md')) {
  .btnCheckLucky {
    text-align: left;
  }

  .modalChooseItemNumber {
    .modalChooseItemNumberContent {
      height: auto;
    }
    .btnCheck {
      width: 157px !important;
    }
  }
}
</style>
