<template>
  <div class="position-relative d-flex z-1001 mr-sub-3">
    <ComonIconNotify />
    <!-- <router-link to="/tai-khoan/thong-bao" class="pointer ml-lg-2 d-block d-md-none" @click="openModal('modal_1')">
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
        <g transform="translate(16363 21897)">
          <rect width="28" height="28" transform="translate(-16363 -21897)" fill="#f7f7f7" opacity="0" />
          <path
            d="M8.414-3.348c-.755-.811-2.167-2.031-2.167-6.027a6.169,6.169,0,0,0-5-6.061v-.814A1.25,1.25,0,0,0,0-17.5a1.25,1.25,0,0,0-1.249,1.25v.814a6.169,6.169,0,0,0-5,6.061c0,4-1.412,5.216-2.167,6.027A1.221,1.221,0,0,0-8.75-2.5,1.251,1.251,0,0,0-7.5-1.25h15A1.251,1.251,0,0,0,8.75-2.5a1.22,1.22,0,0,0-.336-.848Zm-14.526.223c.829-1.093,1.735-2.9,1.739-6.227v-.023A4.375,4.375,0,0,1,0-13.75,4.375,4.375,0,0,1,4.375-9.375v.023A9.758,9.758,0,0,0,6.114-3.125ZM0,2.5A2.5,2.5,0,0,0,2.5,0h-5A2.5,2.5,0,0,0,0,2.5Z"
            transform="translate(-16349.25 -21876)"
            fill="#0d234a"
          />
          <circle v-if="isSeen" cx="4" cy="4" r="4" transform="translate(-16348 -21893)" fill="#d31904" />
        </g>
      </svg>
    </router-link> -->
    <a @click="openModal('modal_1')" class="pointer ml-lg-2 d-none d-md-block">
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
        <g transform="translate(16363 21897)">
          <rect width="28" height="28" transform="translate(-16363 -21897)" fill="#f7f7f7" opacity="0" />
          <path
            d="M8.414-3.348c-.755-.811-2.167-2.031-2.167-6.027a6.169,6.169,0,0,0-5-6.061v-.814A1.25,1.25,0,0,0,0-17.5a1.25,1.25,0,0,0-1.249,1.25v.814a6.169,6.169,0,0,0-5,6.061c0,4-1.412,5.216-2.167,6.027A1.221,1.221,0,0,0-8.75-2.5,1.251,1.251,0,0,0-7.5-1.25h15A1.251,1.251,0,0,0,8.75-2.5a1.22,1.22,0,0,0-.336-.848Zm-14.526.223c.829-1.093,1.735-2.9,1.739-6.227v-.023A4.375,4.375,0,0,1,0-13.75,4.375,4.375,0,0,1,4.375-9.375v.023A9.758,9.758,0,0,0,6.114-3.125ZM0,2.5A2.5,2.5,0,0,0,2.5,0h-5A2.5,2.5,0,0,0,0,2.5Z"
            transform="translate(-16349.25 -21876)"
            fill="#0d234a"
          />
          <circle v-if="isSeen" cx="4" cy="4" r="4" transform="translate(-16348 -21893)" fill="#d31904" />
        </g>
      </svg>
      <!-- <span :class="{ myNavbarIconsRepNotify: navbarIconsRepNotify }"></span> -->
    </a>
    <div class="modals d-none d-md-block" :class="{ active: modal }">
      <div tabindex="0" class="modal-inner" @blur="closeModal()" ref="modal_1">
        <div class="empty-notify" v-if="listNotifications.length == 0">
          <p>Bạn chưa có thông báo nào</p>
        </div>
        <div v-else>
          <div class="modal-scroll">
            <div v-for="item in listNotifications" :key="item.id">
              <div @click="handlePressNoti(item)" class="d-flex boxItemNotify">
                <div class="mr-lg-2 mt-1">
                  <img :src="item.image_url" width="40" height="40" class="obfit rounded50" alt />
                </div>
                <div class="w-80">
                  <p class="color-black-1 font-weight-600">{{ item.title }}</p>
                  <p class="color-grey fontSize13">
                    <span>{{ item.content }}</span>
                  </p>
                  <p class="mt-1 fontSize13" style="color:#666666">
                    {{ formatDate(item.notify_time, 'HH:mm:ss') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <router-link
              to="/tai-khoan/thong-bao"
              class="color-black-1 fontSize13 text-uppercase text-center py-lg-3 pointer d-block boxAll"
              >Xem tất cả</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationService from '../../api/notification';
import ComonIconNotify from './ComonIconNotify';
export default {
  components: { ComonIconNotify },
  data() {
    return {
      modal: false
    };
  },
  computed: {
    isSeen() {
      return this.listNotifications.find((x) => !x.viewed_notify);
    },
    listNotifications: {
      get() {
        return this.$store.state.listNotifications;
      },
      set(value) {
        this.$store.commit('setlistNotifications', value);
      }
    },
    isOpenPopupCongrat: {
      get() {
        return this.$store.getters.getterIsOpenPopupCongrat;
      },
      set(newVal) {
        return this.$store.commit('setIsOpenPopupCongrat', newVal);
      }
    },
    popupCongrateData: {
      get() {
        return this.$store.getters.getterPopupCongrateData;
      },
      set(newVal) {
        return this.$store.commit('setPopupCongrateData', newVal);
      }
    },
    isOpenPopupGiftTicket: {
      get() {
        return this.$store.getters.getterIsOpenPopupGiftTicket;
      },
      set(newVal) {
        return this.$store.commit('setIsOpenPopupGiftTicket', newVal);
      }
    },
    popupGiftTicketData: {
      get() {
        return this.$store.getters.getterPopupGiftTicketData;
      },
      set(newVal) {
        return this.$store.commit('setPopupGiftTicketData', newVal);
      }
    },
    isOpenPopupThank: {
      get() {
        return this.$store.getters.getterIsOpenPopupThank;
      },
      set(newVal) {
        return this.$store.commit('setIsOpenPopupThank', newVal);
      }
    },
    popupThankData: {
      get() {
        return this.$store.getters.getterPopupThankData;
      },
      set(newVal) {
        return this.$store.commit('setPopupThankData', newVal);
      }
    }
  },
  methods: {
    handlePressNoti(item) {
      if (item.isPopup) {
        if (item.customField) {
          this.$store.commit('setIsOpenPopupNotifyMessage', true);
          this.$store.commit('setPopupNotifyMessageData', {
            ...item.customField,
            popup_message: item.customField.popup_message || item.content
          });
          return;
        }
      }
      if (item.handler === 'payment_error') {
        this.$store.commit('setIsOpenPopupPaymentError', true);
        return;
      }

      if (item.handler === 'reward_congrat' || item.handler === 'gift_reward_congrat') {
        this.popupCongrateData = item;
        this.isOpenPopupCongrat = true;
        return;
      }

      if (item.handler === 'gift_success') {
        this.popupGiftTicketData = item;
        this.isOpenPopupGiftTicket = true;
        return;
      }
      if (item.handler === 'buy_reply' || item.handler === 'reward_reply') {
        this.popupThankData = item;
        this.isOpenPopupThank = true;
        return;
      }

      this.$router.push(item.target_url || '/').catch((err) => {
        if (
          err.name !== 'NavigationDuplicated' &&
          !err.message.includes('Avoided redundant navigation to current location')
        ) {
          console.error(err);
        }
      });
    },
    setSeenNotify() {
      this.listNotifications.forEach((x, index) => {
        this.listNotifications[index].viewed_notify = 1;
      });
    },
    closeModal() {
      this.modal = false;
    },
    async openModal(e) {
      this.modal = true;
      this.$refs[e].focus();
      if (this.isSeen) {
        const res = await NotificationService.updateSeenNotify(this.$store.getters.getterAccountId).catch((e) => {
          console.error(e);
        });
        if (res.status) {
          this.setSeenNotify();
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.empty-notify {
  width: 335px;
  height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.modals {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transform: scale(0);
  transition: ease opacity 0.3s, ease transform 0s 0.3s;

  &.active {
    opacity: 1;
    transform: scale(1);
    transition: ease opacity 0.3s, ease transform 0s;
  }
}

.modal-inner {
  width: 335px;
  // height: 401px;
  position: absolute;
  border-radius: 5px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #d8d8d8;
  background-color: #ffffff;
  right: 0;
  top: 47px;
  outline: none;
  div {
    .boxItemNotify {
      &:first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
  }
  .boxItemNotify {
    overflow: hidden;
    padding: 0.9rem 1rem;
    border-bottom: 1px solid #efefef;
    transition: 0.3s all;
    cursor: pointer;

    &:hover {
      background-color: #f7f7f7;
    }
  }
  .boxAll {
    transition: 0.3s all;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    &:hover {
      background-color: #f7f7f7;
    }
  }
  .modal-scroll {
    max-height: calc(100vh - 75px - 48px);
    overflow: auto;
  }
}

.mr-sub-3 {
  margin-right: -3px;
}
</style>
