<template>
  <div>
    <form ref="form">
      <div class="row row-5 ">
        <div class="col-lg-4 px5px">
          <!-- <p>Họ và tên</p> -->
          <b-form-group>
            <label class="pb-lg-1">Họ và tên</label>
            <b-form-input
              v-model="userName"
              @input="changeUserName"
              data-vv-as="Họ và tên"
              :class="{ input: true, 'is-danger': errors.has('userName') }"
              v-validate="{ required: true, min: 3, regex: /^\S[^`~!@#$%^&*()_+={}\[\]|\\:;“’<,>.?๐฿0-9]*$/ }"
              id="userName"
              name="userName"
              trim
              placeholder="Nhập họ & tên"
            ></b-form-input>
            <span v-show="errors.has('userName')" class="is-danger-text">{{ errors.first('userName') }}</span>
          </b-form-group>
        </div>
        <div
          class="col-lg-4 px5px"
          :class="{
            'd-none': isHiddenPhone
          }"
        >
          <!-- <p>Họ và tên</p> -->
          <b-form-group>
            <label>Số điện thoại</label>
            <b-form-input
              @input="changeUserName"
              v-validate="{
                min: 10,
                max: 10,
                regex: /\b(0|3|7|8|9)+([0-9]{8})\b/,
                required: true
              }"
              v-model="phoneNumber"
              data-vv-as="Số điện thoại"
              :class="{ input: true, 'is-danger': errors.has('phoneNumber') }"
              id="phoneNumber"
              name="phoneNumber"
              type="number"
              trim
              disabled
            ></b-form-input>
            <span v-show="errors.has('phoneNumber')" class="is-danger-text">{{ errors.first('phoneNumber') }}</span>
          </b-form-group>
        </div>
        <div class="col-lg-4 px5px">
          <!-- <p>Họ và tên</p> -->
          <b-form-group>
            <label class="pb-lg-1">Số CMND/CCCD</label>
            <b-form-input
              @input="changeUserName"
              v-validate="{ regex: /^([0-9]{9}|[0-9]{12})$/, required: true }"
              type="number"
              v-model="numberCMND"
              data-vv-as="Số CMND/CCCD"
              :class="{ input: true, 'is-danger': errors.has('numberCMND') }"
              id="numberCMND"
              name="numberCMND"
              placeholder="Nhập số CMND/CCCD"
            ></b-form-input>
            <span v-show="errors.has('numberCMND')" class="is-danger-text">{{
              errors.first('numberCMND') ? 'CMND/CCCD chỉ 9 hoặc 12 kí tự' : ''
            }}</span>
          </b-form-group>
        </div>
        <!-- HIDE BIRTHDAY -->
        <!-- <div class="col-lg-3 px5px"> -->
        <!-- <p>Họ và tên</p> -->
        <!-- <b-form-group class="titleBirthday" id="group-dob" label="Ngày sinh (tùy chọn)" label-for="input-dob">
            <div class="justify-content-center timeDialFilter">
              <UIDateTimePickerCommon
                :isMobile="isMobile"
                :title="'Ngày sinh'"
                :date_of_birth="dateOfBirth"
                :maxDateDesktop="maxDateDesktop"
                :maxDateMobile="maxDateMobile"
                :minYear="1920"
                :minDateDesktop="new CustomDate('1920')"
                :defaultSelectValue="'20/01/2000'"
                @handlerInputCalendar="handlerInputCalendar"
              ></UIDateTimePickerCommon> -->
        <!-- <vc-date-picker
                @input="changeUserName"
                locale="vi"
                id="input-dob"
                :to-page="{
                  month: dateOfBirth ? dateOfBirth.getMonth() + 1 : new CustomDate().getMonth() + 1,
                  year: dateOfBirth ? dateOfBirth.getFullYear() : new CustomDate().getFullYear() - 18
                }"
                :max-date="new CustomDate()"
                class="w-100"
                :input-debounce="500"
                v-model="dateOfBirth"
                :popover="{ visibility: 'click', placement: 'top-start' }"
                is-expanded
              >
                <input
                  id="date"
                  :readonly="isMobile ? 'readonly' : undefined"
                  slot-scope="{ inputProps, inputEvents }"
                  data-vv-as="Ngày sinh"
                  v-bind="inputProps"
                  v-on="inputEvents"
                  name="dob"
                  ref="dob"
                  locale="vi"
                  class="form-control"
                />
              </vc-date-picker>
              <label for="date" class="iconTimeDial"></label> -->
        <!-- </div>
          </b-form-group>
        </div> -->
      </div>
      <!-- <div class="d-flex align-items-center">
        <div class="checkbox-custom-input-wrapper rememberPassword mr-2">
          <input
            id="input-remember"
            class="checkbox-hidden-input"
            :class="{
              'checkbox-custom-input-wrapper--checked': isSaveReceiver
            }"
            type="checkbox"
            @click="handlerSetting"
            checked
          />
          <span
            v-if="isSaveReceiver"
            class="checkbox-custom-input"
            :class="{
              'checkbox-custom-input--checked': isSaveReceiver
            }"
          >
            &#x2713;
          </span>
         
        </div>
        <label for="input-remember" class="w-100 mb-0">
          Lưu thông tin để mua lần sau
        </label>
      </div> -->
    </form>
  </div>
</template>
<script>
// import UIDateTimePickerCommon from '@/components/UI/DateTimePicker/UIDateTimePickerCommon';
export default {
  components: {
    // UIDateTimePickerCommon
  },
  props: ['listUser', 'saveReceiver', 'isHiddenPhone'],
  data() {
    return {
      userName: '',
      phoneNumber: '',
      numberCMND: '',
      dateOfBirth: '',
      rememberPassword: false
    };
  },
  mounted() {
    const { name, phone, passport, date_of_birth } = this.getUserInfo;
    // this.userName = this.listUser.name;
    // this.phoneNumber = this.listUser.phone;
    // this.numberCMND = this.listUser.passport;
    // this.dateOfBirth = this.listUser.date_of_birth;
    // this.rememberPassword = this.saveReceiver === 0 ? false : true;
    this.userName = name;
    this.phoneNumber = phone;
    this.numberCMND = passport;
    this.dateOfBirth = date_of_birth;

    if (!passport || !name) {
      const { name, passport } = this.parentUserInfo;
      if (name) {
        this.userName = name;
      }
      if (passport) {
        this.numberCMND = passport;
      }
    }
  },
  watch: {
    // listUser: function(newVal, oldVal) {
    //   if (newVal != oldVal) {
    //     this.userName = newVal.name;
    //     this.phoneNumber = newVal.phone;
    //     this.numberCMND = newVal.passport;
    //     this.dateOfBirth = newVal.date_of_birth;
    //   }
    // },
    // saveReceiver: function(newVal, oldVal) {
    //   if (newVal != oldVal) {
    //     this.rememberPassword = newVal === 0 ? false : true;
    //   }
    // }
  },
  created() {
    this.$validator.validateAll();
    // this.$bus.$emit('resultCheckForm', result ? true : false);
    this.$bus.$off('checkForm');
    this.$bus.$on('checkForm', ($event) => {
      this.resultCheckForms($event);
    });
  },
  computed: {
    // isSaveReceiver() {
    //   return this.$store.getters.getterIsSaveReceiver;
    // },
    maxDateDesktop() {
      let d = new CustomDate();
      return new CustomDate(d.getFullYear() - 18, d.getMonth(), d.getDate());
    },
    maxDateMobile() {
      var d = new CustomDate(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear() - 18;

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      return [year, month, day].join('-');
    },
    isMobile() {
      return window.innerWidth <= this.getMobileThresshold();
    },
    getUserInfo() {
      return this.$store.getters.getterUserInfo;
    },
    parentUserInfo() {
      return this.$store.state.parentUserInfo;
    }
  },
  methods: {
    handlerInputCalendar(newVal) {
      this.dateOfBirth = newVal;
      this.changeUserName();
    },
    resultCheckForms(e) {
      if (!e) return;
      this.$validator.validateAll().then(() => {
        if (this.$validator.errors.any()) {
          document.getElementById('numberCMND').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
          this.$bus.$emit('resultCheckForm', false);
          // Prevent the form from submitting
        } else {
          this.$bus.$emit('resultCheckForm', true);
        }
      });
    },
    changeUserName() {
      let data = {
        name: this.userName,
        passport: this.numberCMND,
        date_of_birth: this.dateOfBirth || ''
      };
      this.$emit('handlerUser', data);
    },
    checkForm() {
      this.resultCheckForm();
    },
    async handlerSetting() {
      this.rememberPassword = !this.rememberPassword;
      await this.$store.commit('setIsSaveReceiver', this.rememberPassword);
    }
  }
};
</script>
<style lang="scss">
.timeDialFilter {
  .iconTimeDial {
    background-image: url('../../assets/img/icon/icon_calendar.svg');
    width: 30px;
    height: 40px;
    background-repeat: no-repeat;
    position: absolute;
    right: 3px;
    top: 12px;
    cursor: pointer;
  }
}

.recipientModal {
  [class^='col-lg-'] {
    width: 100% !important;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.form-control {
  background-color: #f7f7f7;
  border: solid 1px #efefef;
  &:focus {
    color: #495057;

    border-color: #efefef;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
.form-control.input[disabled='disabled'] {
  background-color: #d8d8d8;
}

.vc-w-full {
  width: 310px;
  .vc-popover-content-wrapper {
    transform: translate3d(-95px, 27px, 0px) !important;
  }
  .vc-nav-container {
    width: 265px;
  }
  .vc-w-12 {
    width: 68px !important;
    height: 35px !important;
  }
}

@media (max-width: 320px) {
  .vc-w-full {
    width: 280px;
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .recipientModal2 {
    [class^='col-lg-'] {
      width: 100% !important;
      flex: 0 0 100%;
      max-width: 50% !important;
      margin-bottom: 10px;
    }
  }
}
</style>
