var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form',{ref:"form"},[_c('div',{staticClass:"row row-5 "},[_c('div',{staticClass:"col-lg-4 px5px"},[_c('b-form-group',[_c('label',{staticClass:"pb-lg-1"},[_vm._v("Họ và tên")]),_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true, min: 3, regex: /^\S[^`~!@#$%^&*()_+={}\[\]|\\:;“’<,>.?๐฿0-9]*$/ }),expression:"{ required: true, min: 3, regex: /^\\S[^`~!@#$%^&*()_+={}\\[\\]|\\\\:;“’<,>.?๐฿0-9]*$/ }"}],class:{ input: true, 'is-danger': _vm.errors.has('userName') },attrs:{"data-vv-as":"Họ và tên","id":"userName","name":"userName","trim":"","placeholder":"Nhập họ & tên"},on:{"input":_vm.changeUserName},model:{value:(_vm.userName),callback:function ($$v) {_vm.userName=$$v},expression:"userName"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('userName')),expression:"errors.has('userName')"}],staticClass:"is-danger-text"},[_vm._v(_vm._s(_vm.errors.first('userName')))])],1)],1),_c('div',{staticClass:"col-lg-4 px5px",class:{
          'd-none': _vm.isHiddenPhone
        }},[_c('b-form-group',[_c('label',[_vm._v("Số điện thoại")]),_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
              min: 10,
              max: 10,
              regex: /\b(0|3|7|8|9)+([0-9]{8})\b/,
              required: true
            }),expression:"{\n              min: 10,\n              max: 10,\n              regex: /\\b(0|3|7|8|9)+([0-9]{8})\\b/,\n              required: true\n            }"}],class:{ input: true, 'is-danger': _vm.errors.has('phoneNumber') },attrs:{"data-vv-as":"Số điện thoại","id":"phoneNumber","name":"phoneNumber","type":"number","trim":"","disabled":""},on:{"input":_vm.changeUserName},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('phoneNumber')),expression:"errors.has('phoneNumber')"}],staticClass:"is-danger-text"},[_vm._v(_vm._s(_vm.errors.first('phoneNumber')))])],1)],1),_c('div',{staticClass:"col-lg-4 px5px"},[_c('b-form-group',[_c('label',{staticClass:"pb-lg-1"},[_vm._v("Số CMND/CCCD")]),_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({ regex: /^([0-9]{9}|[0-9]{12})$/, required: true }),expression:"{ regex: /^([0-9]{9}|[0-9]{12})$/, required: true }"}],class:{ input: true, 'is-danger': _vm.errors.has('numberCMND') },attrs:{"type":"number","data-vv-as":"Số CMND/CCCD","id":"numberCMND","name":"numberCMND","placeholder":"Nhập số CMND/CCCD"},on:{"input":_vm.changeUserName},model:{value:(_vm.numberCMND),callback:function ($$v) {_vm.numberCMND=$$v},expression:"numberCMND"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('numberCMND')),expression:"errors.has('numberCMND')"}],staticClass:"is-danger-text"},[_vm._v(_vm._s(_vm.errors.first('numberCMND') ? 'CMND/CCCD chỉ 9 hoặc 12 kí tự' : ''))])],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }