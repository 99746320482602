<template>
  <main
    class="layoutMain"
    :class="{
      bgTransparent: isBgTransparent
    }"
  >
    <slot />
  </main>
</template>
<script>
export default {
  props: ['isBgTransparent'],
  name: `EmptyLayout`
};
</script>
<style lang="scss">
// @import "../assets/sass/main";
.bgTransparent {
  background-color: transparent;
}
</style>
