import { Axios } from '../common/config';
import store from '../store/store';

export const GameAppService = {
  getMaintenanceStatus() {
    return Axios.get('/maintenance-status').then((resp) => {
      return resp;
    });
  },
  getRecentResults() {
    return Axios.get('/recent-results').then((resp) => {
      return resp;
    });
  },
  getPeriodsByGameId(gameId, params = {}) {
    return Axios.get(`/games/${gameId}/periods`, {
      params
    }).then((resp) => {
      return resp;
    });
  },
  getPrizeByGameId(gameId, partner) {
    return Axios.get(partner ? `/games/${gameId}?partner=${partner}` : `/games/${gameId}`).then((resp) => {
      return resp;
    });
  },
  getGameResultByPeriod(gameId, periodNo) {
    return Axios.get(`/games/${gameId}/results/${periodNo}`).then((response) => response);
  },
  getGameResult(gameId, data) {
    return Axios.get(`/games/${gameId}/results`, {
      params: data
    }).then((response) => response);
  },
  getTraditionalGameResult(gameId, data) {
    return Axios.get(`/traditional-ticket/${gameId}/results`, {
      params: data
    }).then((response) => response);
  },
  getListGame(partner) {
    return Axios.get(partner ? `/games?partner=${partner}` : `/games`)
      .then((resp) => {
        return resp;
      })
      .catch((e) => {
        if (!e.response || e.response.status == 500) {
          store.commit('setServerStatus', false);
        }
        return { data: { status: false, data: [] } };
      });
  },
  getListGameId() {
    return Axios.get('/game-names')
      .then((resp) => {
        return resp;
      })
      .catch((e) => {
        if (!e.response || e.response.status == 500) {
          store.commit('setServerStatus', false);
        }
        return { data: { status: false, data: [] } };
      });
  },
  getListStatisticsById(gameId, limit, statisticObject) {
    return Axios.get(`/games/${gameId}/statistics`, {
      params: { statisticObject: statisticObject, limit: limit }
    }).then((resp) => {
      return resp;
    });
  },
  getGamePeriods() {
    return Axios.get('/games/periods').then((resp) => {
      return resp;
    });
  },
  postGameTrial(data) {
    return Axios.post('/game-trial', data).then((resp) => {
      return resp;
    });
  },
  getTraditionalTicket(params = {}) {
    return Axios.get(`traditional-ticket/tickets`, {
      params
    }).then((resp) => {
      return resp;
    });
  },
  getBuyGroupList(params = {}) {
    return Axios.get(`/buy-group`, {
      params
    }).then((resp) => {
      return resp.data;
    });
  },
  getBuyGroupDetail(buyGroupId, params) {
    return Axios.get(`/buy-group/${buyGroupId}`, { params }).then((resp) => {
      return resp.data;
    });
  },
  getBuyListOfBuyGroupDetail(buyGroupId) {
    return Axios.get(`/buy-group/${buyGroupId}/buy-list`).then((resp) => {
      return resp.data;
    });
  },
  getNumberGen() {
    return Axios.get(`/gen-numbers`).then((resp) => {
      return resp;
    }).catch((e) => {
      if (!e.response || e.response.status == 500) {
        store.commit('setServerStatus', false);
      }
      return { data: { status: false, data: [] } };
    });
  },
  getTtbs(gameId) {
    return Axios.get(`/statistic-ttbs/${gameId}`).then((resp) => {
      return resp.data;
    });
  }
};

export default GameAppService;
