<template>
  <div class="container-maintance">
    <img draggable="false" src="../../assets/img/thantai-sad.png" />
    <p class="text-center mt-3">
      Nội dung hay địa chỉ web bạn yêu cầu không tồn tại.
    </p>
    <a href="/" class="btn btnConfirm text-uppercase font-weight-600 mt-3">
      Quay về trang chủ
    </a>
  </div>
</template>

<script>
export default {
  name: 'Page404'
};
</script>

<style lang="scss" scoped>
.container-maintance {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  background-color: #f7f7f7;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    color: #666666;
    font-size: 15px;
  }
  img {
    width: 196px;
    height: 196px;
  }
  @media (max-width: map-get($grid-breakpoints, 'lg')) {
    img {
      width: 136px;
      height: 136px;
    }
  }
}
.btnConfirm {
  display: inline-block;
  border-radius: 3px;
  background-color: $primaryColor;
  color: #fff;
  padding: 0.25rem 1.25rem;
  &:hover {
    background-color: $primaryColorDarkest;
    color: #ffffff;
  }
}
</style>
