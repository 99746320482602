<template>
  <b-modal
    headerClass="border-bottom-0"
    footerClass="border-top-0 pt-0"
    modal-class="modalRegister modalRegisterRep"
    no-close-on-backdrop
    no-close-on-esc
    :content-class="{ loadingModal: isLoading }"
    @shown="handleShownModalForgot"
    :dialog-class="{ modaDialogAppLogin: isApp }"
    @hide="onCloseModal"
    hide-footer
    v-model="modalForgot"
    centered
  >
    <template v-slot:modal-title>
      <div id="recaptchaWrapper">
        <div id="captcha-container" />
      </div>
      <p @click="onBack()" class="d-block pointer font-20 modalRegisterHeader headerIcon ">
        Quên mật khẩu
      </p>
    </template>
    <div v-if="!isSent" class="d-flex flex-column justify-content-between w-100 modalRegisterContent">
      <div class="w-100">
        <p class="text-center mb-lg-2">
          Nhập số điện thoại bạn sử dụng để đăng ký tài khoản.
        </p>
      </div>
      <div>
        <form @submit.prevent ref="form">
          <b-form-group>
            <label for="phoneNumberInput">Số điện thoại</label>
            <b-form-input
              @keyup.enter="requestCaptchaCommon"
              v-validate="{ min: 10, max: 10, regex: /(03|07|08|09|05)+([0-9]{8})\b/, required: true }"
              :class="{ input: true, 'is-danger': errors.has('phoneNumber') }"
              ref="inputRegister"
              name="phoneNumber"
              v-model="phoneNumber"
              id="phoneNumberInput"
              data-vv-as="Số điện thoại"
              type="number"
              pattern="[0-9]*"
            ></b-form-input>
            <span v-show="errors.has('phoneNumber')" class="is-danger-text position-relative">
              {{ errors.first('phoneNumber') }}
            </span>
          </b-form-group>
        </form>

        <button
          type="button"
          @click="requestCaptchaCommon"
          :disabled="isDisabledSend"
          class="btn w-100 text-uppercase btnConfirm position-relative"
          :class="{ 'opacity-7 cursor-none ': timer > 0 || isDisabledSend }"
        >
          <span v-if="timer > 0">Vui lòng đợi sau {{ timer }}</span>
          <span v-else>TIẾP TỤC</span>
        </button>
      </div>
    </div>
    <div v-else class="d-flex flex-column justify-content-between w-100 modalRegisterContent">
      <p class="text-center mb-lg-2">
        Vui lòng điền mã xác nhận đã được gửi đến số điện thoại
        <span class="font-weight-bold">{{ phoneNumber }}</span>
        để khởi tạo mật khẩu mới.
      </p>
      <form @submit.prevent ref="form2">
        <b-form-group>
          <div class="modalRegisterLabel">
            <label for="verificationInput">Mã xác nhận</label>
            <button
              :disabled="timer > 0"
              type="button"
              @click="requestCaptchaCommon"
              class="modalRegisterSendAgain pointer btn p-0"
            >
              <p v-if="timer == 0">Gửi lại mã</p>
              <p v-else for="verificationInput" class="modalRegisterLabelRight">(Gửi lại sau {{ timer }} giây)</p>
            </button>
          </div>
          <b-form-input
            ref="code"
            v-model="code"
            id="code"
            trim
            v-validate="'required|numeric|min:6|max:6'"
            :class="{
              input: true,
              'is-danger': errors.has('code')
            }"
            type="number"
            data-vv-as="Mã xác nhận"
            name="code"
          ></b-form-input>
          <span v-show="errors.has('code')" class="is-danger-text position-relative">{{ errors.first('code') }}</span>
        </b-form-group>
        <b-form-group class="mt-1">
          <div class="modalRegisterLabel">
            <label for="password" class="modalRegisterLabelPass">Mật khẩu mới</label>
          </div>
          <div class="position-relative">
            <b-form-input
              ref="password"
              v-model="password"
              data-vv-as="Mật khẩu"
              :class="{ input: true, 'is-danger': errors.has('password') }"
              v-validate="'required|min:8|max:16'"
              id="password"
              name="password"
              aria-describedby="password-help-block"
              :type="typePassword"
            ></b-form-input>
            <UIShowPassword
              @handlerShowPassword="handlerShowPassword($event, 'key_password')"
              :typePassword="typePassword"
            >
            </UIShowPassword>
          </div>
          <span v-show="errors.has('password')" class="is-danger-text position-relative">
            {{ errors.first('password') }}
          </span>
        </b-form-group>
        <b-form-group class="mt-1">
          <div class="modalRegisterLabel">
            <label for="verifyPassword" class="modalRegisterLabelPass">Nhập lại mật khẩu mới</label>
          </div>
          <div class="position-relative">
            <b-form-input
              ref="verifyPassword"
              v-validate="'required|confirmed:password'"
              v-model="verifyPassword"
              name="verifyPassword"
              :type="typePasswordRemember"
              data-vv-as="Mật khẩu nhập lại"
              :class="{ input: true, 'is-danger': errors.has('verifyPassword') }"
            ></b-form-input>
            <UIShowPassword
              @handlerShowPassword="handlerShowPassword($event, 'key_rememberPassword')"
              :typePassword="typePasswordRemember"
            >
            </UIShowPassword>
          </div>
          <span v-show="errors.has('verifyPassword')" class="is-danger-text position-relative">{{
            errors.first('verifyPassword') === 'Vui lòng nhập Mật khẩu nhập lại'
              ? 'Vui lòng nhập lại mật khẩu'
              : errors.first('verifyPassword')
          }}</span>
        </b-form-group>
        <div class="modalRegisterFooter">
          <div class="justify-content-center">
            <div class="w-100">
              <button
                @click="handlerChangePassword()"
                class="btn w-100 text-uppercase btnConfirm position-relative test"
                :disabled="isLoading || isDisabledReset"
                :class="{ 'opacity-7 cursor-none': isLoading || isDisabledReset }"
              >
                TẠO MẬT KHẨU MỚI
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import firebase from 'firebase/app';
import AccountService from '../../api/account';
// import 'firebase/auth'; // for authentication
// import 'firebase/storage'; // for storage
// import 'firebase/database'; // for realtime database
// import 'firebase/firestore'; // for cloud firestore
// import 'firebase/messaging'; // for cloud messaging
// import 'firebase/functions';
const Swal = require('sweetalert2');
import { Axios, AxiosNoty, AxiosCommonSerice } from '../../common/config';
import UIShowPassword from '../../components/UI/UIShowPassword';
// import Vue from 'vue';

// const firebaseConfig = {
//   apiKey: 'AIzaSyDSYe_lUCysUPhhGPZ9DpFAVizSFracSBE',
//   authDomain: 'vietlott-52ced.firebaseapp.com',
//   databaseURL: 'https://vietlott-52ced.firebaseio.com',
//   projectId: 'vietlott-52ced',
//   storageBucket: 'vietlott-52ced.appspot.com',
//   messagingSenderId: '1008696483090',
//   appId: '1:1008696483090:web:b6f9590b31daf2d2b367cb',
//   measurementId: 'G-QJBQBYWWVQ'
// };
// if (!firebase.apps.length) {
//   firebase.initializeApp(firebaseConfig);
// }
export default {
  components: {
    UIShowPassword
  },
  data() {
    let lastTimestamp = 0;
    try {
      lastTimestamp = parseInt(window.storageHelper.getItem('verify_code_timestamp_phone'));
    } catch (error) {
      console.error(error);
    }

    const currentTimestamp = new CustomDate().getTime();
    const sec = parseInt((currentTimestamp - lastTimestamp) / 1000);
    return {
      timer: sec > 60 ? 0 : 60 - sec,
      isSent: false,
      phoneNumber: '',
      verificationNumber: '',
      verifyInterval: null,
      passwordUser: '',
      tokenCapcha: '',
      isLoading: false,
      code: '',
      password: '',
      verifyPassword: '',
      hash: '',
      sessionId: '',
      typePassword: 'password',
      typePasswordRemember: 'password',
      timeWaiting: null
    };
  },
  computed: {
    isApp() {
      return this.$store.getters.getterIsApp;
    },
    isDisabledSend() {
      return this.timer > 0 || this.errors.has('phoneNumber') || !this.phoneNumber;
    },
    isDisabledReset() {
      return (
        !this.code ||
        this.errors.has('verificationNumber') ||
        !this.password ||
        this.errors.has('password') ||
        !this.verifyPassword ||
        this.errors.has('verifyPassword')
      );
    },
    modalForgot: {
      get() {
        return this.$store.getters.getterValForgotPassword;
      },
      set(newVal) {
        this.$store.commit('setModalForgotPassword', newVal);
      }
    },
    isPhoneNumber() {
      return this.phoneNumber;
    }
  },
  mounted() {
    // this.initRecaptcha();
    if (this.verifyInterval) {
      clearInterval(this.verifyInterval);
    }
    if (this.timer > 0) {
      this.verifyInterval = setInterval(() => {
        this.timer--;
        if (this.timer <= 0) {
          this.timer = 0;
          clearInterval(this.verifyInterval);
        }
      }, 1000);
    }
  },
  watch: {
    modalForgot: function(newVal) {
      this.handleToggleModal(newVal);
    }
  },
  methods: {
    handlerShowPassword(event, key) {
      switch (key) {
        case 'key_password':
          this.typePassword = event;
          break;
        default:
          this.typePasswordRemember = event;
          break;
      }
    },
    onBack() {
      this.password = '';
      this.verifyPassword = '';
      this.phoneNumber = '';
      if (this.isSent) {
        this.isSent = false;
        this.renewRecaptcha();
      } else {
        this.$store.commit('setModalLogin', true);
        this.onCloseModal();
      }
    },
    startCountDown() {
      const currentTimestamp = new CustomDate().getTime();

      window.storageHelper.setItem('verify_code_timestamp_phone', currentTimestamp);
      this.timer = 60;
      if (this.verifyInterval) {
        clearInterval(this.verifyInterval);
      }
      this.verifyInterval = setInterval(() => {
        this.timer--;
        if (this.timer <= 0) {
          this.timer = 0;
          clearInterval(this.verifyInterval);
        }
      }, 1000);
    },
    handlerLink() {},
    handleShownModalForgot() {
      // this.$refs.inputRegister.focus();
      this.initRecaptcha();
    },
    requestCaptchaCommon() {
      clearTimeout(this.timeWaiting);
      this.timeWaiting = setTimeout(() => {
        this.requestCaptcha();
      }, 1000);
    },
    async requestCaptcha() {
      this.isLoading = true;
      if (this.isDisabledSend) {
        this.isLoading = false;
        return;
      }

      // not check in resend case
      window.dataLayer.push({
        event: 'btn-reset-pw-click',
        phoneNumber: this.phoneNumber
      });
      if (!this.isSent) {
        const isExist = await AccountService.phoneValidate({ phone: this.phoneNumber }).catch((e) => {
          console.error(e);
        });
        if (!isExist) {
          this.errors.add({
            field: 'phoneNumber',
            msg: 'Số điện thoại không tồn tại'
          });
          this.isLoading = false;
          window.dataLayer.push({
            event: 'reset-pw-fail',
            phoneNumber: this.phoneNumber,
            messageError: 'Số điện thoại không tồn tại'
          });
          return;
        }
      }
      this.tokenCapcha = await window.recaptchaVerifier.verify();
      if (typeof this.tokenCapcha === 'object') {
        Swal.fire({
          icon: 'error',
          title: 'Xảy ra lỗi khi yêu cầu reCaptcha. Vui lòng thử lại',
          showConfirmButton: true,
          timer: 2000
        });
        this.onCloseModal();
        window.dataLayer.push({
          event: 'reset-pw-fail',
          phoneNumber: this.phoneNumber,
          messageError: 'Xảy ra lỗi khi yêu cầu reCaptcha. Vui lòng thử lại'
        });
        return;
      }
      if (this.tokenCapcha == '') {
        this.isLoading = false;
        return;
      }
      await this.handleSendSMS();
      this.isLoading = false;
    },
    async handleSendSMS() {
      if (this.timer > 0) {
        return;
      }
      var data = {
        phone: this.phoneNumber,
        token: this.tokenCapcha,
        hash: this.hash
      };

      AccountService.sendOTPResetPassword(data)
        .then((resp) => {
          if (resp) {
            const data = resp.data;
            if (data.status) {
              this.startCountDown();
              this.isSent = true;
              this.hash = data.data.hash;
              this.sessionId = data.data.sessionId;
            } else {
              this.errors.add({
                field: 'phoneNumber',
                msg: data.data.message
              });

              window.dataLayer.push({
                event: 'reset-pw-fail',
                phoneNumber: this.phoneNumber,
                messageError: data.data.message
              });
            }
          }
        })
        .catch(async (e) => {
          if (e.response) {
            if (e.response.data.data.error.message === 'TOO_MANY_ATTEMPTS_TRY_LATER') {
              e.response.data.data.error.message = 'Bạn đã yêu cầu quá nhiều lần, hãy thử lại sau';
            }
            this.errors.add({
              field: 'phoneNumber',
              msg: e.response.data.data.error.message
            });
            window.dataLayer.push({
              event: 'reset-pw-fail',
              phoneNumber: this.phoneNumber,
              messageError: e.response.data.data.error.message
            });

            await this.renewRecaptcha();
            return;
          }
          Swal.fire({
            icon: 'error',
            title: 'Có lỗi xảy ra hãy thử lại',
            showConfirmButton: true,
            timer: 2000
          });
          this.onCloseModal();
        });
    },
    async renewRecaptcha() {
      await window.recaptchaVerifier.clear();

      document.getElementById('recaptchaWrapper').innerHTML = `<div id="captcha-container" />`;
      this.initRecaptcha();
    },
    initRecaptcha() {
      // this.isLoading = true;

      window.recaptchaVerifier = null;
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('captcha-container', {
        size: 'invisible',
        'expired-callback': async () => {
          this.renewRecaptcha();
        },
        'error-callback': () => {
          Swal.fire({
            icon: 'error',
            title: 'Xảy ra lỗi khi yêu cầu reCaptcha. Vui lòng thử lại',
            showConfirmButton: true,
            timer: 2000
          });
          this.onCloseModal();
          // this.isLoading = false;
        }
      });

      window.recaptchaVerifier.render().then((widgetId) => {
        window.recaptchaWidgetId = widgetId;
        // this.isLoading = false;
      });
    },
    handlerChangePassword() {
      if (this.isLoading) {
        return;
      }
      if (!this.errors.has('code')) {
        var data = {
          phone: this.phoneNumber,
          new_password: this.password,
          verify_code: this.code,
          hash: this.hash,
          user_session_code: this.sessionId
        };
        this.isLoading = true;
        AccountService.verifyOTPResetPassword(data)
          .then((resp) => {
            let result = resp.data;
            if (result.status) {
              window.dataLayer.push({
                event: 'reset-pw-success',
                phoneNumber: result.data.phone
              });
              Axios.defaults.headers.common['Authorization'] = `Bearer ${result.data.token}`;
              AxiosNoty.defaults.headers.common['Authorization'] = `Bearer ${result.data.token}`;
              AxiosCommonSerice.defaults.headers.common['Authorization'] = `Bearer ${result.data.token}`;

              this.setAccessToken(result.data.token);

              this.$store.commit('setToken', result.data.token);
              this.$store.commit('setUserProfile', {
                id: result.data.id,
                name: result.data.name,
                email: result.data.email,
                phone: result.data.phone,
                hasPassword: true
              });

              this.$store.commit('setUserInfo', {
                id: result.data.id,
                name: result.data.name || '',
                email: result.data.email,
                phone: result.data.phone
              });
              window.storageHelper.setItem('_clear-login', false);
              Swal.fire({
                icon: 'success',
                title: 'Tạo mật khẩu mới thành công',
                showConfirmButton: false,
                timer: 2000
              });
              this.onCloseModal();
            } else {
              if (result.data.code) {
                this.errors.add({
                  field: 'code',
                  msg: result.data.message
                });
                window.dataLayer.push({
                  event: 'reset-pw-fail',
                  phoneNumber: this.phoneNumber,
                  messageError: result.data.message
                });
              } else {
                this.errors.add({
                  field: 'code',
                  msg: 'Có lỗi xãy ra hãy thử lại.'
                });
                window.dataLayer.push({
                  event: 'reset-pw-fail',
                  phoneNumber: this.phoneNumber,
                  messageError: 'Có lỗi xãy ra hãy thử lại.'
                });
              }
            }
          })
          .catch((e) => {
            let code = e.response.data.data.code;
            let message = e.response.data.data.message;
            if (code) {
              this.errors.add({
                field: 'code',
                msg: message
              });
              window.dataLayer.push({
                event: 'reset-pw-fail',
                phoneNumber: this.phoneNumber,
                messageError: message
              });
            } else {
              this.errors.add({
                field: 'code',
                msg: 'Có lỗi xãy ra hãy thử lại.'
              });
              window.dataLayer.push({
                event: 'reset-pw-fail',
                phoneNumber: this.phoneNumber,
                messageError: 'Có lỗi xãy ra hãy thử lại.'
              });
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    onCloseModal() {
      this.modalForgot = false;
      this.isSent = false;
      this.typePassword = 'password';
      this.phoneNumber = '';
      this.code = '';
      this.password = '';
      this.verifyPassword = '';
    }
  }
};
</script>

<style lang="scss" scoped>
.modalRegisterFooterTxt > a {
  color: #0d234a;
}
.modalRegisterContent {
  .modalRegisterLabel {
    .modalRegisterLabelRight {
      font-style: italic;
      font-size: 14px;
    }
    .modalRegisterLabelPass {
      margin-top: 16px;
    }
  }
  input::-webkit-input-placeholder {
    font-size: 15px;
    line-height: 3;
    color: #999999;
  }
  p {
    font-size: 15px;
  }
}
.modalRegisterFooter {
  p {
    font-size: 14px;
    a {
      text-decoration: underline;
    }
  }
}
</style>
