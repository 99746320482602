/* eslint-disable import/no-duplicates */
import storageHelper from 'storage-helper';

import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

import { isMobileOnly } from 'mobile-device-detect';

import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import global from './global.module';
import article from './article.module';
// import cart from './cart.module';
import partner from './partner.module';
import oauth2 from './/oauth2.module';
import countDown from './countDown/countDown.module';
import commonServices from './commonServices.module';
import ttbs from './ttbs.module';

let lastTimestamp = 0;
let isRegister = false;
let isModalTerm = false;
let objPrizeKeno = null;
let metadataKeno = null;
let userProfile = null;
let isShowConfirmPayment = false;
let listTimeDialOverviewForReal = [];
let listTicketOverviewForReal = [];
const currentTimestamp = new CustomDate().getTime();

try {
  lastTimestamp = parseInt(storageHelper.getItem('verify_code_timestamp_phone'));
  isRegister = JSON.parse(storageHelper.getItem('__is_register'));
  isModalTerm = JSON.parse(storageHelper.getItem('__modal_term'));
  objPrizeKeno = JSON.parse(storageHelper.getItem('obj_prize_keno'));
  metadataKeno = JSON.parse(storageHelper.getItem('meta_data_keno'));
  userProfile = JSON.parse(storageHelper.getItem('user_local'));
  isShowConfirmPayment = JSON.parse(storageHelper.getItem('is_show_confirm_payment'));

  listTimeDialOverviewForReal = storageHelper.getItem('list_timer_dial_for_real')
    ? JSON.parse(storageHelper.getItem('list_timer_dial_for_real'))
    : [];
  listTicketOverviewForReal = storageHelper.getItem('list_ticket_for_real')
    ? JSON.parse(storageHelper.getItem('list_ticket_for_real'))
    : [];
} catch (e) {
  console.error(e.message);
  //
}

const sec = parseInt((currentTimestamp - lastTimestamp) / 1000);

const store = new Vuex.Store({
  modules: {
    global,
    // cartModule: cart,
    oauth2,
    article,
    partner,
    CD: countDown,
    commonServices,
    ttbs
  },
  state: {
    inviteCodeDisplayData: {},
    popupNotifyMessageData: {},
    isOpenPopupNotifyMessage: false,
    isOpenPopupPaymentError: false,
    isLoadBalances: false,
    isMaintain: false,
    deferredPrompt: null,
    isRegister,
    isHomeScreen: Vue.$cookies.get('__timerHomeScreen') ? Vue.$cookies.get('__timerHomeScreen') : false,
    // isHomeScreen: false,
    isApp: false,
    modalDeposit: false,
    isOnlyMobile: isMobileOnly,
    isModalTerm,
    driverUniqueId: null,
    timezone: null,
    listProduct: [],
    isNotification: false,
    objPrizeKeno,
    metadataKeno,
    listProductGame: [],
    showModalRegister: false,
    showModalVerifyPhone: false,
    isTerraMode: false,
    showModalForgotPassword: false,
    isOpenModalPaymentSuccess: false,
    isOpenPopupCongrats: false,
    objNoCloseOnEscAndBackdrop: {
      isNoCloseOnEsc: false,
      isNoCloseOnBackdrop: false
    },
    urlPopupGrate: '/',
    isCheckLoginBuyGame: false,
    isCheckLoginAfterBuyGame: false,
    showModalLogin: false,
    showVerifyPhone: false,
    timerWaiting: sec > 60 ? 0 : 60 - sec,
    timerWaitingPhone: sec > 60 ? 0 : 60 - sec,
    token: Vue.$cookies.get('access_token') ? Vue.$cookies.get('access_token') : null,
    userProfile,
    rewardBalance: 0,
    depositBalance: 0,
    pendingBalance: 0,
    users: {
      phoneNumber: undefined,
      password: undefined,
      name: '',
      hash: '',
      sessionId: '',
      callbackSocialLinkModify: {}
    },
    isSentValidate: false,
    objBanner: {},
    isLoadingBanner: false,
    isLoadingProduct: false,
    listTicketActived: [],
    listTicketOverview: [],
    listTicketOverviewFeedKeno: [],
    listTimeDialOverview: [],
    listTimeDialOverviewForReal,
    listTicketOverviewForReal,
    listTimeDialPowerOverview: [],
    listTicketPowerOverview: [],
    keepDataActived: [],
    listNotifications: [],
    listPageNotification: [],
    userInfo: {
      phone: '',
      passport: '',
      name: '',
      date_of_birth: '',
      lastName: ''
    },
    locationHome: false,
    withdrawHistoryDetail: {},
    inputPasswordAgain: '',
    orderTab: 1,
    isCheckRouter: false,
    ListTicketFavoriteKeno: [],
    textExpire: 'Bạn cần đăng nhập để mua vé.',
    listAllFavorite: [],
    listAllFavoriteDropdown: [],
    isCreateFavorite: true,
    isSaveReceiver: false,
    lastName: '',
    isLoadingDropdown: false,
    totalPageDropdown: 0,
    isOpenModalTransactionResult: false,
    powerPeriod: {},
    megaPeriod: {},
    listGame: {},
    display_order_id: '',
    listGameHandler: {},
    listFavoriteProp: [],
    savePagination: null,
    gameStatus: {},
    savePaginationNotiPage: {
      currenPage: 1,
      pageSize: 10,
      nextItem: true
    },
    savePaginationFavorite: {
      currentPage: 0,
      pageSize: 10
    },
    historyResult: false,
    saveHistoryFlagLength: null,
    saveFilterListActived: null,
    saveFilterListActivedGiftTicket: null,
    redirectAfterLogin: '/',
    kenoCustomProductList: [],
    userSettings: {},
    playTrial: false,
    showOverlay: false,
    isOpenModalPlayTrial: false,
    tooltipCoordinates: {
      top: 0,
      left: 0,
      isActive: true,
      position: 'top'
    },
    currentTrialStep: '',
    loadByIframe: false,
    listPaymentMethod: [],
    isConnectedSocket: false,
    inviteCode: '',
    locationLoad: '',
    parentUserInfo: {
      birthday: '',
      email: '',
      name: '',
      passport: '',
      phone: '',
      user_id: '',
      invite_code: ''
    },
    modalQuickBuyInfo: {
      passportId: '',
      phoneNumber: ''
    },
    quickPaymentSuccessData: [],
    isOpenModalQuickPaymentSuccess: false,
    currentTransaction: '',
    customStyleData: {},
    paymentIntegration: false,
    allowPopupOverflowIframe: false,
    allowPopupRegister: true,
    fakeBackground: '',
    isExistModalOpen: 0,
    isExistModalOpenNormal: 0,
    payloadGameTrial: {},
    isOpenPopupWaitingPayment: false,
    transactionFinisedFromParent: false,
    newOrder: '',
    reloadFlagResultScreen: null,
    isOpenPopupCongrat: false,
    popupCongrateData: {},
    isWeluckMode: false,
    listPeriodFullCapacity: [],
    listIdols: [],
    newFeature: {
      inviteIdol: {
        title: 'Tặng vé số cho idol',
        content:
          'Tính năng mới giúp bạn có thể tặng vé số cho idol của mình. Khi tặng, vé của bạn sẽ được thể hiện trên màn hình stream của idol',
        image: 'https://assets.tailoc68.vn/hotro/tangveso-intro.jpg',
        alt: 'tangveso-intro'
      }
    },
    referralCode: {},
    dataRequestPayment: {},
    isOpenModalShare: false,
    waitingData: {},
    isOpenPopupGiftTicket: false,
    popupGiftTicketData: {},
    isOpenSendThank: false,
    popupSendThankData: {},

    isOpenPopupThank: false,
    popupThankData: {},
    saveSelectIdolData: {},
    isSetSelectIdolData: false,
    firebaseReady: false,
    buyTicketAgainData: [],
    isShowConfirmPayment,
    kenoThreeLastestResult: [],
    threeLatestResult: {},
    isLoadingGameResult: false,
    isShowPeriodHint: false,
    excludeListNumber: [],
    statisticKenoResult: {
      least: [],
      lose: [],
      most: [],
      win: []
    },
    maxGameLatestResult: [],
    isOpenFilterBuyTogether: false,
    filterBuyTogetherValue: 'finish_percent',
    powerGameType: 0,
    megaGameType: 0,
    isListenedReview: false
  },
  getters,
  mutations,
  actions
});

export default store;
