<template>
  <a @click.prevent="handlerShowPassword" class="showHidePasswrod pointer">
    <img v-if="typePassword === 'password'" src="../../assets/img/icon/icon_showpass_on.svg" />
    <img v-else src="../../assets/img/icon/icon_showpass_off.svg" />
  </a>
</template>
<script>
export default {
  props: {
    typePassword: {
      type: String,
      default: 'password'
    }
  },
  methods: {
    handlerShowPassword() {
      if (this.typePassword === 'password') {
        this.$emit('handlerShowPassword', 'text');
      } else {
        this.$emit('handlerShowPassword', 'password');
      }
    }
  }
};
</script>
