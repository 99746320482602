<template>
  <div
    :data-step="dataStep"
    class="myTooltip"
    :class="{
      active: currentTrialStep === dataStep,
      backgroundChild: isBackgroundChild
    }"
  >
    <slot />
    <span
      class="tooltipContent d-lg-none"
      :class="{
        [contentPosition]: true,
        [tooltipContentClass]: !!tooltipContentClass
      }"
    >
      <span v-html="content"></span>
      <div v-if="hasUnderstood">
        <button class="btn btn-text" @click="$emit('handleUnderstood')">{{ buttonText }}</button>
      </div>
      <span class="iconX" @click="handleClose">×</span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: 'top'
    },
    content: {
      type: String,
      default: ''
    },
    isBackgroundChild: {
      type: Boolean,
      default: false
    },
    hasUnderstood: {
      type: Boolean,
      default: false
    },
    tooltipContentClass: {
      type: String,
      default: ''
    },
    handleUnderstood: {
      type: Function
    },
    dataStep: String,
    buttonText: {
      type: String,
      default: 'Đã hiểu'
    }
  },
  computed: {
    contentPosition() {
      const listAcceptValue = ['top', 'left', 'right', 'bottom'];
      return listAcceptValue.includes(this.position) ? this.position : 'top';
    },
    currentTrialStep: {
      get: function() {
        return this.$store.getters.getterCurrentTrialStep;
      },
      set: function(newValue) {
        this.$store.commit('setCurrentTrialStep', newValue);
      }
    }
  },

  methods: {
    handleClose() {
      this.runPlayTrial();
    }
  }
};
</script>

<style lang="scss" scoped>
$arrowTooltip: 10px;
$arrowColor1: #ffefc3;

$arrowColor: #ffd764;

.myTooltip {
  position: relative;
  display: inline-block;
  width: 100%;

  .btn-text {
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    color: #00509d;
    text-transform: uppercase;
    padding: 5px 0 0;
  }

  .tooltipContent {
    visibility: hidden;
    display: inline-block;
    line-height: 1.4;
    // background-color: #555;

    box-shadow: 1px 1px 0 0 #d8a23d;
    background-image: linear-gradient(to bottom, $arrowColor1, $arrowColor);

    color: #333333;
    text-align: center;
    border-radius: 6px;
    padding: 12px 14px 8px;
    position: absolute;
    z-index: 1000;
    opacity: 0;
    font-size: 15px;

    transition: 0.3s all;
    width: 90%;

    &::before {
      content: '';
      position: absolute;
      border-width: #{$arrowTooltip};
      border-style: solid;
    }

    & .iconX {
      position: absolute;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 999px;
      background: white;
      width: 23px;
      height: 23px;
    }

    &.top {
      top: -#{$arrowTooltip};
      left: 50%;
      transform: translate(-50%, -100%);
      &::before {
        top: 100%;
        left: 50%;
        border-color: $arrowColor transparent transparent transparent;
        transform: translate(-50%, 0);
      }

      & .iconX {
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
      }
    }

    &.bottom {
      top: calc(100% + #{$arrowTooltip});
      left: 50%;
      transform: translate(-50%, 0);
      &::before {
        bottom: 100%;
        left: 50%;
        border-color: transparent transparent $arrowColor1 transparent;
        transform: translate(-50%, 0);
      }

      & .iconX {
        bottom: 0;
        right: 0;
        transform: translate(50%, 50%);
      }
    }

    &.left {
      left: -#{$arrowTooltip};
      top: 50%;
      transform: translate(-100%, -50%);

      &::before {
        top: 50%;
        left: 100%;
        border-color: transparent transparent transparent $arrowColor;
        transform: translate(0, -50%);
      }

      & .iconX {
        top: 0;
        left: 0;
        transform: translate(-50%, -50%);
      }
    }

    &.right {
      left: calc(100% + #{$arrowTooltip});
      top: 50%;
      transform: translate(0, -50%);
      &::before {
        top: 50%;
        right: 100%;
        border-color: transparent $arrowColor transparent transparent;
        transform: translate(0, -50%);
      }

      .iconX {
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
      }
    }
  }

  &.active {
    &.backgroundChild > :first-child {
      background-color: #ffffff;
    }
    & > .tooltipContent {
      visibility: visible;
      opacity: 1;
    }

    z-index: 1039;
  }
}
</style>
