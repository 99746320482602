// import GameAppService from "../api/game";
import { Axios, AxiosNoty, AxiosCommonSerice } from '../common/config';
import { resetRouter } from '../js/router';
// import { GameUrl } from '../api/mock/enum';

import Vue from 'vue';
// import NotificationService from '../api/notification';
import dayjs from 'dayjs';
// import { Noti } from '../main';
import { timerHomeScreen, expires } from '../api/mock/enum';
import AccountService from '../api/account';
import { SOCKET_EVENT } from '../common/global';
import { io } from '../main';

export default {
  getConfig(context, strPayLoad) {
    return new Promise((resolve, reject) => {
      Axios.get(`${strPayLoad}?time=${CustomDate.now()}`, {
        headers: { Authorization: '' }
      })
        .then((resp) => {
          // context.commit('setListAllFavorite', resp.data.data.tickets);
          return resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  handlerHomeScreen(context) {
    setTimeout(() => {
      context.state.deferredPrompt.prompt();
      context.state.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          window.dataLayer.push({
            event: 'add-to-home-screen'
          });
        } else {
          context.commit('setIsHomeScreen', true);
        }
      });
    }, timerHomeScreen * 1000);
  },
  handlerHomeScreenRegister(context, { Swal, promo }) {
    // setTimeout(() => {
    if (!context.state.deferredPrompt) {
      if (promo) {
        Swal.fire({
          icon: 'success',
          title: 'Bạn đã ký thành công và được cộng 20.000đ vào tài khoản',
          showConfirmButton: false,
          timer: 5000
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Đăng ký thành công',
          showConfirmButton: false,
          timer: 5000
        });
      }
      return;
    }
    context.state.deferredPrompt.prompt();
    context.state.deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        window.dataLayer.push({
          event: 'add-to-home-screen'
        });
      } else {
        context.commit('setIsHomeScreen', true);
      }
      if (promo) {
        Swal.fire({
          icon: 'success',
          title: 'Bạn đã ký thành công và được cộng 20.000đ vào tài khoản',
          showConfirmButton: false,
          timer: 5000
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Đăng ký thành công',
          showConfirmButton: false,
          timer: 5000
        });
      }
      return;
    });
    // }, timerHomeScreen * 1000);
  },
  getFavoriteNumberById(context, obj) {
    return new Promise((resolve, reject) => {
      Axios.get(`/accounts/${obj.accountId}/favorite-numbers/games/${obj.id}`, {
        params: {
          offset: obj.pagination.currentPage,
          limit: obj.pagination.pageSize,
          minLength: obj.minLength,
          isPagination: obj.isPagination
        }
      })
        .then((resp) => {
          // context.commit('setListAllFavorite', resp.data.data.tickets);
          return resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getObjBanner(context) {
    context.commit('setIsLoadingBanner', true);
    Axios.get('/ads')
      .then((resp) => {
        context.commit('setObjBanner', resp.data.data);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        context.commit('setIsLoadingBanner', false);
      });
  },
  getListProductPower(context, gameId) {
    const partner = context.state.partner.partnerData?.handler;
    return new Promise((resolve, reject) => {
      Axios.get(partner ? `/games/${gameId}?partner=${partner}` : `/games/${gameId}`)
        .then((resp) => {
          if (resp.data.status) {
            context.commit('setListProductGame', resp.data.data.products);
            resolve(resp.data.data);
          } else {
            resolve();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getPrizeKeno(context, gameId) {
    const partner = context.state.partner.partnerData?.handler;

    return new Promise((resolve, reject) => {
      Axios.get(partner ? `/games/${gameId}?partner=${partner}` : `/games/${gameId}`)
        .then((resp) => {
          context.commit('setMetadataKeno', JSON.parse(resp.data.data.metadata));
          window.storageHelper.setItem('meta_data_keno', resp.data.data.metadata);
          context.commit('setObjPrize', resp.data.data.products);
          window.storageHelper.setItem('obj_prize_keno', JSON.stringify(resp.data.data.products));
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  destroyToken(context, isCheck = false) {
    if (context.getters.getterLoggedIn) {
      io.emit(SOCKET_EVENT.UNSUBSCRIBED, {
        socketId: io.id,
        accountId: context.getters.getterAccountId,
        platform: context.isMobileApp ? 'app' : 'web',
        timezone: dayjs.tz.guess()
      });

      return new Promise((resolve) => {
        window.storageHelper.setItem('_clear-login', true);

        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(JSON.stringify({ event: 'LOGOUT', data: {} }), window.origin);
        }
        if (Vue.prototype.$messaging) {
          Vue.prototype.$messaging.deleteToken();
        }
        Axios.defaults.headers.common = { Authorization: '' };
        AxiosNoty.defaults.headers.common = { Authorization: '' };
        AxiosCommonSerice.defaults.headers.common = { Authorization: '' };
        context.commit('setUserProfile', {});
        context.commit('setUserInfo', {});
        // context.commit('setListTicketOverview', []);
        context.commit('setListTicketOverviewForReal', []);
        context.commit('setListTimeDialOverview', []);
        context.commit('setListTimeDialOverviewForReal', []);
        context.commit('setKeepDataActived', []);
        context.commit('setlistNotifications', []);
        context.commit('setListPageNotification', []);
        context.commit('setCart', []);
        context.commit('setRemoveIsCheckCodeError');
        context.commit('setModalQuickBuyInfo', {
          passportId: '',
          phoneNumber: ''
        });

        context.commit('setReferralCode', {});

        // clear play trial
        context.dispatch('exitPlayTrialMode');
        // window.storageHelper.setItem('is_play_trial', null);

        window.storageHelper.removeItem('fcm-token');
        if (context.getters.getterLoadByIframe) {
          Vue.$cookies.set('access_token', '', expires, null, null, true, 'None');
        } else {
          Vue.$cookies.remove('access_token');
        }

        // Vue.$cookies.set('access_token', '', expires, null, null, true, 'None');

        context.commit('setDestroyToken');
        if (!isCheck && !context.getters.getterLoadByIframe) {
          context.commit('removeInfoOauth2');
        }
        if (!Vue.$cookies.get('access_token') && !context.getters.getterLoggedIn) {
          resetRouter();
          resolve();
        }
        // resolve();
      });
    }
  },
  retrieveToken(context, credentials) {
    return new Promise((resolve, reject) => {
      Axios.post('/accounts/authentication', {
        phone_number: credentials.phone_number,
        password: credentials.password,
        remember_password: credentials.remember_password
      })
        .then((resp) => {
          let result = resp.data.data;
          if (resp.data.status) {
            context.dispatch('onAfterRetrieveToken', result);
            return resolve(true);
          } else {
            return resolve(false);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  onAfterRetrieveToken(context, result) {
    return new Promise((resolve) => {
      Axios.defaults.headers.common['Authorization'] = `Bearer ${result.token}`;
      AxiosNoty.defaults.headers.common['Authorization'] = `Bearer ${result.token}`;
      AxiosCommonSerice.defaults.headers.common['Authorization'] = `Bearer ${result.token}`;
      window.storageHelper.setItem('_clear-login', false);

      if (context.getters.getterLoadByIframe) {
        Vue.$cookies.set('access_token', 'Bearer ' + result.token, expires, null, null, true, 'None');
      } else {
        Vue.$cookies.set('access_token', 'Bearer ' + result.token, expires);
      }

      // Vue.$cookies.set('access_token', 'Bearer ' + result.token, expires, null, null, true, 'None');

      context.commit('setUserProfile', {
        id: result.id,
        name: result.name,
        email: result.email,
        phone: result.phone,
        hasPassword: result.hasPassword
      });
      context.commit('setToken', result.token);
      resolve(true);
    });
  },
  clearCart(context) {
    const { cart, listGame } = context.state;
    if (!cart || (cart && cart.length < 1) || !listGame) {
      return;
    }
    let listOldPeriod = {};
    let cartItemOld = [];

    const newCart = cart
      .map((cartItem) => {
        if (!listOldPeriod[cartItem.id]) {
          listOldPeriod[cartItem.id] = { ...cartItem, period_no: [] };
        }

        let { metadata } = listGame[cartItem.game_id] || { metadata: '{}' };
        metadata = JSON.parse(metadata);

        cartItem.period_no = cartItem.period_no.filter((periodItem) => {
          // const x = dayjs()
          //   .subtract(metadata.count_down, 'second')
          //   .isBefore(dayjs(periodItem.lottery_time));

          let x =
            new CustomDate(CustomDate.now() + metadata.count_down * 1000).getTime() -
              new CustomDate(periodItem.lottery_time).getTime() <
            0;

          if (!x) {
            cartItemOld.push(cartItem.id);
            listOldPeriod[cartItem.id].period_no.push(periodItem);
          }
          return x;
        });
        return cartItem;
      })
      .filter((i) => i.period_no.length > 0);
    context.commit('setCart', newCart);
    const listOldPeriodData = Object.values(listOldPeriod).filter((item) => item.period_no.length > 0);
    if (listOldPeriodData.length > 0) {
      let rs = listOldPeriodData.map((item) => ({
        gameName: listGame[item.game_id] ? listGame[item.game_id].name : '',
        ticket: item.product_name + ' - ' + item.details.length + ' bộ số',
        listPeriodNo: item.period_no
      }));
      context.commit('setListGameClearCart', rs);
      setTimeout(() => {
        context.commit('setModalClearCart', true);
      }, 400);
      // Noti.fire({
      //   icon: 'info',
      //   html: `<div class="notiCart">
      // <h5>Các vé đã hết hạn</h5>
      // ${listOldPeriodData
      //   .map(
      //     (item) =>
      //       `<p class="py-1"><span>${listGame[item.game_id] ? listGame[item.game_id].name : ''} - ${
      //         item.product_name
      //       } </span><br/>Kỳ: <strong>${item.period_no
      //         .map((i) => `#${i.period_no + i.lottery_time}`)
      //         .join(',')}</strong></p>`
      //   )
      //   .join('')}</div>`
      // });
    }
  },
  updateTrackingId(context) {
    const userProfile = context.state.userProfile;
    if (userProfile) AccountService.updateTrackingId(userProfile.id);
  },
  exitPlayTrialMode(context) {
    context.commit('setCurrentTrialStep', null);
    context.commit('setPlayTrial', null);
  },
  genTtbsNumber(context, data) {
    return new Promise((resolve, reject) => {
      Axios.post(`/gen-numbers`, data)
        .then((resp) => {
          if (resp.data.status) {
            resolve(resp.data.data);
          } else {
            reject();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};
