export default {
  updateCurrentTimestamp(state) {
    state.currentTimestamp = CustomDate.now();
  },
  updateTimer(state, { name, value }) {
    if (state[name] !== undefined) {
      state[name] = value;
    }
  },
  setAllGameData(state, value) {
    if (typeof value === 'object') {
      Object.keys(value).forEach((gameName) => {
        state[gameName] = value[gameName];
      });
    }
  },
  setGamePeriod(state, { gameName, value }) {
    if (state[gameName] && state[gameName].periodList) {
      state[gameName].periodList = value || [];
    }
  },
  setCartLotteryTime(state, value) {
    state.cartLotteryTime = value;
  },
  setCartTimer(state, value) {
    state.cartTimer = value;
  }
};
