function isAvailablePeriod(period, metadata = { count_down: 0 }) {
  const timestamp = new CustomDate(period.lottery_time).getTime();
  let now = CustomDate.now();
  return now <= timestamp - (metadata.count_down || 0) * 1000;
}

function filterPeriod(context, gameName) {
  if (!context.state[gameName]) {
    return;
  }

  const { periodList, metadata } = context.state[gameName];

  if (periodList.length === 0) {
    return;
  } else {
    const isAvailable = isAvailablePeriod(periodList[0], metadata);

    if (isAvailable) {
      return;
    }
  }

  const array = periodList.filter((period) => {
    return isAvailablePeriod(period, metadata);
  });

  context.commit('setGamePeriod', { gameName, value: array });
}

function calculateTimer(context, gameName) {
  if (!context.state[gameName]) {
    return 0;
  }
  const { periodList, metadata } = context.state[gameName];
  const dateStr = new CustomDate(context.state.currentTimestamp).toISOString();
  let item = periodList[0] || { lottery_time: dateStr };
  let lottery_time = item.lottery_time;
  return (
    (new CustomDate(lottery_time).getTime() - context.state.currentTimestamp) / 1000 -
    (metadata ? metadata.count_down || 0 : 0)
  );
}

const showCountdownTime = 600; // 10min

export default {
  filterMegaPeriod(context) {
    filterPeriod(context, 'mega');
    context.commit('updateTimer', { value: calculateTimer(context, 'mega'), name: 'megaHomeTimer' });
  },
  filterPowerPeriod(context) {
    filterPeriod(context, 'power');
    context.commit('updateTimer', { value: calculateTimer(context, 'power'), name: 'powerHomeTimer' });
  },
  filterMax3DPeriod(context) {
    filterPeriod(context, 'max3d');
    context.commit('updateTimer', { value: calculateTimer(context, 'max3d'), name: 'max3DHomeTimer' });
  },
  filterMax3DProPeriod(context) {
    filterPeriod(context, 'max3dpro');
    context.commit('updateTimer', { value: calculateTimer(context, 'max3dpro'), name: 'max3DProHomeTimer' });
  },
  filterMax4DPeriod(context) {
    filterPeriod(context, 'max4d');
    context.commit('updateTimer', { value: calculateTimer(context, 'max4d'), name: 'max4DHomeTimer' });
  },
  filterXsmnPeriod(context) {
    filterPeriod(context, 'xsmn');
    context.commit('updateTimer', { value: calculateTimer(context, 'xsmn'), name: 'xsmnHomeTimer' });
  },
  getMinimumTimeInCart(context) {
    const cart = context.rootState.cart;
    if (cart.length === 0) {
      return;
    }

    let minLotteryTime;
    cart.forEach((item) => {
      if (!minLotteryTime) {
        minLotteryTime = item.period_no[0].lottery_time;
      } else if (new CustomDate(minLotteryTime).getTime() > new CustomDate(item.period_no[0])) {
        minLotteryTime = item.period_no[0].lottery_time;
      }
    });

    context.commit('setCartLotteryTime', minLotteryTime);
  },
  checkCartTimer(context) {
    if (context.state.cartLotteryTime) {
      const timer = (new CustomDate(context.state.cartLotteryTime).getTime() - context.state.currentTimestamp) / 1000;
      if (timer > 0 && timer < showCountdownTime) {
        context.commit('setCartTimer', timer);
      } else if (timer <= 0) {
        context.dispatch('clearCart', null, { root: true });
        context.commit('setCartTimer', 0);
        context.dispatch('getMinimumTimeInCart');
      }
    }
  }
};
