import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
import SubKenoResultApp from '../layouts/SubKenoResultApp';
import { NOT_FOUND_URL } from '../common/global';

export let _savedPosition = {};
const routes = [
  {
    path: '/',
    name: 'Trang chủ',
    meta: { name: 'Trang chủ', requiresAuth: false, isPlayTrialMode: true },
    component: () => import('../views/Home')
  },
  {
    path: '/download',
    name: 'Tải ứng dụng',
    meta: { name: 'Tải ứng dụng', requiresAuth: false },
    component: () => import('../views/DownloadApp')
  },
  {
    path: '/mua-ho-concept',
    redirect: '/mua-ho-concept',
    component: () => import('../layouts/SubIntroApp'),
    children: [
      {
        path: '',
        name: 'intro',
        component: () => import('../views/Intro/index')
      },
      {
        path: 'tai-khoan-intro',
        meta: { requiresAuth: true, icon: 'cart' },
        name: 'intro-profile',
        component: () => import('../views/Intro/ProfileIndex')
      }
    ]
  },
  {
    path: '/tai-khoan',
    name: 'Tài khoản',
    meta: { requiresAuth: true },
    component: () => import('../views/Profile'),
    children: [
      {
        path: '/tai-khoan/nap-tien/',
        name: 'Nạp tiền tài khoản mua vé',
        meta: {
          redirectBack: '/tai-khoan/',
          icon: 'history',
          requiresAuth: true,
          name: 'Nạp tiền tài khoản mua vé',
          uniqueName: 'deposit'
        },
        component: () => import('../components/Profile/Deposit.vue')
      },
      {
        path: '/tai-khoan/rut-tien/',
        name: 'Rút tiền',
        meta: {
          redirectBack: '/tai-khoan/',
          icon: 'historyWithdraw',
          requiresAuth: true,
          name: 'Rút tiền'
        },
        component: () => import('../components/Profile/Withdraw.vue')
      },
      {
        path: '/tai-khoan/chi-tiet/',
        name: 'Chi tiết tài khoản',
        meta: {
          redirectBack: '/',
          requiresAuth: true,
          root: 'profile',
          name: 'Chi tiết tài khoản'
        },
        component: () => import('../components/Profile/Profile.vue')
      },
      {
        path: '/tai-khoan/lich-su-dat-mua/',
        name: 've-da-mua',
        meta: {
          redirectBack: '/tai-khoan/',
          requiresAuth: true,
          root: 'order',
          name: 'vé đã mua',
          icon: 'search',
          placeholder: 'Tìm mã vé',
          searchType: 'TICKET',
          gift_type: 'OWN'
        },
        component: () => import('../components/Profile/TransactionHistory.vue')
      },
      {
        path: '/tai-khoan/gioi-thieu-ban/',
        name: 'Giới thiệu bạn bè',
        meta: {
          redirectBack: '/tai-khoan/',
          requiresAuth: true,
          root: 'invite_friend',
          name: 'Giới thiệu bạn'
        },
        component: () => import('../components/Profile/InviteFriend/InviteFriend.vue')
      },
      {
        path: '/tai-khoan/danh-sach-ban-gioi-thieu/',
        name: 'Giới thiệu bạn',
        meta: {
          redirectBack: '/tai-khoan/gioi-thieu-ban/',
          requiresAuth: true,
          root: 'invite_friend_list',
          name: 'Giới thiệu bạn'
        },
        component: () => import('../components/Profile/InviteFriend/InviteFriendList.vue')
      },
      {
        path: '/tai-khoan/lich-su-nap-tien/',
        name: 'Lịch sử nạp tiền',
        meta: {
          redirectBack: '/tai-khoan/nap-tien/',
          icon: 'none',
          requiresAuth: true,
          name: 'Lịch sử nạp tiền',
          root: 'history'
        },
        component: () => import('../components/Profile/DepositHistory.vue')
      },
      {
        path: '/tai-khoan/lich-su-nap-tien/:id',
        name: 'chi-tiet-lich-su-nap-tien',
        meta: {
          redirectBack: '/tai-khoan/lich-su-nap-tien/',
          icon: 'history',
          requiresAuth: true,
          name: 'Chi tiết nạp tiền'
        },
        component: () => import('../components/Profile/DepositHistoryDetail.vue')
      },
      {
        path: '/tai-khoan/lich-su-dat-mua/:ticketId',
        name: 'don-mua',
        meta: {
          redirectBack: '/tai-khoan/lich-su-dat-mua/',
          redirectTitle: 'Vé đã mua',
          icon: 'info',
          requiresAuth: true,
          root: 'order',
          name: 'Đơn mua'
        },
        component: () => import('../components/Profile/TransactionDetailWrapper.vue')
      },
      {
        path: '/tai-khoan/thong-bao',
        name: 'Thông báo',
        meta: { requiresAuth: true, root: 'notification', name: 'Thông báo' },
        component: () => import('../components/Profile/Notification.vue')
      },
      {
        path: '/tai-khoan/lich-su-giao-dich/',
        name: 'Lịch sử giao dịch',
        meta: {
          redirectBack: '/tai-khoan/rut-tien/',
          icon: 'none',
          requiresAuth: true,
          name: 'Lịch sử giao dịch',
          root: 'history'
        },
        component: () => import('../components/Profile/Widthdraw/WithdrawHistory.vue')
      },
      {
        path: '/tai-khoan/tim-ve',
        name: 'Tìm vé',
        meta: { requiresAuth: true, root: 'order', name: 'Tìm vé' },
        component: () => import('../components/Profile/SearchTicket.vue')
      },
      {
        path: '/tai-khoan/bao-cao',
        name: 'Báo cáo',
        meta: { requiresAuth: true, root: 'shop', name: 'Báo cáo' },
        component: () => import('../components/Profile/Shop/ShopSummary.vue')
      }
    ]
  },
  {
    path: '/truc-tiep',
    name: 'truc-tiep',
    component: () => import('../views/LiveResult.vue')
  },
  {
    path: '/xo-so',
    meta: { redirectBack: '/', isRedirect: false },
    component: () => import('../layouts/SubLotteryApp.vue'),
    children: [
      {
        path: ':slug',
        meta: { icon: 'cart', isPlayTrialMode: true },
        component: () => import('../views/Game/index.vue')
      }
    ]
  },
  {
    path: '/thong-tin',
    meta: { redirectBack: '/', isRedirect: false },
    component: () => import('../layouts/SubArticleApp'),
    children: [
      {
        path: ':slug',
        meta: { icon: 'cart', isGoToTop: true },
        component: () => import('../components/Article/index')
      }
    ]
  },
  // {
  //   path: '/thong-tin',
  //   meta: { redirectBack: '/', isRedirect: false },
  //   component: () => import('../layouts/SubArticleApp'),
  //   children: [
  //     {
  //       meta: { icon: 'cart', isGoToTop: true },
  //       component: () => import('../components/Article/index')
  //     }
  //   ]
  // },
  {
    path: '/thong-tin/huong-dan-download',
    name: 'huong-dan-download',
    component: () => import('../components/Article/index')
  },
  {
    path: '/lien-he',
    meta: { redirectBack: '/', isRedirect: false, name: 'Liên hệ' },
    component: () => import('../layouts/SubArticleApp'),
    children: [
      {
        path: '/',
        meta: { icon: 'cart', name: 'Liên hệ' },
        component: () => import('../views/Contact/index.vue')
      }
    ]
  },
  {
    path: '/ho-tro',
    meta: { redirectBack: '/', isRedirect: false },
    component: () => import('../layouts/SubArticleListApp'),
    children: [
      {
        path: '/',
        meta: { icon: 'cart', isGoToTop: true },
        component: () => import('../views/Article/Article')
      },
      {
        path: '/cau-hoi-thuong-gap',
        meta: { icon: 'cart', isGoToTop: true },
        props: () => ({ query: 'cau-hoi-thuong-gap' }),
        component: () => import('../views/Article/List/ArticleList')
      },
      {
        path: ':slug',
        meta: { icon: 'cart', isGoToTop: true },
        component: () => import('../views/Article/List/ArticleList')
      }
    ]
  },
  {
    path: '/luat-choi',
    redirect: '/khong-tim-thay/404',
    meta: { redirectBack: '/', isRedirect: false },
    component: () => import('../layouts/SubArticleListApp'),
    children: [
      {
        path: ':slug',
        meta: { icon: 'cart', isTab: true, isGoToTop: true },
        component: () => import('../views/Article/List/ArticleList')
      }
    ]
  },
  {
    path: '/nap-rut-tien',
    redirect: '/khong-tim-thay/404',
    meta: { redirectBack: '/', isRedirect: false, isGoToTop: true },
    component: () => import('../layouts/SubArticleListApp'),
    children: [
      {
        path: ':slug',
        meta: { icon: 'cart' },
        component: () => import('../views/Article/List/ArticleList')
      }
    ]
  },
  {
    path: '/deep-link',
    name: 'Trang chuyển hướng',
    meta: { name: 'Trang chuyển hướng' },
    component: () => import('../views/DeepLink.vue')
  },
  {
    path: '/payment',
    name: 'payment-redirect',
    meta: { name: 'Trang chuyển hướng thanh toán' },
    component: () => import('../views/PaymentRedirect.vue')
  },
  {
    path: '/game-session',
    name: 'Game',
    meta: { name: 'Game', requiresAuth: false },
    component: () => import('../views/GameSession')
  },
  {
    path: NOT_FOUND_URL,
    name: 'notFound',
    component: () => import('@/views/404/404'),
    hidden: true
  },
  {
    path: '/ket-qua',
    name: 'ket-qua',
    component: SubKenoResultApp,
    meta: { requiresAuth: false },
    children: [
      {
        name: 'ket-qua-game',
        path: ':slug',
        component: () => import('../views/Result/index')
      }
    ]
  },
  {
    path: '/:kol_code',
    meta: { name: 'Trang chủ', requiresAuth: false, isPlayTrialMode: true },
    component: () => import('../views/Home')
  },
  { path: '*', redirect: NOT_FOUND_URL, hidden: true }
];
const createRouter = () =>
  new VueRouter({
    routes: routes,
    mode: 'history',
    scrollBehavior: (to, from, savedPosition) => {
      let position = { y: 0 };
      // Keep scroll position when using browser buttons
      if (savedPosition) {
        position = savedPosition;
      }

      if (to.fullPath === '/tai-khoan/thong-bao') {
        _savedPosition[to.fullPath] = savedPosition;
      }
      if ((to.query && to.query.notify_id) || (from.query && from.query.notify_id)) {
        return savedPosition;
      } else {
        return position;
      }
    }
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

router.onError((error) => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload();
  }
});

Vue.router = router;

export default router;
