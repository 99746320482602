<template>
  <b-modal
    headerClass="border-bottom-0"
    modal-class="modalRegister modalRegisterRep"
    :content-class="{ loadingModal: isLoading }"
    :dialog-class="{ modaDialogAppLogin: isApp }"
    no-close-on-backdrop
    no-close-on-esc
    @shown="handleShownModalLogin"
    @hide="onCloseModal"
    v-model="modalShowAssociate"
    centered
  >
    <template v-slot:modal-title>
      <p class="d-block pointer font-20 modalRegisterHeader">Liên kết tài khoản</p>
    </template>

    <div class="contentAssociate">
      <div class="d-flex justify-content-center align-items-center mb-4">
        <div class="contentAssociate__iconSaveextra">
          <img src="../../assets/img/icon_saveextra.png" />
        </div>
        <img class="mr-10" src="../../assets/img/icon/icon_link.svg" width="15" height="16" />
        <img src="../../assets/img/icon_ball68.png" width="48" height="48" />
      </div>
      <p>
        Hãy liên kết tài khoản <b>Save Extra</b> với tài khoản <b>Tài Lộc</b> để đăng nhập dễ dàng và nhận nhiều thông
        tin khuyến mãi hơn?
      </p>
    </div>
    <template v-slot:modal-footer>
      <div class="d-flex align-items-center w-100 m-0">
        <button @click="onCancel" type="submit" class="btn text-uppercase btnRemove mr-10 font-15">
          Để sau
        </button>
        <button
          @click="onSubmit"
          :disabled="isLoading"
          id="captcha-container"
          type="submit"
          class="btn w-100 text-uppercase btnConfirm position-relative font-15"
        >
          <span>Liên kết ngay</span>
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      timerWaiting: null,
      isLoading: false
    };
  },
  computed: {
    numberAssociate: {
      get() {
        return this.$store.getters.getterNumberAssociate;
      },
      set(newVal) {
        this.$store.commit('setNumberAssociate', newVal);
      }
    },
    objOauth2: {
      get() {
        return this.$store.getters.getterObjOauth2;
      },
      set(newVal) {
        this.$store.commit('setObjOauth2', newVal);
      }
    },
    modalShowAssociate: {
      get() {
        return this.$store.getters.getterModalAssociate;
      },
      set(newVal) {
        this.$store.commit('setModalAssociate', newVal);
      }
    },
    isApp() {
      return this.$store.getters.getterIsApp;
    },
    textExpire: {
      get() {
        return this.$store.getters.getterTextExpire;
      },
      set(newVal) {
        this.$store.commit('setRouterExpire', newVal);
      }
    },
    infoOauth2() {
      return this.$store.getters.getterInfoOauth2;
    },
    tokenInfoOauth2() {
      return this.$store.getters.getterTokenInfoOauth2;
    },
    redirectAfterLogin() {
      return this.$store.getters.getterRedirectAfterLogin;
    },
    accountId() {
      return this.$store.getters.getterAccountId;
    },
    isErrorPopupOauth2: {
      get() {
        return this.$store.getters.getterIsErrorPopupOauth2;
      },
      set(newVal) {
        this.$store.commit('setIsErrorPopupOauth2', newVal);
      }
    }
  },
  mounted() {},
  methods: {
    onCloseModal() {
      if (this.modalShowAssociate) {
        this.onNumberAssociate();
      }
      this.$store.commit('setRemoveIsCheckCodeError');
    },
    onCancel() {
      this.modalShowAssociate = false;
      this.onNumberAssociate();
    },
    onNumberAssociate() {
      this.numberAssociate = 1;
    },
    handleShownModalLogin() {},
    onSubmit() {
      if (!this.infoOauth2 || !this.tokenInfoOauth2) return;

      if (!this.accountId) {
        this.modalShowAssociate = false;
        this.isErrorPopupOauth2 = true;
        return;
      }

      let { _aff_sid, _aff_network } = this.infoOauth2;

      let isAffInfoLocalStore = null;
      try {
        isAffInfoLocalStore = window.storageHelper.getItem('_aff_info_local_store');
      } catch (error) {
        console.error(error);
      }

      let infoLocalStore = isAffInfoLocalStore ? isAffInfoLocalStore : { _aff_sid: null, _aff_network: null };

      const token = this.tokenInfoOauth2;

      if (this.timerWaiting) {
        clearTimeout(this.timerWaiting);
      }

      this.isLoading = true;

      this.timerWaiting = setTimeout(async () => {
        this.onAccountLinkProfile({
          _aff_sid: _aff_sid || infoLocalStore._aff_sid,
          token,
          _aff_network: _aff_network || infoLocalStore._aff_network,
          account_id: this.accountId
        })
          .then(() => {
            this.$router.push('/tai-khoan').catch(() => {});
            this.$store.commit('setIsAcceptAssociate', false);
          })
          .catch(() => {})
          .finally(() => {
            this.onCancel();
            this.isLoading = false;
          });
      }, 1000);
    }
  },
  watch: {
    modalShowAssociate: function(newVal) {
      this.handleToggleModal(newVal);
    }
  }
};
</script>

<style lang="scss" scoped>
.contentAssociate {
  &__iconSaveextra {
    width: 48px;
    height: 48px;
    margin: 0 10px 0 0;
    padding: 2px;
    border: solid 1px #e3e3e3;
    background-color: #ffffff;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.btnRemove {
  background-color: #efefef;
  color: #666666;
  font-weight: 600;
  padding: 0.45rem 1rem;
  transition: 0.3s all;
  height: 40px;
  flex-basis: 60%;
  &:hover {
    background-color: #d9d9d9;
  }
}

.btnConfirm {
  height: 40px;
}
</style>
