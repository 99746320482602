import { AxiosCommonSerice } from '../common/config';
import Vue from 'vue';

const state = {
  vnShopURL: {},
  loading: false
};

const getters = {
  getVNShopUrl(state) {
    return (accountId) => {
      return state.vnShopURL[accountId];
    };
  }
};

const mutations = {
  FETCH_VNSHOP_URL(state, { url, accountId }) {
    Vue.set(state.vnShopURL, accountId, url);
  },
  START_LOADING(state) {
    state.loading = true;
  },
  STOP_LOADING(state) {
    state.loading = false;
  }
};

const actions = {
  fetchVnShopURL({ state, commit }, accountId) {
    return new Promise((resolve, reject) => {
      if (state.vnShopURL[accountId]) {
        resolve(state.vnShopURL[accountId]);
      } else {
        commit('START_LOADING');
        AxiosCommonSerice.get(`/api/v1/partners/webapp/vnshop/?user_id=${accountId}`)
          .then((res) => {
            const { url } = res.data.data;
            commit('FETCH_VNSHOP_URL', { url, accountId });
            return resolve(url);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            commit('STOP_LOADING');
          });
      }
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
