class CustomDate extends Date {
  static offset = 0;
  static now() {
    return Date.now() + CustomDate.offset;
  }
  constructor(value) {
    if (value) {
      super(value);
    } else {
      super(Date.now() + CustomDate.offset);
    }
  }
}

window.CustomDate = CustomDate;
