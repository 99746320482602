<template>
  <div class=" modalRegisterFooter">
    <div class="justify-content-center">
      <div class="w-100"></div>
    </div>
    <div>
      <p class="modalRegisterFooterTxt">
        Bạn đã có tài khoản?
        <a @click="openLogin">Đăng nhập</a>
      </p>
    </div>
    <div>
      <p class="modalRegisterFooterTxt">
        Bằng cách tiếp tục, bạn sẽ đồng ý rằng đã đọc và hiểu các
        <router-link target="_blank" to="/thong-tin/dieu-khoan-su-dung">Điều khoản sử dụng</router-link>
        và
        <router-link target="_blank" to="/thong-tin/chinh-sach-quyen-rieng-tu">Chính sách quyền riêng tư</router-link>.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    // isApp() {
    //   return this.$store.getters.getterIsApp;
    // }
  },
  methods: {
    openLogin() {
      this.$store.commit('setModalLogin', true);
      this.$emit('onCloseModal');
    }
  }
};
</script>
<style lang="scss" scoped>
.modalRegisterFooterTxt > a {
  color: #0d234a;
  cursor: pointer;
}
.modalRegisterFooter {
  p {
    font-size: 14px;
    a {
      text-decoration: underline !important;
    }
  }
}
</style>
