<template>
  <div class="mb-lg-40">
    <content-placeholders v-if="$store.getters.getterIsLoadingBanner">
      <content-placeholders-img :class="classLoading" />
    </content-placeholders>
    <div v-else>
      <b-carousel :id="carouselId" class="carouselUI" :indicators="objBanner.pos_01.length > 1 ? true : false">
        <a
          v-for="(item, index) in objBanner.pos_01"
          :key="index"
          :title="item.display_text"
          :target="item.target_type"
          class="select-none"
          :class="{ pointer: item.target_url != '' && item.target_url != '#' }"
          @click.prevent="handleClickBanner(item)"
        >
          <b-carousel-slide>
            <template v-slot:img>
              <div class="img-wrapper" :class="classImage">
                <img v-if="isHome" class="img-small" :src="item.image_url" :alt="item.display_text" draggable="false" />
                <img v-else class="img-small" src="@/assets/img/home-testimonial-bg.jpg" :alt="item.display_text" draggable="false" />
                <div v-if="isShowCaption" class="bannerCaption bannerCaptionGame">
                  <p>
                    <span class="text-uppercase">{{ item.display_text }}&nbsp;</span><span>{{ reward }}</span>
                  </p>
                </div>
              </div>
            </template>
          </b-carousel-slide>
        </a>
      </b-carousel>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import { BASE_URL } from '../../../common/global';

export default {
  props: {
    keyData: {
      type: String,
      required: true
    },
    classImage: {
      type: String,
      default: ''
    },
    classLoading: {
      type: String,
      default: ''
    },
    isShowCaption: {
      type: Boolean,
      default: false
    },
    reward: {
      type: String
    },
    srcImg: String,
    carouselId: {
      type: String,
      default: 'carousel-1'
    },
    isHome: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    objBanner() {
      if (this.srcImg)
        return {
          pos_01: [
            {
              id: 1,
              display_text: '',
              image_url: this.srcImg,
              target_url: '',
              target_type: '_self'
            }
          ]
        };

      let data = this.$store.getters.getterBanner;
      let obj = Object.keys(data).filter((p) => p === this.keyData)[0];
      let rs = !_.isEmpty(data[obj])
        ? data[obj]
        : {
            pos_01: [
              {
                id: 1,
                display_text: '',
                image_url: 'https://d604u3cjqmj8m.cloudfront.net/resources/images/no-photo-banners.png',
                target_url: '',
                target_type: '_self'
              }
            ]
          };
      return rs;
    }
  },
  methods: {
    handleClickBanner(item) {
      let url = item.target_url;
      if (url.includes('/deep-link')) {
        this.openDeepLink(`${BASE_URL}${url}`, '_blank');
      } else {
        if (url.startsWith('/')) {
          if (item.target_type === '_self') {
            this.$router.push(url);
            return;
          } else {
            url = window.location.origin + url;
          }
        }
        this.openDeepLink(url, item.target_type);
      }

      //$router.push(item.target_url ? item.target_url : '#'
    }
  }
};
</script>
