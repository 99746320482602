<template>
  <div v-if="!checkHideFeature">
    <slot />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'ExcludeFeatureWrapper',
  props: {
    featureName: {
      type: String,
      require: true
    }
  },
  data() {
    return {};
  },

  computed: {
    ...mapGetters('partner', ['getterExcludeFeature']),
    ...mapGetters(['getterGlobalExcludeFeature']),
    checkHideFeature() {
      return (
        this.getterExcludeFeature?.includes(this.featureName) ||
        this.getterGlobalExcludeFeature?.includes(this.featureName)
      );
    }
  }
};
</script>

<style></style>
