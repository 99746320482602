import { render, staticRenderFns } from "./NavbarIconNotify.vue?vue&type=template&id=d74788fe&scoped=true&"
import script from "./NavbarIconNotify.vue?vue&type=script&lang=js&"
export * from "./NavbarIconNotify.vue?vue&type=script&lang=js&"
import style0 from "./NavbarIconNotify.vue?vue&type=style&index=0&id=d74788fe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d74788fe",
  null
  
)

export default component.exports