<template>
  <div class="headerTrial">
    <span>Bạn đang ở chế độ chơi thử</span>
    <span class="btnExit" @click="setModalConfirmPlayTrial(true)">
      Thoát
    </span>
  </div>
</template>

<script>
export default {
  methods: {
    setModalConfirmPlayTrial(newVal) {
      this.$store.commit('setIsOpenModalPlayTrial', newVal);
    }
  }
};
</script>

<style lang="scss" scoped>
.headerTrial {
  background-color: $primaryColor;
  font-size: 0.875rem;
  color: #ffffff;
  display: flex;
  align-items: center;

  z-index: 1100;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  padding-left: 17px;
  padding-right: 10px;
  height: 40px;

  & > .btnExit {
    color: inherit;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    margin-left: auto;
  }

  & > span {
    z-index: 10;
  }

  // &::after {
  //   content: '';
  //   width: 100vw;
  //   height: 100vh;
  //   opacity: 0.2;
  //   background-color: #000000;
  //   // clip-path: polygon(0 0, 0 100vh, 2vw 100vh, 2vw 53vh, 98vw 53vh, 98vw 90vh, 2vw 90vh, 2vw 100vh, 100vw 100vh, 100vw 0vh);
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   z-index: 5;
  // }
}
</style>
