import { Axios } from '../common/config';
import { AxiosNoty } from '../common/config';
import Vue from 'vue';
const AccountService = {
  inviteCodeValidate(invite_code) {
    return Axios.post('/accounts/invite-code-validation', {
      invite_code
    })
      .then((resp) => {
        const respData = resp.data || { status: false };
        if (invite_code.toUpperCase() === 'DIVINE') {
          respData.data = {
            ...respData.data,
            kol_logo: 'https://d1aleksa3dshq6.cloudfront.net/SignUp-desktop_divine.jpg',
            kol_logo_mobile: 'https://d1aleksa3dshq6.cloudfront.net/SignUp-mobile_divine.jpg'
          };
        }
        if (invite_code.toUpperCase() === 'THAYGIAOBA') {
          respData.data = {
            ...respData.data,
            kol_logo: 'https://d1aleksa3dshq6.cloudfront.net/SignUp-desktop_ThayGiaoBa.jpg',
            kol_logo_mobile: 'https://d1aleksa3dshq6.cloudfront.net/SignUp-mobile_ThayGiaoBa.jpg'
          };
        }
        return respData;
      })
      .catch(() => {
        return { status: false };
      });
  },
  phoneValidate(data) {
    return Axios.post('/accounts/phone-validate', data)
      .then((resp) => {
        const respData = resp.data || { status: false };
        return respData.status;
      })
      .catch(() => {
        return false;
      });
  },
  checkPhoneValidateRegister(data) {
    return Axios.post('/accounts/phone-validate', data)
      .then((resp) => {
        return resp;
      })
      .catch((e) => {
        return e.response;
      });
  },
  sendOTPResetPassword(data) {
    return Axios.post('/accounts/request-change-password', data).then((resp) => {
      return resp;
    });
  },
  postValidationCoupon(data) {
    if (data.promo_code) {
      return Axios.post('/promo-code-validations', data)
        .then((resp) => {
          return resp;
        })
        .catch((e) => {
          return e.response;
        });
    } else {
      return { data: undefined };
    }
  },
  verifyOTPResetPassword(data) {
    return Axios.post('/accounts/change-password-with-otp', data).then((resp) => {
      return resp;
    });
  },
  postSmsSending(data) {
    return Axios.post('/accounts/sms-sending', data).then((resp) => {
      return resp;
    });
  },
  postSmsSendingV2(data) {
    return Axios.post('/accounts/v2/sms-sending', data).then((resp) => {
      return resp;
    });
  },
  updatePartnerPhoneV2(data) {
    return Axios.post('/accounts/v2/partner-phone-update', data).then((resp) => {
      return resp;
    });
  },
  postRegistration(data) {
    return Axios.post('/accounts/phone-registration', data).then((resp) => {
      return resp;
    });
  },
  postRegistrationV2(data) {
    return Axios.post('/accounts/v2/phone-registration', data).then((resp) => {
      return resp;
    });
  },
  getReceiver(accountId) {
    return Axios.get(`/accounts/${accountId}/receiver`).then((resp) => {
      return resp;
    });
  },
  getListAccountLink(accountId) {
    return Axios.get(`/accounts/${accountId}/links`).then((resp) => {
      return resp;
    });
  },
  patchSaveSetting(accountId, data) {
    return Axios.patch(`/accounts/${accountId}/save-settings`, {
      save_receiver: data
    }).then((resp) => {
      return resp;
    });
  },
  patchSaveUserSettings(accountId, data) {
    return Axios.patch(`/accounts/${accountId}/save-settings`, data).then((resp) => {
      return resp;
    });
  },
  getBalances(accountId) {
    return Axios.get(`/accounts/${accountId}/balances`).then((resp) => {
      return resp;
    });
  },
  getUserInfo(accountId) {
    return Axios.get(`/accounts/${accountId}`).then((resp) => {
      return resp;
    });
  },
  changeUserInfo(accountId, data) {
    return Axios.patch(`/accounts/${accountId}`, data).then((resp) => {
      return resp;
    });
  },
  postVerifyifyPassword(data) {
    return Axios.post('/accounts/verify-password', data).then((resp) => {
      return resp;
    });
  },
  changePassword(accountId, data) {
    return Axios.patch(`/accounts/${accountId}/change-password`, data).then((resp) => {
      return resp;
    });
  },
  createTicketFavoriteKeno(accountId, data) {
    return Axios.post(`/accounts/${accountId}/favorite-numbers`, data).then((resp) => {
      return resp;
    });
  },
  removeTicketFavoriteKeno(accountId, ticketId) {
    return Axios.delete(`/accounts/${accountId}/favorite-numbers/${ticketId}`).then((resp) => {
      return resp;
    });
  },
  updateTicketFavoriteKeno(accountId, ticketId, data) {
    return Axios.put(`/accounts/${accountId}/favorite-numbers/${ticketId}`, data).then((resp) => {
      return resp;
    });
  },
  getPeriodResult(accountId, data) {
    return Axios.post(`/accounts/${accountId}/period-results`, data).then((resp) => {
      return resp;
    });
  },
  getNotificationById(accountId, notyId, handler) {
    const params = {
      handler
    };
    return AxiosNoty.get(`/accounts/${accountId}/notification/${notyId}`, { params })
      .then((resp) => {
        return resp.data;
      })
      .catch((e) => {
        return e.response.data;
      });
  },
  updateTrackingId(accountId) {
    const source = Vue.$cookies.get('_aff_network');
    const tracking_id = Vue.$cookies.get('_aff_sid');
    if (accountId && source && tracking_id)
      return Axios.patch(`/accounts/${accountId}/campaigns`, { source, tracking_id }).then((resp) => {
        return resp;
      });
    else return null;
  },
  postUnlinkAccount(accountId, source) {
    if (accountId && source) return Axios.post(`/accounts/${accountId}/unlinks`, { source }).then((resp) => resp);
    return null;
  },
  createPassword(accountId, data) {
    return Axios.patch(`/accounts/${accountId}/create-password`, data).then((resp) => {
      return resp;
    });
  },
  getShopSummary(accountId, filter) {
    return Axios.get(`/accounts/${accountId}/summaries?${new URLSearchParams(filter).toString()}`).then((resp) => {
      return resp;
    });
  },
  claimUserTicket(accountId, body) {
    return Axios.post(`/accounts/${accountId}/instant-orders`, body).then((resp) => {
      return resp;
    });
  },
  getPeriodTicket(accountId, data) {
    return Axios.post(`/accounts/${accountId}/period-tickets`, data).then((resp) => {
      return resp.data;
    });
  },
  getReferralCode(accountId) {
    return Axios.get(`/accounts/${accountId}/referral-code`).then((resp) => {
      return resp.data;
    });
  },
  updateInviteCode(accountId, invite_code) {
    return Axios.patch(`/accounts/${accountId}/referral-code`, {
      referral_code: invite_code
    }).then((resp) => {
      return resp.data;
    });
  }
};

export default AccountService;
