<template>
  <div class="ml-auto myNavbarIcons">
    <navbar-icon-notify></navbar-icon-notify>
    <div v-if="loggedIn">
      <b-navbar-nav class="mr-auto">
        <div class="d-lg-flex myNavbarContentTxt">
          <ul class="d-lg-flex text-left">
            <li class="px-2">
              <b-nav-item-dropdown :text="getLastName" class="headerDropdown" right>
                <div>
                  <div class="wallet-info">
                    <ExcludeFeatureWrapper :featureName="FEATURE_HANDLER.DEPOSIT_BALANCE">
                      <div class="wallet-section">
                        <b-row class="mx-0 align-items-center">
                          <b-col class="x-0">
                            <div class="wallet-title">TÀI KHOẢN MUA VÉ</div>
                            <div class="wallet-detail">
                              <div class="wallet-balance">
                                <CensorData
                                  dataKey="wallet"
                                  :data="formatPrice(depositBalance + pendingBalance, ' đ')"
                                  :showLength="1"
                                  :fixedLength="6"
                                />
                              </div>
                            </div>
                          </b-col>
                          <button class="wallet-btn btn-deposit btn" @click="customRedirect('/tai-khoan/nap-tien/')">
                            NẠP
                          </button>
                        </b-row>
                      </div>
                    </ExcludeFeatureWrapper>
                    <ExcludeFeatureWrapper :featureName="FEATURE_HANDLER.REWARD_BALANCE">
                      <div class="wallet-section mt-2">
                        <b-row class="mx-0 align-items-center">
                          <b-col class="x-0">
                            <div class="wallet-title">TÀI KHOẢN THƯỞNG</div>
                            <div class="wallet-detail">
                              <div class="wallet-balance">
                                <CensorData
                                  dataKey="wallet"
                                  :data="formatPrice(rewardBalance, ' đ')"
                                  :showLength="1"
                                  :fixedLength="6"
                                />
                              </div>
                            </div>
                          </b-col>
                          <button class="wallet-btn btn-withdraw btn btn-light" @click="handleWithdraw">RÚT</button>
                        </b-row>
                      </div>
                    </ExcludeFeatureWrapper>
                  </div>
                </div>
                <ExcludeFeatureWrapper :featureName="FEATURE_HANDLER.USER_INFO">
                  <router-link class="itemDropdown" to="/tai-khoan">
                    <img class="mr-2" src="@/assets/img/icon/icon_acc_profile.svg" alt />Chi tiết tài khoản</router-link
                  >
                </ExcludeFeatureWrapper>
                <!-- <router-link class="itemDropdown" to="/tai-khoan/bao-cao/" v-if="vipInfo"
                  ><img class="mr-2" src="@/assets/img/icon/pie-chart.svg" alt />Báo cáo</router-link
                > -->
                <router-link class="itemDropdown" to="/tai-khoan/lich-su-dat-mua/"
                  ><img class="mr-2" src="@/assets/img/icon/icon_acc_tickets.svg" alt />Vé đã mua</router-link
                >

                <!-- <ExcludeFeatureWrapper v-if="!isPaymentIntegration" :featureName="FEATURE_HANDLER.BUY_GROUP">
                  <router-link class="itemDropdown" to="/tai-khoan/lich-su-dat-mua-chung/"
                    ><img class="mr-2" src="@/assets/img/icon/icon_acc_tickets.svg" alt />Vé mua chung</router-link
                  >
                </ExcludeFeatureWrapper> -->

                <!-- <ExcludeFeatureWrapper :featureName="FEATURE_HANDLER.GIFT_TRANSACTION">
                  <router-link class="itemDropdown" to="/tai-khoan/ve-da-tang/"
                    ><img class="mr-2" src="@/assets/img/icon/icon_vedatang.svg" alt />Vé đã tặng</router-link
                  >
                </ExcludeFeatureWrapper> -->
                <!-- <ExcludeFeatureWrapper :featureName="FEATURE_HANDLER.GIFTED_TRANSACTION">
                  <router-link class="itemDropdown" to="/tai-khoan/ve-duoc-tang/"
                    ><img class="mr-2" src="@/assets/img/icon/icon_veduoctang.svg" alt />Vé được tặng</router-link
                  >
                </ExcludeFeatureWrapper> -->

                <ExcludeFeatureWrapper :featureName="FEATURE_HANDLER.TRANSACTION_HISTORY">
                  <router-link class="itemDropdown" to="/tai-khoan/lich-su-giao-dich/"
                    ><img class="mr-2 " src="@/assets/img/icon/icon_acc_tx-history.svg" alt />
                    <span>Lịch sử giao dịch</span>
                  </router-link>
                </ExcludeFeatureWrapper>
                <!-- <router-link class="itemDropdown" to="/tai-khoan/bo-so-yeu-thich/"
                  ><img class="mr-2" src="@/assets/img/icon/icon_acc_fav.svg" alt />Bộ số yêu thích</router-link
                > -->
                <!-- <ExcludeFeatureWrapper :featureName="FEATURE_HANDLER.INVITE_FRIEND">
                  <router-link class="itemDropdown" to="/tai-khoan/gioi-thieu-ban/"
                    ><img class="mr-2 " src="@/assets/img/icon/icon_invite-menu.svg" alt />
                    <span>Giới thiệu bạn</span>
                  </router-link>
                </ExcludeFeatureWrapper> -->
                <router-link class="itemDropdown" to="/tai-khoan/thong-bao"
                  ><img class="mr-2 " src="@/assets/img/icon/icon_acc_notify.svg" alt />
                  <span>Thông báo</span>
                </router-link>
                <ExcludeFeatureWrapper :featureName="FEATURE_HANDLER.LOGOUT">
                  <a class="dropdown-item pointer" @click="handlerLogout" v-if="!isWeluckMode">
                    <p class="headerTitleParent router-link-exact-active router-link-active">
                      <img src="@/assets/img/icon/icon_acc_logout.svg" class="logout" alt />Đăng xuất
                    </p>
                  </a>
                </ExcludeFeatureWrapper>
              </b-nav-item-dropdown>
            </li>
          </ul>
        </div>
      </b-navbar-nav>
    </div>
  </div>
</template>

<script>
import { eventTypeMessage } from '../../common/global';
import NavbarIconNotify from '../HeaderDesktop/NavbarIconNotify';
import Swal from 'sweetalert2';
import CensorData from '../CensorData';
export default {
  props: {
    isLogin: { type: Boolean }
  },
  data() {
    return {
      minWithdraw: 10000,
      censorStatus: {
        deposit: true,
        reward: true,
        phone: true
      }
    };
  },
  components: { CensorData, NavbarIconNotify },
  created() {
    this.refreshBalances();
  },
  mounted() {},
  watch: {},
  computed: {
    loggedIn() {
      return this.$store.getters.getterLoggedIn;
    },
    getAccountId() {
      return this.$store.getters.getterAccountId;
    },
    getLastName() {
      const { phone, name } = this.$store.getters.getterUserInfo;
      return name?.split(' ').pop() || this.censorData(phone, 4);
    },
    vipInfo() {
      return this.$store.getters.getterUserInfo.vip_data;
    }
  },
  methods: {
    handleWithdraw() {
      if (this.rewardBalance >= this.minWithdraw) {
        this.customRedirect('/tai-khoan/rut-tien/');
      } else
        Swal.fire({
          icon: 'info',
          title: `Bạn cần có tối thiểu ${this.formatPrice(
            this.minWithdraw
          )} trong tài khoản thưởng để thực hiện rút tiền.`,
          showClass: {
            popup: 'swal2-noanimation',
            backdrop: 'swal2-noanimation'
          },
          confirmButtonColor: '#d31904',
          showConfirmButton: false,
          customClass: {
            title: 'sweetTitle',
            cancelButton: 'confirm-button-class'
          },
          timer: 2000
        });
    },
    customRedirect(path) {
      this.$router.push(path).catch(this.errSolve);
    },
    toggleCensorStatus(key, value) {
      this.censorStatus = { ...this.censorStatus, [key]: value };
    },
    handlerLogout() {
      this.$store.dispatch('destroyToken').then(() => {
        this.sendMessageToParent(eventTypeMessage.SIGN_OUT);
      });
    }
  }
};
</script>
<style lang="scss" scoped>
// override scss profile
.wallet-info {
  margin-top: 14px;
  .wallet-title {
    font-size: 13px;
    color: #999;
    line-height: 1.69;
  }
  .wallet-detail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .wallet-balance {
      height: 18px;
      font-size: 16px;
      font-weight: bold;
      line-height: 1;
      color: $colorBlue;
    }
  }
  .wallet-btn {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    height: 30px;
    padding: 0.375rem 1.25rem;
    border: none;
    &:focus {
      box-shadow: none;
    }
    &:hover {
      border: none;
    }
  }
  .btn-deposit {
    background-color: $primaryColor;
    color: #ffffff;
    height: 35px;
  }
  .btn-withdraw {
    color: #00509d;
    background-color: #e5edf5;
    height: 35px;
  }
}
.customIcon {
  margin: 0 0 0 -4px !important;
}
.customNoti {
  margin-left: 4px;
}
</style>
