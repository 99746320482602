<template>
  <div v-if="loggedIn" class="d-flex">
    <navbar-icon-notify v-if="!isOnlyCart"></navbar-icon-notify>
  </div>
  <div v-else class="empty ml-lg-2"></div>
</template>

<script>
import NavbarIconNotify from '../HeaderDesktop/NavbarIconNotify';
export default {
  data() {
    return {};
  },
  props: {
    isOnlyNotify: {
      type: Boolean,
      default: false
    },
    isOnlyCart: {
      type: Boolean,
      default: false
    }
  },
  components: {
    NavbarIconNotify
  },
  computed: {
    loggedIn() {
      return this.$store.getters.getterLoggedIn;
    }
  }
};
</script>
<style lang="scss" scoped>
.empty {
  width: 28px;
  height: 28px;
}
</style>
