import { render, staticRenderFns } from "./ModalErrorOauth.vue?vue&type=template&id=196f3dc9&scoped=true&"
import script from "./ModalErrorOauth.vue?vue&type=script&lang=js&"
export * from "./ModalErrorOauth.vue?vue&type=script&lang=js&"
import style0 from "./ModalErrorOauth.vue?vue&type=style&index=0&id=196f3dc9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "196f3dc9",
  null
  
)

export default component.exports