<template>
  <b-modal
    headerClass=""
    :footerClass="footerClass"
    :modal-class="{
      customModal: true,
      pt40: isHeaderPlaytrial,
      fullHeight: fullHeight,
      [modalClass]: !!modalClass
    }"
    :content-class="{
      [contentClass]: !!contentClass
    }"
    :dialog-class="`customDialog ${dialogClass}`"
    :body-class="`pt-0 ${isNoPaddingContent ? 'px-0' : ''}`"
    @hide="hideModal"
    @show="showModal"
    @shown="shownModal"
    :hide-footer="isHiddenFooter"
    :no-close-on-esc="isNoCloseOnEsc"
    v-model="isOpenModel"
    :scrollable="isScrollable"
    :centered="isCentered"
    :hide-header="isHiddenHeader"
    :static="isStatic"
    :no-close-on-backdrop="isNoCloseOnBackdrop"
    :hide-backdrop="isHideBackdrop"
    :hide-header-close="hideHeaderClose"
  >
    <template v-slot:modal-title>
      <p class="modalTitle">{{ title }}</p>
    </template>

    <div>
      <slot></slot>
    </div>

    <template v-if="isBodyTooltip && isHeaderPlaytrial" v-slot:modal-footer>
      <Tooltip
        :dataStep="dataStepBody"
        :content="contentBodyTooltip"
        :position="positionBodyTooltip"
        tooltipContentClass="mt-4"
      >
        <div class="w-100 text-center d-md-block mb-md-2" :class="{ 'd-none': countAction < 2 }">
          <slot v-if="isHasSlotTextFooter" name="textFooter" />
          <div
            v-else
            :class="{
              textFooter: true,
              'textFooter--italic': isTextFooterItalic
            }"
          >
            {{ textFooter }}
          </div>
        </div>
        <div class="w-100 actionGroup d-flex justify-content-center">
          <div
            v-if="(countAction < 2 && isHasSlotTextFooter) || textFooter"
            class="pr-2 w-100 d-flex align-items-center d-md-none"
          >
            <slot v-if="isHasSlotTextFooter" name="textFooter" />
            <div
              v-else
              :class="{
                textFooter: true,
                'textFooter--italic': isTextFooterItalic
              }"
            >
              {{ textFooter }}
            </div>
          </div>

          <button
            v-if="isCancleBtn"
            @click.prevent="handleCancleBtn"
            type="submit"
            class="btn w-100 text-uppercase actionGroup__btn actionGroup__btn--cancle"
            :class="{
              custom: isCustom,
              customUI: isCustomUI,
              fullWidth: isFullWidthCancelButton
            }"
            :disabled="isCancleBtnDisable || isCancleBtnLoading"
          >
            <div v-if="isCancleBtnLoading" class="text-center w-100">
              <b-spinner small></b-spinner>
            </div>
            <span v-else>{{ cancleTextBtn ? cancleTextBtn : 'Hủy' }}</span>
          </button>

          <Tooltip
            v-if="isConfirmTooltip && isConfirmBtn && isHeaderPlaytrial"
            :dataStep="dataStepConfirm"
            :content="contentConfirmTooltip"
            :position="positionConfirmTooltip"
          >
            <button
              v-if="isConfirmBtn"
              @click.prevent="handleConfirmBtn"
              type="submit"
              class="btn w-100 text-uppercase actionGroup__btn actionGroup__btn--confirm actionGroup__btn--tooltip"
              :class="{
                custom: isCustom,
                fullWidth: isFullWidthConfirmButton
              }"
              :disabled="isConfirmBtnDisable || isConfirmBtnLoading"
            >
              <div v-if="isConfirmBtnLoading" class="text-center w-100">
                <b-spinner small></b-spinner>
              </div>
              <span v-else>{{ confirmTextBtn ? confirmTextBtn : 'Xác nhận' }}</span>
            </button>
          </Tooltip>

          <button
            v-else-if="isConfirmBtn"
            @click.prevent="handleConfirmBtn"
            type="submit"
            class="btn w-100 text-uppercase actionGroup__btn actionGroup__btn--confirm"
            :class="{
              custom: isCustom,
              fullWidth: isFullWidthConfirmButton,
              customUI: isCustomUI
            }"
            :disabled="isConfirmBtnDisable || isConfirmBtnLoading"
          >
            <div v-if="isConfirmBtnLoading" class="text-center w-100">
              <b-spinner small></b-spinner>
            </div>
            <span v-else>{{ confirmTextBtn ? confirmTextBtn : 'Xác nhận' }}</span>
          </button>
        </div>
      </Tooltip>
    </template>
    <template v-else-if="isHasSlotFooter" v-slot:modal-footer>
      <slot name="slotFooter" />
    </template>
    <template v-else v-slot:modal-footer>
      <div class="w-100 text-center d-md-block mb-md-2" :class="{ 'd-none': countAction < 2 }">
        <slot v-if="isHasSlotTextFooter" name="textFooter" />
        <div
          v-else
          :class="{
            textFooter: true,
            'textFooter--italic': isTextFooterItalic
          }"
        >
          {{ textFooter }}
        </div>
      </div>
      <div class="w-100 actionGroup d-flex justify-content-center">
        <div
          v-if="(countAction < 2 && isHasSlotTextFooter) || textFooter"
          class="pr-2 w-100 d-flex align-items-center d-md-none"
        >
          <slot v-if="isHasSlotTextFooter" name="textFooter" />
          <div
            v-else
            :class="{
              textFooter: true,
              'textFooter--italic': isTextFooterItalic
            }"
          >
            {{ textFooter }}
          </div>
        </div>

        <button
          v-if="isCancleBtn"
          @click.prevent="handleCancleBtn"
          type="submit"
          class="btn w-100 text-uppercase actionGroup__btn actionGroup__btn--cancle"
          :class="{
            custom: isCustom,
            customUI: isCustomUI,
            fullWidth: isFullWidthCancelButton
          }"
          :disabled="isCancleBtnDisable || isCancleBtnLoading"
        >
          <div v-if="isCancleBtnLoading" class="text-center w-100">
            <b-spinner small></b-spinner>
          </div>
          <span v-else>{{ cancleTextBtn ? cancleTextBtn : 'Hủy' }}</span>
        </button>

        <Tooltip
          v-if="isConfirmTooltip && isConfirmBtn && isHeaderPlaytrial"
          :dataStep="dataStepConfirm"
          :content="contentConfirmTooltip"
          :position="positionConfirmTooltip"
        >
          <button
            v-if="isConfirmBtn"
            @click.prevent="handleConfirmBtn"
            type="submit"
            class="btn w-100 text-uppercase actionGroup__btn actionGroup__btn--confirm actionGroup__btn--tooltip"
            :class="{
              custom: isCustom,
              fullWidth: isFullWidthConfirmButton,
              customUI: isCustomUI
            }"
            :disabled="isConfirmBtnDisable || isConfirmBtnLoading"
          >
            <div v-if="isConfirmBtnLoading" class="text-center w-100">
              <b-spinner small></b-spinner>
            </div>
            <span v-else>{{ confirmTextBtn ? confirmTextBtn : 'Xác nhận' }}</span>
          </button>
        </Tooltip>

        <button
          v-else-if="isConfirmBtn"
          @click.prevent="handleConfirmBtn"
          type="submit"
          class="btn w-100 text-uppercase actionGroup__btn actionGroup__btn--confirm"
          :class="{
            custom: isCustom,
            fullWidth: isFullWidthConfirmButton,
            customUI: isCustomUI
          }"
          :disabled="isConfirmBtnDisable || isConfirmBtnLoading"
        >
          <div v-if="isConfirmBtnLoading" class="text-center w-100">
            <b-spinner small></b-spinner>
          </div>
          <span v-else>{{ confirmTextBtn ? confirmTextBtn : 'Xác nhận' }}</span>
        </button>
      </div>
    </template>

    <div id="idReward"></div>
    <div class="overlayScreen" v-if="showOverlay"></div>
  </b-modal>
</template>
<script>
import Tooltip from '@/components/UI/Tooltip';

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    modalClass: {
      type: String,
      default: ''
    },
    contentClass: {
      type: String,
      default: ''
    },
    dialogClass: {
      type: String,
      default: ''
    },
    isHiddenFooter: {
      type: Boolean,
      default: false
    },
    isHiddenHeader: {
      type: Boolean,
      default: false
    },
    // Hide or show button cancle
    isCancleBtn: {
      type: Boolean,
      default: true
    },
    // Hide or show button confirm
    isConfirmBtn: {
      type: Boolean,
      default: true
    },
    isTextFooterItalic: {
      type: Boolean,
      default: false
    },
    handleHide: {
      type: Function
    },
    handleCancle: {
      type: Function
    },
    handleConfirm: {
      type: Function
    },
    updateOpen: {
      type: Function
    },
    handleShownModal: {
      type: Function
    },
    cancleTextBtn: {
      type: String,
      default: null
    },
    confirmTextBtn: {
      type: String,
      default: null
    },
    textFooter: {
      type: String,
      default: ''
    },
    isCancleBtnDisable: {
      type: Boolean,
      default: false
    },
    isConfirmBtnDisable: {
      type: Boolean,
      default: false
    },
    isCancleBtnLoading: {
      type: Boolean,
      default: false
    },
    isConfirmBtnLoading: {
      type: Boolean,
      default: false
    },
    isCloseWhenCancle: {
      type: Boolean,
      default: true
    },
    isCloseWhenConfirm: {
      type: Boolean,
      default: true
    },
    isScrollable: {
      type: Boolean,
      default: true
    },
    isCentered: {
      type: Boolean,
      default: true
    },
    fullHeight: {
      type: Boolean,
      default: false
    },
    isNoPaddingContent: {
      type: Boolean,
      default: false
    },
    isStatic: {
      type: Boolean,
      default: false
    },
    isNoCloseOnBackdrop: {
      type: Boolean,
      default: false
    },
    isHideBackdrop: {
      type: Boolean,
      default: false
    },
    isNoCloseOnEsc: {
      type: Boolean,
      default: false
    },
    isCustom: {
      type: Boolean,
      default: true
    },
    isCustomUI: {
      type: Boolean,
      default: false
    },
    footerClass: [String, Object],
    showOverlay: {
      type: Boolean,
      default: false
    },
    isConfirmTooltip: {
      type: Boolean,
      default: false
    },
    dataStepConfirm: String,
    contentConfirmTooltip: String,
    positionConfirmTooltip: String,
    isBodyTooltip: {
      type: Boolean,
      default: false
    },
    dataStepBody: String,
    contentBodyTooltip: String,
    positionBodyTooltip: String,
    isModalOverflowIframe: {
      type: Boolean,
      default: false
    },
    isFullWidthConfirmButton: {
      type: Boolean,
      default: false
    },
    isFullWidthCancelButton: {
      type: Boolean,
      default: false
    },
    hideHeaderClose: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Tooltip
  },
  data() {
    return {};
  },
  computed: {
    isOpenModel: {
      get() {
        return this.isOpen;
      },
      set(newVal) {
        this.$emit('updateOpen', newVal);
      }
    },
    countAction() {
      if (this.isConfirmBtn && this.isCancleBtn) return 2;
      if (this.isConfirmBtn || this.isCancleBtn) return 1;
      return 0;
    },
    isHasSlotTextFooter() {
      return !!this.$slots['textFooter'];
    },
    isHasSlotFooter() {
      return !!this.$slots['slotFooter'];
    },
    isHeaderPlaytrial() {
      return this.$store.state.playTrial && this.$store.getters.getterLoggedIn;
    }
  },
  methods: {
    hideModal(bvModalEvt) {
      this.$emit('handleHide', bvModalEvt);
    },
    showModal() {
      this.$emit('handleShow');
    },
    handleCancleBtn() {
      this.$emit('handleCancle');

      if (this.isCloseWhenCancle) this.$emit('updateOpen', false);
    },
    handleConfirmBtn() {
      this.$emit('handleConfirm');
      if (this.isCloseWhenConfirm) this.$emit('updateOpen', false);
    },
    shownModal() {
      this.$emit('handleShownModal');
    }
  },
  watch: {
    isOpen: function(newVal) {
      if (!this.isAllowPopupOverflowIframe) {
        return;
      }
      if (this.isModalOverflowIframe) {
        if (newVal) {
          this.isExistModalOpen++;
        } else {
          this.$nextTick().then(() => {
            this.isExistModalOpen--;
          });
        }
      } else {
        if (newVal) {
          this.isExistModalOpenNormal++;
        } else {
          this.$nextTick().then(() => {
            this.isExistModalOpenNormal--;
          });
        }

        // if (newVal) {
        //   this.sendMessageToParent(eventTypeMessage.OPEN_MODAL_BACKDROP);
        // } else {
        //   this.sendMessageToParent(eventTypeMessage.CLOSE_MODAL_BACKDROP);
        // }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.modalTitle {
  font-size: 1.25rem;
  color: #666666;
}
.actionGroup {
  // padding: 15px;
  height: 40px;
  &__btn {
    border-radius: 3px;
    padding: 7px 0px;
    width: 100%;
    max-width: 157px;
    font-weight: 600;
    font-size: 0.9375rem;
    &--cancle {
      background-color: #efefef;
      color: #666666;
      margin-right: 10px;

      &.custom {
        max-width: 35%;
      }
      &:hover {
        background-color: darken(#efefef, 10%);
      }

      &.customUI {
        background-color: #e5edf5;
        color: #00509d;
      }

      &.fullWidth {
        max-width: 100%;
        margin-right: 0px;
      }
    }
    &--confirm {
      // background-image: linear-gradient(to bottom, #e8a500, #d68c00);
      background-color: $primaryColor;
      color: #ffffff;

      &.custom {
        max-width: 75%;
      }

      &.fullWidth {
        max-width: 100%;
      }

      &:hover {
        background-color: $primaryColorDarkest;
        color: #ffffff;
      }

      &.customUI {
        color: #ffffff;
        background-color: $colorBlue;
        &:hover {
          background-color: darken($colorBlue, 10%);
        }
      }
    }

    &--tooltip {
      &.custom {
        max-width: 100%;
      }
    }
  }
}

.textFooter {
  line-height: 1.47;
  text-align: center;
  color: #333333;

  &--italic {
    font-style: italic;
  }
}

@media (max-width: map-get($grid-breakpoints, 'fix-lg')) {
  .actionGroup {
    &__btn {
      max-width: 50%;
    }
  }
}
</style>
