import { Axios } from '../common/config';
const PaymentService = {
  requestPayment(methodId, value, fee, totalValue, requestUrl, againLink) {
    return Axios.get(
      `/payments/requests?method=${methodId}&value=${value}&fee=${fee}&total=${totalValue}&requestUrl=${encodeURIComponent(
        requestUrl
      )}&againLink=${encodeURIComponent(againLink)}`
    ).then((response) => {
      return response;
    });
  },
  requestPaymentUrl(data) {
    return Axios.post(`/payment-urls`, data).then((response) => {
      return response;
    });
  },
  requestWithdraw(data) {
    return Axios.post(`/withdraws`, data).then((response) => {
      return response;
    });
  },
  verifyPayment(query) {
    let queryString = Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&');
    return Axios.post('/payments/requests', { query: queryString });
  },
  getDepositResult(id) {
    return Axios.get(`/deposits/${id}`).then((response) => response);
  },
  getDepositHistory(offset, limit = 10) {
    return Axios.get(`/deposits?offset=${offset}&limit=${limit}`).then((response) => response);
  },
  getPaymentMethods() {
    return Axios.get('/payment-methods').then((resp) => {
      return resp;
    });
  },
  getListShipping() {
    return Axios.get('/shipping-methods').then((resp) => {
      return resp;
    });
  },
  getWithdrawHistory(id, limit, offet) {
    return Axios.get(`/accounts/${id}/withdraws?limit=${limit}&offset=${offet}`).then((resp) => {
      return resp;
    });
  },
  getWithdrawHistoryDetail(accountId, withdrawId) {
    return Axios.get(`/accounts/${accountId}/withdraws/${withdrawId}`).then((resp) => {
      return resp;
    });
  },
  applyVoucher(accountId, voucher) {
    return Axios.post(`/accounts/${accountId}/vouchers`, { voucher }).then((resp) => {
      return resp;
    });
  },
  getPaymentCode(accountId, handler) {
    return Axios.get(`/accounts/${accountId}/ref-payments/${handler}`).then((resp) => {
      return resp;
    });
  },
  getPaymentQr(params) {
    return Axios.post(`/payment-qrs`, params).then((resp) => {
      return resp.data;
    });
  },
  getPaymentRequestInfo(paymentId) {
    return Axios.get(`/payment-request/${paymentId}`).then((resp) => {
      return resp.data;
    });
  }
};

export default PaymentService;
