<template>
  <div>
    <b-modal
      body-class="p-2"
      hide-footer
      hide-header
      size="lg"
      id="modal-center"
      v-model="showModal"
      centered
      title="BootstrapVue"
    >
      <img
        @click="showModal = false"
        src="https://d1aleksa3dshq6.cloudfront.net/artworks/thongbao-tet-popup.jpg"
        class="w-100 h-100"
      />
    </b-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showModal: false
    };
  },
  mounted() {
    setTimeout(() => {
      this.showModal = true;
    }, 1000);
  },
  watch: {
    showModal: function(newVal) {
      this.handleToggleModal(newVal);
    }
  }
};
</script>
