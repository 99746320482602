/* eslint-disable no-unused-vars */
// import { GameUrl } from '../api/mock/enum';
// import { DateTime } from 'luxon';

import _ from 'lodash';

function clearAllInterval() {
  var interval_id = setInterval('', 9999);
  for (var i = 1; i < interval_id; i++) {
    clearInterval(i);
  }
}

function handlerCreatedListTicket(array, value, guide) {
  return [...Array(value)].map((x, index) => {
    return {
      title: array[index],
      listNumberActive: [],
      additionals: {},
      product_id: 0,
      price: guide === 0 ? 10000 : 0
    };
  });
}

export const getEntityGameName = (character, url = []) => {
  const gameData = url.find((item) => item.id === character);
  let entityName = undefined;
  let handlerName = gameData.slug;
  let keyBannerDesktop = undefined;
  let keyBannerMobile = undefined;
  let buyTogetherComponent = undefined;
  switch (gameData.slug) {
    case 'keno':
      entityName = 'Keno';
      keyBannerDesktop = 'w_keno';
      keyBannerMobile = 'm_keno';
      break;
    case 'power':
      entityName = 'Power';
      buyTogetherComponent = 'BuyTogetherPM';
      keyBannerDesktop = 'w_power';
      keyBannerMobile = 'm_power';
      break;
    case 'mega':
      entityName = 'Power';
      buyTogetherComponent = 'BuyTogetherPM';
      keyBannerDesktop = 'w_mega';
      keyBannerMobile = 'm_mega';
      break;
    case 'max3d':
      entityName = 'MaxGame';
      handlerName = 'max3d';
      keyBannerDesktop = 'w_max3d';
      keyBannerMobile = 'm_max3d';
      break;
    case 'max3d+':
      entityName = 'MaxGame';
      keyBannerDesktop = 'w_max3d+';
      keyBannerMobile = 'm_max3d+';
      break;
    case 'max3dpro':
      entityName = 'MaxGame';
      buyTogetherComponent = 'BuyTogetherPM';
      keyBannerDesktop = 'w_max3dpro';
      keyBannerMobile = 'm_max3dpro';
      break;
    case 'max4d':
      entityName = 'MaxGame';
      keyBannerDesktop = 'w_max4d';
      keyBannerMobile = 'm_max4d';
      break;
    case 'xsmn':
      entityName = 'TraditionalGame';
      keyBannerDesktop = 'w_max4d';
      keyBannerMobile = 'm_max4d';
      break;
  }
  return { entityName, handlerName, keyBannerDesktop, keyBannerMobile, buyTogetherComponent };
};

function checkGame(path, url) {
  return path ? url.some((p) => path.includes(p.url)) : false;
}

function getEntityInfo(path, url, isCheckPath = true) {
  const numberRegex = /\d+/g;
  let isCheck = isCheckPath ? checkGame(path, url) : true;
  if (!isCheck) {
    return undefined;
  }
  if (!path) {
    return undefined;
  }
  path = path.split(/[?#]/)[0];
  const entityObj = path.split('-').pop();
  const obj = getEntityGameName(parseInt(entityObj), url);
  const entityCharacter = entityObj.replace(numberRegex, '');
  // const entityName = getEntityName(entityCharacter);

  if (entityObj.indexOf(entityCharacter) !== 0) {
    return undefined;
  }

  if (entityObj) {
    return {
      entityId: parseInt(entityObj),
      entityName: obj.entityName,
      handler: obj.handlerName,
      keyBannerDesktop: obj.keyBannerDesktop,
      keyBannerMobile: obj.keyBannerMobile,
      buyTogetherComponent: obj.buyTogetherComponent
    };
  }

  return undefined;
}

export const getEntityName = (character) => {
  let entityName = undefined;
  let objWeek = {};
  let timerPicker = new CustomDate();
  switch (character) {
    case 'keno':
      objWeek = { weekdays: undefined };
      entityName = 'KenoResult';
      break;
    case 'power':
      objWeek = { weekdays: [2, 4, 6, 1] };
      entityName = 'PowerMegaResult';
      timerPicker = null;
      break;
    case 'mega':
      objWeek = { weekdays: [3, 5, 7, 1] };
      entityName = 'PowerMegaResult';
      timerPicker = null;
      break;
    case 'max3d':
      objWeek = { weekdays: [3, 5, 7, 1] };
      entityName = 'Max3D4DResult';
      timerPicker = null;
      break;
    case 'max4d':
      objWeek = { weekdays: [2, 4, 6, 1] };
      entityName = 'Max3D4DResult';
      timerPicker = null;
      break;
    case 'max3dpro':
      objWeek = { weekdays: [2, 4, 6, 1] };
      entityName = 'Max3D4DResult';
      timerPicker = null;
      break;
    case 'xsmn':
      objWeek = { weekdays: undefined };
      entityName = 'TraditionalResult';
      timerPicker = null;
      break;
  }
  return { entityName, objWeek, timerPicker };
};

function getEntityInfoResult(path, url) {
  let isCheck = path ? url.some((p) => path.path == p.url) : false;
  if (!isCheck) {
    return undefined;
  }
  if (!path) {
    return undefined;
  }
  const entityObj = path.params.slug.split('-');
  const obj = getEntityName(entityObj[0]);

  if (entityObj) {
    return {
      entityId: parseInt(entityObj[1]),
      entityName: obj.entityName,
      entityWeekday: obj.objWeek,
      entityTimerPicker: obj.timerPicker
    };
  }
  return undefined;
}

function convertArrayToObject(data, key) {
  if (!key || !data[0][key]) return {};
  return data.reduce((result, item) => ({ ...result, [item[key]]: { ...item } }), {});
}

function getQueryVariable(query) {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
        let [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return params;
      }, {})
    : null;
}

// Giai thừa
function factorial(n) {
  let r = 1;
  while (n > 1) r *= n--;
  return r;
}

// Tính chỉnh hợp
function countPermutations(n, k) {
  return factorial(n) / factorial(n - k);
}

// Tính chỉnh hợp
function countCombination(n, k) {
  return factorial(n) / (factorial(k) * factorial(n - k));
}

function calcCombinationMax3dPro(listNumber = []) {
  if (!listNumber?.length) return 0;

  let ListUniqueNumber = new Set(listNumber);
  let length = [...ListUniqueNumber].length;
  switch (length) {
    case 1:
      return 1;
    case 2:
      return countCombination(listNumber.length, 2);
    case 3:
      return factorial(listNumber.length);
    default:
      return 0;
  }
}

// Tính bao bộ ba số
function countMax3dProCombine(listNumber = []) {
  if (listNumber?.length !== 6) return 0;

  if (listNumber.some((item) => item === null)) return 0;

  return calcCombinationMax3dPro(listNumber.slice(0, 3)) * calcCombinationMax3dPro(listNumber.slice(3));
}

// Tính bao nhiều bộ ba số
function countMax3dProCombo(listNumber = []) {
  if (listNumber?.some((item) => item === null)) return 0;

  let chunkListNumber = _.chunk(listNumber, 3);

  return countPermutations(chunkListNumber.length, 2);
}

// Tạo array hoán vị
function createPermutations(xs) {
  let ret = [];
  for (let i = 0; i < xs.length; i = i + 1) {
    let rest = createPermutations(xs.slice(0, i).concat(xs.slice(i + 1)));
    if (!rest.length) {
      ret.push([xs[i]]);
    } else {
      for (let j = 0; j < rest.length; j = j + 1) {
        ret.push([xs[i]].concat(rest[j]));
      }
    }
  }
  return ret;
}

function createUniqueArray(arr) {
  return [...new Set(arr.map((a) => a.join('')))].map((a) => a.split(''));
}

function generateMax3DProCombine(listNumber = []) {
  if (!listNumber || listNumber.length != 6) {
    return [];
  }

  const arrayNumber = _.chunk(listNumber, 3);

  const firstNumberPermutations = createUniqueArray(createPermutations(arrayNumber[0]));
  const secondNumberPermutations = createUniqueArray(createPermutations(arrayNumber[1]));

  const result = [];
  firstNumberPermutations.forEach((arr1) => {
    secondNumberPermutations.forEach((arr2) => {
      result.push([...arr1, ...arr2]);
    });
  });

  return result;
}

function generateMax3DProCombo(listNumber = []) {
  if (!listNumber || listNumber.length < 9) {
    return [];
  }

  const arrayNumber = _.chunk(listNumber, 3).map((arr) => arr.join(''));

  const result = [];
  for (let i = 0; i < arrayNumber.length; i++) {
    for (let j = i + 1; j < arrayNumber.length; j++) {
      result.push([...arrayNumber[i], ...arrayNumber[j]].join('').split(''));
      result.push([...arrayNumber[j], ...arrayNumber[i]].join('').split(''));
    }
  }

  return result;
}

function calcAmountKenoCombo(lengthNumber, level) {
  if (lengthNumber <= level || !lengthNumber || !level) {
    return 0;
  }
  return factorial(lengthNumber) / factorial(lengthNumber - level) / factorial(level);
}

function getCombinations(arr, k, prefix = []) {
  if (k == 0) return [prefix];
  return arr.flatMap((v, i) => getCombinations(arr.slice(i + 1), k - 1, [...prefix, v]));
}

function calcPercentageToNumber(percentage, maxNumber) {
  try {
    const total = Object.values(percentage).reduce((result, value) => result + value, 0);

    let arrayResult = [percentage.A, percentage.B, percentage.C, percentage.D].map((item) => {
      const percentItem = (item * 100) / total;
      return Math.round((percentItem * maxNumber) / 100);
    });

    const totalNumber = arrayResult.reduce((result, value) => result + value, 0);

    if (totalNumber > maxNumber) {
      // console.log('Case 1 ');

      let reverseArray = arrayResult.reverse();
      let arraySum = [];
      for (let i = 0; i < reverseArray.length; i++) {
        const totalRest = reverseArray.slice(i + 1).reduce((result, value) => result + value, 0);
        if (totalRest >= maxNumber) {
          reverseArray[i] = 0;
        } else {
          reverseArray[i] = maxNumber - totalRest;
          break;
        }
      }
      arrayResult = reverseArray.reverse();
    }

    if (totalNumber < maxNumber) {
      // console.log('Case 2 ');
      let isFullNumber = false;

      let i = 0;
      while (!isFullNumber) {
        if (i > 3) i = 0;
        arrayResult[i] = arrayResult[i] + 1;
        const reCalcTotalNumber = arrayResult.reduce((result, value) => result + value, 0);
        if (reCalcTotalNumber === maxNumber) {
          isFullNumber = true;
        }
      }
    }

    return {
      totalA: arrayResult[0],
      totalB: arrayResult[1],
      totalC: arrayResult[2],
      totalD: arrayResult[3]
    };
  } catch (error) {
    console.error(error.message);
  }
}

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}

function countNumberInList(array) {
  if (!array.length) return {};

  return array.reduce((result, value) => {
    if (result[value]) {
      result[value] = result[value] + 1;
    } else {
      result[value] = 1;
    }

    return result;
  }, {});
}

function getLastCharacter(str) {
  str = `${str}`;
  return str.charAt(str.length - 1);
}

function checkConditionalTtbs(number = []) {
  if (number?.length < 3) return true;

  const sortNumber = _.orderBy(number.map(Number));
  const tail = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  const head = [0, 1, 2, 3, 4, 5];

  //check head
  for (const h of head) {
    const filterNumberHead = sortNumber.filter((n) => {
      if (h === 0 && n < 10) return true;

      let tailValue = `${n}`.slice(0, 1);

      if (tailValue == h && h !== 0 && n > 9) return true;

      return false;
    });

    const checkInc = filterNumberHead.some((value, index, array) => {
      if (value + 1 === array[index + 1] && value - 1 === array[index - 1]) return true;

      return false;
    });
    // console.log(h, filterNumberHead);

    // console.log(checkInc);

    if (checkInc) return false;
  }

  //check tail
  for (const t of tail) {
    const filterNumberTail = sortNumber.filter((n) => {
      let tailValue = `${n}`.slice(-1);

      if (t == tailValue) return true;

      return false;
    });

    const checkInc = filterNumberTail.some((value, index, array) => {
      if (
        value + 10 === array[index + 1] && //
        value + 20 === array[index + 2] &&
        value + 30 === array[index + 3]
      ) {
        return true;
      }

      return false;
    });
    // console.log(t, filterNumberTail);

    // console.log(checkInc);

    if (checkInc) return false;
  }

  return true;
}

export {
  clearAllInterval,
  handlerCreatedListTicket,
  getEntityInfo,
  getEntityInfoResult,
  convertArrayToObject,
  getQueryVariable,
  countPermutations,
  countMax3dProCombine,
  countMax3dProCombo,
  generateMax3DProCombine,
  generateMax3DProCombo,
  calcAmountKenoCombo,
  getCombinations,
  randomIntFromInterval,
  countNumberInList,
  checkConditionalTtbs
};
