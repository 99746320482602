<template>
  <div>
    <b-modal
      v-if="isPromo(dataPromo)"
      headerClass="border-bottom-0"
      footerClass="border-top-0 pt-0"
      :modal-class="{
        modalRegisterPromoIsSent: isSentValidate,
        'modalRegister modalRegisterPromo  modalRegisterRep': true
      }"
      :dialog-class="{ modaDialogAppLogin: isApp && !isSentValidate && !isSentValidate }"
      no-close-on-backdrop
      no-close-on-esc
      :content-class="{ loadingModal: isLoading }"
      @shown="handleShownModalVerify"
      @hidden="onCloseModalHidden"
      :hide-footer="true"
      :hide-header="true"
      v-model="modalRegisterItem"
      centered
    >
      <div v-if="!isSmsVerify" id="recaptchaWrapper">
        <div id="captcha-container" />
      </div>
      <div v-if="!isSentValidate" class="w-100 modalRegisterContent">
        <div class="row mx-0">
          <AccountModalRegisterRemove @handlerUpdateModal="modalRegisterItem = false" class="d-block d-lg-none" />
          <div class="colCustomPromo-left d-none d-lg-block">
            <img
              v-if="inviteCodeDisplayData.kol_logo"
              class="w-100 h-100 obfit"
              :src="inviteCodeDisplayData.kol_logo"
            />
            <img v-else class="w-100 h-100 obfit ml-banner" src="@/assets/img/Signup-banner.jpg" />
          </div>
          <div class="contentBgMobile d-block d-lg-none">
            <img v-if="inviteCodeDisplayData.kol_logo_mobile" :src="inviteCodeDisplayData.kol_logo_mobile" />
            <img v-else src="@/assets/img/SignUp-mobile.jpg" />
          </div>
          <div class="col-12 colCustomPromo-right px-3 pb-3 pt-lg-3">
            <AccountModalRegisterRemove @handlerUpdateModal="modalRegisterItem = false" class="d-none d-lg-block" />
            <div class="w-100 d-none d-lg-block">
              <p class="text-center mb-lg-2">
                Chào mừng bạn tham gia
                <span class="font-weight-bold">Thần Tài</span>, <br />vui lòng điền thông tin bên dưới để đăng ký.
              </p>
            </div>
            <div class="contentPromo">
              <form @submit.prevent ref="form">
                <b-form-group class="mt-0 pt-0 pt-lg-3 mt-lg-3 pb-0">
                  <label for="phoneNumberInput">Số điện thoại</label>
                  <b-form-input
                    autocomplete="off"
                    v-validate="{
                      min: 10,
                      max: 10,
                      regex: /(03|07|08|09|05)+([0-9]{8})\b/,
                      required: true
                    }"
                    :class="{ input: true, 'is-danger': errors.has('phoneNumber') }"
                    name="phoneNumber"
                    v-model="phoneNumbera"
                    trim
                    id="phoneNumberInput"
                    data-vv-as="Số điện thoại"
                    type="number"
                    ref="inputRegister"
                    pattern="[0-9]*"
                    :disabled="phoneNumberFromUrl && !errors.first('phoneNumber')"
                  ></b-form-input>
                  <span
                    id="errorNumber"
                    v-show="errors.has('phoneNumber')"
                    class="is-danger-text position-relative"
                    v-html="
                      errors.first('phoneNumber') == 'Số điện thoại đã được sử dụng'
                        ? 'Số điện thoại đã được sử dụng' + forgotPass
                        : errors.first('phoneNumber')
                    "
                  ></span>
                </b-form-group>

                <b-form-group>
                  <label for="name">Họ và tên</label>
                  <b-form-input
                    autocomplete="off"
                    aria-autocomplete="off"
                    :class="{ input: true, 'is-danger': errors.has('name') }"
                    v-validate="{ required: false, min: 3, regex: /^\S[^`~!@#$%^&*()_+={}\[\]|\\:;“’<,>.?๐฿0-9]*$/ }"
                    name="name"
                    v-model="name"
                    id="name"
                    data-vv-as="Họ và tên"
                    ref="name"
                    trim
                  ></b-form-input>
                  <span v-show="errors.has('name')" class="is-danger-text">{{ errors.first('name') }}</span>
                </b-form-group>

                <b-form-group class="mt-1">
                  <div class="modalRegisterLabel">
                    <label for="password" class="modalRegisterLabelPass">Mật khẩu</label>
                  </div>
                  <div class="position-relative">
                    <b-form-input
                      ref="password"
                      autocomplete="off"
                      v-model="password"
                      data-vv-as="mật khẩu"
                      :class="{ input: true, 'is-danger': errors.has('password') }"
                      v-validate="'required|min:8|max:16'"
                      id="password"
                      name="password"
                      aria-describedby="password-help-block"
                      :type="typePassword"
                    ></b-form-input>
                    <UIShowPassword
                      @handlerShowPassword="handlerShowPassword($event, 'key_password')"
                      :typePassword="typePassword"
                    ></UIShowPassword>
                  </div>
                  <span v-show="errors.has('password')" class="is-danger-text">{{ errors.first('password') }}</span>
                </b-form-group>
                <b-form-group class="mt-1">
                  <div class="modalRegisterLabel">
                    <label for="verifyPassword" class="modalRegisterLabelPass">Nhập lại mật khẩu</label>
                  </div>
                  <div class="position-relative">
                    <b-form-input
                      autocomplete="off"
                      v-validate="'required|confirmed:password'"
                      v-model="verifyPassword"
                      name="verifyPassword"
                      :type="typePasswordRemember"
                      data-vv-as="Mật khẩu nhập lại"
                      :class="{ input: true, 'is-danger': errors.has('verifyPassword') }"
                    ></b-form-input>
                    <UIShowPassword
                      @handlerShowPassword="handlerShowPassword($event, 'key_rememberPassword')"
                      :typePassword="typePasswordRemember"
                    ></UIShowPassword>
                  </div>
                  <span v-show="errors.has('verifyPassword')" class="is-danger-text">
                    {{
                      errors.first('verifyPassword') === 'Vui lòng nhập Mật khẩu nhập lại'
                        ? 'Vui lòng nhập lại mật khẩu'
                        : errors.first('verifyPassword')
                    }}
                  </span>
                </b-form-group>
                <b-form-group class="mb-lg-1" v-if="!isAccesstrade">
                  <label for="inviteCode">Mã giới thiệu</label>
                  <b-form-input
                    autocomplete="off"
                    :class="{ input: true, 'is-danger': errors.has('inviteCode') }"
                    name="inviteCode"
                    v-validate
                    v-model="inviteCode"
                    id="inviteCode"
                    style="text-transform: uppercase"
                    data-vv-as="Mã khuyến mãi"
                    ref="inviteCode"
                    :disabled="couponInviteFromUrl"
                  ></b-form-input>
                  <span v-show="errors.has('inviteCode')" class="is-danger-text">{{ errors.first('inviteCode') }}</span>
                </b-form-group>
              </form>

              <AccountModalRegisterBtn @requestCaptcha="requestCaptchaCommon" :isDisabled="isDisabled" :timer="timer" />
            </div>
            <AccountModalRegisterFooter @onCloseModal="onCloseModal" class="mb-lg-4" />
          </div>
        </div>
      </div>
      <div v-else class="d-flex flex-column justify-content-between w-100 modalRegisterContent p-3">
        <AccountModalRegisterRemove
          v-if="isAssociateRegister"
          @handlerUpdateModal="modalRegisterItem = false"
          class="d-block d-lg-none mb-4 modalRegisterContent_close"
        />
        <p
          @click="onBackPromoSms()"
          v-if="isSentValidate && !isAssociateRegister"
          class="d-block pointer font-20 modalRegisterHeader headerIcon mb-4"
        >
          Xác minh số điện thoại
        </p>
        <AccountModalRegisterComfirmCode
          :phoneNumber="phoneNumber"
          :timer="timer"
          :code="code"
          :isLoading="isLoading"
          :isSmsVerify="stateOTPUI"
          :verificationNumber="verificationNumber"
          @handlerConfirmRegister="handlerConfirmRegisterCommon"
          @requestCaptcha="requestCaptchaCommon(true)"
          :isPromo="true"
          class="btnConfirmPromo"
        />
      </div>
    </b-modal>
    <b-modal
      v-else
      headerClass="border-bottom-0"
      footerClass="border-top-0 pt-0"
      modal-class="modalRegister modalRegisterRep"
      :dialog-class="{ modaDialogAppLogin: isApp }"
      no-close-on-backdrop
      no-close-on-esc
      :content-class="{ loadingModal: isLoading }"
      @shown="handleShownModalVerify"
      @hidden="onCloseModalHidden"
      v-model="modalRegisterItem"
      centered
    >
      <template v-slot:modal-title>
        <p v-if="!isSent" class="d-block pointer font-20 modalRegisterHeader">Đăng ký</p>
        <p @click="onBack()" v-else class="d-block pointer font-20 modalRegisterHeader headerIcon">
          Xác minh số điện thoại
        </p>
        <div id="recaptchaWrapper">
          <div id="captcha-container" />
        </div>
      </template>
      <div v-if="!isSent" class="d-flex flex-column justify-content-between w-100 modalRegisterContent">
        <div class="w-100">
          <p class="text-center mb-lg-2">
            Chào mừng bạn tham gia
            <span class="font-weight-bold">Thần Tài</span>, <br />vui lòng điền thông tin bên dưới để đăng ký.
          </p>
        </div>
        <div>
          <form @submit.prevent ref="form">
            <b-form-group>
              <label for="phoneNumberInput">Số điện thoại</label>
              <b-form-input
                v-validate="{ min: 10, max: 10, regex: /(03|07|08|09|05)+([0-9]{8})\b/, required: true }"
                :class="{ input: true, 'is-danger': errors.has('phoneNumber') }"
                name="phoneNumber"
                v-model="phoneNumber"
                id="phoneNumberInput"
                data-vv-as="Số điện thoại"
                type="number"
                ref="inputRegister"
                pattern="[0-9]*"
                autocomplete="off"
              ></b-form-input>
              <span v-show="errors.has('phoneNumber')" class="is-danger-text position-relative">{{
                errors.first('phoneNumber')
              }}</span>
              <a @click="openForgotPassword" class="forgot-password pointer mt-1 d-block">Quên mật khẩu?</a>
            </b-form-group>

            <b-form-group class="mt-1">
              <div class="modalRegisterLabel">
                <label for="password" class="modalRegisterLabelPass">Mật khẩu</label>
              </div>
              <div class="position-relative">
                <b-form-input
                  ref="password"
                  v-model="password"
                  data-vv-as="mật khẩu"
                  :class="{ input: true, 'is-danger': errors.has('password') }"
                  v-validate="'required|min:8|max:16'"
                  id="password"
                  name="password"
                  aria-describedby="password-help-block"
                  :type="typePassword"
                  autocomplete="off"
                ></b-form-input>
                <UIShowPassword
                  @handlerShowPassword="handlerShowPassword($event, 'key_password')"
                  :typePassword="typePassword"
                ></UIShowPassword>
              </div>
              <span v-show="errors.has('password')" class="is-danger-text position-relative">{{
                errors.first('password')
              }}</span>
            </b-form-group>
            <b-form-group class="mt-1">
              <div class="modalRegisterLabel">
                <label for="verifyPassword" class="modalRegisterLabelPass">Nhập lại mật khẩu</label>
              </div>
              <div class="position-relative">
                <b-form-input
                  v-validate="'required|confirmed:password'"
                  v-model="verifyPassword"
                  name="verifyPassword"
                  :type="typePasswordRemember"
                  data-vv-as="Mật khẩu nhập lại"
                  :class="{ input: true, 'is-danger': errors.has('verifyPassword') }"
                  autocomplete="off"
                ></b-form-input>
                <UIShowPassword
                  @handlerShowPassword="handlerShowPassword($event, 'key_rememberPassword')"
                  :typePassword="typePasswordRemember"
                ></UIShowPassword>
              </div>
              <span v-show="errors.has('verifyPassword')" class="is-danger-text position-relative">
                {{
                  errors.first('verifyPassword') === 'Vui lòng nhập Mật khẩu nhập lại'
                    ? 'Vui lòng nhập lại mật khẩu'
                    : errors.first('verifyPassword')
                }}
              </span>
            </b-form-group>
          </form>
          <AccountModalRegisterBtn @requestCaptcha="requestCaptchaCommon" :isDisabled="isDisabled" :timer="timer" />
        </div>
      </div>
      <div v-else class="d-flex flex-column justify-content-between w-100 modalRegisterContent">
        <AccountModalRegisterComfirmCode
          :phoneNumber="phoneNumber"
          :timer="timer"
          :code="code"
          :isLoading="isLoading"
          :verificationNumber="verificationNumber"
          @handlerConfirmRegister="handlerConfirmRegisterCommon"
          :isPromo="false"
        />
      </div>
      <template v-slot:modal-footer>
        <AccountModalRegisterFooter @onCloseModal="onCloseModal" v-show="!isSent" />
      </template>
    </b-modal>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import AccountService from '../../api/account';
import { clearAllInterval } from '../../utils/functions';
import { Axios, AxiosNoty, AxiosCommonSerice } from '../../common/config';
import UIShowPassword from '../../components/UI/UIShowPassword';
import AccountModalRegisterFooter from '../../components/Account/AccountModalRegisterFooter';
import AccountModalRegisterBtn from '../../components/Account/AccountModalRegisterBtn';
import AccountModalRegisterRemove from '../../components/Account/AccountModalRegisterRemove';
import AccountModalRegisterComfirmCode from '../../components/Account/AccountModalRegisterComfirmCode';
import { eventTypeMessage, NOT_FOUND_URL } from '../../common/global';
import Vue from 'vue';
import _ from 'lodash';
import { SIGNATURE_SECRET_KEY } from '../../common/global';

import md5 from 'js-md5';

let TIMER = 15;

// const vnMobilePhonePrefix = ['092', '056', '058', '070', '079', '077', '076', '078', '090', '093', '089'];

const Swal = require('sweetalert2');
export default {
  components: {
    UIShowPassword,
    AccountModalRegisterFooter,
    AccountModalRegisterBtn,
    AccountModalRegisterRemove,
    AccountModalRegisterComfirmCode
  },
  props: {
    modalRegister: {
      type: Boolean,
      default: false
    }
  },
  data() {
    let lastTimestamp = 0;
    try {
      lastTimestamp = parseInt(window.storageHelper.getItem('verify_code_timestamp_phone'));
    } catch (error) {
      console.error(error);
    }
    const currentTimestamp = new CustomDate().getTime();
    const sec = parseInt((currentTimestamp - lastTimestamp) / 1000);
    return {
      timer: sec > TIMER ? 0 : TIMER - sec,
      isDisabledButton: false,
      isSent: false,
      // isSentSmsVerify: false,
      timeAwaiting: null,
      phoneNumber: '',
      verificationNumber: '',
      verifyInterval: null,
      forgotPass:
        '<span class="d-block"><a id="forgot_password" class="forgot-password pointer mt-1 d-inline-block">Quên mật khẩu?</a></span>',
      passwordUser: '',
      tokenCapcha: '',
      isLoading: false,
      code: '',
      password: '',
      verifyPassword: '',
      promoCode: '',
      sentPromoCode: '',
      hash: '',
      sessionId: '',
      typePassword: 'password',
      typePasswordRemember: 'password',
      timeOut: null,
      timerOpen: null,
      flagRegisterSuccess: false,
      isAccesstrade: false,
      recaptchaVerifier: null,
      stateOTPUI: false,
      name: ''
    };
  },
  watch: {
    '$route.path': function() {
      if (this.$route.path === NOT_FOUND_URL) {
        clearTimeout(this.timeOut);
      }
    },
    '$route.params.kol_code': function(newVal) {
      this.inviteCode = newVal;
    },
    modalLoginData: function(newVal) {
      if (newVal) {
        clearTimeout(this.timeOut);
        this.getDataFromRoute();
      }
    },
    modalRegisterItem: function(newVal) {
      if (this.isAllowPopupOverflowIframe) {
        if (newVal) {
          this.isExistModalOpen++;
        } else {
          this.$nextTick().then(() => {
            this.isExistModalOpen--;
          });
        }
      }

      if (newVal) {
        clearTimeout(this.timeOut);
        const { phone, invite_code } = this.parentUserInfo;
        if (phone) {
          this.phoneNumber = phone;
        }

        if (this.parentInviteCode) {
          this.inviteCode = this.parentInviteCode;
        }

        if (invite_code) {
          this.inviteCode = invite_code;
        }

        this.getDataFromRoute();
      } else {
        this.phoneNumber = '';
        this.inviteCode = '';
      }
    },
    isAssociateRegister: function(newVal) {
      if (newVal) {
        const { hash, phone } = this.$store.getters.getterInfoRegisterAssociate;
        this.phoneNumber = phone;
        this.hash = hash;
        this.startCountDown();
      }
    }
  },
  computed: {
    inviteCodeDisplayData() {
      return this.$store.getters.getterInviteCodeDisplayData;
    },
    isShowPopup() {
      return this.$route.path !== NOT_FOUND_URL;
    },
    isAssociateRegister: {
      get() {
        return this.$store.getters.getterIsAssociateRegister;
      },
      set(newVal) {
        this.$store.commit('setIsAssociateRegister', newVal);
      }
    },
    isSentValidate: {
      get() {
        return this.$store.getters.getterIsSentValidate;
      },
      set(newVal) {
        this.$store.commit('setIsSentValidate', newVal);
      }
    },
    couponFromUrl() {
      //lấy coupon từ url
      return !!this.$route.query.code;
    },
    couponInviteFromUrl() {
      return !!this.$route.query.invite_code;
    },
    phoneNumberFromUrl() {
      return !!this.$route.query.phone_number;
    },
    isError() {
      return this.$store.getters.getterServerStatus;
    },
    modalLoginData() {
      return this.$store.getters.getterValLogin;
    },
    deferredPrompt() {
      return this.$store.getters.getterDeferredPrompt;
    },
    isRegister() {
      return this.$store.getters.getterIsRegister;
    },
    isHomeScreen() {
      return this.$store.getters.getterIsHomeScreen;
    },
    vnMobilePhonePrefix() {
      return this.$store.getters.getterVnMobilePhonePrefix;
    },
    isSmsVerify() {
      let isVietNameMobile = false;
      if (this.phoneNumber && this.phoneNumber.length === 10) {
        for (let phonePrefix of this.vnMobilePhonePrefix) {
          if (this.phoneNumber.startsWith(phonePrefix)) {
            isVietNameMobile = true;
          }
        }
      }

      return isVietNameMobile || this.$store.getters.getterIsSmsVerify;
    },
    dataPromo: {
      get() {
        return this.$store.getters.getterDataPromo;
      },
      set(newVal) {
        this.$store.commit('DataPromo', newVal);
      }
    },
    dataPromoBe: {
      get() {
        return this.$store.getters.getterDataPromoBe;
      },
      set(newVal) {
        this.$store.commit('DataPromoBe', newVal);
      }
    },
    phoneNumbera: {
      get() {
        return this.phoneNumber;
      },
      set(newVal) {
        this.phoneNumber = newVal.replace(/\D/g, '').replace('/[^\x00-\x7F]/g', '');
      }
    },
    isDisabled() {
      return (
        this.timer > 0 ||
        this.errors.has('phoneNumber') ||
        !this.phoneNumber ||
        !this.password ||
        this.errors.has('password') ||
        !this.verifyPassword ||
        this.errors.has('verifyPassword') ||
        this.errors.has('name')
      );
    },
    inviteCode: {
      get() {
        return this.$store.getters.getterUserInviteCode;
      },
      set(newVal) {
        this.$store.commit('setInviteCode', newVal);
      }
    },
    modalRegisterItem: {
      get() {
        return this.$store.getters.getterValRegister;
      },
      set(newVal) {
        this.$store.commit('setModalRegister', newVal);
      }
    },
    isPhoneNumber() {
      return this.phoneNumber;
    },
    isApp() {
      return this.$store.getters.getterIsApp;
    },
    loggedIn() {
      return this.$store.getters.getterLoggedIn;
    },
    redirectAfterLogin() {
      return this.$store.getters.getterRedirectAfterLogin;
    },
    parentInviteCode() {
      return this.$store.getters.getterParentInviteCode;
    },
    parentUserInfo() {
      return this.$store.getters.getterParentUserInfo;
    },
    isAllowPopupRegister() {
      return this.$store.getters.getterAllowPopupRegister;
    }
  },
  mounted() {
    clearAllInterval();
    // this.initRecaptcha();

    if (this.timer > 0) {
      this.verifyInterval = setInterval(() => {
        this.timer--;
        if (this.timer <= 0) {
          this.timer = 0;
          clearInterval(this.verifyInterval);
        }
      }, 1000);
    }

    //trường hợp là trang chủ và có chiến dịch thì tư show popup, ngược lại thì show luôn
    setTimeout(() => {
      if (
        !this.isReview &&
        !this.isThanhCo &&
        this.isCheckShowPromo() &&
        !this.$store.getters.getterIsRouterAssociate &&
        !this.compareTime
      ) {
        this.handlerPromo();
      }
    }, 0);

    window.onbeforeunload = () => {
      const { isSent, isSentValidate, phoneNumber } = this;

      if (isSent | isSentValidate) {
        window.dataLayer.push({
          event: 'cancle-verify-otp',
          phoneNumber: phoneNumber,
          labelCancelOtp: 'Reload page when verify otp'
        });
      }
    };

    window.addEventListener('offline', () => {
      const { isSent, isSentValidate, phoneNumber, modalRegisterItem } = this;
      if (!modalRegisterItem) return;
      if (isSent | isSentValidate) {
        window.storageHelper.setItem('phoneVerify', phoneNumber);
      }
    });
    window.addEventListener('online', () => {
      const { isSent, isSentValidate } = this;

      let phoneNumber = null;
      try {
        phoneNumber = window.storageHelper.getItem('phoneVerify');
      } catch (error) {
        console.error(error);
      }

      if (phoneNumber && !isSent && !isSentValidate) {
        window.dataLayer.push({
          event: 'cancle-verify-otp',
          phoneNumber: phoneNumber,
          labelCancelOtp: ' Disconnect network when verify otp'
        });
      }

      window.storageHelper.setItem('phoneVerify', '');
    });

    if (this.$route.query.invite_code) {
      this.inviteCode = this.$route.query.invite_code;
    }
  },
  created() {
    //trường hợp có chiến dịch hay không
    document.addEventListener('click', this.listenForgotPw);
    this.isAccesstrade = this.checkAccesstrade();
  },

  methods: {
    checkAccesstrade() {
      let source = this.$store.getters.getterAffNetwork || '';
      let tracking_id = this.$store.getters.getterAffSid || '';

      return source === 'accesstrade' && tracking_id !== '';
    },
    disableAutoComplete() {
      let elements = document.querySelectorAll('[autocomplete="off"]');

      if (!elements) {
        return;
      }

      elements.forEach((element) => {
        element.setAttribute('readonly', 'readonly');

        setTimeout(() => {
          element.removeAttribute('readonly');
        }, 500);
      });
    },
    isCheckShowPromo() {
      return (
        this.$route.path === '/' && !this.loggedIn && (!this.modalRegisterItem || !this.modalLoginData) && this.isError
      );
    },
    async requestCaptchaCommon(isPromoAgain = false) {
      if (this.isSmsVerify) {
        this.stateOTPUI = true;
        if (isPromoAgain) {
          this.handleSendSMSWithSMS(isPromoAgain);
          return;
        }
        this.sentPromoCode = this.promoCode;
        clearTimeout(this.timeAwaiting);
        this.timeAwaiting = setTimeout(() => {
          this.isLoading = true;
          this.handleValidateAll(this.phoneNumber, this.promoCode);
          window.dataLayer.push({ event: 'btn-register-click', phoneNumber: this.phoneNumber });
        }, 1000);
        // this.handleSendSMSWithSMS();
      } else {
        this.isLoading = true;
        if (isPromoAgain) {
          // await this.renewRecaptcha();
          this.stateOTPUI = true;

          this.handleSendSMSWithSMS(isPromoAgain);
        } else {
          this.stateOTPUI = false;
          this.requestCaptcha();
        }
      }
    },
    handlerConfirmRegisterCommon(code) {
      if (!code) return;
      if (this.stateOTPUI) {
        this.handlerConfirmRegisterSms({ code, phone_number: this.phoneNumber, hash: this.hash });
      } else {
        this.handlerConfirmRegister(code);
      }
    },
    getCouponFromUrl() {
      //app gắn promo code

      return this.$route.query.code ? this.$route.query.code.split('/')[0].toUpperCase() : '';
    },
    handlerPromo() {
      if (_.isEmpty(this.dataPromo)) {
        return;
      }

      if (!this.isAllowPopupRegister) {
        return;
      }

      const {
        popup: { after }
      } = this.dataPromo;
      //kiểm tra có chiến dịch mới show modal đăng ký
      if (this.isPromo(this.dataPromo) && this.isShowPopup) {
        this.timeOut = setTimeout(() => {
          if (!this.modalLoginData) {
            this.modalRegisterItem = true;
          }
        }, after * 1000);
      }
    },
    isPromo(dataPromo) {
      //kiểm tra Object có tồn tài hay không, nếu có thì kiểm tra thời gian diễn ra
      //nếu nằm trong khoản thời gian thì kiểm ra có cho hiển thị modal đăng ký hay không
      if (_.isEmpty(dataPromo)) {
        return;
      }
      const {
        from_date,
        to_date,
        popup: { enable }
      } = dataPromo;
      return this.isCompareTimer(from_date, to_date) && enable;
    },
    isCompareTimer(from_date, to_date) {
      // kiểm tra thời gian phải nằm từ -> đến chiến dịch
      return (
        new CustomDate().getTime() > new CustomDate(from_date).getTime() &&
        new CustomDate().getTime() < new CustomDate(to_date).getTime()
      );
    },
    handlerShowPassword(event, key) {
      switch (key) {
        case 'key_password':
          this.typePassword = event;
          break;
        default:
          this.typePasswordRemember = event;
          break;
      }
    },
    openForgotPassword() {
      this.$store.commit('setModalForgotPassword', true);
      this.onCloseModal();
    },
    onBack() {
      window.dataLayer.push({
        event: 'cancle-verify-otp',
        phoneNumber: this.phoneNumber,
        labelCancelOtp: 'Back to register form'
      });
      this.isSent = false;
      this.password = '';
      this.name = '';
      this.verifyPassword = '';
      this.phoneNumber = '';
      this.renewRecaptcha();
    },
    onBackPromoSms() {
      window.dataLayer.push({
        event: 'cancle-verify-otp',
        phoneNumber: this.phoneNumber,
        labelCancelOtp: 'Back to register form'
      });
      // this.isSentSmsVerify = false;
      this.errors.clear();
      this.isSentValidate = false;
      this.password = '';
      this.name = '';
      this.verifyPassword = '';
      this.phoneNumber = '';
    },
    startCountDown() {
      const currentTimestamp = new CustomDate().getTime();

      window.storageHelper.setItem('verify_code_timestamp_phone', currentTimestamp);
      this.timer = TIMER;
      if (this.verifyInterval) {
        clearInterval(this.verifyInterval);
      }
      this.verifyInterval = setInterval(() => {
        this.timer--;
        if (this.timer <= 0) {
          this.timer = 0;
          clearInterval(this.verifyInterval);
        }
      }, 1000);
    },
    handlerLink() {},
    handleShownModalVerify() {
      this.disableAutoComplete();
      this.isAccesstrade = this.checkAccesstrade();
      if (document.getElementById('phoneNumberInput')) {
        document.getElementById('phoneNumberInput').addEventListener('paste', (e) => {
          e.stopPropagation();
          e.preventDefault();
          this.$validator.validate('phoneNumber');
          const clipboardData = e.clipboardData || window.clipboardData;
          const pastedData = clipboardData.getData('Text');

          this.phoneNumber = pastedData.replace(/\D/g, '').replace('/[^\x00-\x7F]/g', '');
        });
      }

      this.timerOpen = new CustomDate();
      window.dataLayer.push({
        event: 'open-register',
        labelRegister: this.getCouponFromUrl() ? `with code ${this.getCouponFromUrl()}` : 'without code'
      });
      //trường hợp có chiến dịch thì tư fill promoCode,
      if (this.isPromo(this.dataPromo)) {
        this.promoCode = this.getCouponFromUrl();
      }
      if (this.$refs.inputRegister) {
        this.$refs.inputRegister.focus();
      }
      if (!this.isAssociateRegister) {
        this.isSentValidate = false;
      }
      // this.isSentSmsVerify = false;
      if (!this.isSmsVerify) {
        this.initRecaptcha();
      }
    },
    async requestCaptcha() {
      this.isLoading = true;

      if (this.phoneNumber === '' || this.timer > 0 || this.isDisabled) {
        this.isLoading = false;
        return;
      }
      this.tokenCapcha = await this.recaptchaVerifier.verify();
      if (typeof this.tokenCapcha === 'object') {
        Swal.fire({
          icon: 'error',
          title: 'Xảy ra lỗi khi yêu cầu reCaptcha. Vui lòng thử lại',
          showConfirmButton: true,
          timer: 2000
        });
        this.onCloseModal();
        return;
      }
      if (this.tokenCapcha == '') {
        this.isLoading = false;
        return;
      }
      await this.handleSendSMS();
      this.isLoading = false;
    },
    async handleSendSMS() {
      if (this.timer > 0) {
        return;
      }
      let data = {
        phone: this.phoneNumber,
        token: this.tokenCapcha,
        hash: this.hash
      };

      clearTimeout(this.timeAwaiting);
      this.timeAwaiting = setTimeout(() => {
        window.dataLayer.push({ event: 'btn-register-click', phoneNumber: this.phoneNumber });
        AccountService.postSmsSending(data)
          .then((resp) => {
            if (resp) {
              const data = resp.data;
              if (data.status) {
                this.isSentValidate = true;
                this.startCountDown();
                this.isSent = true;
                // setTimeout(() => {
                //   this.renewRecaptcha();
                // }, 60000);
                this.hash = data.data.hash;
                this.sessionId = data.data.sessionId;
              } else {
                this.errors.add({
                  field: 'phoneNumber',
                  msg: data.data.message
                });
                window.dataLayer.push({
                  event: 'incorrect-phone-number',
                  phoneNumber: this.phoneNumber,
                  messagePhoneError: data.data.message
                });
              }
            }
          })
          .catch((e) => {
            const { code, message } = e.response.data.data;
            if (code === '002.001.018') {
              this.errors.add({
                field: 'phoneNumber',
                msg: message
              });
              window.dataLayer.push({
                event: 'incorrect-phone-number',
                phoneNumber: this.phoneNumber,
                messagePhoneError: message
              });
              return;
            }
            if (code === '009.001.002' || code === '008.001.001') {
              this.errors.add({
                field: 'promoCode',
                msg: message
              });
              return;
            }
            if (e.response) {
              this.errors.add({
                field: 'phoneNumber',
                msg: message
              });
              window.dataLayer.push({
                event: 'incorrect-phone-number',
                phoneNumber: this.phoneNumber,
                messagePhoneError: message
              });
              return;
            }
            Swal.fire({
              icon: 'error',
              title: 'Có lỗi xảy ra hãy thử lại',
              showConfirmButton: true,
              timer: 2000
            });
            this.onCloseModal();
          });
      }, 1000);
    },
    async renewRecaptcha() {
      await this.recaptchaVerifier.clear();
      document.getElementById('recaptchaWrapper').innerHTML = `<div id="captcha-container" />`;
      this.initRecaptcha();
    },
    initRecaptcha() {
      this.isLoading = true;

      this.recaptchaVerifier = null;
      this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('captcha-container', {
        size: 'invisible',
        'expired-callback': async () => {
          this.renewRecaptcha();
        },
        'error-callback': () => {
          Swal.fire({
            icon: 'error',
            title: 'Xảy ra lỗi khi yêu cầu reCaptcha. Vui lòng thử lại',
            showConfirmButton: true,
            timer: 2000
          });
          this.onCloseModal();
          this.isLoading = false;
        }
      });

      this.isLoading = false;
    },

    handlerConfirmRegister(code) {
      if (this.isLoading) {
        return;
      }
      if (!this.errors.has('code')) {
        let source = this.$store.getters.getterAffNetwork || '';
        let tracking_id = this.$store.getters.getterAffSid || '';

        let data = {
          // name: this.phoneNumber,
          phone: this.phoneNumber,
          password: this.password,
          user_name: this.name || '',
          verify_code: this.code || code,
          hash: this.hash,
          user_session_code: this.sessionId,
          promo_code: this.promoCode ? this.promoCode.toUpperCase() : undefined,
          invite_code: this.inviteCode ? this.inviteCode : undefined,
          source,
          tracking_id,
          order_token: this.$route.query.orderToken
        };

        this.isLoading = true;
        let me = this;
        clearTimeout(this.timeAwaiting);
        this.timeAwaiting = setTimeout(() => {
          AccountService.postRegistration(data)
            .then((resp) => {
              this.handlerAfterConfirmRegister(me, resp, data);
            })
            .catch((e) => {
              let code = e.response.data.data.code;
              let message = e.response.data.data.message;
              window.dataLayer.push({
                event: 'otp-fail',
                phoneNumber: this.phoneNumber,
                messageOtpFail: message,
                verifyCode: data.verify_code
              });
              window.dataLayer.push({
                event: 'register-fail',
                phoneNumber: this.phoneNumber
              });
              this.$bus.$emit('validate', { code, message });
            })
            .finally(() => {
              this.isLoading = false;
            });
        }, 1000);
      }
    },
    handlerAfterConfirmRegister(me, resp, data) {
      window.dataLayer.push({
        event: 'otp-success',
        phoneNumber: this.phoneNumber
      });
      this.flagRegisterSuccess = true;
      if (this.$store.getters.getterInfoOauth2._aff_network !== 'saveextra') {
        Vue.$cookies.remove('_aff_network');
        Vue.$cookies.remove('_aff_network', null, `.${window.location.host}`);
        Vue.$cookies.remove('_aff_sid');
        Vue.$cookies.remove('_aff_sid', null, `.${window.location.host}`);
        this.$store.commit('removeInfoOauth2');
      }

      let result = resp.data;
      if (result.status) {
        const dataResult = result.data;
        Axios.defaults.headers.common['Authorization'] = `Bearer ${dataResult.token}`;
        AxiosNoty.defaults.headers.common['Authorization'] = `Bearer ${dataResult.token}`;
        AxiosCommonSerice.defaults.headers.common['Authorization'] = `Bearer ${dataResult.token}`;

        this.setAccessToken(dataResult.token);
        window.storageHelper.setItem('_clear-login', false);
        me.$store.commit('setToken', dataResult.token);

        me.$store.commit('setUserProfile', {
          id: dataResult.id,
          name: dataResult.name || '',
          email: data.email,
          phone: data.phone,
          hasPassword: dataResult.hasPassword
        });

        me.$store.commit('setUserInfo', {
          id: dataResult.id,
          name: dataResult.name || '',
          email: data.email,
          phone: data.phone
        });
        //trường hợp xét sau 1 ngày mới dược gọi lại modal add home screen cho dù đăng ký nhiều tài khoản 1 ngày
        if (data.promo_success) {
          window.dataLayer.push({
            event: 'register-success-with-promo',
            phoneNumber: this.phoneNumber,
            promoCode: this.promoCode ? this.promoCode.toUpperCase() : ''
          });
        } else {
          window.dataLayer.push({ event: 'register-success', phoneNumber: this.phoneNumber });
        }
        let paramsToParent = {
          id: dataResult.id,
          name: dataResult.name || '',
          email: data.email,
          phone: data.phone,
          invite_code: this.inviteCode || ''
        };

        this.sendMessageToParent(eventTypeMessage.REGISTER_SUCCESS, paramsToParent);

        if (!me.isRegister) {
          me.$store.dispatch('handlerHomeScreenRegister', { Swal, promo: data.promo_success });
        } else {
          if (!me.isHomeScreen) {
            me.$store.dispatch('handlerHomeScreenRegister', { Swal, promo: data.promo_success });
          } else {
            if (data.promo_success) {
              Swal.fire({
                icon: 'success',
                title: `Bạn đã ký thành công và được cộng ${this.formatMoney(data.promo_value)}đ vào tài khoản`,
                showConfirmButton: false,
                timer: 5000
              });
            } else {
              Swal.fire({
                icon: 'success',
                title: 'Đăng ký thành công',
                showConfirmButton: false,
                timer: 5000
              });
            }
          }
        }
        me.$store.commit('setIsRegister', true);

        //liên kết saveextra
        if (me.$store.getters.getterIsPhoneNotExistAssociate) {
          const token = me.$store.getters.getterTokenInfoOauth2;
          const { _aff_sid, _aff_network } = me.$store.getters.getterInfoOauth2;
          this.$store
            .dispatch('onValidateAccountLinksWithId', { _aff_sid, token, _aff_network, account_id: dataResult.id })
            .then((resp) => {
              if (!resp) return;
              if (!resp.data.data.linked) {
                this.onUpdateLinkAccount(100);
              }
            });
        }

        //chỉ hiển thị modal quy định trên app
        if (window.location.host.includes('app')) {
          if (me.$route && (me.$route.name === 'intro-profile' || me.$route.name === 'intro')) {
            me.$store.commit('setModalTerm', false);
            // return;
          } else {
            me.$store.commit('setModalTerm', true);
          }
        }
        me.onCloseModal();
        if (this.redirectAfterLogin) this.$router.push(this.redirectAfterLogin);
      } else {
        const { code, message } = data;
        me.$bus.$emit('validate', { code, message });
      }
    },
    onCloseModal() {
      this.modalRegisterItem = false;
      // this.isSent = false;
      // this.phoneNumber = '';
      // this.code = '';
      // this.password = '';
      // this.verifyPassword = '';
      // if (this.isPromo(this.dataPromo)) {
      //   this.promoCode = '';
      // }
      // this.typePassword = 'password';
      // this.typePasswordRemember = 'password';
      // this.timerOpen = null;
      // this.errors.clear();
    },
    onCloseModalHidden() {
      if (!this.flagRegisterSuccess) {
        const currentTime = new CustomDate();
        window.dataLayer.push({
          event: 'close-register',
          durationRegister: `${currentTime - this.timerOpen}ms`
        });
      }
      this.isSent = false;
      this.phoneNumber = '';
      this.code = '';
      this.password = '';
      this.name = '';
      this.verifyPassword = '';
      if (this.isPromo(this.dataPromo)) {
        this.promoCode = '';
      }
      this.typePassword = 'password';
      this.typePasswordRemember = 'password';
      this.timerOpen = null;
      this.flagRegisterSuccess = false;
      this.errors.clear();
    },
    // Validate phone and voucher promoCode
    async handleValidateAll(phoneNumber, promoCode) {
      try {
        const [phoneRes, couponRes] = await Promise.all([
          AccountService.checkPhoneValidateRegister({ phone: phoneNumber }),
          AccountService.postValidationCoupon({ promo_code: promoCode ? promoCode.toUpperCase() : '' })
        ]);

        const [{ data: phoneData }, { data: couponData }] = [phoneRes, couponRes];
        let isCheckPromoValidate = false;
        // let promo = promoCode;
        if (phoneData) {
          if (phoneData.status) {
            this.errors.add({
              field: 'phoneNumber',
              msg: 'Số điện thoại đã được sử dụng'
            });
            window.dataLayer.push({
              event: 'incorrect-phone-number',
              phoneNumber: this.phoneNumber,
              messagePhoneError: 'Số điện thoại đã được sử dụng'
            });
            return;
          } else {
            const { code, message } = phoneData.data;
            if (code === '002.001.022') {
              isCheckPromoValidate = true;
            } else {
              this.errors.add({
                field: 'phoneNumber',
                msg: message
              });
              window.dataLayer.push({
                event: 'incorrect-phone-number',
                phoneNumber: this.phoneNumber,
                messagePhoneError: message
              });
            }
          }
        }

        if (couponData && !couponData.status) {
          const { code, message } = couponData.data;
          this.sentPromoCode = undefined;
          isCheckPromoValidate = true;
          if (code === '009.001.002' || code === '008.001.001') {
            window.dataLayer.push({
              event: 'incorrect-promo-code',
              phoneNumber: this.phoneNumber,
              promoCode: this.promoCode ? this.promoCode.toUpperCase() : '',
              messagePromoError: message
            });
          }
        }

        if (isCheckPromoValidate) {
          // setTimeout(() => {
          //   this.isSentValidate = true;
          // }, 1000);
          this.handleSendSMSWithSMS();
          // this.promoCode = promo;
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
    // Phu HERE
    async handleSendSMSWithSMS(isResend = false) {
      if (this.timer > 0) {
        return;
      }

      let promo = this.sentPromoCode ? this.sentPromoCode.toUpperCase() : '';
      let signature = md5(this.phoneNumber + '_' + SIGNATURE_SECRET_KEY + '_' + promo);
      var data = {
        phone: this.phoneNumber,
        hash: this.hash,
        signature: signature,
        promo_code: promo ? promo : undefined
      };
      this.isDisabledButton = true;
      clearTimeout(this.timeAwaiting);
      this.timeAwaiting = setTimeout(() => {
        if (isResend) {
          window.dataLayer.push({ event: 'btn-resend-click', phoneNumber: this.phoneNumber });
        }
        AccountService.postSmsSendingV2(data)
          .then((resp) => {
            if (resp) {
              const data = resp.data;
              if (data.status) {
                if (data.data.isBe) {
                  this.handlerConfirmRegisterSms({
                    code: data.data.isBe,
                    phone_number: this.phoneNumber,
                    hash: this.hash
                  });
                } else {
                  this.isSentValidate = true;
                  // window.dataLayer.push({ event: 'otp-success', phoneNumber: this.phoneNumber });
                  this.startCountDown();
                  // this.isSentSmsVerify = true;
                  this.hash = data.data.hash;
                  this.errors.clear();
                }
              } else {
                this.errors.add({
                  field: 'phoneNumber',
                  msg: data.data.message
                });
                window.dataLayer.push({
                  event: 'incorrect-phone-number',
                  phoneNumber: this.phoneNumber,
                  messagePhoneError: data.data.message
                });
              }
            }
          })
          .catch((e) => {
            this.errors.clear();

            if (e.response) {
              const { message, code } = e.response.data.data;

              if (code == '009.001.002') {
                return;
              }
              this.$bus.$emit('validate', { code, message });

              if (code === '008.001.001') {
                Swal.fire({
                  icon: 'error',
                  title: message,
                  showConfirmButton: false,
                  timer: 2000
                });
                window.dataLayer.push({ event: 'request-sms-otp-fail', phoneNumber: this.phoneNumber });
                return;
              }
              if (isResend) {
                window.dataLayer.push({
                  event: 'request-resend-otp-fail',
                  phoneNumber: this.phoneNumber,
                  messageResendOtpFail: message
                });
              }
              this.errors.add({
                field: code === '009.001.002' ? 'promoCode' : 'phoneNumber',
                msg: message
              });

              if (code !== '009.001.002' && !isResend) {
                window.dataLayer.push({
                  event: 'incorrect-phone-number',
                  phoneNumber: this.phoneNumber,
                  messagePhoneError: message
                });
              }
              return;
            }
            Swal.fire({
              icon: 'error',
              title: 'Có lỗi xảy ra hãy thử lại',
              showConfirmButton: false,
              timer: 2000
            });
            this.onCloseModal();
          })
          .finally(() => {
            this.isDisabledButton = false;
            this.isLoading = false;
          });
      }, 1000);
    },
    handlerConfirmRegisterSms({ phone_number, code, hash }) {
      if (this.isLoading) {
        return;
      }
      if (!this.errors.has('code')) {
        let source = this.$store.getters.getterAffNetwork || '';
        let tracking_id = this.$store.getters.getterAffSid || '';

        let data = {
          name: phone_number,
          phone: phone_number,
          password: this.password || undefined,
          user_name: this.name || '',
          verify_code: code,
          hash: hash,
          user_session_code: this.sessionId,
          source,
          tracking_id,
          invite_code: this.inviteCode ? this.inviteCode : undefined,
          order_token: this.$route.query.orderToken
        };
        if (this.promoCode) {
          data.promo_code = this.promoCode ? this.promoCode.toUpperCase() : '';
        }

        this.isLoading = true;
        let me = this;
        clearTimeout(this.timeAwaiting);
        this.timeAwaiting = setTimeout(() => {
          AccountService.postRegistrationV2(data)
            .then((resp) => {
              this.handlerAfterConfirmRegister(me, resp, data);
              this.isSentValidate = false;
              this.modalRegisterItem = false;
              this.isAssociateRegister = false;
              this.$store.commit('setIsAcceptAssociate', false);
              this.$store.commit('setInfoRegisterAssociate', null);
              // this.isSentSmsVerify = false;
            })
            .catch((e) => {
              if (e.response) {
                let code = e.response.data.data.code;
                let message = e.response.data.data.message;
                this.$bus.$emit('validate', { code, message });
              }

              window.dataLayer.push({
                event: 'otp-fail',
                phoneNumber: this.phoneNumber,
                messageOtpFail: e.response?.data.data.message || '',
                verifyCode: data.verify_code || ''
              });
              window.dataLayer.push({ event: 'register-fail', phoneNumber: this.phoneNumber });
            })
            .finally(() => {
              this.isLoading = false;
            });
        }, 1000);
      }
    },
    listenForgotPw(e) {
      const myID = e.target.id;
      if (myID === 'forgot_password') {
        this.openForgotPassword();
      }
    },
    getDataFromRoute() {
      const { phone_number, invite_code } = this.$route.query;
      if (phone_number) {
        this.phoneNumber = phone_number;
      }
      if (invite_code) {
        this.inviteCode = invite_code;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.modalRegisterContent {
  .modalRegisterLabel {
    .modalRegisterLabelRight {
      font-style: italic;
      font-size: 14px;
    }
    .modalRegisterLabelPass {
      margin-top: 16px;
    }
  }
  input::-webkit-input-placeholder {
    font-size: 15px;
    line-height: 3;
    color: #999999;
  }
  p {
    font-size: 15px;
  }
}
.is-danger-text::first-letter {
  text-transform: capitalize;
}

.ml-banner {
  margin-left: -1px;
}
</style>
