/**
 * Importing pusher
 */
var Pusher = require('pusher-js');

/**
 * VuePusher class.
 *
 * @param {String} api_key
 * @param {Object} options
 */
function VuePusher(api_key, options) {
  if (options.logToConsole) {
    Pusher.logToConsole = true;
  }
  this.pusher = new Pusher(api_key, options);
  this.channels = [];
}

/**
 * Subscribe to the given channel and give a fallback to bind events to the channel.
 *
 * @param  {String}   channel_name
 * @param  {Function} callback
 */
VuePusher.prototype.subscribe = function(channel_name, callback) {
  var replacedChannel = channel_name.replaceAll('/', '.');
  var channel = this.pusher.subscribe(replacedChannel);

  if (!this.channels.includes(channel)) {
    this.channels.push(channel);
  }
  if (callback) callback(channel);
  else return channel;
};

/**
 * Unsubscribe from the given channel.
 *
 * @param  {String} channel
 */
VuePusher.prototype.unsubscribe = function(channel) {
  var replacedChannel = channel.replaceAll('/', '.');
  this.pusher.unsubscribe(replacedChannel);
};

/**
 * Return all the chanels.
 *
 * @return {Array}
 */
VuePusher.prototype.getChannels = function() {
  return this.channels;
};

module.exports = {
  install: function(Vue, options) {
    var pusher = new VuePusher(options.api_key, options.options);

    Vue.prototype.pusher = pusher;
    Vue.prototype.$pusher = pusher.pusher; // Just in case they want to manage it themselves.
  }
};
