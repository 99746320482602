import { Axios } from '../common/config';
const PartnerService = {
  getPartnerInfo(handler) {
    return Axios.get('/partner-info', {
      params: {
        handler
      }
    }).then((resp) => {
      return resp.data;
    });
  },
  accountPartnerLogin(data) {
    return Axios.post('/partner-accounts', data).then((resp) => {
      return resp.data;
    });
  }
};

export default PartnerService;
