<template>
  <span class="pointer ml-lg-2 d-block d-md-none" @click="handleSeenNoti">
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
      <g transform="translate(16363 21897)">
        <rect width="28" height="28" transform="translate(-16363 -21897)" fill="#f7f7f7" opacity="0" />
        <path
          d="M8.414-3.348c-.755-.811-2.167-2.031-2.167-6.027a6.169,6.169,0,0,0-5-6.061v-.814A1.25,1.25,0,0,0,0-17.5a1.25,1.25,0,0,0-1.249,1.25v.814a6.169,6.169,0,0,0-5,6.061c0,4-1.412,5.216-2.167,6.027A1.221,1.221,0,0,0-8.75-2.5,1.251,1.251,0,0,0-7.5-1.25h15A1.251,1.251,0,0,0,8.75-2.5a1.22,1.22,0,0,0-.336-.848Zm-14.526.223c.829-1.093,1.735-2.9,1.739-6.227v-.023A4.375,4.375,0,0,1,0-13.75,4.375,4.375,0,0,1,4.375-9.375v.023A9.758,9.758,0,0,0,6.114-3.125ZM0,2.5A2.5,2.5,0,0,0,2.5,0h-5A2.5,2.5,0,0,0,0,2.5Z"
          transform="translate(-16349.25 -21876)"
          fill="#0d234a"
        />
        <circle v-if="isSeen" cx="4" cy="4" r="4" transform="translate(-16348 -21893)" fill="#d31904" />
      </g>
    </svg>
  </span>
</template>

<script>
import NotificationService from '../../api/notification';
export default {
  data() {
    return {};
  },
  computed: {
    isSeen() {
      return this.listNotifications.find((x) => !x.viewed_notify);
    },
    listNotifications: {
      get() {
        return this.$store.state.listNotifications;
      },
      set(value) {
        this.$store.commit('setlistNotifications', value);
      }
    }
  },
  methods: {
    setSeenNotify() {
      this.listNotifications.forEach((x, index) => {
        this.listNotifications[index].viewed_notify = 1;
      });
    },
    async handleSeenNoti() {
      if (this.isSeen) {
        const res = await NotificationService.updateSeenNotify(this.$store.getters.getterAccountId).catch((e) => {
          console.error(e);
        });
        if (res.status) {
          this.setSeenNotify();
        }
      }

      this.$router.push('/tai-khoan/thong-bao');
    }
  }
};
</script>
