export const ErrorOrderService = [
  { key: '006.001.001', message: 'Bậc không trùng số lượng với thông tin vé!' },
  { key: '006.001.002', message: 'Bậc không tìm thấy!' },
  { key: '006.001.003', message: 'Kỳ không tìm thấy!' },
  { key: '006.001.004', message: 'Phương thức thanh toán không tìm thấy!' },
  { key: '006.001.005', message: 'Phương thức vận chuyển không tìm thấy!' },
  { key: '006.001.006', message: 'Ví nạp không tìm thấy!' },
  { key: '006.001.007', message: 'Game không tìm thấy!' },
  { key: '006.001.008', message: 'Tài khoản không tìm thấy!' },
  { key: '006.001.009', message: 'Không thể kết nối tới máy chủ' },
  { key: '006.001.010', message: 'Có lỗi trong quá trình xử lý, vui lòng thử lại' },
  { key: '006.001.011', message: 'Bộ số có số trùng nhau.' },
  { key: '006.001.012', message: 'Tên vé trùng nhau' },
  { key: '006.001.013', message: 'Sai mật khẩu!' },
  { key: '006.001.014', message: 'Kỳ đã quá hạn!' },
  { key: '006.001.019', message: 'Không tìm thấy vé' },
  { key: '006.001.020', message: 'Giao dịch không hợp lệ' }
];

export const ErrorWithdrawService = [
  { key: '007.001.001', message: 'Hình thức rút không tồn tại!' },
  { key: '007.001.002', message: 'Ví rút không tồn tại!' },
  { key: '007.001.003', message: 'Ví không đủ tiền!' },
  { key: '007.001.004', message: 'Có lỗi trong quá trình xử lý, vui lòng thử lại' },
  { key: '007.001.005', message: 'Ngân hàng không tồn tại' },
  { key: '007.001.006', message: 'Tỉnh không tồn tại.' },
  { key: '006.001.100', message: 'Giao dịch rút tiền lỗi, Vui lòng thực hiện lại giao dịch!' }
];

export const ErrorBuyPowerMegaService = [
  { key: '008.001.001', message: 'Thêm giỏ hàng thất bại' },
  { key: '008.001.002', message: 'Không tìm thấy mã đơn hàng' },
  { key: '008.001.003', message: 'Kì không hợp lệ' },
  { key: '008.001.004', message: 'Thông tin vé không hợp lệ' },
  { key: '008.001.005', message: 'Danh sách vé không hợp lệ' },
  { key: '008.001.100', message: 'Vui lòng thực hiện lại!' }
];

export const VNPayGatewayError = {
  '01': {
    message: 'Giao dịch đã tồn tại'
  },
  '05': {
    message:
      'Giao dịch không thành công. Quý khách nhập sai mật khẩu quá số lần quy định. Xin quý khách vui lòng thực hiện lại giao dịch'
  },
  '13': {
    message:
      'Giao dịch không thành công. Quý khách nhập sai mật khẩu xác thực giao dịch (OTP). Xin quý khách vui lòng thực hiện lại giao dịch.'
  },
  '07': {
    message: 'Giao dịch bị nghi ngờ là giao dịch gian lận'
  },
  '09': {
    message:
      'Giao dịch không thành công. Thẻ/Tài khoản của khách hàng chưa đăng ký dịch vụ InternetBanking tại ngân hàng.'
  },
  '10': {
    message: 'Giao dịch không thành công do. Khách hàng xác thực thông tin thẻ/tài khoản không đúng quá 3 lần'
  },
  '11': {
    message: 'Giao dịch không thành công do. Đã hết hạn chờ thanh toán. Xin quý khách vui lòng thực hiện lại giao dịch.'
  },
  '12': {
    message: 'Giao dịch không thành công do. Thẻ/Tài khoản của khách hàng bị khóa.'
  },
  '51': {
    message: 'Giao dịch không thành công do. Tài khoản của quý khách không đủ số dư để thực hiện giao dịch.'
  },
  '65': {
    message: 'Giao dịch không thành công do. Tài khoản của Quý khách đã vượt quá hạn mức giao dịch trong ngày.'
  },
  '08': {
    message:
      'Giao dịch không thành công do. Hệ thống Ngân hàng đang bảo trì. Xin quý khách tạm thời không thực hiện giao dịch bằng thẻ/tài khoản của Ngân hàng này.'
  },
  default: {
    message: 'Giao dịch xảy ra lỗi. Vui lòng liên hệ CSKH để được hỗ trợ'
  }
};
