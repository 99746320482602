<template>
  <div>
    <!-- Modal thanh toán thành công -->
    <CommonModal
      title="Thanh toán"
      :isOpen="isOpenModalQuickPaymentSuccess"
      :fullHeight="true"
      dialogClass="modalPaymentSuccessCustom"
      :isNoCloseOnBackdrop="true"
      :isNoCloseOnEsc="true"
      @updateOpen="setOpenModalPayment"
      :confirmTextBtn="isPhoneExist ? 'Đăng nhập' : 'Đăng ký'"
      @handleConfirm="handleConfirm"
      @handleHide="handleCloseModal"
      :isConfirmBtn="true"
      :isCancleBtn="false"
      :isHiddenFooter="false"
      :footerClass="{
        customConfirmBtn: true,
        customPaymentSuccess: true
      }"
    >
      <div class="modalPaymentSuccess">
        <div class="modalPaymentSuccess__description text-center">
          <img
            src="@/assets/img/icon/icon_result-success.svg"
            alt="icon success"
            width="46px"
            height="46px"
            draggable="false"
          />
          <p>Thanh toán thành công</p>
          <p>Mã thanh toán: #{{ (paymentSuccessData && paymentSuccessData.display_order_id) || '' }}</p>
        </div>

        <div
          class="pb-3"
          :class="{
            borderBottomDash: true
          }"
        >
          <div class="d-flex">
            <p class="font-18 mb-0">Thông tin chủ vé</p>
          </div>
          <div class="boxTicketRecipientInfo">
            <div class="recipientInfo d-flex mb-1">
              <div>Số điện thoại</div>
              <div>{{ phoneNumber }}</div>
            </div>
            <hr class="my-1" />
            <div class="recipientInfo d-flex">
              <div>Số CMND/CCCD</div>
              <div>{{ passportId }}</div>
            </div>
          </div>
          <div class="mt-1">
            <p class="mb-1">Email nhận thông báo trúng thưởng</p>
            <b-form-input
              v-validate="{ regex: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ }"
              :class="{ input: true, 'is-danger': errors.has('email'), customInputWithDraw: true }"
              name="email"
              v-model="email"
              id="email"
              data-vv-as="Email"
              :disabled="isUpdateEmail"
            ></b-form-input>
            <span v-show="errors.has('email')" class="is-danger-text position-relative">
              {{ errors.first('email') }}
            </span>
            <button
              v-if="!isUpdateEmail"
              class="btn btnUpdateEmail mt-3"
              :disabled="errors.has('email') || !email || isLoadingUpdateEmail"
              @click="handleUpdateEmail"
            >
              <b-spinner v-if="isLoadingUpdateEmail" small variant="light"></b-spinner>
              <span v-else>Cập nhật</span>
            </button>
            <p v-else class="mt-3 mb-1">
              <i>
                Thông tin vé mua đã được gửi về email.
              </i>
            </p>
            <button
              v-if="isGiftOrder && isUpdateEmail && isShowShareFB"
              @click="$store.commit('setIsOpenModalShare', true)"
              class="btn font-15 text-uppercase w-100 buttonShareFb"
            >
              Chia sẻ
            </button>
          </div>
        </div>
        <div>
          <p v-if="isPhoneExist" class="text-center">
            Hệ thống nhận thấy số điện thoại mua vé đã có tài khoản. Bạn có muốn đăng nhập để liên kết và quản lý vé đã
            mua?
          </p>
          <p v-else class="text-center">Bạn có thể tạo tài khoản để quản lý vé đã mua dễ dàng hơn</p>
        </div>
      </div>
    </CommonModal>
    <ModalPaymentSuccess />
  </div>
</template>

<script>
import CommonModal from '@/components/CommonModal';

import AccountService from '../../api/account';

import OrderService from '../../api/order';
import { Noti } from '../../main';
import { BASE_URL, eventTypeMessage, SHAREFB_TYPE, SOCKET_EVENT } from '../../common/global';
import ModalPaymentSuccess from '@/components/Game/ModalPaymentSuccess';
import { mapGetters } from 'vuex';

export default {
  components: {
    CommonModal,
    ModalPaymentSuccess
  },

  data() {
    return {
      passportId: '',
      phoneNumber: '',
      email: '',
      isPhoneExist: false,
      isUpdateEmail: false,
      isLoadingUpdateEmail: false
    };
  },
  sockets: {
    [SOCKET_EVENT.ORDER_PAYMENT]: function(data) {
      const dataParse = JSON.parse(data);
      if (dataParse.success) {
        if (dataParse.hash === this.currentTransaction) {
          return;
        }

        const { phone, name } = this.loggedIn ? this.userInfo : this.parentUserInfo;
        let source = this.$store.getters.getterAffNetwork || '';

        this.sendMessageToParent(eventTypeMessage.BUY_TICKET_SUCCESS, {
          order_id: dataParse.gateway_order_id,
          partner: source,
          user_name: name || '',
          user_phone: phone || '',
          refer_code: this.referCode || source,
          money: dataParse.amount,
          lottery_type: dataParse.game,
          display_order_id: dataParse.display_order_id,
          ticket_url: dataParse.display_ticket_list?.length
            ? `${BASE_URL}/tai-khoan/lich-su-dat-mua/${dataParse.display_ticket_list?.[0]}`
            : ''
        });

        this.isOpenPopupWaitingPayment = false;

        this.$store.commit('setDataRequestPayment', {
          gift_type: SHAREFB_TYPE.GIFT,
          gift_code: dataParse.gift_code,
          gift_message: dataParse.gift_message,
          order_id: dataParse.order_id
        });

        if (this.accountId) {
          this.isOpenModalPaymentSuccess = true;
        } else {
          this.enqueuePaymentSuccessData({
            order_id: dataParse.order_id,
            display_order_id: dataParse.display_order_id
          });
          this.setOpenModalPayment(true);
        }
      }
    }
  },
  computed: {
    getLoadByIframe() {
      return this.$store.state.loadByIframe;
    },
    getParentInviteCode() {
      return this.$store.state.parentInviteCode;
    },
    comPassportId: {
      get() {
        return this.$store.getters.getterQuickBuyInfo.passportId;
      },
      set(newVal) {
        this.$store.commit('setModalQuickBuyInfo', {
          phoneNumber: this.phoneNumber,
          passportId: newVal
        });
      }
    },
    comPhoneNumber: {
      get() {
        return this.$store.getters.getterQuickBuyInfo.phoneNumber;
      },
      set(newVal) {
        this.$store.commit('setModalQuickBuyInfo', {
          passportId: this.passportId,
          phoneNumber: newVal
        });
      }
    },
    paymentSuccessData: {
      get() {
        return this.$store.getters.getterQuickPaymentSuccessData[0] || {};
      },
      set(newVal) {
        this.$store.commit('setQuickPaymentSuccessData', newVal);
      }
    },
    isOpenModalQuickPaymentSuccess: {
      get() {
        return this.$store.getters.getterIsOpenModalQuickPaymentSuccess;
      },
      set(newVal) {
        this.$store.commit('setIsOpenModalQuickPaymentSuccess', newVal);
      }
    },
    currentTransaction: {
      get() {
        return this.$store.getters.getterCurrentTransaction;
      },
      set(newVal) {
        this.$store.commit('setCurrentTransaction', newVal);
      }
    },
    accountId() {
      return this.$store.getters.getterAccountId;
    },
    loggedIn() {
      return this.$store.getters.getterLoggedIn;
    },
    isOpenModalPaymentSuccess: {
      get() {
        return this.$store.getters.getterIsOpenModalPaymentSuccess;
      },
      set(newVal) {
        this.$store.commit('setIsOpenModalPaymentSuccess', newVal);
      }
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    parentUserInfo() {
      return this.$store.state.parentUserInfo;
    },
    referCode() {
      return this.$store.getters.getterUserInviteCode;
    },
    isOpenPopupWaitingPayment: {
      get() {
        return this.$store.getters.getterIsOpenPopupWaitingPayment;
      },
      set(newVal) {
        return this.$store.commit('setIsOpenPopupWaitingPayment', newVal);
      }
    },
    ...mapGetters(['getterDataRequestPayment']),
    isGiftOrder() {
      return !!this.getterDataRequestPayment.gift_code;
    }
  },
  methods: {
    setOpenModalPayment(newValue) {
      this.isOpenModalQuickPaymentSuccess = newValue;
    },
    setUserInfo({ passportId, phoneNumber }) {
      this.passportId = passportId;
      this.phoneNumber = phoneNumber;
    },

    async checkPhoneNumber() {
      const res = await AccountService.checkPhoneValidateRegister({
        phone: this.phoneNumber,
        passport: this.passportId
      });
      this.isPhoneExist = res.status === 200;
    },
    handleConfirm() {
      let data = {
        phone_number: this.phoneNumber
      };

      if (this.isUpdateEmail) {
        data.email = this.email;
      }

      if (this.getLoadByIframe && this.getParentInviteCode) {
        data.invite_code = this.getParentInviteCode;
      }

      let urlSearchParams = new URLSearchParams(window.location.search);
      Object.entries(data).forEach(([key, value]) => {
        urlSearchParams.set(key, value);
      });

      this.$router.replace(`${window.location.pathname}?${urlSearchParams.toString()}`);

      if (this.isPhoneExist) {
        this.$store.commit('setRedirectAfterLogin', null);
        this.$store.commit('setModalLogin', true);
      } else {
        this.$store.commit('setRedirectAfterLogin', null);
        this.$store.commit('setModalRegister', true);
      }
    },
    handleCloseModal() {
      this.isUpdateEmail = false;
    },
    handleUpdateEmail() {
      if (!this.paymentSuccessData.order_id) return;
      this.isLoadingUpdateEmail = true;
      OrderService.updateMailForOrder(this.paymentSuccessData.order_id, {
        mail: this.email
      })
        .then((status) => {
          if (status === 204) {
            this.isUpdateEmail = true;
          } else {
            throw new Error('Nhận thông tin email không thành công. Vui lòng thử lại.');
          }
        })
        .catch((error) => {
          Noti.fire({
            icon: 'error',
            title: error?.message || error.response?.data?.data?.message || '',
            showConfirmButton: false,
            timer: 3000
          });
        })
        .finally(() => {
          this.isLoadingUpdateEmail = false;
        });
    }
  },
  mounted() {},
  watch: {
    isOpenModalQuickPaymentSuccess: function(newValue) {
      if (newValue) {
        if (this.comPassportId !== this.passportId || this.comPhoneNumber !== this.phoneNumber) {
          this.setUserInfo({
            passportId: this.comPassportId,
            phoneNumber: this.comPhoneNumber
          });
        }

        if (this.parentUserInfo.email) {
          this.email = this.parentUserInfo.email;
          this.$nextTick(function() {
            this.$validator.validateAll();
          });
        }
      } else {
        this.dequeuePaymentSuccessData();
        this.$nextTick(function() {
          if (this.paymentSuccessData.order_id) {
            setTimeout(() => {
              this.setOpenModalPayment(true);
            }, 300);
          }
        });
      }
    },
    phoneNumber: function(newValue) {
      if (newValue) {
        this.checkPhoneNumber();
      }
    },
    loggedIn: function() {
      this.paymentSuccessData = [];
    }
  }
};
</script>

<style lang="scss" scoped>
.modalPaymentSuccess {
  &__btnUpdate {
    width: 100%;
    height: 40px;
    border-radius: 3px;
    background-color: $primaryColor;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: #fff;
  }

  &__description {
    width: 75%;
    max-width: 200px;
    margin: 0 auto;
    margin-bottom: 15px;

    img {
      margin-bottom: 10px;
    }

    p {
      // margin-bottom: 5px;
    }

    button.btn {
      width: 100%;
      height: 40px;
      border-radius: 3px;
      background-color: #00509d;
      color: #fff;
    }
  }
}
.boxTicketHeader {
  margin-bottom: 5px;
}
.boxTicketRecipientInfo {
  padding: 11px 0px;
  .recipientInfo {
    flex-direction: row;
  }
}

.groupQrPaymnet {
  .totalPayment {
    margin-bottom: 3px;
  }
  &__left {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .qrCode {
      width: 100%;
      padding-left: 2px;
      padding-right: 2px;
    }
  }
  &__right {
    padding: 0 20px 20px;

    .tutorialQr {
      ul {
        list-style: decimal;
        margin-left: 15px;
        margin-bottom: 15px;
        li {
          padding-left: 10px;
          line-height: 1.73;
        }
      }
      .listBankSupport {
        color: $colorBlue;
        cursor: pointer;
        padding-left: 25px;
      }
    }
  }
}

.totalPayment {
  font-weight: bold;
  font-size: 25px;
  color: $colorBlue;
}

.loadingBox {
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.placeholders-qr {
  max-width: 80%;
  height: calc(244px * 0.8);
  margin: 0 auto;
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .modalPaymentSuccess {
    &__btnUpdate {
      width: 182px;
      margin: 0 auto;
    }
  }

  .groupQrPaymnet {
    &__left {
      max-width: 272px;
    }
  }
}
.borderBottomDash {
  border-bottom: dashed 1px #d8d8d8;
  margin-bottom: 20px;
}

.btnUpdateEmail {
  text-transform: uppercase;
  border-radius: 3px;
  font-weight: 600;
  min-height: 40px;
  font-size: 0.9375rem;
  color: #fff;
  background-color: $primaryColor;
  width: 100%;
  &:hover {
    color: #fff;
    background-color: $primaryColorDarkest;
  }
}

.btnDownloadQr {
  width: 155px;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;

  margin-bottom: 20px;
  border-radius: 3px;

  font-weight: 600;
  font-size: 0.9375rem;
  color: $colorBlue;
  text-transform: initial;

  background-color: #e5edf5;
  &:hover {
    background-color: darken(#e5edf5, 10%);
  }

  & > img {
    margin-right: 10px;
  }
}

.buttonShareFb {
  height: 40px;
  border-radius: 3px;
  background-color: #00509d;
  color: #fff;
}
</style>

<style lang="scss">
.customConfirmBtn {
  button.actionGroup__btn--confirm {
    max-width: 100% !important;
  }
}

.customPaymentSuccess {
  button.actionGroup__btn--confirm {
    color: $colorBlue;
    background-color: #e5edf5;
    &:hover {
      color: $colorBlue;
      background-color: darken(#e5edf5, 10%);
    }
  }
}

.modalPaymentCustomNew {
  max-width: 731px !important;
}
.modalPaymentSuccessCustom {
  max-width: 344px !important;
}
</style>
