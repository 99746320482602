import { Axios } from '../common/config';

const IntegrationService = {
  validateIntegration(data) {
    return Axios.post('/integration/validate', data).then((res) => {
      return res.data;
    });
  }
};
export default IntegrationService;
