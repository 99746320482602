import { Axios } from '../common/config';
import Vue from 'vue';
import storageHelper from 'storage-helper';
import { APP_ENV } from '../common/global';

let isCheckCodeError = false;
let numberAssociate = 0;
try {
  isCheckCodeError = JSON.parse(storageHelper.getItem('__icerr_'));
  numberAssociate = JSON.parse(storageHelper.getItem('__nbss_'));
} catch (e) {
  console.error(e.message);
  //
}

const state = {
  objOauth2: {
    isLogin: true,
    isTextError: false,
    decodeUrlSaveextra:
      APP_ENV == 'production'
        ? 'https://www.saveextra.com.vn'
        : APP_ENV == 'staging'
        ? 'https://web-staging.saveextra.com.vn'
        : 'http://localhost:3000',
    textError:
      'Số điện thoại tài khoản Save Extra đã được đăng ký ở hệ thống Tài Lộc trước đây. Vui lòng thực hiện đăng nhập bằng số điện thoại.'
  },
  infoRegisterAssociate: null,
  isRouterAssociate: false,
  modalAssociate: false,
  tokenInfoOauth2: null,
  // infoOauth2: Vue.$cookies.get('__info_oauth') || null,
  affNetwork: Vue.$cookies.get('_aff_network') || null,
  affSid: Vue.$cookies.get('_aff_sid') || null,

  isErrorPopupOauth2: false,
  isBuyGameOauth2: false,
  isCheckCodeError,
  isAcceptAssociate: false,
  isAssociateRegister: false,
  isPhoneNotExistAssociate: Vue.$cookies.get('_is_phone_associate') || false,
  numberAssociate,
  parentInviteCode: ''
};

const getters = {
  getterInfoRegisterAssociate(state) {
    return state.infoRegisterAssociate;
  },
  getterObjOauth2(state) {
    return state.objOauth2;
  },
  getterIsAssociateRegister(state) {
    return state.isAssociateRegister;
  },
  getterObjOauth2TextError(state) {
    return state.objOauth2.isTextError;
  },
  getterModalAssociate(state) {
    return state.modalAssociate;
  },
  getterInfoOauth2(state) {
    return { _aff_sid: state.affSid, _aff_network: state.affNetwork };
  },
  getterAffNetwork(state) {
    return state.affNetwork;
  },
  getterAffSid(state) {
    return state.affSid;
  },
  getterTokenInfoOauth2(state) {
    return state.tokenInfoOauth2;
  },
  getterIsErrorPopupOauth2(state) {
    return state.isErrorPopupOauth2;
  },
  getterIsBuyGameOauth2(state) {
    return state.isBuyGameOauth2;
  },
  getterIsCheckCodeError(state) {
    return state.isCheckCodeError;
  },
  getterIsAcceptAssociate(state) {
    return state.isAcceptAssociate;
  },
  getterIsPhoneNotExistAssociate(state) {
    return state.isPhoneNotExistAssociate;
  },
  getterIsRouterAssociate(state) {
    return state.isRouterAssociate;
  },
  getterNumberAssociate(state) {
    return state.numberAssociate;
  },
  getterParentInviteCode(state) {
    return state.parentInviteCode;
  }
};

const actions = {
  onAccountLinks(context, { _aff_sid, token, _aff_network }) {
    return new Promise((resolve, reject) => {
      Axios.post(`/accounts/links`, {
        tracking_id: _aff_sid,
        token: token,
        source: _aff_network
      })
        .then((resp) => {
          if (resp) {
            return resolve(resp);
          }
          reject();
        })
        .catch((error) => {
          let code = error.response.data.data.code;
          context.commit('setIsAcceptAssociate', true);
          // if (!context.getters.getterIsCheckCodeError) {
          //   if (code === '002.001.038') {
          //     context.commit('setIsAcceptAssociate', false);
          //   }
          //   return reject(error);
          // }
          if (!error.response.data.status) {
            if (code === '002.001.020') {
              context.commit('setModalRegister', true);
              context.commit('setIsPhoneNotExistAssociate', true);
            } else if (code === '002.001.038') {
              Vue.$cookies.remove('__info_oauth');
              Vue.$cookies.remove('__token_info_oauth');
            }
            //else if (code === '002.001.031') {
            //   this.$store.commit('setModalRegister', true);
            // }
          }
          reject(error);
        })
        .finally(() => {
          // context.commit('setRemoveIsCheckCodeError');
        });
    });
  },
  onPutAccountLinks(context, { _aff_sid, token, _aff_network }) {
    return new Promise((resolve, reject) => {
      Axios.put(`/accounts/links`, {
        tracking_id: _aff_sid,
        token: token,
        source: _aff_network
      })
        .then((resp) => {
          if (resp) {
            return resolve(resp);
          }
          reject();
        })
        .catch((error) => {
          let code = error.response.data.data.code;
          context.commit('setIsAcceptAssociate', true);
          if (!error.response.data.status) {
            if (code === '002.001.036') {
              context.commit('setModalLogin', true);
              context.commit('setRemoveIsCheckCodeError');
              context.commit('setObjOauth2TextError', true);
            } else if (code === '002.001.038') {
              // context.commit('setInfoOauth2', null);
              // context.commit('setTokenInfoOauth2', null);
              Vue.$cookies.remove('__info_oauth');
              Vue.$cookies.remove('__token_info_oauth');
              context.commit('setIsErrorPopupOauth2', true);
            } else if (code === '002.001.037') {
              const { hash, phone } = error.response.data.data;
              context.commit('setIsSentValidate', true);
              context.commit('setModalRegister', true);
              context.commit('setInfoRegisterAssociate', { hash, phone });
              context.commit('setIsAssociateRegister', true);
            }
          }
          reject(error);
        })
        .finally(() => {
          // context.commit('setRemoveIsCheckCodeError');
        });
    });
  },
  onAccountLinksWithId(context, { _aff_sid, token, _aff_network, account_id }) {
    return new Promise((resolve, reject) => {
      Axios.put(`/accounts/${account_id}/links`, {
        tracking_id: _aff_sid,
        token: token,
        source: _aff_network
      })
        .then((resp) => {
          if (resp) {
            return resolve(resp);
          }
          reject();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  onValidateAccountLinksWithId(context, { _aff_sid, token, _aff_network, account_id }) {
    return new Promise((resolve, reject) => {
      Axios.post(`/accounts/${account_id}/links`, {
        tracking_id: _aff_sid,
        token: token,
        source: _aff_network
      })
        .then((resp) => {
          if (resp) {
            return resolve(resp);
          }
          reject();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

const mutations = {
  setObjOauth2(state, value) {
    state.objOauth2 = value;
  },
  setInfoRegisterAssociate(state, value) {
    state.infoRegisterAssociate = value;
  },
  setIsAssociateRegister(state, value) {
    state.isAssociateRegister = value;
  },
  setRemoveIsCheckCodeError() {
    storageHelper.removeItem('__icerr_');
  },
  setObjOauth2TextError(state, value) {
    state.objOauth2.isTextError = value;
  },
  setIsAcceptAssociate(state, value) {
    state.isAcceptAssociate = value;
  },
  setModalAssociate(state, value) {
    state.modalAssociate = value;
  },
  setInfoOauth2(state, value) {
    this.commit('setAffNetwork', value._aff_network);
    this.commit('setAffSid', value._aff_sid);
    this.commit('setParentInviteCode', value._utm_source_invite_code);
  },
  setAffNetwork(state, value) {
    APP_ENV === 'development'
      ? Vue.$cookies.set('_aff_network', value, '30d')
      : Vue.$cookies.set('_aff_network', value, '30d', '/', window.location.host);
    state.affNetwork = value;
  },
  setAffSid(state, value) {
    APP_ENV === 'development'
      ? Vue.$cookies.set('_aff_sid', value, '30d')
      : Vue.$cookies.set('_aff_sid', value, '30d', '/', window.location.host);
    state.affSid = value;
  },
  setParentInviteCode(state, value) {
    state.parentInviteCode = value;
  },
  setTokenInfoOauth2(state, value) {
    state.tokenInfoOauth2 = value;
  },
  removeInfoOauth2() {
    this.commit('removeAffNetwork');
    this.commit('removeAffSid');
  },
  removeAffNetwork() {
    APP_ENV === 'development'
      ? Vue.$cookies.remove('_aff_network')
      : Vue.$cookies.remove('_aff_network', '/', window.location.host);
    state.affNetwork = null;
  },
  removeAffSid() {
    APP_ENV === 'development'
      ? Vue.$cookies.remove('_aff_sid')
      : Vue.$cookies.remove('_aff_sid', '/', window.location.host);
    state.affSid = null;
  },
  setIsErrorPopupOauth2(state, value) {
    state.isErrorPopupOauth2 = value;
  },
  setIsBuyGameOauth2(state, value) {
    state.isBuyGameOauth2 = value;
  },
  setIsCheckCodeError(state, value) {
    storageHelper.setItem('__icerr_', JSON.stringify(value));
    state.isCheckCodeError = value;
  },
  setIsPhoneNotExistAssociate(state, value) {
    Vue.$cookies.set('_is_phone_associate', value, '10MIN');
    state.isPhoneNotExistAssociate = value;
  },
  setIsRouterAssociate(state, value = true) {
    state.isRouterAssociate = value;
  },
  setNumberAssociate(state, value) {
    storageHelper.setItem('__nbss_', JSON.stringify(value));
    state.numberAssociate = value;
  },
  removeNumberAssociate(state, value) {
    storageHelper.removeItem('__nbss_');
    state.numberAssociate = value;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
