<template>
  <!-- Modal thanh toán thành công -->
  <CommonModal
    title="Ghi nhận đặt vé"
    :isOpen="isOpenModalPaymentSuccess"
    @updateOpen="setModalPaymentSuccess"
    :fullHeight="true"
    :isHiddenFooter="true"
    :isNoCloseOnBackdrop="isNotUpdateInfo"
    @handleHide="handleHideModal()"
    @handleShow="handleShowModal()"
    dialogClass="modalPaymentCustom"
    @handleShownModal="checkFormDataMobile"
  >
    <div v-if="!isPlayTrialModeMobile" class="modalPaymentSuccess">
      <div class="modalPaymentSuccess__description text-center">
        <img src="@/assets/img/icon/icon_result-success.svg" alt="icon success" width="46px" height="46px" />
        <p>Đang xử lý in vé</p>

        <p v-if="isGiftOrder">
          Vé bạn tặng đã được gửi đến <strong>{{ getterDataRequestPayment.gift_code }}</strong>
        </p>
        <div class="d-flex justify-content-center mb-4">
          <button
            @click="handleHide(true)"
            class="btn font-15 text-uppercase mr-2"
            :class="{
              customBtn: isGiftOrder && isShowShareFB,
              'w-100': isGiftOrder && isShowShareFB
            }"
          >
            KIỂM TRA
          </button>
          <button
            v-if="isGiftOrder && isShowShareFB"
            @click="$store.commit('setIsOpenModalShare', true)"
            class="btn font-15 text-uppercase w-100"
          >
            Chia sẻ
          </button>
        </div>
        <p class="moreInfo">Tiền mua vé và phí giao dịch chỉ bị trừ khi vé của quý khách được in thành công.</p>
      </div>

      <div v-if="!isGiftOrder">
        <div v-if="isNotUpdateInfo || isUpdate" class="mb-3">
          <p class="pt-2 mb-2">
            <i>
              Vui lòng cập nhật thông tin chủ vé. Thông tin này sẽ được ghi vào mặt sau của vé khi được in ra.
            </i>
          </p>
          <TicketRecipients
            :listUser="listUserReceiver"
            :saveReceiver="valSetting"
            @handlerSetting="handlerSetting"
            @handlerUser="handlerUser"
            class="boxTicketRecipientItem recipientModal2"
            isHiddenPhone="true"
          />
          <button
            :disabled="!infoFormValidated"
            class="btn modalPaymentSuccess__btnUpdate mt-2"
            @click="handleUpdateUserInfo()"
          >
            Cập nhật
          </button>
        </div>
        <div v-else class="mb-3">
          <div class="boxTicketHeader d-flex">
            <p class="font-18 color-grey mb-0">Thông tin chủ vé</p>
            <span
              class="btn-edit-info color-blue pointer ml-4"
              v-if="getUserInfo && getUserInfo.name"
              @click="isUpdate = true"
              >Cập nhật</span
            >
          </div>
          <div class="boxTicketRecipientInfo d-flex" v-if="getUserInfo && getUserInfo.name">
            <div class="recipientInfo d-flex">
              <div class="recipientInfo__title">Họ và tên</div>
              <div class="recipientInfo__value">{{ getUserInfo.name }}</div>
            </div>
            <div class="recipientInfo d-flex">
              <div class="recipientInfo__title">Số điện thoại</div>
              <div class="recipientInfo__value">
                <span v-if="censorStatus.phone">
                  <span>{{ censorData(getUserInfo.phone) }}</span>
                  <img
                    src="@/assets/img/icon/icon_showpass_on.svg"
                    alt=""
                    class="icon-censor"
                    @click="toggleCensorStatus('phone', false)"
                  />
                </span>
                <span v-else>
                  <span>{{ getUserInfo.phone }}</span>
                  <img
                    src="@/assets/img/icon/icon_showpass_off.svg"
                    alt=""
                    class="icon-censor"
                    @click="toggleCensorStatus('phone', true)"
                  />
                </span>
              </div>
            </div>
            <div class="recipientInfo d-flex">
              <div class="recipientInfo__title">Số CMND/CCCD</div>
              <div class="recipientInfo__value">
                <span v-if="censorStatus.passport">
                  <span>{{ censorData(getUserInfo.passport, 0) }}</span>
                  <img
                    src="@/assets/img/icon/icon_showpass_on.svg"
                    alt=""
                    class="icon-censor"
                    @click="toggleCensorStatus('passport', false)"
                  />
                </span>
                <span v-else>
                  <span>{{ getUserInfo.passport }}</span>
                  <img
                    src="@/assets/img/icon/icon_showpass_off.svg"
                    alt=""
                    class="icon-censor"
                    @click="toggleCensorStatus('passport', true)"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="modalPaymentSuccessTrial text-center">
      <img class="h-auto modalPaymentSuccessTrial__image" src="../../assets/img/lotto-machine.png" width="200" />
      <p class="mt-4 pt-2">
        Quý khách thanh toán thành công.<br />
        Vui lòng chờ quay số.
      </p>
      <p class="modalPaymentSuccessTrial__timer">{{ timer }}</p>
    </div>
  </CommonModal>
</template>

<script>
import AccountService from '@/api/account';
import GameService from '@/api/game';
import TicketRecipients from '@/components/Payment/TicketRecipients';
import CommonModal from '@/components/CommonModal';
import { URL_DEFAUTL_CONGRATS } from '@/common/global.js';
import { mapGetters } from 'vuex';

const TIMER = 3;

export default {
  components: {
    TicketRecipients,
    CommonModal
  },

  data() {
    return {
      timeWaiting: null,
      valSetting: 0,
      listUserReceiver: [],
      timer: 0,
      verifySetTimeout: null,
      newUserData: {
        name: '',
        passport: '',
        date_of_birth: ''
      },
      isUpdate: false,
      censorStatus: {
        passport: true,
        phone: true
      }
    };
  },
  computed: {
    isOpenModalPaymentSuccess: {
      get() {
        return this.$store.getters.getterIsOpenModalPaymentSuccess;
      },
      set(newVal) {
        this.$store.commit('setIsOpenModalPaymentSuccess', newVal);
      }
    },
    isPlayTrialMode() {
      return this.$store.getters.getterPlayTrial;
    },
    accountId() {
      return this.$store.getters.getterAccountId;
    },
    infoFormValidated: {
      get() {
        return this.$store.getters.getterInfoFormValidated;
      },
      set(newVal) {
        this.$store.commit('setInfoFormValidated', newVal);
      }
    },
    getUserInfo: {
      get() {
        return this.$store.getters.getterUserInfo;
      },
      set(data) {
        this.$store.commit('setUserInfo', { ...this.getUserInfo, ...data });
      }
    },

    isNotUpdateInfo() {
      return !(this.getUserInfo && this.getUserInfo.name);
    },
    loggedIn() {
      return this.$store.getters.getterLoggedIn;
    },
    payloadGameTrial: {
      get() {
        return this.$store.getters.getterPayloadGameTrial;
      },
      set(newVal) {
        this.$store.commit('setPayloadGameTrial', newVal);
      }
    },
    ...mapGetters(['getterDataRequestPayment']),
    isGiftOrder() {
      return !!this.getterDataRequestPayment.gift_code;
    },
    isBuyGroup() {
      return !!this.getterDataRequestPayment.is_buy_group;
    }
  },
  methods: {
    // UPDATE INFO USER
    handlerSetting(value) {
      this.valSetting = value;
      if (this.timeWaiting) {
        clearTimeout(this.timeWaiting);
      }
      this.timerWaiting = setTimeout(() => {
        AccountService.patchSaveSetting(this.accountId, value ? 1 : 0).catch((e) => {
          console.error(e);
        });
      }, 500);
    },
    handleShowModal() {
      if (this.isPlayTrialMode) {
        this.timer = TIMER;
        this.startCountDown();
      }
    },
    handlerUser(value) {
      this.listUserReceiver = value;
      this.newUserData = value;
    },
    startCountDown() {
      if (this.timer > 0) {
        this.verifySetTimeout = setTimeout(() => {
          this.timer -= 1;
          this.startCountDown();
        }, 1000);
      } else {
        //chuyển đến mua vé thành công
        // payloadGameTrial
        GameService.postGameTrial(this.payloadGameTrial)
          .then((resp) => {
            if (resp.data.status) {
              this.$store.commit('setObjResultGameTrial', resp.data.data);
              this.$store.commit('setObjNoCloseOnEscAndBackdrop', { isNoCloseOnEsc: true, isNoCloseOnBackdrop: true });
              this.$store.commit('setListTicketOverview', []);
              if (resp.data.data.is_winning_ticket) {
                this.$store.commit('setIsOpenPopupCongrats', true);
                this.$store.commit('setUrlPopupGrate', URL_DEFAUTL_CONGRATS);
              } else {
                this.$router.push(URL_DEFAUTL_CONGRATS);
                this.isOpenModalPaymentSuccess = false;
              }
            }
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
    handleHideModal() {
      this.isUpdate = false;
      this.$store.commit('setIsBuyGameOauth2', false);
      this.onUpdateLinkAccount(2000);
      clearTimeout(this.verifySetTimeout);
    },
    handleHide(isRoute = false) {
      const isGiftOrder = this.isGiftOrder;

      this.handleHideModal();
      this.setModalPaymentSuccess(false);

      if (isRoute) {
        if (this.isBuyGroup) {
          this.$router.push('/tai-khoan/lich-su-dat-mua-chung/');
        } else if (isGiftOrder) {
          this.$router.push('/tai-khoan/ve-da-tang/');
        } else {
          this.$router.push('/tai-khoan/lich-su-dat-mua/');
        }
      }

      this.$store.commit('setDataRequestPayment', {});
    },
    getReceiver() {
      AccountService.getReceiver(this.accountId)
        .then((resp) => {
          if (resp.data.data.receiver_info.date_of_birth) {
            let timerSet = resp.data.data.receiver_info.date_of_birth;
            if (timerSet) {
              timerSet = new CustomDate(timerSet);
            }
            this.listUserReceiver = { ...resp.data.data.receiver_info, date_of_birth: timerSet };
          } else {
            this.listUserReceiver = resp.data.data.receiver_info;
          }
          let rs = resp.data.data.save_receiver;
          this.$store.commit('setIsSaveReceiver', rs === 1 ? true : false);
          this.valSetting = resp.data.data.save_receiver;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    shouldUpdatedata() {
      // const { id, name, phone, passport, date_of_birth } = this.getUserInfo;
      let newData = {
        name: this.newUserData.name,
        passport: this.newUserData.passport,
        date_of_birth: this.newUserData.date_of_birth
      };
      // if (newData.date_of_birth) {
      //   newData.date_of_birth = new CustomDate(newData.date_of_birth);
      // }
      let flag = false;
      for (const [key, value] of Object.entries(newData)) {
        if (key === 'date_of_birth') {
          if (this.formatDate(this.getUserInfo[key]) !== this.formatDate(value)) {
            flag = true;
          }
        } else {
          if (this.getUserInfo[key] !== value) {
            flag = true;
          }
        }
      }
      return flag;
    },
    handleUpdateUserInfo() {
      if (!this.accountId) return;
      if (!this.shouldUpdatedata()) {
        this.isUpdate = false;
        // End update
        return;
      }

      const { name, passport, date_of_birth } = this.newUserData;
      let data = {
        name: name,
        passport: passport
      };
      if (date_of_birth) data.date_of_birth = date_of_birth;

      AccountService.changeUserInfo(this.accountId, data)
        .then((resp) => {
          let result = resp.data.data;
          if (resp.data.status) {
            let timerSet = resp.data.data.date_of_birth;
            if (timerSet) {
              timerSet = new CustomDate(timerSet);
            }
            data = { ...result, date_of_birth: timerSet, lastName: result.name ? result.name.split(' ').pop() : '' };
            this.getUserInfo = data;
            // End update
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isUpdate = false;
        });
    },
    setModalPaymentSuccess(newVal, payload = {}) {
      this.infoFormValidated = true;
      this.isOpenModalPaymentSuccess = newVal;
      this.payloadGameTrial = payload;
    },

    checkFormDataMobile() {
      this.$bus.$emit('checkForm', true);
      this.$bus.$off('resultCheckForm');

      this.$bus.$on('resultCheckForm', (value) => {
        this.infoFormValidated = value;
      });
    },
    checkLogin(isLogin) {
      this.$store.commit('setCheckLoginAfterBuyGame', false);
      if (!this.loggedIn) {
        this.$store.commit('setCheckLoginBuyGame', true);
        this.$store.commit('setRedirectAfterLogin', null);
        this.$store.commit('setModalLogin', true);
      }
      return isLogin;
    },
    toggleCensorStatus(key, value) {
      this.censorStatus = { ...this.censorStatus, [key]: value };
    }
  },
  mounted() {},
  watch: {
    isUpdate: function(newVal) {
      if (newVal) {
        this.$bus.$emit('checkForm', true);
      }
    },
    listUserReceiver: function(newVal, oldVal) {
      if (newVal != oldVal) {
        this.checkFormDataMobile();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.modalPaymentSuccessTrial {
  &__image {
    margin-top: 70px;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 3s linear infinite;
  }
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  &__timer {
    margin-top: 1rem;
    font-size: 2.5rem;
    color: $primaryColor;
  }
}
.modalPaymentSuccess {
  padding-top: 20px;
  &__btnUpdate {
    width: 100%;
    height: 40px;
    border-radius: 3px;
    background-color: $primaryColor;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: #fff;

    &:hover {
      color: #fff;
      background-color: $primaryColorDarker;
    }
  }

  &__description {
    margin-bottom: 20px;

    img,
    p {
      margin-bottom: 10px;
    }

    button.btn {
      width: 50%;
      height: 40px;
      border-radius: 3px;
      background-color: #00509d;
      color: #fff;

      &.customBtn {
        background-color: #e5edf5;
        color: #00509d;
      }
    }
  }
}
.boxTicketHeader {
  justify-content: space-between;
  margin-bottom: 5px;
}
.boxTicketRecipientInfo {
  padding: 10px 11px 10px 10px;
  border-radius: 3px;
  background-color: #f7f7f7;
  flex-direction: column;

  .recipientInfo {
    flex-direction: row;
  }
}

.moreInfo {
  text-align: left;
  font-style: italic;
  font-size: 15px;
  color: #666666;
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .modalPaymentSuccess {
    &__btnUpdate {
      width: 182px;
      margin: 0 auto;
    }
  }
}
</style>

<style lang="scss">
.modalPaymentCustom {
  max-width: 410px !important;
}
</style>
