<template>
  <div class="censorData">
    <div v-if="isShow">
      <span class="censorData__data">{{ data }}</span>
      <img src="@/assets/img/icon/icon_showpass_off.svg" alt="" class="icon-censor" @click="toggleShow(false)" />
    </div>
    <div v-else class="censorData__data">
      <span>{{ censorData(data, showLength, fixedLength) }}</span
      ><img src="@/assets/img/icon/icon_showpass_on.svg" alt="" class="icon-censor" @click="toggleShow(true)" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: String,
      default: ''
    },
    showLength: {
      type: Number,
      default: 4
    },
    fixedLength: {
      type: Number,
      default: null
    },
    defaultState: {
      type: Boolean,
      default: false
    },
    dataKey: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isShow: this.defaultState
    };
  },
  created() {
    this.handleDatakey();
  },
  methods: {
    toggleShow(value) {
      this.isShow = value;
    },
    handleDatakey() {
      if (this.dataKey === 'wallet' && this.userCampaignConfig.show_wallet) {
        this.isShow = true;
      }
    }
  },
  watch: {
    dataKey() {
      this.handleDatakey();
    },
    userCampaignConfig() {
      this.handleDatakey();
    }
  }
};
</script>

<style lang="scss" scoped>
.censorData {
  & > div {
    display: flex;
    align-items: center;
  }
  &__data {
    white-space: nowrap;
  }
}
</style>
