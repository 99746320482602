const state = {
  config: null,
  partnerData: null
};

const getters = {
  getterConfig(state) {
    return state.config || {};
  },
  getterPartnerData(state) {
    return state.partnerData || {};
  },
  getterExcludeFeature(state) {
    return state.config?.exclude_feature || [];
  }
};

const actions = {};

const mutations = {
  setConfig(state, value) {
    state.config = value;
  },
  setPartnerData(state, value) {
    state.partnerData = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
