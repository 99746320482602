/* eslint-disable import/no-duplicates */
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const countDown = {
  namespaced: true,
  state: {
    currentTimestamp: null,
    megaHomeTimer: 0,
    powerHomeTimer: 0,
    max3DHomeTimer: 0,
    max3DProHomeTimer: 0,
    xsmnHomeTimer: 0,
    max4DHomeTimer: 0,
    mega: {},
    power: {},
    max3d: {},
    max4d: {},
    xsmn: {},
    cartLotteryTime: undefined,
    cartTimer: 0
  },
  getters,
  mutations,
  actions
};

export default countDown;
