import axios from 'axios';
import Vue from 'vue';
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);
import store from '../store/store';
import router from '../js/router';
import { Noti } from '../main';
import { NOTY_BASE_URL_API, BASE_URL_API, COMMON_SERVICE_API } from './global';

const Axios = axios.create({
  baseURL: BASE_URL_API
});

const AxiosNoty = axios.create({
  baseURL: NOTY_BASE_URL_API
});
AxiosNoty.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : null;
    if (status === 403) {
      if (router.currentRoute.meta.requiresAuth) {
        store.commit('setRouterExpire', { isChecked: true, isText: true });
        store.commit('setRedirectAfterLogin', null);
        store.commit('setModalLogin', true);
        store.commit('setLocationHome', true);
      }
      store.dispatch('destroyToken');
      if (error.response.data?.data?.code === '002.001.032') {
        Noti.fire({
          icon: 'warning',
          title: error.response.data?.data?.message,
          confirmButtonText: 'Đóng'
        });
      }
    }
    return Promise.reject(error);
  }
);

AxiosNoty.defaults.headers.common['Authorization'] = Vue.$cookies.get('access_token') || '';
AxiosNoty.defaults.headers.common['X-Timezone'] = (new Date().getTimezoneOffset() / 60) * -1;

Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : null;
    if (status === 403) {
      if (router.currentRoute.meta.requiresAuth) {
        store.commit('setRouterExpire', { isChecked: true, isText: true });
        store.commit('setRedirectAfterLogin', null);
        store.commit('setModalLogin', true);
        store.commit('setLocationHome', true);
      }
      store.dispatch('destroyToken');
    }
    if (status == null) {
      store.commit('setServerStatus', false);
    }

    if (error.response.data?.data?.code === '002.001.032') {
      Noti.fire({
        icon: 'warning',
        title: error.response.data?.data?.message,
        confirmButtonText: 'Đóng'
      });
    }

    return Promise.reject(error);
  }
);

Axios.defaults.headers.common['Authorization'] = Vue.$cookies.get('access_token') || '';
Axios.defaults.headers.common['Access-Control-Expose-Headers'] = '*';
Axios.defaults.headers.common['X-Timezone'] = (new Date().getTimezoneOffset() / 60) * -1;

const AxiosCommonSerice = axios.create({
  baseURL: COMMON_SERVICE_API
});

AxiosCommonSerice.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : null;
    if (status === 403) {
      if (router.currentRoute.meta.requiresAuth) {
        store.commit('setRouterExpire', { isChecked: true, isText: true });
        store.commit('setRedirectAfterLogin', null);
        store.commit('setModalLogin', true);
        store.commit('setLocationHome', true);
      }
      store.dispatch('destroyToken');

      if (error.response.data?.data?.code === '002.001.032') {
        Noti.fire({
          icon: 'warning',
          title: error.response.data?.data?.message,
          confirmButtonText: 'Đóng'
        });
      }
    }
    if (status == null) {
      store.commit('setServerStatus', false);
    }
    return Promise.reject(error);
  }
);

AxiosCommonSerice.defaults.headers.common['Authorization'] = Vue.$cookies.get('access_token') || '';
AxiosCommonSerice.defaults.headers.common['X-Timezone'] = (new Date().getTimezoneOffset() / 60) * -1;

export { Axios, AxiosNoty, AxiosCommonSerice };
