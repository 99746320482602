<template>
  <b-modal
    headerClass="border-bottom-0"
    modal-class="modalRegister modalRegisterRep"
    :content-class="{ loadingModal: isLoading }"
    :dialog-class="{ modaDialogAppLogin: isApp }"
    no-close-on-backdrop
    no-close-on-esc
    @shown="handleShownModalLogin"
    @hide="onCloseModal"
    v-model="modalLoginData"
    centered
  >
    <template v-slot:modal-title>
      <p class="d-block pointer font-20 modalRegisterHeader">Đăng nhập</p>
    </template>

    <div class="d-flex flex-column justify-content-between w-100 modalRegisterContent mb-lg-1">
      <div class="h-100 w-100">
        <p class="text-center" v-html="textExpire">
          <!-- Phiên đăng nhập đã hết hạn.<br />
          Vui lòng đăng nhập lại để tiếp tục. -->
        </p>
        <!-- Bạn cần đăng nhập để mua vé. -->
        <!-- <a
          v-if="$route.name != 'intro' && objOauth2.isLogin && !checkHideAssociateProduction"
          @click="onLoginAssociate()"
          class="btn w-100 text-uppercase btnConfirm btnConfirm__green position-relative mb-2"
        >
          Đăng nhập với Save Extra
        </a> -->
        <p v-if="objOauth2.isTextError" class="text-danger-custom text-center font-italic">{{ objOauth2.textError }}</p>
      </div>
      <div>
        <form ref="form">
          <b-form-group>
            <label for="phoneNumberInput">Số điện thoại</label>
            <b-form-input
              v-validate="{ min: 10, max: 10, regex: /(03|07|08|09|05)+([0-9]{8})\b/, required: true }"
              :class="{ input: true, 'is-danger': errors.has('phoneNumber') }"
              ref="inputs"
              name="phoneNumber"
              v-model="phoneNumber"
              id="phoneNumberInput"
              data-vv-as="Số điện thoại"
              type="number"
              pattern="[0-9]*"
            ></b-form-input>
            <span v-show="errors.has('phoneNumber')" class="is-danger-text position-relative">{{
              errors.first('phoneNumber')
            }}</span>
          </b-form-group>
          <b-form-group>
            <label for="passwordInput">Mật khẩu</label>
            <div class="position-relative">
              <b-form-input
                v-model="password"
                data-vv-as="Mật khẩu"
                :class="{ input: true, 'is-danger': errors.has('passwordUser') }"
                v-validate="'required|min:8|max:16'"
                id="passwordInput"
                name="passwordUser"
                aria-describedby="password-help-block"
                :type="typePassword"
              ></b-form-input>
              <UIShowPassword @handlerShowPassword="handlerShowPassword" :typePassword="typePassword"> </UIShowPassword>
            </div>
            <span v-show="errors.has('passwordUser')" class="is-danger-text position-relative">
              {{ errors.first('passwordUser') }}
            </span>
          </b-form-group>
          <div class="d-flex justify-content-between align-items-center mt-lg-3 modalLoginForgotPass">
            <div class="d-flex align-items-center">
              <div
                class="checkbox-custom-input-wrapper rememberPassword mr-2"
                :class="{
                  checked: rememberPassword
                }"
              >
                <input
                  id="input-remember"
                  class="checkbox-hidden-input"
                  :class="{
                    'checkbox-custom-input-wrapper--checked': rememberPassword
                  }"
                  type="checkbox"
                  :checked="rememberPassword"
                  @click="rememberPassword = !rememberPassword"
                />
                <span
                  v-if="rememberPassword"
                  class="checkbox-custom-input"
                  :class="{
                    'checkbox-custom-input--checked': rememberPassword
                  }"
                  >&#x2713;</span
                >
              </div>
              <label for="input-remember" class="w-100 mb-0">Lưu đăng nhập</label>
            </div>
            <a @click="openForgotPassword" class="forgot-password float-right pointer">Quên mật khẩu?</a>
          </div>
          <div class="h-100 modalRegisterFooter">
            <div class="justify-content-center">
              <div class="w-100">
                <button
                  id="captcha-container"
                  type="submit"
                  @click.prevent="login()"
                  class="btn w-100 text-uppercase btnConfirm position-relative"
                  :class="{ 'opacity-7 cursor-none': isLoading }"
                >
                  <!-- <div v-show="isLoading" class="isLoading">
                <div class="lds-dual-ring"></div>
                  </div>-->
                  <span>Đăng nhập</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <div class="h-100 modalRegisterFooter">
        <div class="justify-content-center">
          <div class="w-100"></div>
        </div>

        <div>
          <p class="modalRegisterFooterTxt">
            Bằng cách tiếp tục, bạn sẽ đồng ý rằng đã đọc và hiểu các
            <router-link :target="isApp ? `_self` : `_blank`" to="/thong-tin/dieu-khoan-su-dung"
              >Điều khoản sử dụng</router-link
            >
            và
            <router-link :target="isApp ? `_self` : `_blank`" to="/thong-tin/chinh-sach-quyen-rieng-tu"
              >Chính sách quyền riêng tư</router-link
            >.
          </p>
        </div>
        <div class="text-center mb-4">
          <p class="mb-2">
            Bạn chưa có tài khoản?
          </p>
          <button @click="openRegister" type="submit" class="btn text-uppercase btnRemove font-15">
            Đăng ký
          </button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import AccountService from '../../api/account';
import { eventTypeMessage } from '../../common/global';
import UIShowPassword from '../../components/UI/UIShowPassword';
export default {
  components: {
    UIShowPassword
  },
  data() {
    return {
      userNameErrorCode: ['002.001.016'],
      isLoading: false,
      phoneNumber: '',
      password: '',
      rememberPassword: false,
      typePassword: 'password'
    };
  },
  computed: {
    objOauth2: {
      get() {
        return this.$store.getters.getterObjOauth2;
      },
      set(newVal) {
        this.$store.commit('setObjOauth2', newVal);
      }
    },
    isApp() {
      return this.$store.getters.getterIsApp;
    },
    textExpire: {
      get() {
        return this.$store.getters.getterTextExpire;
      },
      set(newVal) {
        this.$store.commit('setRouterExpire', newVal);
      }
    },
    modalLoginData: {
      get() {
        if (this.isWeluckMode) {
          return false;
        }
        return this.$store.getters.getterValLogin;
      },
      set(newVal) {
        this.$store.commit('setModalLogin', newVal);
      }
    },
    isOpenModalForWeluck() {
      return this.$store.getters.getterValLogin;
    },
    redirectAfterLogin() {
      return this.$store.getters.getterRedirectAfterLogin;
    },
    parentUserInfo() {
      return this.$store.state.parentUserInfo;
    }
  },
  created() {
    if (this.isOpenModalForWeluck) {
      this.sendMessageToParent(eventTypeMessage.HANDLE_MODAL_LOGIN, this.isOpenModalForWeluck);
    }
  },
  methods: {
    async onLoginAssociate() {
      this.isLoading = true;
      let data = await this.onAssociateOauth(
        window.location.origin,
        this.objOauth2.decodeUrlSaveextra,
        this.$store.getters.getterTokenInfoOauth2,
        this.$store.getters.getterInfoOauth2,
        true
      );
      if (data) {
        this.modalLoginData = false;
        this.isLoading = false;
      }
    },
    handlerShowPassword(event) {
      this.typePassword = event;
    },
    openForgotPassword() {
      this.$store.commit('setModalForgotPassword', true);
      this.onCloseModal();
    },
    openRegister() {
      this.$store.commit('setModalRegister', true);
      this.$store.commit('setRouterExpire', { isChecked: false, isText: false });
      this.onCloseModal();
    },
    onCloseModal(isRedirect) {
      this.phoneNumber = '';
      this.password = '';
      this.rememberPassword = false;
      this.isLoading = false;
      this.modalLoginData = false;
      this.typePassword = 'password';
      if (this.$store.getters.getterLocationHome === true) {
        // location.href = '/';
        if (this.$route && this.$route.name === 'intro-profile') {
          this.$router.push('/mua-ho-concept');
          return;
        }
        this.$router.push('/');
      }
      if (this.$store.getters.getterRouterExpire) {
        this.$store.commit('setRouterExpire', { isChecked: false, isText: false });
        // window.location.reload();
        if (!isRedirect) {
          if (this.$route.name && this.$route.name === 'intro-profile') {
            this.$router.push('/mua-ho-concept');
            return;
          }
          this.$router.push('/');
        }
      }
    },
    handleShownModalLogin() {
      this.$refs.inputs.focus();
    },
    login() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$store.commit('setLocationHome', false);
          this.isLoading = true;
          let infoUser = {
            phone_number: this.phoneNumber,
            password: this.password,
            remember_password: this.rememberPassword
          };
          this.retrieveLogin(infoUser);
        }
      });
    },
    retrieveLogin(infoUser) {
      this.$store
        .dispatch('retrieveToken', {
          ...infoUser
        })
        .then(async () => {
          this.modalLoginData = false;
          this.onAfterLogin();
          window.storageHelper.setItem('_clear-login', false);
          let data = await this.getUserInfoLogin(AccountService);
          if (data) {
            this.onCloseModal(true);
          }
          this.$store.dispatch('updateTrackingId');
          this.onUpdateLinkAccount(2000);
          if (this.redirectAfterLogin) this.$router.push(this.redirectAfterLogin);
        })
        .catch((e) => {
          let code = e?.response?.data?.data?.code;
          if (code) {
            let field = 'passwordUser';
            if (this.userNameErrorCode.includes(code)) {
              field = 'phoneNumber';
            }
            this.errors.add({
              field,
              msg: e.response.data.data.message
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  },
  watch: {
    modalLoginData: function(newVal) {
      if (this.isAllowPopupOverflowIframe) {
        if (newVal) {
          this.isExistModalOpen++;
        } else {
          this.$nextTick().then(() => {
            this.isExistModalOpen--;
          });
        }
      }

      if (newVal) {
        const { phone } = this.parentUserInfo;
        if (phone) {
          this.phoneNumber = phone;
        }

        const { phone_number } = this.$route.query;
        if (phone_number) {
          this.phoneNumber = phone_number;
        }
      }
    },
    isOpenModalForWeluck: function(newValue) {
      this.sendMessageToParent(eventTypeMessage.HANDLE_MODAL_LOGIN, newValue);
    }
  }
};
</script>

<style lang="scss" scoped>
.btnRemove {
  background-color: #e5edf5;
  color: #00509d;
  font-weight: 600;
  padding: 0.45rem 1rem;
  transition: 0.3s all;
  height: 40px;
  width: 100%;
  &:hover {
    background-color: #c8d9ea;
    color: #00509d;
  }
}
.modalRegisterFooterTxt > a {
  color: #0d234a;
  cursor: pointer;
}
.modalRegisterContent {
  .modalRegisterLabel {
    .modalRegisterLabelRight {
      font-style: italic;
      font-size: 14px;
    }
    .modalRegisterLabelPass {
      margin-top: 16px;
    }
  }
  input::-webkit-input-placeholder {
    font-size: 15px;
    line-height: 3;
    color: #999999;
  }
  p {
    font-size: 15px;
  }
}
.modalRegisterFooter {
  p {
    font-size: 14px;
    a {
      text-decoration: underline;
    }
  }
}
</style>
