<template>
  <div>
    <div class="headerPromo">
      <button @click="handlerUpdateModal" class="close">×</button>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    handlerUpdateModal() {
      this.$emit('handlerUpdateModal');
    }
  }
};
</script>
