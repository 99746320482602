import GameAppService from '@/api/game';
import { randomIntFromInterval } from '@/utils/functions';
import _ from 'lodash';
import { checkConditionalTtbs } from '../utils/functions';

const state = {
  power: {
    number: [],
    schema: []
  },
  mega: {
    number: [],
    schema: []
  },
  isLoadingTtbs: true
};

const getters = {};
const mutations = {
  setIsLoading(state, value) {
    state.isLoadingTtbs = value;
  },
  setTtbsData(state, data) {
    state[data.handler] = data.value;
  }
};

const actions = {
  fetchTtbs({ commit, state }, { id, handler }) {
    return new Promise((resolve, reject) => {
      if (state[handler]?.number?.length) {
        return resolve();
      }

      GameAppService.getTtbs(id)
        .then((res) => {
          if (res.status) {
            commit('setTtbsData', {
              handler,
              value: res.data
            });
            resolve();
          }
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          commit('setIsLoading', false);
        });
    });
  },
  getTtbs({ state }, data) {
    const { handler, quantity } = data;

    try {
      if (!handler || !state[handler]) {
        return [];
      }

      let { number, schema } = JSON.parse(JSON.stringify(state[handler]));

      let listAllNumberTtbs = number.flat().map(Number);

      if (listAllNumberTtbs?.length < 6) {
        throw new Error('NOT ENOUGH NUMBERS');
      }

      if (quantity === 5) {
        let randomList = _.sampleSize(listAllNumberTtbs, quantity);
        return [..._.orderBy(randomList)];
      }

      schema = schema.filter((schemaItem) => schemaItem.split(' ').length === quantity);
      // console.log(quantity, schema);

      const randomSchema = randomIntFromInterval(0, schema.length - 1);
      const chooseSchema = schema[randomSchema].split(' ');

      // console.log('schema[randomSchema]', schema[randomSchema]);

      let resultNumber = [];
      chooseSchema.forEach((item) => {
        const indexNumber = item[0];

        let isRetry = true;
        let maxLoop = 10;
        while (isRetry) {
          if (maxLoop < 0) {
            throw new Error('MAXIMUM LOOP CHOOSE NUMBER');
          }

          maxLoop--;

          if (!number[indexNumber].length) {
            continue;
          }

          const randomIndexNumber = randomIntFromInterval(0, number[indexNumber].length - 1);

          const chooseNumber = number[indexNumber].splice(randomIndexNumber, 1);
          const checkValidNumber = checkConditionalTtbs([...resultNumber, ...chooseNumber]);

          // console.log('checkValidNumber', checkValidNumber, [...resultNumber, ...chooseNumber]);

          if (checkValidNumber) {
            resultNumber.push(...chooseNumber);
            isRetry = false;
          }
        }
      });

      return resultNumber.map(Number);
    } catch (error) {
      console.error('getTtbs', error);

      let listAllNumber = [...Array(handler === 'power' ? 55 : 45)].map((x, index) => {
        return index + 1;
      });

      let data = _.sampleSize(listAllNumber, quantity);
      return [..._.orderBy(data)];
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
