import { Axios } from '../common/config';

export const HealthCheckServices = {
  check() {
    return Axios.get('/health-check')
      .then((resp) => {
        CustomDate.offset = new Date(resp.headers.date).getTime() - Date.now();
        return resp.status == 200;
      })
      .catch(() => {
        return false;
      });
  }
};
export default HealthCheckServices;
